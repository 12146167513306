import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccountService } from '@wilson/account';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Observable, catchError, map, of } from 'rxjs';
import { UsersSelectComponent } from '@wilson/non-domain-specific/account/account-helpers';

@Component({
  selector: 'wilson-assign-shifts-modal',
  templateUrl: './assign-shifts-modal.component.html',
  styleUrls: ['./assign-shifts-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    NzDividerModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    UsersSelectComponent,
  ],
})
export class AssignShiftsModalComponent implements OnInit {
  @Input() isSingleShift = false;

  protected userIdFormControl = new FormControl<string>('', [
    Validators.required,
  ]);

  userOptions$!: Observable<{ id: string; name: string }[]>;

  get selectedUserId() {
    return this.userIdFormControl.value;
  }

  get isInputValid() {
    return this.userIdFormControl.valid;
  }

  constructor(private readonly accountService: AccountService) {}

  ngOnInit(): void {
    this.userOptions$ = this.accountService.getUsers().pipe(
      map((users) => {
        const filterOptionUsers = users.map((user) => {
          return {
            id: user.id,
            name: user.lastName + ', ' + user.firstName,
          };
        });
        filterOptionUsers.sort((a, b) => a.name.localeCompare(b.name));
        return filterOptionUsers;
      }),
      catchError(() => of([])),
    );
  }
}

import { Injectable } from '@angular/core';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { ShiftId } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class UnassignedRegionVisibleShiftsStateService {
  @WilsonState(new Set())
  private visibleShiftIdsSet!: Set<ShiftId>;
  visibleShiftIdsSet$!: Observable<Set<ShiftId>>;

  updateVisibility(isVisible: boolean, shiftId: ShiftId) {
    let newSet: Set<string>;
    if (isVisible) {
      if (!this.visibleShiftIdsSet.has(shiftId)) {
        newSet = new Set(this.visibleShiftIdsSet);
        newSet.add(shiftId);
        this.visibleShiftIdsSet = newSet;
      }
    } else {
      if (this.visibleShiftIdsSet.has(shiftId)) {
        newSet = new Set(this.visibleShiftIdsSet);
        newSet.delete(shiftId);
        this.visibleShiftIdsSet = newSet;
      }
    }
  }
}

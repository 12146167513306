import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@Component({
  selector: 'wilson-necessary-stay-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, NzSwitchModule],
  templateUrl: './necessary-stay-toggle.component.html',
  styleUrl: './necessary-stay-toggle.component.scss',
})
export class NecessaryStayToggleComponent {
  @Input()
  currentShiftNecessaryStay = false;

  @Input()
  boldText = false;

  @Output()
  updatedNecessaryStay = new EventEmitter();
}

import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { AccountService } from '@wilson/account';
import {
  AbsenceWithCategory,
  AffectedShifts,
  AssignableShiftsWithDate,
  AssignableTasksWithDate,
  PublicationStatus,
  ShiftDispositionTask,
  ShiftInDateRangeDto,
  ShiftsFilters,
  ShiftWithActivitiesWithLocations,
  User,
  UserFilters,
  FindConditions,
} from '@wilson/interfaces';
import {
  SetDateRange,
  Reset,
  Initialize,
  SetFilters,
  FetchAssignedAbsences,
  FetchUnassignedShifts,
  FetchAllDataInView,
  AssignShift,
  UnassignShift,
  PublishShifts,
  UnpublishShifts,
  CleanOptimisticallyUpdatedShiftCache,
  FetchShiftValidations,
  FetchAssignedShiftsInView,
  UpdateViewableRowIndex,
  UpdateViewableRowCount,
  UpdateAssignedShiftsKPIs,
} from './shift-disposition.actions';
import { ShiftDispositionsDataService } from './shift-dispositions-data.service';
import { catchError, map, tap, filter, switchMap, first } from 'rxjs/operators';
import { endOfDay, startOfDay } from 'date-fns';
import { ShiftsService } from '@wilson/shifts';
import { AbsencesService } from '@wilson/absences';
import { cloneDeep, merge } from 'lodash';
import { ShiftDispositionStateHandlerService } from './shift-disposition-state-handler.service';
import { ShiftDispositionFilterService } from '../shift-disposition-filter.service';
import { EventCategory } from '@wilson/matomo';
import { MatomoTracker } from 'ngx-matomo-client';

export interface ShiftDispositionStateModel {
  absenceCategoriesFilter: string[];
  currentViewableRowIndex: number;
  dateRange: [Date, Date];
  groupsFilter: string[];
  isDoneLoadingUsers: boolean;
  isLoadingUnreleasedShifts: boolean;
  isLoadingAssignedShifts: boolean;
  isLoadingAssignedAbsences: boolean;
  largestViewedRowIndex: number;
  optimisticallyUpdatedShifts: AffectedShifts;
  orgUnitsFilter: string[];
  publicationStatusFilter: PublicationStatus[];
  shiftCategoryIdsFilter: string[];
  shiftPlansFilter: string[];
  shiftSearchTerm: string;
  sortedUsers: User[];
  unassignedShifts: AssignableShiftsWithDate[];
  unreleasedShifts: ShiftWithActivitiesWithLocations[];
  userIdsFilter: string[];
  userRoleIdsFilter: string[];
  userSearchTerm: string;
  viewableAssignedAbsences: AbsenceWithCategory[];
  viewableAssignedShifts: ShiftWithActivitiesWithLocations[];
  viewableAssignedTasks: ShiftDispositionTask;
  viewableRowCount: number;
}

const defaults: ShiftDispositionStateModel = {
  absenceCategoriesFilter: [],
  currentViewableRowIndex: 0,
  dateRange: [new Date(), new Date()],
  groupsFilter: [],
  isDoneLoadingUsers: false,
  isLoadingUnreleasedShifts: false,
  isLoadingAssignedShifts: false,
  isLoadingAssignedAbsences: false,
  largestViewedRowIndex: 0,
  optimisticallyUpdatedShifts: {},
  orgUnitsFilter: [],
  publicationStatusFilter: [],
  shiftCategoryIdsFilter: [],
  shiftPlansFilter: [],
  shiftSearchTerm: '',
  sortedUsers: [],
  unassignedShifts: [],
  unreleasedShifts: [],
  userIdsFilter: [],
  userRoleIdsFilter: [],
  userSearchTerm: '',
  viewableAssignedAbsences: [],
  viewableAssignedShifts: [],
  viewableAssignedTasks: {},
  viewableRowCount: 22,
};

@State<ShiftDispositionStateModel>({
  name: 'shiftDisposition',
  defaults,
})
@Injectable()
export class ShiftDispositionState {
  constructor(
    private store: Store,
    private accountService: AccountService,
    private readonly shiftsService: ShiftsService,
    private readonly absencesService: AbsencesService,
    private shiftDispositionsDataService: ShiftDispositionsDataService,
    private shiftDispositionStateHandlerService: ShiftDispositionStateHandlerService,
    private shiftDispositionFilterService: ShiftDispositionFilterService,
    private readonly tracker: MatomoTracker,
  ) {}

  @Selector()
  static isLoadingUnreleasedShifts(state: ShiftDispositionStateModel): boolean {
    return state.isLoadingUnreleasedShifts;
  }

  @Selector()
  static largestViewedRowIndex(state: ShiftDispositionStateModel): number {
    return state.largestViewedRowIndex;
  }

  @Selector()
  static viewableRowCount(state: ShiftDispositionStateModel): number {
    return state.viewableRowCount;
  }

  @Selector()
  static viewableUsers(state: ShiftDispositionStateModel): User[] {
    return ShiftDispositionStateHandlerService.calculateViewableUsers(
      state.sortedUsers,
      state.largestViewedRowIndex,
      state.viewableRowCount,
    );
  }

  @Selector()
  static isLoadingAssignedShiftsAndAbsences(state: ShiftDispositionStateModel) {
    return state.isLoadingAssignedAbsences && state.isLoadingAssignedShifts;
  }

  @Selector()
  static assignedShifts(
    state: ShiftDispositionStateModel,
  ): ShiftWithActivitiesWithLocations[] {
    return state.viewableAssignedShifts;
  }

  @Selector()
  static isDoneLoadingUsers(state: ShiftDispositionStateModel): boolean {
    return state.isDoneLoadingUsers;
  }

  @Selector()
  static users(state: ShiftDispositionStateModel): User[] {
    return state.sortedUsers;
  }

  @Selector()
  static dateRange(state: ShiftDispositionStateModel): [Date, Date] {
    return state.dateRange;
  }

  @Selector()
  static userFilters(state: ShiftDispositionStateModel): UserFilters {
    return {
      orgUnits: state.orgUnitsFilter,
      searchTerm: state.userSearchTerm,
      userRoleIds: state.userRoleIdsFilter,
      userIds: state.userIdsFilter,
    };
  }

  @Selector()
  static assignedTasks(
    state: ShiftDispositionStateModel,
  ): ShiftDispositionTask {
    return state.viewableAssignedTasks;
  }

  @Selector()
  static unassignedShifts(
    state: ShiftDispositionStateModel,
  ): AssignableTasksWithDate[] {
    return state.unassignedShifts;
  }

  @Selector()
  static shiftFilters(state: ShiftDispositionStateModel): ShiftsFilters {
    return {
      orgUnits: state.orgUnitsFilter,
      shiftCategoryIds: state.shiftCategoryIdsFilter,
      publicationStatus: state.publicationStatusFilter,
      absenceCategories: state.absenceCategoriesFilter,
      groups: state.groupsFilter,
      shiftPlans: state.shiftPlansFilter,
    };
  }

  @Selector()
  static areAllFiltersEmpty(state: ShiftDispositionStateModel): boolean {
    return (
      ShiftDispositionState.areAllShiftFiltersEmpty(state) &&
      ShiftDispositionState.areAllUserFiltersEmpty(state)
    );
  }

  @Selector()
  static areAllShiftFiltersEmpty(state: ShiftDispositionStateModel): boolean {
    const {
      orgUnits,
      shiftCategoryIds,
      publicationStatus,
      absenceCategories,
      groups,
      shiftPlans,
    } = ShiftDispositionState.shiftFilters(state);

    return (
      !orgUnits.length &&
      !shiftCategoryIds.length &&
      !publicationStatus.length &&
      !absenceCategories.length &&
      !groups.length &&
      !shiftPlans.length
    );
  }

  @Selector()
  static areAllUserFiltersEmpty(state: ShiftDispositionStateModel): boolean {
    const { orgUnits, searchTerm, userRoleIds, userIds } =
      ShiftDispositionState.userFilters(state);

    return (
      !orgUnits?.length &&
      !searchTerm &&
      !userRoleIds?.length &&
      !userIds?.length
    );
  }

  @Selector()
  static filteredUnreleasedShifts(
    state: ShiftDispositionStateModel,
  ): ShiftWithActivitiesWithLocations[] {
    if (state.userIdsFilter.length) {
      return state.unreleasedShifts.filter(
        (shift) => state.userIdsFilter.indexOf(shift.userId as string) > -1,
      );
    } else {
      return state.unreleasedShifts;
    }
  }

  @Action(UpdateViewableRowCount)
  updateViewableRowCount(
    { patchState }: StateContext<ShiftDispositionStateModel>,
    { payload }: UpdateViewableRowCount,
  ) {
    patchState({
      viewableRowCount: payload.count,
    });
  }

  @Action(UpdateViewableRowIndex)
  updateViewableRowIndex(
    {
      patchState,
      getState,
      dispatch,
    }: StateContext<ShiftDispositionStateModel>,
    { payload }: UpdateViewableRowIndex,
  ) {
    const state = getState();

    patchState({
      currentViewableRowIndex: payload.index,
    });

    if (payload.index > state.largestViewedRowIndex) {
      patchState({
        largestViewedRowIndex: payload.index,
      });
      dispatch(new FetchAllDataInView());
    }
  }

  @Action(SetFilters)
  setFilters(
    {
      patchState,
      getState,
      dispatch,
    }: StateContext<ShiftDispositionStateModel>,
    { payload }: SetFilters,
  ) {
    const state = getState();

    const nextAction: UpdateViewableRowIndex | FetchAllDataInView | null =
      this.shiftDispositionStateHandlerService.handleFiltersChangeEvent(
        payload,
        {
          orgUnitsFilter: state.orgUnitsFilter,
          shiftCategoryIdsFilter: state.shiftCategoryIdsFilter,
          publicationStatusFilter: state.publicationStatusFilter,
          absenceCategoriesFilter: state.absenceCategoriesFilter,
          groupsFilter: state.groupsFilter,
          shiftPlansFilter: state.shiftPlansFilter,
          userSearchTerm: state.userSearchTerm,
          userRoleIdsFilter: state.userRoleIdsFilter,
          userIdsFilter: state.userIdsFilter,
        },
        state.largestViewedRowIndex,
        state.sortedUsers.length,
        patchState,
      );

    if (nextAction) {
      dispatch(nextAction);
    }
  }

  @Action(Initialize)
  initialize({ patchState }: StateContext<ShiftDispositionStateModel>) {
    patchState({
      isDoneLoadingUsers: false,
    });

    return this.accountService.getUsers().pipe(
      tap((users: User[]) => {
        patchState({
          sortedUsers: this.shiftDispositionFilterService.sortUserByName(users),
          isDoneLoadingUsers: true,
        });
      }),
    );
  }

  @Action(SetDateRange)
  setDateRange(
    { patchState, dispatch }: StateContext<ShiftDispositionStateModel>,
    { payload }: SetDateRange,
  ) {
    patchState({ dateRange: payload });
    dispatch(new FetchAllDataInView());
    dispatch(new UpdateAssignedShiftsKPIs());
  }

  @Action(UpdateAssignedShiftsKPIs, {
    cancelUncompleted: true,
  })
  UpdateAssignedShiftsKPIs({
    patchState,
    getState,
  }: StateContext<ShiftDispositionStateModel>) {
    patchState({
      isLoadingUnreleasedShifts: true,
    });

    return this.store.select(ShiftDispositionState.isDoneLoadingUsers).pipe(
      filter((isDone) => !!isDone),
      switchMap(() => {
        const state = getState();
        return this.shiftDispositionStateHandlerService
          .createFetchAssignedShiftsRequest(state, true)
          .pipe(catchError(() => []))
          .pipe(
            first(),
            tap((assignedShifts: ShiftWithActivitiesWithLocations[]) => {
              const unreleasedShifts =
                this.shiftDispositionFilterService.findUnreleasedShifts(
                  assignedShifts,
                  state.dateRange[0],
                );

              patchState({
                unreleasedShifts,
                isLoadingUnreleasedShifts: false,
              });
            }),
          );
      }),
    );
  }

  @Action([FetchUnassignedShifts, FetchAllDataInView], {
    cancelUncompleted: true,
  })
  fetchUnassignedShifts({
    patchState,
    getState,
  }: StateContext<ShiftDispositionStateModel>) {
    const state: ShiftDispositionStateModel = getState();
    const startDateStartOfDay = startOfDay(state.dateRange[0]);
    const endDateEndOfDay = endOfDay(state.dateRange[1]);
    const shiftFilters = ShiftDispositionState.shiftFilters(state);
    const relations = [
      `activities.endLocation`,
      `activities.startLocation`,
      `activities`,
    ];
    const lookup: FindConditions<ShiftInDateRangeDto> = {
      startDate: startDateStartOfDay.toISOString(),
      endDate: endDateEndOfDay.toISOString(),
    };

    return this.shiftsService
      .getInDateRange(
        shiftFilters.orgUnits?.length
          ? {
              ...lookup,
              organizationalUnitId: shiftFilters.orgUnits,
              userId: undefined,
            }
          : {
              ...lookup,
              userId: undefined,
            },
        relations,
      )
      .pipe(
        map((list) => list.map((shift) => ({ shift }))),
        tap((shifts) => {
          const processedData =
            this.shiftDispositionsDataService.processAssignables(shifts, []);
          patchState({
            unassignedShifts: processedData.assignableShifts,
          });
        }),
      );
  }

  @Action([FetchAssignedShiftsInView, FetchAllDataInView], {
    cancelUncompleted: true,
  })
  fetchAssignedShifts({
    patchState,
    getState,
  }: StateContext<ShiftDispositionStateModel>) {
    return this.store.select(ShiftDispositionState.isDoneLoadingUsers).pipe(
      filter((isDone) => !!isDone),
      switchMap(() => {
        patchState({
          isLoadingAssignedShifts: true,
        });

        return this.shiftDispositionStateHandlerService
          .createFetchAssignedShiftsRequest(getState(), false)
          .pipe(catchError(() => []))
          .pipe(
            first(),
            tap((assignedShifts: ShiftWithActivitiesWithLocations[]) => {
              const latestAssignedAbsences = cloneDeep(
                getState().viewableAssignedAbsences,
              );

              this.shiftDispositionStateHandlerService.handleAssignedShiftsAndAbsencesResponse(
                this.shiftDispositionsDataService.transformToShiftObjectList(
                  assignedShifts,
                ),
                latestAssignedAbsences,
                patchState,
              );

              patchState({
                isLoadingAssignedShifts: false,
              });
            }),
          );
      }),
    );
  }

  @Action([FetchAssignedAbsences, FetchAllDataInView], {
    cancelUncompleted: true,
  })
  fetchAssignedAbsences({
    patchState,
    getState,
  }: StateContext<ShiftDispositionStateModel>) {
    patchState({
      isLoadingAssignedAbsences: true,
    });

    return this.store.select(ShiftDispositionState.isDoneLoadingUsers).pipe(
      filter((isDone) => !!isDone),
      switchMap(() => {
        const state: ShiftDispositionStateModel = getState();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lookup: FindConditions<any | ShiftInDateRangeDto> =
          this.shiftDispositionStateHandlerService.createUserIdLookUp(
            state.userIdsFilter,
            state.sortedUsers,
            state.largestViewedRowIndex,
            state.viewableRowCount,
          );
        const startDateStartOfDay = startOfDay(state.dateRange[0]);
        const endDateEndOfDay = endOfDay(state.dateRange[1]);

        return this.absencesService
          .getInDateRange(startDateStartOfDay, endDateEndOfDay, lookup)
          .pipe(catchError(() => []))
          .pipe(
            first(),
            tap((assignedAbsences: AbsenceWithCategory[]) => {
              const lastestAssignedShifts = cloneDeep(
                getState().viewableAssignedShifts,
              );

              this.shiftDispositionStateHandlerService.handleAssignedShiftsAndAbsencesResponse(
                this.shiftDispositionsDataService.transformToShiftObjectList(
                  lastestAssignedShifts,
                ),
                assignedAbsences,
                patchState,
              );

              patchState({
                isLoadingAssignedAbsences: false,
              });
            }),
          );
      }),
    );
  }

  @Action([FetchShiftValidations, FetchAllDataInView], {
    cancelUncompleted: true,
  })
  fetchShiftValidations({
    patchState,
    getState,
  }: StateContext<ShiftDispositionStateModel>) {
    return this.store.select(ShiftDispositionState.isDoneLoadingUsers).pipe(
      filter((isDone) => !!isDone),
      switchMap(() => {
        const state: ShiftDispositionStateModel = getState();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lookup: FindConditions<any | ShiftInDateRangeDto> =
          this.shiftDispositionStateHandlerService.createUserIdLookUp(
            state.userIdsFilter,
            state.sortedUsers,
            state.largestViewedRowIndex,
            state.viewableRowCount,
          );

        const startDateStartOfDay = startOfDay(state.dateRange[0]);
        const endDateEndOfDay = endOfDay(state.dateRange[1]);

        return this.shiftsService
          .getInDateRangeWithValidations(
            startDateStartOfDay,
            endDateEndOfDay,
            lookup,
          )
          .pipe(catchError(() => []))
          .pipe(
            tap((shiftValidationResult) => {
              const latestAssignedAbsences = cloneDeep(
                getState().viewableAssignedAbsences,
              );

              this.shiftDispositionStateHandlerService.handleAssignedShiftsAndAbsencesResponse(
                shiftValidationResult.data,
                latestAssignedAbsences,
                patchState,
              );
            }),
          );
      }),
    );
  }

  @Action(AssignShift)
  assignShift(
    { patchState, getState }: StateContext<ShiftDispositionStateModel>,
    { payload }: AssignShift,
  ) {
    const state = getState();

    const newAssignedShiftList =
      this.shiftDispositionsDataService.addAssignableShiftToAssignedList(
        state.viewableAssignedShifts,
        payload.shiftToAssign.record as ShiftWithActivitiesWithLocations,
        payload.user.id as string,
      );

    const processedData = this.shiftDispositionsDataService.processAssignables(
      newAssignedShiftList,
      state.viewableAssignedAbsences,
    );

    const unassignedShifts =
      this.shiftDispositionsDataService.removeAssignableShiftFromAssignableList(
        state.unassignedShifts,
        payload.shiftToAssign,
      );

    patchState({
      viewableAssignedTasks: processedData.assignedTasks,
      viewableAssignedShifts: processedData.assignedShifts,
      unassignedShifts,
    });
    this.tracker.trackEvent(
      EventCategory.Shift,
      'staff_assigned_from_shift_disposition',
    );
  }

  @Action(UnassignShift)
  unassignShift(
    { patchState, getState }: StateContext<ShiftDispositionStateModel>,
    { payload }: UnassignShift,
  ) {
    const state = getState();

    const newAssignedShiftList =
      this.shiftDispositionsDataService.removeAssignedShiftFromAssignedList(
        state.viewableAssignedShifts,
        payload.shiftsToUnassign,
      );

    const processedData = this.shiftDispositionsDataService.processAssignables(
      newAssignedShiftList,
      state.viewableAssignedAbsences,
    );

    const unassignedShifts =
      this.shiftDispositionsDataService.addAssignedShiftToAssignableList(
        state.unassignedShifts,
        payload.shiftsToUnassign,
      );

    patchState({
      viewableAssignedTasks: processedData.assignedTasks,
      viewableAssignedShifts: processedData.assignedShifts,
      unassignedShifts,
    });
  }

  @Action(PublishShifts)
  publishShifts(
    { patchState, getState }: StateContext<ShiftDispositionStateModel>,
    { payload }: PublishShifts,
  ) {
    const state = getState();

    const newAssignedShiftList =
      this.shiftDispositionsDataService.publishAssignedShifts(
        payload.shifts.map((shift) => shift.id as string),
        state.viewableAssignedShifts,
      );

    const processedData = this.shiftDispositionsDataService.processAssignables(
      newAssignedShiftList.newRecords,
      state.viewableAssignedAbsences,
    );

    patchState({
      viewableAssignedTasks: processedData.assignedTasks,
      viewableAssignedShifts: processedData.assignedShifts,
      optimisticallyUpdatedShifts: merge(
        cloneDeep(state.optimisticallyUpdatedShifts),
        newAssignedShiftList.affectedShifts,
      ),
    });
  }

  @Action(UnpublishShifts)
  unpublishShifts(
    {
      patchState,
      getState,
      dispatch,
    }: StateContext<ShiftDispositionStateModel>,
    { payload }: UnpublishShifts,
  ) {
    const state = getState();

    const newAssignedShiftList =
      this.shiftDispositionsDataService.unpublishAssignedShifts(
        payload.shifts.map((shift) => shift.id as string),
        state.viewableAssignedShifts,
        state.optimisticallyUpdatedShifts,
      );

    dispatch(
      new CleanOptimisticallyUpdatedShiftCache({ shifts: payload.shifts }),
    );

    const processedData = this.shiftDispositionsDataService.processAssignables(
      newAssignedShiftList,
      state.viewableAssignedAbsences,
    );

    patchState({
      viewableAssignedTasks: processedData.assignedTasks,
      viewableAssignedShifts: processedData.assignedShifts,
    });
  }

  @Action(CleanOptimisticallyUpdatedShiftCache)
  cleanOptimisticallyUpdatedShiftCache(
    { patchState, getState }: StateContext<ShiftDispositionStateModel>,
    { payload }: CleanOptimisticallyUpdatedShiftCache,
  ) {
    const state = getState();
    const updatedShiftsClone = cloneDeep(state.optimisticallyUpdatedShifts);

    payload.shifts.forEach(
      (shift) => delete updatedShiftsClone[shift.id as string],
    );

    patchState({
      optimisticallyUpdatedShifts: updatedShiftsClone,
    });
  }

  @Action(Reset)
  reset({ setState }: StateContext<ShiftDispositionStateModel>) {
    setState(defaults);
  }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-show-tooltip-renderer',
  template: `
    <div>
      <span
        class="text-dark"
        nz-tooltip
        [nzTooltipTitle]="cellValue | locationName"
        [nzTooltipColor]="'#fff'"
      >
        {{ cellValue | locationName }}
      </span>
    </div>
  `,
  styles: [
    `
      span {
        line-height: 5px;
      }
    `,
  ],
})
export class ShowTooltipRendererComponent implements AgRendererComponent {
  public cellValue!: string;

  async agInit(params: ICellRendererParams) {
    this.cellValue = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    return true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Project } from '@wilson/interfaces';

@Pipe({
  name: 'projectName',
})
export class ProjectNamePipe implements PipeTransform {
  transform(value: Project): string {
    return (
      value?.projectId + (value?.shortName ? ' - ' + value?.shortName : '')
    );
  }
}

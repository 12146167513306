import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-client-agreement-renderer',
  template: `<div class="agreement">
    <div class="text-dark text-truncate fw-normal clientName ">
      {{ clientName ?? '---' }}
    </div>
    <div class="fw-normal text-dark  lh-m">
      {{ agreementName ?? '' }}
    </div>
  </div>`,
  styles: [
    `
      .agreement {
        line-height: 1.5;
      }
      .clientName {
        font-size: 0.77rem;
        color: #585858;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAgreementRendererComponent
  implements ICellRendererAngularComp
{
  public agreementName!: string;
  public clientName!: string;

  async agInit(params: ICellRendererParams) {
    const agreement = params?.data?.agreementDetails;
    this.agreementName = agreement?.agreementName;
    this.clientName = agreement?.clientName;
  }

  refresh(params: ICellRendererParams) {
    const agreement = params?.data?.agreement;
    this.agreementName = agreement?.agreementName;
    this.clientName = agreement?.clientName;
    return true;
  }
}

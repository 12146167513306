import {
  EnvironmentInjector,
  Injectable,
  Pipe,
  PipeTransform,
  runInInjectionContext,
} from '@angular/core';
import {
  Activity,
  ActivitySelectionListItem,
  ActivityTemplate,
} from '@wilson/interfaces';
import { canSharedActivityBeEdited } from './check-shared-activity-edit-permission.fn';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'checkSharedActivityEditPermission',
  standalone: true,
})
export class CheckSharedActivityEditPermissionPipe implements PipeTransform {
  constructor(private injector: EnvironmentInjector) {}
  transform(
    activity: Activity | ActivityTemplate | ActivitySelectionListItem | string,
  ): boolean {
    return runInInjectionContext(this.injector, () =>
      canSharedActivityBeEdited(activity),
    );
  }
}

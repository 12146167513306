import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  ActivitySelectionListItem,
  ClientProposalResponse,
  RequestForProposal,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { groupBy } from 'lodash';

@Component({
  selector: 'wilson-wilson-share-job-link-renderer',
  templateUrl: './wilson-share-job-link-renderer.component.html',
  styleUrls: ['./wilson-share-job-link-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WilsonShareJobLinkRendererComponent
  implements ICellRendererAngularComp
{
  public orgUnitName: string;
  public requestForProposalId: string;
  public jobName: string;
  public jobId: string;
  public isMyActivity = false;
  public status!: ClientProposalResponse | null;
  public ClientProposalResponse = ClientProposalResponse;

  agInit(params: ICellRendererParams): void {
    this.init(params);
    this.orgUnitName = (
      params.data as ActivitySelectionListItem
    )?.jobOriginOrgUnit;
  }

  refresh(params: ICellRendererParams) {
    this.init(params);
    return true;
  }

  openInNewTabAndStopBubbling(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isMyActivity) {
      window.open(
        `${window.location.origin}/share/jobs/provider/${this.requestForProposalId}`,
        '_blank',
      );
    } else {
      window.open(
        `${window.location.origin}/share/jobs/client/${this.jobId}`,
        '_blank',
      );
    }
  }

  private init(params: ICellRendererParams) {
    const data = params.data as ActivitySelectionListItem;
    this.orgUnitName = data?.jobOriginOrgUnit;
    this.requestForProposalId = data?.requestForProposalId;
    this.isMyActivity = this.requestForProposalId ? true : false;
    this.jobId = data?.jobId;
    this.jobName = data?.jobName;
    this.status = this.getInterpretedJobStatus(data?.job?.requestsForProposals);
  }

  private getInterpretedJobStatus(
    requestsForProposals: RequestForProposal[] | undefined,
  ) {
    if (requestsForProposals?.length) {
      const groupedProposals = groupBy(
        requestsForProposals,
        'clientProposalResponse',
      );
      if (groupedProposals[ClientProposalResponse.Withdrawn]?.length) {
        return ClientProposalResponse.Withdrawn;
      }
      if (groupedProposals[ClientProposalResponse.Accepted]?.length) {
        return ClientProposalResponse.Accepted;
      }
      if (groupedProposals[ClientProposalResponse.Open]?.length) {
        return ClientProposalResponse.Open;
      }
      if (groupedProposals[ClientProposalResponse.AutoDeclined]?.length) {
        return ClientProposalResponse.AutoDeclined;
      }
      if (groupedProposals[ClientProposalResponse.Declined]?.length) {
        return ClientProposalResponse.Declined;
      }
    }
    return null;
  }
}

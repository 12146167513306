import { Injectable } from '@angular/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  ShiftQuickCheckValidate,
  ShiftValidationDetails,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs';

@Injectable()
export class ShiftQuickCheckService {
  private shiftValidationSubject = new BehaviorSubject<
    ShiftValidationDetails[]
  >([]);

  public shiftValidation$ = this.shiftValidationSubject.asObservable();

  constructor(
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    private readonly shiftsService: ShiftsService,
  ) {}

  async validateShiftForQuickCheck(shiftId: string, userId: string | null) {
    try {
      const isUserApprovalValidationEnabled = await firstValueFrom(
        this.featureFlagPurePipe.transform(
          'portal-shift-validation-user-approval',
        ),
      );
      const isUserProfessionValidationEnabled = await firstValueFrom(
        this.featureFlagPurePipe.transform(
          'portal-shift-validation-user-professions',
        ),
      );

      if (userId) {
        const shiftValidationDetails = await firstValueFrom(
          this.shiftsService.getShiftValidation(shiftId, userId).pipe(
            map((shiftValidations) => {
              return isUserApprovalValidationEnabled &&
                isUserProfessionValidationEnabled
                ? shiftValidations
                : this.filterShiftValidation(
                    shiftValidations,
                    isUserApprovalValidationEnabled,
                    isUserProfessionValidationEnabled,
                  );
            }),
          ),
        );

        this.shiftValidationSubject.next(shiftValidationDetails);
      } else {
        this.shiftValidationSubject.next([]);
      }
    } catch {
      this.shiftValidationSubject.next([]);
    }
  }

  filterShiftValidation(
    shiftValidations: ShiftValidationDetails[],
    isUserApprovalValidationEnabled: boolean,
    isUserProfessionValidationEnabled: boolean,
  ): ShiftValidationDetails[] {
    if (
      !isUserApprovalValidationEnabled &&
      !isUserProfessionValidationEnabled
    ) {
      return shiftValidations.filter(
        (shiftValidation) =>
          shiftValidation.checkName !==
            ShiftQuickCheckValidate.UserApprovalCheck &&
          shiftValidation.checkName !==
            ShiftQuickCheckValidate.ShiftProfessionCheck,
      );
    }

    if (!isUserApprovalValidationEnabled) {
      return shiftValidations.filter(
        (shiftValidation) =>
          shiftValidation.checkName !==
          ShiftQuickCheckValidate.UserApprovalCheck,
      );
    } else if (!isUserProfessionValidationEnabled) {
      return shiftValidations.filter(
        (shiftValidation) =>
          shiftValidation.checkName !==
          ShiftQuickCheckValidate.ShiftProfessionCheck,
      );
    } else {
      return [];
    }
  }
}

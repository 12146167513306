import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { BillingSetting } from '@wilson/interfaces';
import {
  BillingSettingsInit,
  BillingSettingsUpdate,
} from './billing-settings.action';
import { BillingSettingsService } from './billing-settings.service';
import { patch } from '@ngxs/store/operators';
import { catchError, of, take, tap } from 'rxjs';

export interface BillingSettingsStateModel {
  settings: BillingSetting | null;
  loading: boolean;
}

const BILLING_SETTINGS_STATE_NAME = 'billingSettings';
const defaults: BillingSettingsStateModel = {
  settings: null,
  loading: false,
};

@State<BillingSettingsStateModel>({
  name: BILLING_SETTINGS_STATE_NAME,
  defaults,
})
@Injectable()
export class BillingSettingsState {
  constructor(
    private readonly billingSettingsService: BillingSettingsService,
  ) {}

  @Selector()
  static getState(state: BillingSettingsStateModel) {
    return state;
  }

  @Selector()
  static settings(state: BillingSettingsStateModel) {
    return state.settings;
  }

  @Selector()
  static loading(state: BillingSettingsStateModel) {
    return state.loading;
  }

  @Action(BillingSettingsInit)
  init(ctx: StateContext<BillingSettingsStateModel>) {
    this.setLoading(ctx, true);
    return this.billingSettingsService.get().pipe(
      take(1),
      tap((billingSetting) => {
        ctx.setState(
          patch<BillingSettingsStateModel>({
            settings: billingSetting,
            loading: false,
          }),
        );
      }),
      catchError(() => {
        this.setLoading(ctx, false);
        return of(null);
      }),
    );
  }

  @Action(BillingSettingsUpdate)
  update(
    ctx: StateContext<BillingSettingsStateModel>,
    action: BillingSettingsUpdate,
  ) {
    ctx.setState({
      settings: action.newBillingSettings,
      loading: false,
    });
    return ctx.getState().settings;
  }

  private setLoading(
    ctx: StateContext<BillingSettingsStateModel>,
    newLoadingValue: boolean,
  ) {
    ctx.setState(
      patch<BillingSettingsStateModel>({
        loading: newLoadingValue,
      }),
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WeekDays } from '@wilson/interfaces';

@Component({
  selector: 'wilson-weekday-selector',
  templateUrl: './weekday-selector.component.html',
  styleUrls: ['./weekday-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekdaySelectorComponent),
      multi: true,
    },
  ],
})
export class WeekdaySelectorComponent
  implements ControlValueAccessor, OnChanges
{
  weeks = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  @Input() weekDays: WeekDays = this.weeks;
  @Input() disabledWeekDays: WeekDays = this.weeks;
  @Input() disabled = false;
  @Input() checkDisabledWeekDay = false;

  @Output() selectedWeekDays = new EventEmitter<WeekDays>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    if (this.checkDisabledWeekDay) {
      for (const [key] of Object.entries(this.disabledWeekDays)) {
        if (!this.disabledWeekDays[key]) {
          this.weekDays[key] = false;
        }
      }
      this.selectedWeekDays.emit(this.weekDays);
    }
    this.cdr.detectChanges();
  }

  toggleWeekDay(key: keyof WeekDays) {
    if (this.disabled) return;
    this.weekDays[key] = !this.weekDays[key];
    this.propagateChange(this.weekDays);
    this.selectedWeekDays.emit(this.weekDays);
  }

  // eslint-disable-next-line
  unsorted() {} // To keep original order of object while using keyvalue pipe

  // eslint-disable-next-line
  propagateChange = (_: any) => {};

  writeValue(value: WeekDays) {
    if (value) {
      this.weekDays = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched() {}
}

<i
  nz-tooltip
  [nzTooltipTitle]="infoTooltip"
  class="fas fa-info-circle info-icon"
></i>
<ng-template #infoTooltip>
  <span>
    {{ 'page.shift.name.already_exists' | translate }}
    <a [href]="'/shifts/' + shift.id" target="_blank" class="link-dark">
      {{ shift.name }}
    </a>
  </span>
</ng-template>

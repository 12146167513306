import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivityAdjustmentGuardService } from '../../services/activity-adjustment-guard/activity-adjustment-guard.service';

@Pipe({
  name: 'totalPullableActivities',
})
export class TotalPullableActivitiesPipe implements PipeTransform {
  constructor(
    private activityAdjustmentGuardService: ActivityAdjustmentGuardService,
  ) {}

  transform(activityFormArray: FormArray, index: number): number {
    let totalPullableActivities = 0;
    for (let i = index + 1; i < activityFormArray.value.length; i++) {
      if (
        !this.activityAdjustmentGuardService.canBeAdjusted(
          activityFormArray?.at(i).value,
        )
      ) {
        break;
      } else {
        totalPullableActivities++;
      }
    }
    return totalPullableActivities;
  }
}

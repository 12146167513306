import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';

@Component({
  selector: 'wilson-via-points-list',
  templateUrl: './via-points-list.component.html',
  styleUrls: ['./via-points-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaPointsListComponent {
  @Input()
  startLocation!: GeoLocation;

  @Input()
  endLocation!: GeoLocation;

  @Input()
  viaLocations!: GeoLocation[];

  @Input()
  hideTitle!: boolean;

  @Input()
  noHeightLimit!: boolean;

  @Input()
  noLeftPadding!: boolean;
}

import { activitiesCompareFn } from '@wilson/activities/util';
import { Activity, OperationStatus } from '@wilson/interfaces';
import { isFuture } from 'date-fns';
export function isPendingStart(
  activities: Pick<Activity, 'startDatetime' | 'operationalStatus'>[],
) {
  if (!activities?.length) {
    return false;
  } else {
    const clonedActivities: Pick<
      Activity,
      'startDatetime' | 'operationalStatus'
    >[] = [...activities];
    const sortedActivities = clonedActivities.sort(activitiesCompareFn);
    const firstActivityIsInFuture = isFuture(
      new Date(sortedActivities[0].startDatetime),
    );
    const allActivitiesHaveNotStarted = sortedActivities.every(
      (activity) => activity.operationalStatus === OperationStatus.NotStarted,
    );
    return firstActivityIsInFuture && allActivitiesHaveNotStarted;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { InvoicingDataState } from './invoicing-data/invoicing-data.state';

@NgModule({
  declarations: [],
  providers: [DatePipe],
  imports: [CommonModule, NgxsModule.forFeature([InvoicingDataState])],
})
export class InvoicingStateModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivityDeviationStatus, ResolvedActivity } from '@wilson/interfaces';

@Component({
  selector: 'wilson-activity-deviation-time',
  templateUrl: './activity-deviation-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDeviationTimeComponent {
  @Input() activity: ResolvedActivity;
  @Input() affectedStop: string;
  @Input() plannedDate: string;
  ActivityDeviationStatus = ActivityDeviationStatus;
}

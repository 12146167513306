import { Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PublicationStatus, Shift } from '@wilson/interfaces';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { CanAllShiftBeModifiedPipe } from '../../pipes/can-all-shift-be-modified/can-all-shift-be-modified.pipe';
import {
  ShiftActionsErrorMessages,
  MoveShiftsResponse,
  ShiftActionsGateway,
} from '@wilson/api/gateway';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MoveDirectionType,
  MoveModalComponent,
} from '../../components/move-modal/move-modal.component';

@Injectable()
export class MoveShiftHelperService {
  private _isMoveAllowedSignal = signal(false);
  public isMoveAllowedSignal = this._isMoveAllowedSignal.asReadonly();
  constructor(
    private readonly translate: TranslateService,
    private readonly nzModalService: NzModalService,
    private readonly nzMessageService: NzMessageService,
    private readonly canAllShiftBeModified: CanAllShiftBeModifiedPipe,
    private readonly shiftActionsGateway: ShiftActionsGateway,
  ) {}

  public updateShiftTimePermission(selectedShifts: Shift[]) {
    const doShiftsMatchMoveConditions =
      this.canAllShiftBeModified.transform(selectedShifts);

    if (doShiftsMatchMoveConditions) {
      this._isMoveAllowedSignal.set(true);
    } else {
      this._isMoveAllowedSignal.set(false);
    }
  }

  public openDurationSelectionModal(
    selectedShifts: Omit<Shift, 'activities'>[],
  ): Promise<MoveShiftsResponse | undefined> {
    let title = '';
    let description = '';
    if (selectedShifts.length > 1) {
      title = this.translate.instant('shift.page.move_shifts_header');
      description = this.translate.instant(
        'shift.page.move_shifts_description',
      );
    } else {
      title = this.translate.instant('shift.page.move_shift_header');
      description = this.translate.instant(
        'shift.page.move_single_shift_description',
      );
    }

    const modalRef = this.nzModalService.create<
      MoveModalComponent,
      { description: string },
      MoveShiftsResponse
    >({
      nzTitle: title,
      nzContent: MoveModalComponent,
      nzClosable: false,
      nzCentered: true,
      nzData: {
        description,
      },
      nzFooter: [
        {
          label: this.translate.instant('general.cancel'),
          type: 'default',
          onClick: () => {
            modalRef.destroy();
          },
        },
        {
          label: this.translate.instant('general.apply'),
          type: 'primary',
          disabled: (modalInstance) => {
            if (modalInstance?.areInputsValid) {
              return false;
            } else {
              return true;
            }
          },
          onClick: async (modalInstance) => {
            if (modalInstance) {
              const minutesToMove = this.getMinutesToMove(modalInstance);

              if (minutesToMove > 0) {
                const hasPublishedShift = selectedShifts.some(
                  (shift) =>
                    shift.publicationStatus === PublicationStatus.Published,
                );
                if (hasPublishedShift) {
                  if (
                    await this.showShiftIsPublishedConfirmModal(
                      selectedShifts.length > 1,
                    )
                  ) {
                    await this.updateShifts(
                      modalRef,
                      modalInstance,
                      selectedShifts,
                      minutesToMove,
                    );
                  } else {
                    modalRef.destroy();
                  }
                } else {
                  await this.updateShifts(
                    modalRef,
                    modalInstance,
                    selectedShifts,
                    minutesToMove,
                  );
                }
              } else {
                modalRef.destroy();
              }
            }
          },
        },
      ],
    });
    return firstValueFrom(modalRef.afterClose);
  }

  private async showShiftIsPublishedConfirmModal(isMultiShift: boolean) {
    const contentText = isMultiShift
      ? 'shift.page.move_shifts_confirm'
      : 'shift.page.move_single_shift_confirm';

    const confirmModalRef = this.nzModalService.warning({
      nzTitle: this.translate.instant(
        'page.shift_timeline.drawer.shift_form.edit_shift',
      ),
      nzContent: this.translate.instant(contentText),
      nzCancelText: this.translate.instant('general.cancel'),
      nzOkText: this.translate.instant('general.ok'),
      nzOkType: 'primary',
      nzOkDanger: false,
      nzCentered: true,
      nzOnOk: () => {
        confirmModalRef.close(true);
      },
    });
    return firstValueFrom(confirmModalRef.afterClose);
  }

  private async updateShifts(
    modalRef: NzModalRef<MoveModalComponent, MoveShiftsResponse>,
    modalInstance: MoveModalComponent | undefined,
    selectedShifts: Omit<Shift, 'activities'>[],
    minutesToMove: number,
  ) {
    if (modalInstance) {
      try {
        const minutesToMoveReturn =
          modalInstance.moveDirection === MoveDirectionType.Forward
            ? minutesToMove
            : -minutesToMove;
        const updatedShiftsAndActivities = await firstValueFrom(
          this.shiftActionsGateway.moveShifts(
            selectedShifts.map((shift) => shift.id),
            minutesToMoveReturn,
          ),
        );
        const isMultipleShifts = selectedShifts.length > 1;
        if (isMultipleShifts) {
          this.nzMessageService.success(
            this.translate.instant('shift.page.move_shifts_success'),
          );
        } else {
          this.nzMessageService.success(
            this.translate.instant('shift.page.move_single_shift_success'),
          );
        }

        modalRef.destroy(updatedShiftsAndActivities);
      } catch (error) {
        if (
          (error as HttpErrorResponse)?.message.includes(
            ShiftActionsErrorMessages.ContainsSharedActivities,
          )
        ) {
          this.nzMessageService.error(
            this.translate.instant(
              'shift.page.move_shifts_shared_activity_error',
            ),
          );
        } else {
          this.nzMessageService.error(
            this.translate.instant('general.something_went_wrong'),
          );
        }
      }
    }
  }

  private getMinutesToMove(modalInstance: MoveModalComponent) {
    const selectedDays = Number(modalInstance.days);
    const selectedHours = Number(modalInstance.hours);
    const selectedMinutes = Number(modalInstance.minutes);
    const minutesToMove =
      selectedDays * 24 * 60 + selectedHours * 60 + selectedMinutes;
    return minutesToMove;
  }
}

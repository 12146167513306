import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShiftTemplateActivityTemplates } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { differenceInMinutes, format } from 'date-fns';

@Pipe({
  name: 'shiftDuration',
})
export class ShiftDurationPipe implements PipeTransform {
  constructor(public readonly translate: TranslateService) {}

  transform(activities: ShiftTemplateActivityTemplates[]): string {
    const startDate = new Date(activities[0].activityTemplate.startDatetime);
    const endDate = new Date(
      activities[activities.length - 1].activityTemplate.endDatetime,
    );

    const startDateTime = format(startDate, DateTimeFormat.TimeFormat);

    const endDateTime = format(endDate, DateTimeFormat.TimeFormat);

    const diffInMins = differenceInMinutes(endDate, startDate);

    const hours = Math.trunc(diffInMins / 60);
    const mins = diffInMins % 60;

    const hourText =
      hours > 0
        ? `${hours}${this.translate.instant('general.time.hours')}`
        : '';
    const minText =
      mins > 0
        ? `${mins}${this.translate.instant('general.time.minutes')}`
        : '';

    return `${startDateTime} - ${endDateTime} (${hourText} ${minText})`;
  }
}

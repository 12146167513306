import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CSVBoxAngularModule } from '@csvbox/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AccountAgGridModule } from '@wilson/account/ag-grid';
import { ReportUtilitiesModule } from '@wilson/activities';
import { ActivitiesPipesModule } from '@wilson/activities/pipes';
import { ActivitiesReportsModule } from '@wilson/activities/reports';
import { PayrollSettingsService } from '@wilson/api/gateway';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { FilesModule } from '@wilson/files';
import { LocationsService } from '@wilson/locations';
import { PipesModule as WilsonPipesModule } from '@wilson/pipes';
import {
  LoadingSpinnerModule,
  SharedComponentsModule,
  UserDetailsModule,
} from '@wilson/shared-components';
import { TooltipModule } from '@wilson/tooltip';
import { WorkingTimeModelService } from '@wilson/working-time-model';
import { AgGridModule } from 'ag-grid-angular';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { PipesModule } from '../pipes/pipes.module';
import { WorkingTimeModelReuseService } from '../settings/working-time-model/services/working-time-model-reuse.service';
import { AssignStaffPopupService } from '../shifts/components/assign-staff-popup/assign-staff-popup.service';
import { CalculateWorkingTimeRendererComponent } from './ag-grid-renderers/calculate-working-time-renderer';
import { EndTimeRendererComponent } from './ag-grid-renderers/end-time.renderer';
import { HeaderRendererComponent } from './ag-grid-renderers/header.renderer';
import { InfoIconRendererComponent } from './ag-grid-renderers/info-icon.renderer';
import { StartTimeRendererComponent } from './ag-grid-renderers/start-time.renderer';
import { StatusIconRendererComponent } from './ag-grid-renderers/status-icon.renderer';
import { TitleRendererComponent } from './ag-grid-renderers/title.renderer';
import { WorkingTimeModelIconRendererComponent } from './ag-grid-renderers/working-time-model-icon.renderer';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import { CardDefaultComponent } from './card-default/card-default.component';
import { CsvBoxImportComponent } from './csv-box-import/csv-box-import.component';
import { DeviationComponent } from './deviation/deviation.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { FormDeactivateComponent } from './form-deactivate/form-deactivate.component';
import { LegendsComponent } from './legends/legends.component';
import { RangepickerComponent } from './rangepicker/rangepicker.component';
import { SafetyCertificateAutocompleteComponent } from './safety-certificate-autocomplete/safety-certificate-autocomplete.component';
import { ServiceStatusComponent } from './service-status/service-status.component';
import { ModalService } from './services/modal-service/modal.service';
import { ShiftAssignmentComponent } from './shift-assignment/shift-assignment.component';
import {
  ActivityTimeOverday,
  ShiftAssignedUserIdentityComponent,
  ShiftHelpersModule,
} from '@wilson/non-domain-specific/shift-helpers/core';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ActivityTimeTagComponent } from '@wilson/non-domain-specific/activities-helpers/components';
import { OpenFinalStaffButtonComponent } from '@wilson/non-domain-specific/shift-helpers/assignments';
import {
  ShiftConfirmedDeclinedComponent,
  ShiftLastSeenComponent,
} from '@wilson/shift/core';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    HeaderRendererComponent,
    TitleRendererComponent,
    RangepickerComponent,
    CardDefaultComponent,
    OnlyNumberDirective,
    LegendsComponent,
    ShiftAssignmentComponent,
    StatusIconRendererComponent,
    DeviationComponent,
    InfoIconRendererComponent,
    FormDeactivateComponent,
    CalculateWorkingTimeRendererComponent,
    WorkingTimeModelIconRendererComponent,
    ServiceStatusComponent,
    StartTimeRendererComponent,
    EndTimeRendererComponent,
    SafetyCertificateAutocompleteComponent,
    CsvBoxImportComponent,
  ],
  imports: [
    OpenFinalStaffButtonComponent,
    ActivityTimeTagComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    DateFnsModule,
    PipesModule,
    WilsonPipesModule,
    NgbDatepickerModule,
    NgbModule,
    RouterModule,
    FilesModule,
    NzDatePickerModule,
    TooltipModule,
    NzAutocompleteModule,
    NzFormModule,
    NzToolTipModule,
    ReportUtilitiesModule,
    SharedComponentsModule,
    FeatureFlagsModule,
    ActivitiesReportsModule,
    ActivitiesPipesModule,
    LoadingSpinnerModule,
    AgGridModule,
    FontAwesomeModule,
    AccountAgGridModule,
    NzModalModule,
    NzButtonModule,
    CSVBoxAngularModule,
    ShiftHelpersModule,
    NzTagModule,
    UserDetailsModule,
    ShiftAssignedUserIdentityComponent,
    ShiftLastSeenComponent,
    ShiftConfirmedDeclinedComponent,
  ],
  providers: [
    LocationsService,
    AssignStaffPopupService,
    ModalService,
    WorkingTimeModelReuseService,
    WorkingTimeModelService,
    PayrollSettingsService,
    ActivityTimeOverday,
  ],
  exports: [
    LoadingSpinnerModule, // REFACTOR: In the future, consumers of shared module should directly import LoadingSpinnderModule instead
    DateFnsModule,
    BreadcrumbComponent,
    HeaderRendererComponent,
    TitleRendererComponent,
    RangepickerComponent,
    CardDefaultComponent,
    OnlyNumberDirective,
    LegendsComponent,
    ShiftAssignmentComponent,
    StatusIconRendererComponent,
    DeviationComponent,
    InfoIconRendererComponent,
    CalculateWorkingTimeRendererComponent,
    WorkingTimeModelIconRendererComponent,
    ServiceStatusComponent,
    StartTimeRendererComponent,
    EndTimeRendererComponent,
    SafetyCertificateAutocompleteComponent,
    CsvBoxImportComponent,
  ],
})
export class SharedModule {}

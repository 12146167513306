import { Base } from '@wilson/base';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { ActivityCategoryContract } from '../activities/activity-category';

export interface Contract extends Base {
  name: string;
  minTimeBetweenShiftsInMinutes: number;
  maxShiftLengthInMinutes: number;
  maxDriveTimeInMinutes: number;
  maximumProtectiveWorkingTimeInMinutes: number;
  weekTimeInHours: number;
  maxTimeWithoutBreakInMinutes: number;
  minBreakTimeInMinutes: number;
  requiredBreakTime: RequiredBreakTime[];
  maximumContinuousWorkDays: number;
  organizationalUnit: OrganizationalUnit;
  organizationalUnitId: string;
  validFrom?: string;
  validTill?: string;
  activityCategoryContracts: ActivityCategoryContract[];
}

export interface RequiredBreakTime {
  shiftTimeInMinutes: number;
  breakTimeInMinutes: number;
}

export enum ContractsNameModel {
  MaxDriveTimeInMinutes = 'maxDriveTimeInMinutes',
  MaxShiftLengthInMinutes = 'maxShiftLengthInMinutes',
  MaxTimeWithoutBreakInMinutes = 'maxTimeWithoutBreakInMinutes',
  MaximumContinuousWorkDays = 'maximumContinuousWorkDays',
  MinBreakTimeInMinutes = 'minBreakTimeInMinutes',
  MinTimeBetweenShiftsInMinutes = 'minTimeBetweenShiftsInMinutes',
  WeekTimeInHours = 'weekTimeInHours',
  RequiredBreakTimeAfterShiftLengthOf = 'requiredBreakTimeAfterShiftLengthOf',
  RequiredBreakTime = 'requiredBreakTime',
}

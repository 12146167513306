import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPresetColor } from 'ng-zorro-antd/core/color';
import { AntColorPipe } from '@wilson/pipes';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

export interface ProgressBarSegment {
  absoluteValue: number;
  color: NzPresetColor | undefined;
  label: string | undefined;
  value: number;
}

@Component({
  selector: 'wilson-segmented-progress-bar',
  standalone: true,
  imports: [AntColorPipe, CommonModule, NzToolTipModule],
  templateUrl: './segmented-progress-bar.component.html',
  styleUrl: './segmented-progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentedProgressBarComponent implements OnChanges {
  @Input() segments: ProgressBarSegment[] = [];

  segmentSum = 0;

  ngOnChanges(): void {
    this.segmentSum = this.segments.reduce(
      (previous, current) => previous + current.value,
      0,
    );
  }
}

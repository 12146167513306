import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Shift,
  Stay,
  StayCardPosition,
  StaySuggestionInfo,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { TimelineStayGateway } from '@wilson/api/gateway';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { Observable } from 'rxjs';

@Injectable()
export class StaysDrawerStateService {
  @WilsonState<boolean>(false)
  isCreateStaysDrawerVisible!: boolean;
  isCreateStaysDrawerVisible$!: Observable<boolean>;

  @WilsonState<boolean>(false)
  isEditStaysDrawerVisible!: boolean;
  isEditStaysDrawerVisible$!: Observable<boolean>;

  @WilsonState<Stay | null>(null)
  stayToEdit!: Stay | null;
  stayToEdit$!: Observable<Stay | null>;

  @WilsonState<
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | null
  >(null)
  nextShift!:
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | null;
  nextShift$!: Observable<
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | null
  >;

  @WilsonState<
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | null
  >(null)
  previousShift!:
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | null;
  previousShift$!: Observable<
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | null
  >;

  @WilsonState<StaySuggestionInfo | null>(null)
  private staySuggestionInfo!: StaySuggestionInfo | null;
  staySuggestionInfo$!: Observable<StaySuggestionInfo | null>;

  constructor(
    public readonly store: Store,
    protected timelineStayGateway: TimelineStayGateway,
  ) {}

  public editStay(value: StayCardPosition) {
    this.stayToEdit = value.stay;
    this.setStayToEdit(value.stay);
    this.isEditStaysDrawerVisible = true;
  }

  public setStayToEdit(stay: Stay) {
    this.stayToEdit = stay;
  }

  public toggleCreateStaysDrawer(value: boolean) {
    this.isCreateStaysDrawerVisible = value;
  }

  public setEditStaysDrawerVisibility(value: boolean) {
    this.isEditStaysDrawerVisible = value;
  }

  public setStaysDrawerRelatedAdjacentShifts(
    shifts: (Shift &
      WithPreparedAttributes & {
        id: string;
      })[],
  ) {
    this.nextShift = shifts[0];
    this.previousShift = shifts[1];
  }

  public setStaySuggestionInfo(staySuggestionInfo: StaySuggestionInfo) {
    this.staySuggestionInfo = staySuggestionInfo;
    this.previousShift = staySuggestionInfo.shift;
  }

  public resetSetShifts() {
    this.nextShift = null;
    this.previousShift = null;
    this.staySuggestionInfo = null;
  }

  resetEditStayData() {
    this.stayToEdit = null;
    this.isEditStaysDrawerVisible = false;
  }
}

<div>
  <div
    class="text-truncate"
    nz-tooltip
    [nzTooltipTitle]="displayValue ? tooltipContent : ''"
    [nzTooltipColor]="'#fff'"
    [nzTooltipOverlayStyle]="{ 'min-width': '340px' }"
  >
    {{ displayValue ? displayValue : '---' }}
  </div>
  <ng-template #tooltipContent>
    <div class="row exception p-2" *ngIf="seriesRules?.length">
      <div
        *ngFor="let rule of seriesRules; let i = index"
        [class.border-end]="seriesRules.length > 1 && i % 2 === 0"
        class="date-text"
      >
        <ng-container
          *ngIf="
            (rule.startDate | date : 'dd.MM.yyyy') ===
              (rule.endDate | date : 'dd.MM.yyyy');
            else dateRange
          "
        >
          <i
            class="far"
            [ngClass]="
              rule.type === SeriesRuleType.Include
                ? 'fa-calendar-plus text-success'
                : 'fa-calendar-minus text-danger'
            "
          ></i>
          {{ rule.startDate | date : 'dd.MM.yyyy' }}
        </ng-container>
        <ng-template #dateRange>
          <i
            class="far"
            [ngClass]="
              rule.type === SeriesRuleType.Include
                ? 'fa-calendar-plus text-success'
                : 'fa-calendar-minus text-danger'
            "
          ></i>
          {{ rule.startDate | date : 'dd.MM.yyyy' }} -
          {{ rule.endDate | date : 'dd.MM.yyyy' }}
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

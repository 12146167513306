import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpdateEvent } from '@wilson/activities/ag-grid';
import { ActivitiesGateway } from '@wilson/api/gateway';
import { Activity, ActivitySelectionListItem } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzMessageService } from 'ng-zorro-antd/message';

export interface ActivityCategoryAndNameRendererCellRenderParams {
  enableEdit: boolean;
}

@Component({
  selector: 'wilson-activity-category-and-name-renderer',
  template: `<wilson-activity-category-and-name
    [activityName]="activityName"
    [activityCategoryName]="activityCategoryName"
    [editable]="editable"
    (updated)="handleUpdate($event)"
  ></wilson-activity-category-and-name>`,
  styleUrls: ['./activity-category-and-name-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCategoryAndNameRendererComponent
  implements ICellRendererAngularComp
{
  private cellRendererParams!: ICellRendererParams &
    ActivityCategoryAndNameRendererCellRenderParams;
  private activityId!: string;
  public activityCategoryName!: string;
  public activityName: string;
  protected editable!: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private activitiesGateway: ActivitiesGateway,
    private nzMessage: NzMessageService,
    private translateService: TranslateService,
  ) {}

  async agInit(
    params: ICellRendererParams &
      ActivityCategoryAndNameRendererCellRenderParams,
  ) {
    this.refresh(params);
  }

  refresh(
    params: ICellRendererParams &
      ActivityCategoryAndNameRendererCellRenderParams,
  ) {
    this.cellRendererParams = params;
    this.activityId = params.data.id;
    this.editable = params.enableEdit;
    this.activityCategoryName = (
      params.data as ActivitySelectionListItem
    ).activityCategory?.name;
    this.activityName = (params.data as ActivitySelectionListItem).name;
    return true;
  }

  handleUpdate(event: UpdateEvent) {
    if (
      event.activityName !== undefined ||
      event.activityCategory !== undefined
    ) {
      const previousActivityName = (
        this.cellRendererParams.data as ActivitySelectionListItem
      ).name;
      const previousActivityCategory = (
        this.cellRendererParams.data as ActivitySelectionListItem
      ).activityCategory;

      const payload: Partial<Activity> = {
        id: this.activityId,
      };

      if (event.activityName !== undefined) {
        payload.name = event.activityName;
        (this.cellRendererParams.data as ActivitySelectionListItem).name =
          event.activityName;
      }

      if (event.activityCategory !== undefined) {
        payload.activityCategoryId = event.activityCategory.id;
        (
          this.cellRendererParams.data as ActivitySelectionListItem
        ).activityCategory = event.activityCategory;
        (
          this.cellRendererParams.data as ActivitySelectionListItem
        ).activityCategoryId = event.activityCategory.id as string;
      }

      this.refresh(this.cellRendererParams);

      this.activitiesGateway.updateActivity(payload).catch(() => {
        (this.cellRendererParams.data as ActivitySelectionListItem).name =
          previousActivityName;

        (
          this.cellRendererParams.data as ActivitySelectionListItem
        ).activityCategory = previousActivityCategory;
        (
          this.cellRendererParams.data as ActivitySelectionListItem
        ).activityCategoryId = previousActivityCategory.id;
        this.refresh(this.cellRendererParams);
        this.nzMessage.error(this.translateService.instant('general.error'));
      });
    }
  }
}

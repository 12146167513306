import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-op-report-category-renderer',
  template: `
    <div class="text-dark">
      {{
        categoryId
          ? ('masterdata.operative_report.categories.' + categoryId | translate)
          : '---'
      }}
    </div>
    <div>
      @if (userId === null) {
      {{ 'general.n/a' | translate }}
      } @else { @if (!senderUserId && sender === 'system') { @if (userId |
      userName | async) {
      <a
        [routerLink]="['/employees/', userId]"
        target="_blank"
        class="link-dark"
      >
        ( {{ userId | userName | async }} )
      </a>
      } @else { ({{ 'page.op_reports.deleted_user' | translate }}) } } }
    </div>
  `,
  styles: [],
})
export class OpReportCategoryRendererComponent implements AgRendererComponent {
  public categoryId!: string;
  public userId!: string;
  public senderUserId!: string;
  public sender!: string;

  agInit(params: ICellRendererParams): void {
    this.userId = params.data.shift.userId;
    this.senderUserId = params.data.senderUserId;
    this.categoryId = params.value;
    this.sender = params.data.sender;
  }

  refresh(params: ICellRendererParams) {
    this.categoryId = params.value;
    this.userId = params.data.shift.userId;
    this.senderUserId = params.data.senderUserId;
    this.sender = params.data.sender;
    return true;
  }
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Activity,
  ActivityQualifications,
  ActivityTemplate,
  ActivityTemplateQualifications,
} from '@wilson/interfaces';
import { ActivityQualificationsModalComponent } from '../activity-qualifications-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ActivityQualificationsModalService {
  constructor(private readonly modalService: NgbModal) {}

  openActivityQualificationsModal(
    activity: Activity,
    activityTemplate: ActivityTemplate,
    activityQualifications:
      | ActivityQualifications[]
      | ActivityTemplateQualifications[],
  ) {
    const btnOkText = 'general.save';
    const btnCancelText = 'general.cancel';

    const modalRef = this.modalService.open(
      ActivityQualificationsModalComponent,
      {
        size: 'md',
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.activity = activity;
    modalRef.componentInstance.activityTemplate = activityTemplate;
    modalRef.componentInstance.activityQualifications = activityQualifications;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }
}

export interface ServiceDeviation {
  locationId: string;
  affectedStop: AffectedStop | null;
  serviceId: string;
  deviationInMinutes: number;
  comment: string;
  reason: ServiceDeviationReason | null;
}

export interface SegmentDeviation {
  deviationInMinutes: number;
  isArrival: boolean;
  isDeparture: boolean;
  reason: ServiceDeviationReason | null;
  comment: string;
  applyToAll: boolean;
  index: number;
}

export enum AffectedStop {
  Arrival = 'arrival',
  Departure = 'departure',
}

export enum ServiceDeviationReason {
  ExternalInfluence = 'external_influence',
  OperationsManagement = 'operations_management',
  Infrastructure = 'infrastructure',
  ConstructionReasons = 'construction',
  RailwayUndertaking = 'railway_undertaking',
  OtherRailwayUndertaking = 'other_railway_undertaking',
  Vehicle = 'vehicle',
  Other = 'other',
}

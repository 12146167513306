export interface BillingSetting {
  organizationalUnitId: string;
  invoicingPdfTemplateId: string | null;
  nextInvoiceNumber: number;
  invoiceNumberNumberOfDigits: number;
  invoiceNumberCustomPrefix: string;
  invoicePdfType: InvoicePdfEnum;
  invoicePdfAttachmentType: InvoicePdfAttachmentEnum;
  integrateLexoffice: boolean;
  lexofficeApiKey: string | null;
}

export type BillingSettingPatchPayload = Partial<
  Omit<BillingSetting, 'organizationalUnitId'>
>;

export enum InvoicePdfEnum {
  ONLY_INVOICE = 'onlyInvoice',
  ONLY_INVOICE_ATTACHMENT = 'onlyInvoiceAttachment',
  INVOICE_WITH_ATTACHMENT = 'invoiceWithAttachment',
}

export enum InvoicePdfAttachmentEnum {
  GROUPED_BY_USER_WITH_FULL_SHIFT_INFO = 'groupedByUserWithFullShiftInfo',
  GROUPED_BY_USER_WITH_MIN_SHIFT_INFO = 'groupedByUserWithMinShiftInfo',
  NOT_GROUPED_BY_USER_WITH_FULL_SHIFT_INFO = 'notGroupedByUserWithFullShiftInfo',
  NOT_GROUPED_BY_USER_WITH_MIN_SHIFT_INFO = 'notGroupedByUserWithMinShiftInfo',
  BASED_ON_ACTIVITY = 'basedOnActivity',
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ShiftTimelineFilterStateModel,
  TimelineModalFilters,
} from '../interfaces';
import { shiftTimelineDefaultFilter } from '../shift-timeline-default-data';
import { TimelineSetSearchTerm } from './shift-timeline-filters.actions';

@State({
  name: 'shiftTimelineFilters',
  defaults: shiftTimelineDefaultFilter,
})
@Injectable()
export class ShiftTimelineFiltersState {
  static get defaultModalFilter(): TimelineModalFilters {
    return {
      orgUnit: [],
      role: [],
      absences: [],
      shiftCategory: [],
      releaseStatus: null,
      users: [],
      priority: [],
      profession: [],
      labels: [],
      projects: [],
      confirmedDeclined: [],
    };
  }

  @Selector()
  static filters(state: ShiftTimelineFilterStateModel): TimelineModalFilters {
    return state.filters;
  }

  @Action(TimelineSetSearchTerm)
  timelineSetSearchTerm(
    ctx: StateContext<ShiftTimelineFilterStateModel>,
    action: TimelineSetSearchTerm,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        searchTerm: action.searchTerm,
      },
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ResolvedShiftValidation } from '@wilson/interfaces';
import { Partner } from '@wilson/share/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiftValidationsGateway extends BackendService<Partner> {
  protected readonly path = 'shift-validations';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public getShiftValidationWithContractId(
    shiftId: string,
    contractId: string,
  ): Observable<ResolvedShiftValidation[]> {
    return this.http.post<ResolvedShiftValidation[]>(
      `${this.config.host}/${this.path}`,
      {
        items: [{ shiftId, contractId }],
      },
    );
  }
}

import { UserTimelinesModal } from '@wilson/interfaces';
import { TimelineAssignmentModalFilters } from '../interfaces';

export class SetTimelineModalFilterUsers {
  static readonly type =
    '[TimelineModalFilter] Timeline Modal Filter Fetched Users';
  constructor(public users: UserTimelinesModal[]) {}
}

export class SetTimelineAssignmentModalFilters {
  static readonly type = '[TimelineAssignmentModalFilter] SetFilters';
  constructor(public filters: Partial<TimelineAssignmentModalFilters>) {}
}

export class TimelineModalSetSearchTerm {
  static readonly type =
    '[TimelineAssignmentModalFilter] Timeline Modal Set SearchTerm';
  constructor(public searchTerm: string) {}
}

export class ResetTimelineAssignmentModalFiltersState {
  static readonly type = '[TimelineAssignmentModalFilter] ResetModalFilters';
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import {
  Activity,
  ShiftTemplate,
  ShiftTemplateActivityTemplates,
} from '@wilson/interfaces';
import { ShiftTemplateToShiftService } from '@wilson/non-domain-specific/shift-helpers/core';

@Component({
  selector: 'wilson-shift-template-picker-popup',
  templateUrl: './shift-template-picker-popup.component.html',
  styleUrls: ['./shift-template-picker-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftTemplatePickerComponent {
  public shiftTemplates!: ShiftTemplate[];
  public searchFilter!: string;
  public isLoading = false;
  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions = defaultGridOptionsFactory();

  templateIndex!: number;
  selectedShiftTemplate!: ShiftTemplate;
  shiftTemplateActivities?: ShiftTemplateActivityTemplates[] = [];
  activities!: Activity[];

  constructor(
    private activeModal: NgbActiveModal,
    private shiftTemplateToShift: ShiftTemplateToShiftService,
  ) {}

  public decline(): void {
    this.activeModal.close(false);
  }

  public accept(): void {
    this.activeModal.close(this.activities);
  }

  selectedTemplate(shiftTemplate: ShiftTemplate, index: number) {
    this.selectedShiftTemplate = shiftTemplate;
    this.templateIndex = index;
    this.shiftTemplateActivities = shiftTemplate.shiftTemplateActivityTemplates;
  }

  selectedActivities(activityTemplates: ShiftTemplateActivityTemplates[]) {
    this.activities =
      this.shiftTemplateToShift.sortAndTransformActivitiesTemplateToActivities(
        activityTemplates,
      );
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';
import { Ability } from '@casl/ability';
import { Store } from '@ngxs/store';
import { RolePermissionGuard } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

export const rolePermissionGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> | UrlTree => {
  const ability = inject(Ability);
  const store = inject(Store);
  const router = inject(Router);

  const routePermission: RolePermissionGuard = route.data.rolePermission;
  if (!routePermission || !routePermission.action || !routePermission.subject) {
    return router.createUrlTree(['/']);
  }

  return store
    .select((state) => state.permissions.rolePermissions)
    .pipe(
      skipWhile((permissions) => !permissions.length),
      map(() => {
        if (ability.can(routePermission.action, routePermission.subject)) {
          return true;
        }

        return router.createUrlTree(['/']);
      }),
    );
};

import { Pagination } from '@wilson/base';
import { Activity, InvoiceActivityStatus } from '@wilson/interfaces';

export interface ActivityViewModel {
  user: string;
  originalActivity: Activity;
  activityName: string;
  agreementDetails: { clientName?: string; agreementName?: string };
  shiftDetails: ActivityViewModelShiftDetails;
  id?: string;
  formattedStartDateTime: string;
  serviceDetails: ActivityViewModelServiceDetails;
  invoices: ActivityInvoiceModel[];
  jobOriginOrgUnit?: string;
  jobName?: string;
  requestForProposalId?: string;
}
export interface InvoicingDataStateModel {
  activities: ActivityViewModel[] | null;
  selectedActivities: Activity[];
  selectedClientId: string;
  selectedClientName: string;
  selectedActivityStates: SelectedActivityChecks;
  filters: InvoiceFilters;
  pagination: Pagination | null;
  pageNumber: number;
  pageSize: InvoicingGridPageSizes;
  cacheBlockSize: InvoicingGridPageSizes;
  pageSizes: InvoicingGridPageSizes[];
}

export type InvoicingGridPageSizes = 20 | 50 | 100;
export type InvoicedActivityStatus =
  | InvoiceActivityStatus.Invoiced
  | InvoiceActivityStatus.NotInvoiced;

export interface InvoiceFilters {
  dateTypeFilterIndex: InvoiceDateTypeFilter;
  dateRange: Interval;
  searchString: string | null;
  showServiceActivities: boolean;
  agreements: string[];
  employees: string[];
  shiftCategories: string[];
  activityCategories: string[];
  partners: string[];
  jobId: string[];
  profession: string[];
  invoiced: InvoicedActivityStatus | null;
  shiftLabelsIds: string[];
  shiftUserLabelIds: string[];
  orgUnit: string[];
  shiftProjectIds: string[];
}

export enum InvoiceDateTypeFilter {
  'ByService' = 0,
  'ByShift' = 1,
}

export interface ActivityViewModelShiftDetails {
  shiftName: string;
  shiftId: string;
  shiftReportedStartDate: string | null;
}

export interface ActivityViewModelServiceDetails {
  serviceName: string;
  serviceId: string;
}

export interface SelectedActivityChecks {
  isSameClient: boolean;
  doActivitiesHaveAgreements: boolean;
}

export interface ActivityInvoiceModel {
  invoiceNumber: number;
  id: string;
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Shift } from '@wilson/interfaces';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-shift-name-exists-tooltip',
  standalone: true,
  imports: [CommonModule, NzToolTipModule, TranslateModule],
  templateUrl: './shift-name-exists-tooltip.component.html',
  styleUrl: './shift-name-exists-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftNameExistsTooltipComponent {
  @Input({
    required: true,
  })
  shift!: Shift;
}

<div class="header py-3 d-flex align-items-center justify-content-center">
  <img class="img-fluid" src="assets/img/nav/wilson-white-log.svg" />
</div>

<div class="row mt-4 px-2 justify-content-center">
  <div class="col-xl-6 col-md-9 col mb-2">
    <div class="text-center text-light font-weight-normal">
      Datenschutzerklärung für die Nutzung von WILSON.
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-2">
      {{ '1. Allgemeine Informationen' }}
    </div>

    <div class="text-dark text-center font-weight-bold mt-3 py-1">
      {{ '1.1 Geltungsbereich dieser Datenschutzerklärung' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Mit der nachfolgenden Datenschutzerklärung möchten wir Dich über Art,
        Umfang und Zweck der Erhebung und Verarbeitung Deiner personenbezogenen
        Daten bei Nutzung der mobile App WILSON. und bei der Nutzung des WILSON.
        Portals (nachfolgend „WILSON“) informieren, die von der Menlo79 GmbH,
        Wilhelmine Gemberg Weg 6, 10179 Berlin (nachfolgend auch „wir“ oder
        „M79.“) angeboten und bereitgestellt wird.
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Personenbezogene Daten sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person beziehen.
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Wir verarbeiten sämtliche personenbezogenen Daten ausschließlich gemäß
        den Bestimmungen der EU-Datenschutzgrundverordnung (Verordnung (EU)
        2016/679, DSGVO), des Bundesdatenschutzgesetzes (BDSG) sowie des
        Telemediengesetzes (TMG).
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Falls wir auf beauftragte Diensteanbieter zurückgreifen, werden wir Dich
        untenstehend im Detail über die jeweiligen Vorgänge informieren.
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '1.2 Verantwortlich für die Datenverarbeitung' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Verantwortlich für die Erhebung und Verarbeitung Deiner
        personenbezogenen Daten ist die:
      </small>
      <small class="d-block"> Menlo79 GmbH </small>
      <small class="d-block"> Wilhelmine Gemberg Weg 6 </small>
      <small class="d-block"> 10179 Berlin </small>
      <small class="d-block">
        E-Mail:
        <a class="text-decoration-none" href="mailto:contact@menlo79.com"
          >contact&#64;menlo79.com</a
        >
      </small>
      <small class="d-block"> Datenschutzbeauftragter: </small>
      <small class="d-block"> Wolfgang Wenk</small>
      <small class="d-block">
        <a class="text-decoration-none" href="mailto:w.wenk@pagestreet.de"
          >w.wenk&#64;pagestreet.de
        </a>
      </small>
    </div>
    <div class="px-3 text-center mt-4">
      <small>Weitere Informationen zu M79 erhältst Du auch im Impressum.</small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '1.3 Kontaktaufnahme mit M79' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Wenn Du mit uns Kontakt aufnimmst (z.B. via E-Mail), verarbeiten wir
        Deine uns von Dir mitgeteilten Informationen (E-Mail-Adresse, ggf. Name,
        Telefonnummer und der Inhalt Deiner Anfrage), um Deine Frage zu
        beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir,
        nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die
        Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
        Rechtsgrundlage der Verarbeitung sind Art. 6 Abs. 1 S. 1 lit. b DSGVO,
        wenn Gegenstand Deiner Anfrage vor-vertragliche Informationen sind oder
        Art. 6 Abs. 1 S. 1 lit f DSGVO, weil ein berechtigtes Interesse von M79
        daran besteht, Deine Anfrage zu bearbeiten.
      </small>
      <div class="text-dark text-center font-weight-bold mt-4 py-1">
        {{ '1.4 Datenverarbeitung bei Download und Nutzung der App' }}
      </div>
      <small class="d-block">
        Bei der Nutzung von WILSON werden personenbezogene Daten von Dir erhoben
        und verarbeitet. Der Umfang der Verarbeitung kann variieren, je nachdem,
        welche Funktionen Du verwendest. Soweit wir dabei die Services von
        Diensteanbietern in Anspruch nehmen, informieren wir Dich hierzu unter
        Ziffer 3.
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-2">
      {{ '2. Technische Informationen' }}
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '2.1 Technisch erforderliche Verarbeitung' }}
    </div>
    <div class="px-3 text-center">
      <small> a) Beim Herunterladen der App </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Beim Herunterladen der App werden vom Storebetreiber festgelegte
        Informationen an den App Store (Apple App Store und Google Play)
        übertragen, d.h. insbesondere Name, E-Mail-Adresse und Kundennummer
        Deines Kundenkontos, Zeitpunkt des Downloads, Zahlungsinformationen und
        die individuelle Gerätekennziffer. Auf diese Datenerhebung haben wir
        keinen Einfluss und sind hierfür nicht verantwortlich.
      </small>
    </div>
    <div class="px-3 text-center">
      <small> b) Beim Starten der App </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Beim Starten der App wird die Geräte-ID Deines Smartphones an unsere
        Server übermittelt, um Dir Push-Nachrichten zusenden zu können.
        Gesetzliche Grundlage ist das berechtigte Interesse, die
        Personaldispositions- und Steuerungsprozesse zu gestalten (Art. 6 I f
        EU-DSGVO). Du kannst diese Funktionalität beim Start der App
        unterbinden.
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '2.2 Dauer der Speicherung Löschung von personenbezogenen Daten' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Wir verarbeiten und speichern personenbezogene Daten nur für den
        Zeitraum, der zur Erreichung des jeweiligen Verarbeitungszwecks
        erforderlich ist oder solange und in dem Maße, wie es gesetzliche
        Vorgaben vorschreiben, mindestens aber für die Dauer der gesetzlichen
        Verjährungsfristen.
      </small>
    </div>
    <div class="px-3 text-center mt-2">
      <small>
        Entfällt der Speicherungszweck, widerrufst Du Deine Einwilligung oder
        läuft eine gesetzlich vorgeschriebene Speicherfrist ab, werden die
        personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen
        Vorschriften gesperrt oder gelöscht.
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-2">
      {{ '3. Einbindung von Diensten Dritter' }}
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '3.1 Cloudlösungen' }}
    </div>
    <div class="px-3 text-center">
      <small>
        M79 nutzt die Cloudlösungen verschiedener Diensteanbieter, um die
        Anwendung zur Verfügung stellen und Inhalte der App hosten zu können.
        Hieran haben wir ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1
        S. 1 lit. f DSGVO. Dabei werden auf Grundlage einer
        datenschutzrechtlichen Vereinbarung und nach Maßgabe unserer Weisung
        alle Informationen an die Cloud-Anbieter übermittelt, die im Rahmen der
        Nutzung unserer App gemäß Ziffer 1.3, 2.2 und 2.3. anfallen und auf
        Servern der Diensteanbieter, die jeweils innerhalb der EU stehen,
        solange gespeichert, bis wir diese löschen. Die folgenden
        Diensteanbieter kommen hierfür zum Einsatz:
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ 'a) Amazon Web Services' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Wir nutzen mit der Cloudlösung „Amazon Web Services“, einen Service von
        Amazon Web Services 410 Terry Avenue North Seattle, WA 98109-5210
        (nachfolgend „AWS“). Zur Speicherung von Daten werden ausschließlich
        Server mit einem Standort innerhalb von Deutschland genutzt.
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Sonstige etwaige Datenübermittlungen von AWS in die USA unterliegen den
        Standard Vertragsklauseln nach Art. 46 Abs. 2 DSGVO.
      </small>
    </div>
    <div class="px-3 text-center">
      <small class="d-block mt-2">
        Weitere Informationen zum Datenschutz bei AWS erhältst Du unter
        <a
          class="text-decoration-none"
          href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf"
          >https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf</a
        >
      </small>
      <small class="d-block m-2">
        Weitere Informationen zur Datennutzung im Rahmen von AWS erhältst Du
        unter:
      </small>
      <small class="d-block m-2">
        <a
          class="text-decoration-none"
          href="https://d1.awsstatic.com/whitepapers/compliance/soc3_amazon_web_services.pdf"
          >https://d1.awsstatic.com/whitepapers/compliance/soc3_amazon_web_services.pdf</a
        >
      </small>
      <small class="d-block m-2">
        <a
          class="text-decoration-none"
          href="https://aws.amazon.com/de/compliance/bsi-c5/"
          >https://aws.amazon.com/de/compliance/bsi-c5/</a
        >
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ 'b) TalkJS' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Für Chat Nachrichten zwischen den Nutzern unserer App greifen wir auf
        den Dienst TalkJS zurück. Dabei werden Chat-Nachrichten und
        Kommunikationsmetadaten an die Server von TalkJS übertragen. Deren
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        Standort ist innerhalb der EU.
        <a
          class="text-decoration-none"
          href="https://talkjs.com/pdf/talkjs_terms.pdf"
          >https://talkjs.com/pdf/talkjs_terms.pdf</a
        >
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ 'c) SendGrid / Twilio' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Um das Senden von E-Mail-Benachrichtigungen zu ermöglichen, greift der
        Auftragnehmer auf den Dienst SendGrid (ein Tochterunternehmen von
        Twilio) zurück. Dabei werden die Ziel-E-Mail-Adresse und
        Kommunikationsmetadaten an die Server von SendGrid übertragen. SendGrid
        sichert in seinen Nutzungsbedingungen die Einhaltung der
        Vertragsklauseln nach Art. 46 Abs.2 DSGVO zu.
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        <a
          class="text-decoration-none pe-1"
          href="https://www.twilio.com/legal/data-protection-addendum/data-protection-addendum-augsept-2021"
        >
          https://www.twilio.com/legal/data-protection-addendum/data-protection-addendum-augsept-2021
        </a>
      </small>
    </div>

    <div class="px-3 text-center">
      <small>
        <a
          class="text-decoration-none pe-1"
          href="https://www.twilio.com/legal/tos"
        >
          https://www.twilio.com/legal/tos
        </a>
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ 'd) Google Firebase' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Um das Senden von Push-Benachrichtigungen zu ermöglichen, greift der
        Auftragnehmer auf den Dienst Google Firebase Cloud Messaging (Google
        LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
        nachfolgend „Google”) zurück. Für die Übertragung und Speicherung dieser
        Daten sichert Google die Einhaltung der Vertragsklauseln nach Art. 46
        Abs.2 DSGVO zu. Darüber hinaus hat Google Cloud Services eine
        Bescheinigung für den Cloud Computing Compliance Criteria Catalogue
        ("C5:2020") des Bundesamt für Sicherheit in der Informationstechnik
        erhalten.
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        <a
          class="text-decoration-none pe-1"
          href="https://firebase.google.com/terms/data-processing-terms"
        >
          https://firebase.google.com/terms/data-processing-terms
        </a>
      </small>
    </div>
    <div class="px-3 text-center">
      <small>
        <a
          class="text-decoration-none pe-1"
          href="https://firebase.google.com/support/privacy/"
        >
          https://firebase.google.com/support/privacy
        </a>
      </small>
    </div>
    <div class="px-3 text-center mb-2">
      <small>
        <a
          class="text-decoration-none pe-1"
          href="https://cloud.google.com/security/compliance/bsi-c5"
        >
          https://cloud.google.com/security/compliance/bsi-c5
        </a>
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-2">
      {{ '4. Deine Rechte zum Schutz Deiner personenbezogenen Daten' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Nach Maßgabe der DSGVO sowie nach Maßgabe der einschlägigen Vorschriften
        des Unionsrechts und des nationalen Rechts stehen Dir im Umfang der
        jeweiligen Vorschrift folgende Rechte zu, die Du gegenüber M79 z.B.
        durch eine Nachricht an
        <a class="text-decoration-none" href="mailto:contact@menlo79.com"
          >contact&#64;menlo79.com</a
        >
        oder an die unter Ziffer 1.2. genannten Kontaktdaten geltend machen
        kannst. M79 wird Deine Rechte erfüllen, wenn die entsprechenden
        Voraussetzungen hierfür vorliegen:
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.1 Recht auf Auskunft' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Du kannst gemäß Art. 15 DSGVO von M79 eine Bestätigung darüber
        verlangen, ob personenbezogene Daten, die Dich betreffen, von M79
        erhoben, verarbeitet und gespeichert werden. Liegt eine solche Erhebung,
        Verarbeitung und Speicherung vor, kannst Du von M79 u.a. Auskunft über
        die Zwecke verlangen, zu denen die personenbezogenen Daten erhoben und
        verarbeitet werden und die geplante Dauer der Speicherung der Dich
        betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu
        nicht möglich sind, über Kriterien für die Festlegung der Speicherdauer.
      </small>
    </div>

    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.2 Recht auf Berichtigung und Vervollständigung' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Sofern die erhobenen und verarbeiteten personenbezogenen Daten, die Dich
        betreffen, unrichtig oder unvollständig sind, hast Du gemäß Art 16 DSGVO
        gegenüber M79 ein Recht auf Berichtigung und/oder Vervollständigung
        Deiner personenbezogenen Daten.
      </small>
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.3 Recht auf Einschränkung der Verarbeitung' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Du kannst gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung der
        Dich betreffenden personenbezogenen Daten durch M79 verlangen.
      </small>
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.4 Recht auf Löschung' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Du kannst gemäß Art. 17 DSGVO von M79 verlangen, dass die Dich
        betreffenden personenbezogenen Daten unverzüglich gelöscht werden. M79
        ist ggf. verpflichtet, diese Daten unverzüglich zu löschen, sofern die
        Dich betreffenden personenbezogenen Daten für die Zwecke, für die sie
        erhoben oder auf sonstiger Weise verarbeitet wurden, nicht mehr
        notwendig sind oder, wenn Du Deine Einwilligung, auf die sich die
        Erhebung, Verarbeitung und Speicherung bezieht, widerrufen hast. Das
        Recht auf Löschung besteht u.a. nicht, soweit die Verarbeitung zur
        Erfüllung einer rechtlichen Verpflichtung erforderlich ist, die die
        Erhebung und Verarbeitung nach dem Recht der Union oder der
        Mitgliedsstaaten, dem M79 unterliegt, erfordert und soweit die Erhebung
        und Verarbeitung zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen notwendig ist.
      </small>
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.5 Recht auf Datenübertragbarkeit' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Du hast gemäß Art. 20 DSGVO das Recht, die Dich betreffenden
        personenbezogenen Daten, die Du uns als Verantwortlichem für die
        Datenerhebung bereitgestellt hast, in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten. Außerdem hast Du das Recht, diese
        Daten einem anderen Verantwortlichen, ohne Behinderung durch uns zu
        übermitteln.
      </small>
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.6 Widerspruchsrecht' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Du hast gemäß Art. 21 DSGVO das Recht, gegen die Verarbeitung der Dich
        betreffenden personenbezogenen Daten zu widersprechen. Gemäß Art. 21
        Abs. 1 DSGVO kannst Du aus Gründen, die sich aus Deiner besonderen
        Situation ergeben, gegen die Verarbeitung von personenbezogenen Daten,
        die aufgrund von Artikel 6 Abs. 1 S. 1 lit. f DSGVO erfolgt, Widerspruch
        einlegen. Gegen Direktwerbung kannst Du gemäß Art. 21 Abs. 2 DSGVO
        jederzeit widersprechen.
      </small>
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.7 Recht auf Widerruf der Einwilligungserklärung' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Falls Du uns gegenüber in die Erhebung und Verarbeitung Deiner
        personenbezogenen Daten eingewilligt hast, hast Du das Recht, Deine
        datenschutzrechtliche Einwilligungserklärung gemäß Art. 7 Abs. 3 DSGVO
        jederzeit zu widerrufen. Verarbeitungen, die vor dem Widerruf erfolgt
        sind, bleiben hiervon unberührt.
      </small>
    </div>
    <div class="text-dark text-center font-weight-bold mt-4 py-1">
      {{ '4.8 Recht auf Beschwerde bei einer Aufsichtsbehörde' }}
    </div>
    <div class="px-3 text-center">
      <small>
        Dir steht gemäß Art. 77 DSGVO das Recht auf Beschwerde bei einer
        Aufsichtsbehörde, insbesondere in dem Mitgliedsstaat Deines
        Aufenthaltsortes, Deines Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes zu, wenn Du der Ansicht bist, dass die Erhebung und
        Ver-arbeitung der Dich betreffenden personenbezogenen Daten gegen die
        DSGVO verstößt.
      </small>
    </div>
    <div class="px-3 text-center">
      <small> Stand: Februar 2022 </small>
    </div>
  </div>
</div>

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ShiftPlan, ShiftPlanTemplate } from '@wilson/interfaces';
import { ShiftPlanStoreService } from '@wilson/shift-plan/services';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'wilson-shift-template-link-count',
  template: `
    <span
      *ngIf="count"
      class="count"
      nz-tooltip
      [nzTooltipTitle]="tooltipContent"
      [nzTooltipColor]="'#fff'"
      >{{ count }} {{ 'general.plans' | translate }}</span
    >
    <span *ngIf="!count">{{ '---' }}</span>
    <ng-template #tooltipContent>
      <div class="px-2 mb-1">
        <div *ngFor="let item of shiftPlanNameAndId$ | async">
          <a target="_blank" [routerLink]="['/', 'shift-plan', item.id]">
            {{ item.name }}
          </a>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./shift-template-link-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftTemplateLinkCountComponent implements AgRendererComponent {
  count: number | null;
  shiftPlanNameAndId$: Observable<
    {
      name: string;
      id: string;
    }[]
  > = of([]);

  constructor(private shiftPlanStoreService: ShiftPlanStoreService) {}

  agInit(params: ICellRendererParams): void {
    this.initData(params);
  }

  refresh(params: ICellRendererParams) {
    this.initData(params);
    return true;
  }

  private initData(params: ICellRendererParams) {
    this.count = this.getCount(params.value);
    this.shiftPlanNameAndId$ = this.mapShiftPlanIdsToName(params.value);
  }

  private getCount(shiftPlanTemplates: ShiftPlanTemplate[]): number | null {
    return shiftPlanTemplates.length || null;
  }

  private getShiftPlanWithId(shitplanId: string, shiftPlans: ShiftPlan[]) {
    return shiftPlans.find((plan) => plan.id === shitplanId);
  }

  private mapShiftPlanIdsToName(shiftPlanTemplates: ShiftPlanTemplate[]) {
    return this.shiftPlanStoreService.shiftPlans$.pipe(
      map((shiftPlans) => {
        return shiftPlanTemplates.map((template) => {
          const shiftPlanId = template.shiftPlanId as string;
          const relatedShiftPlan = this.getShiftPlanWithId(
            shiftPlanId,
            shiftPlans,
          );

          return {
            name: relatedShiftPlan?.name as string,
            id: shiftPlanId,
          };
        });
      }),
    );
  }
}

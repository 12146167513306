import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, of } from 'rxjs';
import { OrganizationalUnitsState } from '../state/organizational-unit-state.state';
import { OrganizationalUnit } from '@wilson/interfaces';

@Pipe({
  name: 'orgUnitName',
})
export class OrgUnitNamePipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(
    orgUnitId: string | undefined | null,
    emptyStringOnError = false,
  ): Observable<string | undefined> {
    if (!orgUnitId) {
      return of(this.handleError(emptyStringOnError));
    }
    return this.store
      .select(OrganizationalUnitsState.getOrganizationalUnitById(orgUnitId))
      .pipe(
        map((orgUnit: OrganizationalUnit | undefined) => {
          return orgUnit ? orgUnit.name : this.handleError(emptyStringOnError);
        }),
      );
  }

  private handleError(emptyStringOnError: boolean) {
    if (emptyStringOnError) {
      return '';
    } else {
      return 'general.n/a';
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [OrgUnitNamePipe],
  exports: [OrgUnitNamePipe],
})
export class OrgUnitNamePipeModule {}

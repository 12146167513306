import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'dateIsInsideRange',
})
export class DateIsInsideRangePipe implements PipeTransform {
  transform(d: NgbDate, [fromDate, toDate]: NgbDate[]): boolean {
    return toDate && d.after(fromDate) && d.before(toDate);
  }
}

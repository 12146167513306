import { Base } from '@wilson/base';
import { ActivityCategory } from '../activities/activity-category';
import { Certificate } from '../certificates/certificate';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { QualificationCategory } from '../qualification-categories/qualificationCategory';
import { ShiftCategory } from '../shifts/shift';
import { VehicleModel } from '../vehicles/vehicle';

export interface Sector extends Base {
  name: string;
  isActive: boolean;
  organizationalUnits?: OrganizationalUnit[];
  activityCategories?: ActivityCategory[];
  qualificationCategories?: QualificationCategory[];
  shiftCategories?: ShiftCategory[];
  certificates?: Certificate[];
  vehicleModels?: VehicleModel[];
}

export enum SectorEnum {
  Rail = 'rail',
  PublicTransport = 'public-transport',
  Logistics = 'logistics',
}

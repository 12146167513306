import {
  ControlsOf,
  FormArray,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import {
  ShiftPlanTemplate,
  ShiftPlanTemplateKey,
  TemplatePositionData,
  TemplateType,
} from '@wilson/interfaces';
export class TemplateGridFormArray extends FormArray<ShiftPlanTemplate> {
  private static createTemplateGridFormArray(
    templates: ShiftPlanTemplate[],
  ): FormGroup<ControlsOf<ShiftPlanTemplate>>[] {
    return templates.map((template) => {
      const positionFormArray: FormArray<number>[] = template.positions.map(
        (position) =>
          new FormArray<number>(
            position.map((index: number) => new FormControl(index)),
          ),
      );

      return new FormGroup({
        absenceTemplateId: new FormControl(template.absenceTemplateId),
        shiftTemplateId: new FormControl(template.shiftTemplateId),
        // eslint-disable-next-line
        positions: new FormArray(positionFormArray) as any, //Current typing does not allow for form array of array of numbers
      });
    });
  }

  constructor(templates: ShiftPlanTemplate[]) {
    super(TemplateGridFormArray.createTemplateGridFormArray(templates));
  }

  public addTemplatePosition(data: TemplatePositionData) {
    const templateControl:
      | FormGroup<ControlsOf<ShiftPlanTemplate>>
      | undefined = this.findTemplateWithId(data.templateId, data.templateType);

    if (templateControl) {
      this.addPositionToExistingTemplateControl(
        templateControl,
        data.rowIndex,
        data.columnIndex,
      );
    } else {
      // Setting to type any because reactive form library cannot handle form array of arrays of numbers
      this.addPositionAlongWithANewTemplateControl(data);
    }
  }
  public removeTemplatePositions(data: TemplatePositionData[]) {
    data.forEach((templatePositionData) => {
      const templateControl:
        | FormGroup<ControlsOf<ShiftPlanTemplate>>
        | undefined = this.findTemplateWithId(
        templatePositionData.templateId,
        templatePositionData.templateType,
      );

      if (templateControl) {
        templateControl
          .get(ShiftPlanTemplateKey.Positions)
          .removeWhen(
            (positionForm) =>
              positionForm.value[1] === templatePositionData.rowIndex &&
              positionForm.value[0] === templatePositionData.columnIndex,
          );
      }
    });

    this.cleanUpTemplatesWithNoPositions();
  }
  public removePositionFromTemplatesWithRowIndex(rowIndex: number) {
    this.controls.forEach(
      (shiftTemplateFormGroup: FormGroup<ControlsOf<ShiftPlanTemplate>>) => {
        const positionsFormArray: FormArray<[number, number]> =
          shiftTemplateFormGroup.get(ShiftPlanTemplateKey.Positions);

        positionsFormArray.removeWhen(
          (positionForm) => positionForm.value[1] === rowIndex,
        );

        // We also need to decrement all the rows below the row index
        // so that the mapping between user index on the table and their relevant templates are aligned again
        this.decrementAllRowsAfter(rowIndex, positionsFormArray);
      },
    );

    this.cleanUpTemplatesWithNoPositions();
  }

  public removePositionFromTemplatesWithLastColumnIndexOf(
    lastColumnIndex: number,
  ) {
    this.controls.forEach(
      (shiftTemplateFormGroup: FormGroup<ControlsOf<ShiftPlanTemplate>>) => {
        const positionsFormArray: FormArray<[number, number]> =
          shiftTemplateFormGroup.get(ShiftPlanTemplateKey.Positions);

        positionsFormArray.removeWhen(
          (positionForm) => positionForm.value[0] === lastColumnIndex,
        );
      },
    );

    this.cleanUpTemplatesWithNoPositions();
  }

  private cleanUpTemplatesWithNoPositions() {
    this.removeWhen(
      (shiftTemplateFormGroup) =>
        shiftTemplateFormGroup.get(ShiftPlanTemplateKey.Positions).value
          .length === 0,
    );
  }

  private findTemplateWithId(
    id: string,
    templateType: TemplateType,
  ): FormGroup<ControlsOf<ShiftPlanTemplate>> | undefined {
    const templateKeyToCheck =
      templateType === 'absence'
        ? ShiftPlanTemplateKey.AbsenceTemplateId
        : ShiftPlanTemplateKey.ShiftTemplateId;

    return this.controls.find(
      (control) => control.value[templateKeyToCheck] === id,
    );
  }

  private decrementAllRowsAfter(
    rowIndex: number,
    positionsFormArray: FormArray<[number, number]>,
  ) {
    positionsFormArray.controls.forEach((positionFormArray) => {
      const currentPositionRowIndex = positionFormArray.value[1];
      if (currentPositionRowIndex > rowIndex)
        positionFormArray.controls[1].setValue(currentPositionRowIndex - 1);
    });
  }

  private addPositionToExistingTemplateControl(
    templateControl: FormGroup<ControlsOf<ShiftPlanTemplate>>,
    rowIndex: number,
    columnIndex: number,
  ) {
    const positionsFormArray: FormArray<[number, number]> = templateControl.get(
      ShiftPlanTemplateKey.Positions,
    );

    const isTemplateWithThisCoordinateAlreadyExist =
      !!positionsFormArray.controls.find(
        (
          positionFormArray: FormGroup<
            [FormControl<number>, FormControl<number>]
          >,
        ) =>
          positionFormArray.value[1] === rowIndex &&
          positionFormArray.value[0] === columnIndex,
      );

    if (!isTemplateWithThisCoordinateAlreadyExist) {
      // Setting to type any because reactive form library cannot handle form array of arrays of numbers
      positionsFormArray.push(
        // eslint-disable-next-line
        this.createPositionFormArray(rowIndex, columnIndex) as any,
      );
    }
  }

  private addPositionAlongWithANewTemplateControl(data: TemplatePositionData) {
    const newTemplateControl = new FormGroup<ControlsOf<ShiftPlanTemplate>>({
      absenceTemplateId: new FormControl(),
      shiftTemplateId: new FormControl(),
      // Setting to type any because reactive form library cannot handle form array of arrays of numbers
      positions: new FormArray([
        this.createPositionFormArray(data.rowIndex, data.columnIndex),
        // eslint-disable-next-line
      ]) as any,
    });

    if (data.templateType === 'absence') {
      newTemplateControl
        .get(ShiftPlanTemplateKey.AbsenceTemplateId)
        .setValue(data.templateId);
    } else {
      newTemplateControl
        .get(ShiftPlanTemplateKey.ShiftTemplateId)
        .setValue(data.templateId);
    }
    this.push(newTemplateControl);
  }

  private createPositionFormArray(
    rowIndex: number,
    columnIndex: number,
  ): FormArray<number> {
    return new FormArray<number>([
      new FormControl<number>(columnIndex),
      new FormControl<number>(rowIndex),
    ]);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-vacation-days-renderer',
  template: `
    <div class="d-flex align-items-center">
      <span class="text-dark" *ngIf="cellValue; else noDays">
        {{ cellValue }} {{ 'page.settings.contract.unit_days' | translate }}
      </span>
      <ng-template #noDays>
        <span class="text-dark"> -- </span>
      </ng-template>
    </div>
  `,
  styles: [
    `
      div {
        height: 48px !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationDaysRendererComponent implements AgRendererComponent {
  public cellValue: string | undefined;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams): string {
    return params.value;
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  CreateWorkingTimeModel,
  UserWorkingTimeModel,
  WorkingTimeModel,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';
import { createRelationsQueryParamString } from '@wilson/utils';

interface WorkingTimeOption {
  where?: {
    workingTimeModelId?: string;
    userId?: string;
  };
  relations?: string[];
}
@Injectable()
export class WorkingTimeModelService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getOrganizationalUnitWorkingTimeModels(
    organizationalUnitId: string,
    relations: string[] = [],
  ): Observable<WorkingTimeModel[]> {
    const relationsQuery = relations.length
      ? createRelationsQueryParamString(relations)
      : '';

    const queryParameter = relationsQuery
      ? `?${relationsQuery}&limit=0`
      : '?limit=0';

    return this.httpClient.get<WorkingTimeModel[]>(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models${queryParameter}`,
    );
  }

  getOrganizationalUnitWorkingTimeModelById(
    workingTimeModelById: string,
    organizationalUnitId: string,
    relations: string[] = [],
  ): Observable<WorkingTimeModel> {
    const queryParameter = relations.length
      ? '?' + createRelationsQueryParamString(relations)
      : '';

    return this.httpClient.get<WorkingTimeModel>(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models/${workingTimeModelById}${queryParameter}`,
    );
  }

  createUnitWorkingTimeModel(
    organizationalUnitId: string,
    workingTimeModel: WorkingTimeModel,
  ): Observable<WorkingTimeModel> {
    return this.httpClient.post<WorkingTimeModel>(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models`,
      {
        items: [workingTimeModel],
      },
    );
  }

  updateUnitWorkingTimeModel(
    organizationalUnitId: string,
    workingTimeModelById: string,
    workingTimeModel: WorkingTimeModel,
  ): Observable<WorkingTimeModel> {
    return this.httpClient.patch<WorkingTimeModel>(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models/${workingTimeModelById}`,
      {
        ...workingTimeModel,
      },
    );
  }

  deleteWorkingTimeModel(
    workingTimeModelById: string,
    organizationalUnitId: string,
  ) {
    return this.httpClient.delete(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models/${workingTimeModelById}`,
    );
  }

  getActiveUserModelByWorkingTimeModelId(
    workingTimeModelById: string,
    organizationalUnitId: string,
  ): Observable<WorkingTimeModel> {
    return this.httpClient.get<WorkingTimeModel>(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models/${workingTimeModelById}/active-user-models`,
    );
  }

  bulkDeleteUsersAssignedWorkingTimeModel(userIds: string[]) {
    return this.httpClient.patch(
      `${this.config.host}/user-working-time-models/delete`,
      {
        ids: userIds,
      },
    );
  }

  getActiveUserWorkingTimeModel(
    userId: string,
    date: string,
  ): Observable<UserWorkingTimeModel> {
    return this.httpClient.get<UserWorkingTimeModel>(
      `${this.config.host}/user-working-time-models/${userId}/active/${date}`,
    );
  }

  getAllWorkingTimeModelUsers(
    queryParams: WorkingTimeOption,
  ): Observable<UserWorkingTimeModel[]> {
    const params = stringify(queryParams);
    return this.httpClient.get<UserWorkingTimeModel[]>(
      `${this.config.host}/user-working-time-models?${params}`,
    );
  }

  getUsersAssignedWorkingTimeModel(
    queryParams: WorkingTimeOption,
  ): Observable<UserWorkingTimeModel[]> {
    const params = stringify(queryParams);
    return this.httpClient.get<UserWorkingTimeModel[]>(
      `${this.config.host}/user-working-time-models/users/assigned?${params}`,
    );
  }

  getActiveUserWorkingTimeModels(
    workingTimeModelId: string,
    organizationalUnitId: string,
  ): Observable<UserWorkingTimeModel[]> {
    return this.httpClient.get<UserWorkingTimeModel[]>(
      `${this.config.host}/organizational-units/${organizationalUnitId}/working-time-models/${workingTimeModelId}/active-users`,
    );
  }

  createUserWorkingTimeModel(
    payload: CreateWorkingTimeModel[],
  ): Observable<WorkingTimeModel> {
    return this.httpClient.post<WorkingTimeModel>(
      `${this.config.host}/user-working-time-models`,
      {
        items: payload,
      },
    );
  }

  /**
   * countWeekHoursInDateFormat is returning Date in toISOString format
   */
  countWeekHoursInDateFormat(weekDays: WorkingTimeModel): string {
    const { hours, minutes } = this.countWeekHours(weekDays);
    const currentDate = new Date();
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes,
      0,
    ).toISOString();
  }

  /**
   * countWeekHoursInDateString
   * @returns return converted hour and minutes
   */
  countWeekHoursInDateString(weekDays: WorkingTimeModel): string {
    if (!weekDays) return '0';
    const { hours, minutes } = this.countWeekHours(weekDays);
    return `${hours > 9 ? hours : '0' + hours}:${
      minutes > 9 ? minutes : '0' + minutes
    }`;
  }

  private countWeekHours(weekDays: WorkingTimeModel): {
    hours: number;
    minutes: number;
  } {
    const countedHourAmt =
      weekDays.monday +
      weekDays.tuesday +
      weekDays.wednesday +
      weekDays.thursday +
      weekDays.friday +
      weekDays.saturday +
      weekDays.sunday;

    const minutes = countedHourAmt % 60;
    const hours = Math.floor(countedHourAmt / 60);
    return { hours, minutes };
  }
}

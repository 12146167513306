import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wilson/interfaces';
import { ActivityTimeDifferenceCheckPipe } from '../activity-time-difference-check/activity-time-difference-check.pipe';
import { IsActivityLocationMismatchPipe } from '../activity-location-check/is-activity-location-mismatch.pipe';
import { ActivityTimeOverday } from '@wilson/non-domain-specific/shift-helpers/core';

@Pipe({
  name: 'totalNumberOfGapIssues',
})
export class TotalNumberOfGapIssuesPipe implements PipeTransform {
  constructor(
    private activityTimeDifferenceCheckPipe: ActivityTimeDifferenceCheckPipe,
    private isActivityLocationMismatchPipe: IsActivityLocationMismatchPipe,
    private activityTimeOverday: ActivityTimeOverday,
  ) {}

  transform(
    activities: Activity[],
    {
      shiftStartDate,
      disabled,
      shiftSeries,
    }: { shiftStartDate: string; disabled: boolean; shiftSeries: boolean },
  ): number {
    let totalNumberOfTimeGapIssues = 0;
    activities.forEach((activity, index) => {
      if (
        this.activityTimeDifferenceCheckPipe.transform(activities, {
          index,
          shiftStartDate,
          overlapDaysCurrent: this.activityTimeOverday.transform(
            activities,
            shiftStartDate,
            index,
            false,
            disabled,
            shiftSeries,
          ),
          overlapDaysNext: this.activityTimeOverday.transform(
            activities,
            shiftStartDate,
            index + 1,
            true,
            disabled,
            shiftSeries,
          ),
        }) > 0
      ) {
        totalNumberOfTimeGapIssues++;
      }

      if (this.isActivityLocationMismatchPipe.transform(activities, index)) {
        totalNumberOfTimeGapIssues++;
      }
    });

    return totalNumberOfTimeGapIssues;
  }
}

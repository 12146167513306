import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CleanUmlautsService {
  public cleanUmlauts(string: string) {
    return string
      .replace(/ä/g, 'ae')
      .replace(/Ä/g, 'Ae')
      .replace(/ö/g, 'oe')
      .replace(/Ö/g, 'Oe')
      .replace(/ü/g, 'ue')
      .replace(/Ü/g, 'Ue')
      .replace(/ß/g, 'ss');
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-share-select-tooltip',
  template: `
    <span
      *ngIf="hasShiftSeries"
      style="margin-left:2px; margin-bottom:2px; "
      class="fw-bold text-light custom-tooltip"
    >
      {{ 'wilson.share.tooltip.part_of_shift_series' | translate }}
    </span>
  `,
  styles: [
    `
      .custom-tooltip {
        background-color: var(--secondary-dark-light);
        color: var(--grey-8);
        padding: 3px;
        max-width: 20px;
        border-radius: 4px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareSelectTooltipComponent implements ICellRendererAngularComp {
  hasShiftSeries: boolean;
  async agInit(params: ICellRendererParams) {
    this.hasShiftSeries = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.hasShiftSeries = params.value;
    return true;
  }
}

import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';

export function SplitActivityDateValidator(
  startDate: string,
  endDate: string,
): ValidatorFn {
  return (dateControl): ValidationErrors | null => {
    if (!dateControl.value) {
      return {
        isInvalid: true,
      };
    }
    const date: Date = dateControl.value;
    if (
      differenceInCalendarDays(new Date(startDate), date) === 0 ||
      differenceInCalendarDays(new Date(endDate), date) === 0
    ) {
      return null;
    }
    if (
      isAfter(date, new Date(startDate)) &&
      isBefore(date, new Date(endDate))
    ) {
      return null;
    }
    return {
      isInvalid: true,
    };
  };
}

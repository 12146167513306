import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Activity, ActivityTemplate } from '@wilson/interfaces';

@Injectable()
export class ActivityStartRendererService {
  constructor(private datePipe: DatePipe) {}

  public getFormattedDate(data: Activity | ActivityTemplate) {
    return this.datePipe.transform(data.startDatetime, 'dd.MM.yy') + ',';
  }

  public getFormattedHour(data: Activity | ActivityTemplate) {
    return this.datePipe.transform(data.startDatetime, 'HH:mm');
  }

  public getStartLocation(data: Activity | ActivityTemplate) {
    if (!data?.startLocation) return '---';
    return (
      data?.startLocation?.locationCode + ' - ' + data?.startLocation?.name
    );
  }

  public toString(data: Activity) {
    return [
      this.getFormattedDate(data),
      this.getFormattedHour(data),
      this.getStartLocation(data),
    ].join(' ');
  }
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmWithHighlightDialogueComponent } from './confirm-with-highlight-dialogue.component';

@Injectable()
export class ConfirmWithHighlightDialogueService {
  constructor(private readonly modalService: NgbModal) {}

  public openConfirmationPopup(
    title: string,
    primaryMessage: string,
    secondaryMessage: string,
    cancelButtonText: string,
    confirmButtonText: string,
    highlightTextColor: string,
    highlightClass: string,
    confirmButtonClass: string,
  ): Promise<boolean> {
    const modalRef = this.modalService.open(
      ConfirmWithHighlightDialogueComponent,
      {
        size: 'md',
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.primaryMessage = primaryMessage;
    modalRef.componentInstance.secondaryMessage = secondaryMessage;
    modalRef.componentInstance.cancelButtonText = cancelButtonText;
    modalRef.componentInstance.confirmButtonText = confirmButtonText;
    modalRef.componentInstance.highlightTextColor = highlightTextColor;
    modalRef.componentInstance.highlightClass = highlightClass;
    modalRef.componentInstance.confirmButtonClass = confirmButtonClass;
    return modalRef.result;
  }
}

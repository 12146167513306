import { LinkRendererService } from '@wilson/ag-grid/service';
import { LinkRendererParams, LinkType } from '@wilson/interfaces';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-shift-link-renderer-with-icon',
  template: `
    <div class="link-renderer-container">
      @if(isEdited){
      <wilson-link-renderer-content
        nz-tooltip
        [nzTooltipTitle]="shiftName"
        nzTooltipPlacement="top"
        [class.text-ellipsis]="isEdited"
        [linkData]="linkData"
      ></wilson-link-renderer-content>
      }@else{
      <wilson-link-renderer-content
        [class.text-ellipsis]="isEdited"
        [linkData]="linkData"
      ></wilson-link-renderer-content>
      }

      <div>
        <img
          *ngIf="isEdited"
          class="w-100 form-icon"
          src="/assets/img/service/step-over-service.svg"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .link-renderer-container {
        display: flex;
        justify-content: space-between;
      }
      .text-ellipsis {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftLinkRendererWithIconComponent
  implements ICellRendererAngularComp
{
  linkData: LinkType | undefined;
  isEdited: boolean;
  shiftName: string;

  constructor(private linkRenderer: LinkRendererService) {}

  agInit(params: ICellRendererParams & LinkRendererParams): void {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
    this.isEdited = !!params.data.shiftSeriesEditLogId;
    this.shiftName = params.data.name;
  }

  refresh(params: ICellRendererParams & LinkRendererParams) {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
    this.isEdited = !!params.data.shiftSeriesEditLogId;
    this.shiftName = params.data.name;
    return true;
  }
}

import { Inject, Injectable } from '@angular/core';
import { AnyAbility } from '@casl/ability';
import { AblePurePipe } from '@casl/angular';
import { TranslateService } from '@ngx-translate/core';
import { StateContext, Store } from '@ngxs/store';
import { FeaturePurePipe } from '@wilson/authorization';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  Activity,
  ActivitySelectionListItem,
  DetermineUnassignedActivitiesOverlapWorker,
  DetermineUnassignedActivitiesOverlapWorkerWrapper,
  FeatureName,
  Job,
  RoleAction,
  RolePermissionSubject,
  Service,
} from '@wilson/interfaces';
import { ActivitiesDataService } from '@wilson/non-domain-specific/activities-helpers/services';
import { isDebugEnabled } from '@wilson/non-domain-specific/logger/logger';
import {
  determineUnassignmentsOverlaps,
  groupActivitiesByServiceAndJob,
} from '@wilson/non-domain-specific/overlap-helpers';
import { endOfDay, setSeconds, startOfDay } from 'date-fns';
import { uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { catchError, first, map, shareReplay, switchMap, tap, zip } from 'rxjs';
import { ShiftTimelineUnassignedRegionStateModel } from '../interfaces';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';
import { TimelineUpdateUnassignedActivities } from './shift-timeline-unassigned-region.actions';
import { NewWilsonJobBucketState } from '@wilson/share/state';

@Injectable({
  providedIn: 'root',
})
export class TimelineFetchUnassignedActivitiesActionService {
  private hasWilsonShareReadAccess$ = zip([
    this.featureFlagPurePipe.transform('wilson-share-feature'),
    this.ablePurePipe.transform(
      RoleAction.Read,
      RolePermissionSubject.WilsonShare,
    ),
    this.featurePurePipe.transform(FeatureName.WilsonShare),
  ]).pipe(
    map(
      ([isEnabledOnLaunchDarkly, hasPermissionToRead, hasWilsonFeature]: [
        boolean,
        boolean,
        boolean,
      ]) => isEnabledOnLaunchDarkly && hasPermissionToRead && hasWilsonFeature,
    ),
    shareReplay(1),
  );

  constructor(
    private store: Store,
    private featureFlagPurePipe: FeatureFlagPurePipe,
    private ablePurePipe: AblePurePipe<AnyAbility>,
    private featurePurePipe: FeaturePurePipe,
    private activitiesDataService: ActivitiesDataService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    @Inject(DetermineUnassignedActivitiesOverlapWorker)
    private determineUnassignedActivitiesOverlapWorkerWrapper: DetermineUnassignedActivitiesOverlapWorkerWrapper,
  ) {}

  handleUpdate(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateUnassignedActivities,
  ) {
    const currentTimeFrame = this.store.selectSnapshot(
      ShiftTimelineDataState.timeframe,
    );

    ctx.patchState({
      isRefreshingUnassignedActivities: true,
    });
    return this.getUnassignedActivities(action.timeFrame).pipe(
      tap(async (activities: ActivitySelectionListItem[]) => {
        const allActivities = uniqBy(
          [...activities, ...ctx.getState().unassignedActivities],
          'id',
        ) as ActivitySelectionListItem[];
        const activitiesToUpdate = allActivities.filter((activities) => {
          const shiftStartDateTime = new Date(activities.startDatetime);
          return (
            shiftStartDateTime >= currentTimeFrame.start &&
            shiftStartDateTime <= currentTimeFrame.end
          );
        });
        const updatablePartialState = await this.getUpdatablePartialState(
          activitiesToUpdate,
        );

        ctx.patchState({
          ...updatablePartialState,
          isRefreshingUnassignedActivities: false,
        });
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  public async getUpdatablePartialState(activitiesToUpdate: Activity[]) {
    const viewRows =
      await this.determineUnassignedActivitiesOverlapWorkerWrapper.execute(
        activitiesToUpdate,
      );
    return {
      unassignedActivitiesViewRows: viewRows,
      ...this.getCommonUpdatablePartialState(activitiesToUpdate),
    };
  }

  public getUpdatablePartialStateSync(activitiesToUpdate: Activity[]) {
    const viewRows = determineUnassignmentsOverlaps({
      ...groupActivitiesByServiceAndJob(activitiesToUpdate),
      isDebugEnabled: isDebugEnabled(),
    });

    return {
      unassignedActivitiesViewRows: viewRows,
      ...this.getCommonUpdatablePartialState(activitiesToUpdate),
    };
  }

  private getCommonUpdatablePartialState(activitiesToUpdate: Activity[]) {
    return {
      unassignedServices: activitiesToUpdate.reduce<Service[]>(
        (services, activity) => {
          if (activity.service) {
            services.push(activity.service);
          }
          return services;
        },
        [],
      ),
      unassignedJobs: activitiesToUpdate.reduce<Job[]>((services, activity) => {
        if (activity.job) {
          services.push(activity.job);
        }
        return services;
      }, []),
      unassignedActivities: activitiesToUpdate,
    };
  }

  private getUnassignedActivities(timeFrame: Interval) {
    const activityRelations = [
      'activityCategory',
      'service',
      'serviceDeviations',
      'profession',
      'startLocation',
      'endLocation',
      'service.labels',
    ];
    const startDate = startOfDay(timeFrame.start);
    const endDate = endOfDay(timeFrame.end);
    const activities = this.hasWilsonShareReadAccess$.pipe(
      switchMap((hasWilsonShareAccess: boolean) =>
        this.activitiesDataService
          .loadUnassignedActivities({
            startDate,
            endDate,
            includeActivitiesFromJobs: hasWilsonShareAccess,
            activityRelations,
            filterOutActivitiesInRequestForProposal: true,
            idsOfItemsInTheBucket: this.store.selectSnapshot(
              NewWilsonJobBucketState.itemIds,
            ),
          })
          .pipe(
            map((activities) => {
              return (
                activities?.map((activity) => {
                  activity.startDatetime = setSeconds(
                    new Date(activity.startDatetime),
                    0,
                  ).toISOString();
                  activity.endDatetime = setSeconds(
                    new Date(activity.endDatetime),
                    0,
                  ).toISOString();
                  return activity;
                }) || []
              );
            }),
          ),
      ),
      first(),
    );
    return activities;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { ShiftAssignedUserIdentityComponent } from '../../shift-assigned-user-identity/shift-assigned-user-identity.component';
import { User } from '@wilson/interfaces';

@Component({
  selector: 'wilson-collapsible-shift-assigned-user-identity',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    AuthorizationModule,
    FeatureFlagsModule,
    TranslateModule,
    ShiftAssignedUserIdentityComponent,
  ],
  templateUrl: './collapsible-shift-assigned-user-identity.component.html',
  styleUrl: './collapsible-shift-assigned-user-identity.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftAssignedUserIdentityComponent {
  @Input() user: User | null | undefined;
  @Input() isExpandedOnOpen = false;
  @Input() renderReassignButton = false;
  @Output()
  clicked = new EventEmitter();
}

import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'ngbDateToDate',
})
export class NgbDateToDatePipe implements PipeTransform {
  transform(ngbDate: NgbDate | null): Date {
    const tzoffset = new Date().getTimezoneOffset() / 60; //offset in hours
    if (ngbDate === null) return new Date(Date.now() - tzoffset);
    const transformedDate = new Date(
      `${ngbDate.year}-${ngbDate.month <= 9 ? '0' : ''}${ngbDate.month}-${
        ngbDate.day <= 9 ? '0' : ''
      }${ngbDate.day}`,
    );
    const timeZoneAdjustedDate = transformedDate.setTime(
      transformedDate.getTime() + tzoffset * 60 * 60 * 1000,
    );
    return new Date(timeZoneAdjustedDate);
  }
}

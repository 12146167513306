import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { GridApi } from 'ag-grid-community';
import { AgGridColumnTreeComponent } from '../ag-grid-column-tree/ag-grid-column-tree.component';

export interface WilsonAgGridColumnOptionModalInput {
  userIdGetter: () => string;
  gridUniqueId: string;
  gridApi: GridApi;
}

@Component({
  selector: 'wilson-ag-grid-column-option-modal',
  standalone: true,
  imports: [CommonModule, AgGridColumnTreeComponent],
  templateUrl: './ag-grid-column-option-modal.component.html',
  styleUrl: './ag-grid-column-option-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridColumnOptionModalComponent implements OnInit {
  protected userId!: string;
  protected gridUniqueId!: string;
  protected gridApi!: GridApi;

  constructor(
    @Inject(NZ_MODAL_DATA) private data: WilsonAgGridColumnOptionModalInput,
  ) {}

  ngOnInit(): void {
    this.userId = this.data.userIdGetter();
    this.gridUniqueId = this.data.gridUniqueId;
    this.gridApi = this.data.gridApi;
  }
}

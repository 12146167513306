import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateContext } from '@ngxs/store';
import {
  ResolvedShiftValidation,
  ShiftValidationDetails,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { getTimeZoneCorrectedDateRange } from '@wilson/utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  catchError,
  concatMap,
  EMPTY,
  finalize,
  first,
  from,
  map,
  Observable,
  of,
  scan,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { ShiftTimelineDataStateModel } from '../interfaces';
import { TimelineUpdateShiftValidations } from './shift-timeline-data.actions';
import {
  filterAndUpdateDataRecords,
  trackAndMaintainMaxRequestCount,
} from './validation-overlap-tracking.helper.fn';

@Injectable({
  providedIn: 'root',
})
export class ShiftValidationsHelperService {
  private unsubFetchUserValidationRequestSubject = new Subject<unknown>();
  private readonly shiftValidationUserIdsBatchSize = 5;

  private updateUserValidationRequests: Subject<unknown>[] = [];

  constructor(
    private shiftsService: ShiftsService,
    private readonly translateService: TranslateService,
    private readonly nzMessageService: NzMessageService,
  ) {}

  private generateBatches(userIds: string[]) {
    const batches = [];
    for (
      let i = 0;
      i < userIds.length;
      i += this.shiftValidationUserIdsBatchSize
    ) {
      batches.push(userIds.slice(i, i + this.shiftValidationUserIdsBatchSize));
    }
    return batches;
  }

  private getRequestChain(
    batches: string[][],
    correctedInterval: {
      start: Date;
      end: Date;
    },
    ctx: StateContext<ShiftTimelineDataStateModel>,
    cancellationSubject: Observable<unknown>,
  ) {
    return from(batches).pipe(
      takeUntil(cancellationSubject),
      concatMap((batch) =>
        this.shiftsService
          .getInDateRangeWithValidations(
            correctedInterval.start,
            correctedInterval.end,
            { userId: batch },
          )
          .pipe(
            first(),
            map((response) => ({
              data: response,
              userIds: batch,
            })),
            catchError((error) =>
              of({
                error,
                data: {
                  data: [],
                  v1RequestError: true,
                  v2RequestError: true,
                },
                userIds: batch,
              }),
            ),
          ),
      ),
      scan(
        (acc, response) => {
          const isLoadingValidationsV2Error =
            'v2ValidationFlagEnabled' in response.data &&
            response.data.v2ValidationFlagEnabled
              ? response.data.v2RequestError
              : false;

          const isLoadingValidationsV1Error = response.data.v1RequestError;

          return {
            userIds: response.userIds,
            hasError:
              acc.hasError ||
              isLoadingValidationsV1Error ||
              isLoadingValidationsV2Error,
            isLoadingValidationsV1Error:
              acc.isLoadingValidationsV1Error || isLoadingValidationsV1Error,
            validations: response.data.data,
          };
        },
        {
          hasError: false,
          isLoadingValidationsV1Error: false,
          userIds: [] as string[],
          validations: [] as ResolvedShiftValidation[],
        },
      ),
      tap(({ hasError, userIds, isLoadingValidationsV1Error, validations }) => {
        const previousIds = ctx
          .getState()
          .usersWithUpdatingValidations.filter(
            (userId) => !userIds.includes(userId),
          );

        const clonedExistingValidations: Record<
          string,
          ShiftValidationDetails[]
        > = {
          ...ctx.getState().usersValidationRecords,
        };

        validations.forEach((userValidation) => {
          clonedExistingValidations[userValidation.shift.id as string] =
            userValidation.validations;
        });

        ctx.patchState({
          isLoadingValidationsV1Error:
            isLoadingValidationsV1Error ||
            ctx.getState().isLoadingValidationsV1Error,
          isLoadingValidationsError: hasError,
          usersValidationRecords: clonedExistingValidations,
          usersWithUpdatingValidations: previousIds,
        });
      }),
    );
  }

  async fetchUserValidations(ctx: StateContext<ShiftTimelineDataStateModel>) {
    this.unsubFetchUserValidationRequestSubject.next({});
    const state = ctx.getState();
    ctx.patchState({
      isLoadingValidations: true,
      isLoadingValidationsError: false,
      usersValidationRecords: {}, // reset the state
    });

    const timelineUserDataWithShifts = Object.values(
      state.usersDictionary,
    ).filter(
      (user) =>
        Object.values(user.shiftsWithoutActivitiesDictionary).length > 0,
    );

    const userIdsWithShifts = timelineUserDataWithShifts.map(
      (user) => user.id as string,
    );
    const correctedInterval = getTimeZoneCorrectedDateRange(state.timeframe);

    const batches = this.generateBatches(userIdsWithShifts);

    const { updatedValidatedUsersRecords } = filterAndUpdateDataRecords(
      {},
      userIdsWithShifts,
      correctedInterval.start,
      correctedInterval.end,
    );

    return this.getRequestChain(
      batches,
      correctedInterval,
      ctx,
      this.unsubFetchUserValidationRequestSubject.asObservable(),
    ).pipe(
      finalize(() => {
        if (ctx.getState().isLoadingValidationsError) {
          this.nzMessageService.error(
            this.translateService.instant(
              'page.shift_timeline.failed_update_validations',
            ),
          );
        } else {
          ctx.patchState({
            validatedUserRecords: updatedValidatedUsersRecords,
          });
        }

        ctx.patchState({
          isLoadingValidations: false,
        });
      }),
    );
  }

  fetchAndUpdateShiftValidations(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    {
      payload: { userIdsOfUsersWithShifts, useCache, dates },
    }: TimelineUpdateShiftValidations,
  ) {
    const cancelSubject = new Subject();
    trackAndMaintainMaxRequestCount({
      cancellationSubject: cancelSubject,
      requestReferences: this.updateUserValidationRequests,
      maxParallelRequestCount: 2,
    });

    const state = ctx.getState();
    const fetchRange = {
      start: dates?.start
        ? (dates.start as Date)
        : (state.timeframe.start as Date),
      end: dates?.end ? dates.end : (state.timeframe.end as Date),
    };
    const correctedInterval = getTimeZoneCorrectedDateRange(fetchRange);

    let trackedUserIdsOfUsersWithShifts = userIdsOfUsersWithShifts;
    const { unvalidatedUsers, updatedValidatedUsersRecords } =
      filterAndUpdateDataRecords(
        state.validatedUserRecords,
        trackedUserIdsOfUsersWithShifts,
        correctedInterval.start,
        correctedInterval.end,
      );

    if (useCache) {
      trackedUserIdsOfUsersWithShifts = unvalidatedUsers;
    }

    if (trackedUserIdsOfUsersWithShifts.length > 0) {
      ctx.patchState({
        usersWithUpdatingValidations: [
          ...ctx.getState().usersWithUpdatingValidations,
          ...trackedUserIdsOfUsersWithShifts,
        ],
      });
      const batches = this.generateBatches(trackedUserIdsOfUsersWithShifts);

      return this.getRequestChain(
        batches,
        correctedInterval,
        ctx,
        cancelSubject.asObservable(),
      ).pipe(
        finalize(() => {
          if (ctx.getState().isLoadingValidationsError) {
            this.nzMessageService.error(
              this.translateService.instant(
                'page.shift_timeline.failed_update_validations',
              ),
            );
          } else {
            ctx.patchState({
              validatedUserRecords: updatedValidatedUsersRecords,
            });
          }

          ctx.patchState({
            isLoadingValidations: false,
          });
        }),
      );
    } else {
      return EMPTY;
    }
  }
}

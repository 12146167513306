<div class="row ps-2 pt-3 pb-1">
  <div class="col-half image-left-padding-20">
    <wilson-activity-icon [activity]="activity"></wilson-activity-icon>
  </div>
  <div class="col-one-half">
    <div>
      {{ activity | translateActivityCategory }}
    </div>
    <div class="form-text mt-0">
      {{ 'activity.type' | translate }}
    </div>
  </div>
  <div
    class="col-2"
    *ngIf="startGeoLocation | locationAbbreviationName as plannedStartLocation"
  >
    <div *ngIf="!reportedStartLocation; else startLocation">
      {{ plannedStartLocation }}
    </div>
    <ng-template #startLocation>
      <div
        [class.text-info]="
          selectedUserTab !== ActivityFilter.Planned &&
          plannedStartLocation !== reportedStartLocation
        "
        nz-tooltip
        [nzTooltipTitle]="
          plannedStartLocation !== reportedStartLocation
            ? startLocationTooltipContent
            : ''
        "
        [nzTooltipColor]="'#fff'"
      >
        <div
          *ngIf="
            selectedUserTab !== ActivityFilter.Planned;
            else plannedLocation
          "
        >
          {{
            plannedStartLocation === reportedStartLocation
              ? plannedStartLocation
              : reportedStartLocation
          }}
        </div>
        <ng-template #plannedLocation>
          {{ plannedStartLocation }}
        </ng-template>
      </div>
      <ng-template #startLocationTooltipContent>
        <div class="px-2 py-2 mb-1">
          <div class="mb-2">
            <span>{{ 'activity.planned.location' | translate }}</span>
            <br />
            <b>{{ plannedStartLocation }}</b>
          </div>
          <div class="mt-2">
            <span>{{ 'activity.reported.location' | translate }}</span>
            <br />
            <b>{{ reportedStartLocation }}</b>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <div class="form-text mt-0">
      {{ 'page.services.from' | translate }}
    </div>
  </div>
  <div class="col-half" *ngIf="viaLocations$ | async as viaLocations">
    <wilson-via-points-tool-tip
      *ngIf="viaLocations?.length"
      [startLocation]="startGeoLocation"
      [endLocation]="endGeoLocation"
      [viaLocations]="viaLocations"
    ></wilson-via-points-tool-tip>
  </div>
  <div class="col-2">
    <div
      *ngIf="
        activity?.createdFrom === WilsonApp.Mobile;
        else portalActivityStartDate
      "
    >
      {{ '---' }}
    </div>
    <ng-template #portalActivityStartDate>
      <div>
        {{
          activity?.startDatetime
            ? (activity.startDatetime
                | date : (isSeries ? 'HH:mm' : 'dd.MM.yy, HH:mm')) +
              ' ' +
              ('general.hour' | translate)
            : '---'
        }}
        <wilson-activity-deviation-time
          [activity]="activity"
          [affectedStop]="AffectedStop.Departure"
          [plannedDate]="activity.startDatetime"
        ></wilson-activity-deviation-time>
      </div>
    </ng-template>
    <div class="form-text mt-0">
      {{ 'activity.planned.start' | translate }}
    </div>
  </div>
  <div class="col-half text-secondary fw-bold">
    <span *ngIf="activity?.createdFrom !== WilsonApp.Mobile"
      >+{{ startDateTimeOverlap ?? 0 }}</span
    >
  </div>
  <div class="col-2" *ngIf="!isSeries">
    <div class="text-info">
      {{
        reportedStartTime
          ? (reportedStartTime | date : 'dd.MM.yy, HH:mm') +
            ' ' +
            ('general.hour' | translate)
          : '---'
      }}
    </div>
    <div class="form-text mt-0">
      {{ 'activity.reported.start' | translate }}
    </div>
  </div>
  <div class="col-1" *ngIf="isSeries"></div>
  <div class="col-one-half px-0">
    <div class="form-text mt-0" *ngIf="activity.priority">
      {{ 'general.priority' | translate }}
      <span class="priority-value">
        {{ activity.priority }}
      </span>
    </div>
    <div class="text-info">&nbsp;</div>
    <div class="form-text mt-0">
      {{ 'general.profession' | translate }}
    </div>
  </div>
  <div class="col-1 px-0">
    <div class="text-info">&nbsp;</div>
    <div class="form-text mt-0 ps-1">
      {{ 'general.actions' | translate }}
    </div>
  </div>
</div>

<div class="row ps-2 pb-3 pt-1">
  <div class="col-half"></div>
  <div class="col-one-half">
    <div>
      <ng-container
        *ngIf="activity?.['serviceSeriesId'] || activity?.['serviceId']; else noLinkToService"
      >
        <a
          class="col-1 my-auto btn-like text-dark"
          [routerLink]="[
                    activity?.['serviceSeriesId'] ||
                    activity?.['serviceId']
                        | navigateToService: isSeries
                    ]"
          target="'_blank'"
        >
          {{ activity?.name }}
        </a>
      </ng-container>
      <ng-template #noLinkToService>
        {{ activity?.name.trim() !== '' ? activity?.name : '---' }}
      </ng-template>
    </div>
    <div class="form-text mt-0">
      {{ 'general.name' | translate }}
    </div>
  </div>
  <div
    class="col-2"
    *ngIf="endGeoLocation | locationAbbreviationName as plannedEndLocation"
  >
    <div *ngIf="!reportedEndLocation; else endLocation">
      {{ plannedEndLocation }}
    </div>
    <ng-template #endLocation>
      <div
        [class.text-info]="
          selectedUserTab !== ActivityFilter.Planned &&
          plannedEndLocation !== reportedEndLocation
        "
        nz-tooltip
        [nzTooltipTitle]="
          plannedEndLocation !== reportedEndLocation
            ? endLocationTooltipContent
            : ''
        "
        [nzTooltipColor]="'#fff'"
      >
        <div
          *ngIf="
            selectedUserTab !== ActivityFilter.Planned;
            else plannedLocation
          "
        >
          {{
            plannedEndLocation === reportedEndLocation
              ? plannedEndLocation
              : reportedEndLocation
          }}
        </div>
        <ng-template #plannedLocation>
          {{ plannedEndLocation }}
        </ng-template>
      </div>
      <ng-template #endLocationTooltipContent>
        <div class="px-2 py-2 mb-1">
          <div class="mb-2">
            <span>{{ 'activity.planned.location' | translate }}</span>
            <br />
            <b>{{ plannedEndLocation }}</b>
          </div>
          <div class="mt-2">
            <span>{{ 'activity.reported.location' | translate }}</span>
            <br />
            <b>{{ reportedEndLocation }}</b>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <div class="form-text mt-0">
      {{ 'page.services.to' | translate }}
    </div>
  </div>
  <div class="col-half"></div>
  <div class="col-2">
    <div
      *ngIf="
        activity?.createdFrom === WilsonApp.Mobile;
        else portalActivityEndDate
      "
    >
      {{ '---' }}
    </div>
    <ng-template #portalActivityEndDate>
      <div>
        {{
          activity?.endDatetime
            ? (activity.endDatetime
                | date : (isSeries ? 'HH:mm' : 'dd.MM.yy, HH:mm')) +
              ' ' +
              ('general.hour' | translate)
            : '---'
        }}
        <wilson-activity-deviation-time
          [activity]="activity"
          [affectedStop]="AffectedStop.Arrival"
          [plannedDate]="activity.endDatetime"
        ></wilson-activity-deviation-time>
      </div>
    </ng-template>
    <div class="form-text mt-0">
      {{ 'activity.planned.end' | translate }}
    </div>
  </div>
  <div class="col-half text-secondary fw-bold">
    <span *ngIf="activity?.createdFrom !== WilsonApp.Mobile"
      >+{{ endDateTimeOverlap ?? 0 }}</span
    >
  </div>
  <div class="col-2" *ngIf="!isSeries">
    <div class="text-info">
      {{
        reportedEndTime
          ? (reportedEndTime | date : 'dd.MM.yy, HH:mm') +
            ' ' +
            ('general.hour' | translate)
          : '---'
      }}
    </div>
    <div class="form-text mt-0">
      {{ 'activity.reported.end' | translate }}
    </div>
  </div>
  <div class="col-1" *ngIf="isSeries"></div>
  <div class="col-one-half ps-0">
    <wilson-profession-tag
      *ngIf="'portal-professions' | featureFlagPure | async"
      [isEditable]="
        isSeries
          ? !(activity | activityTemplateIsSharedByClient)
          : !(activity | activityIsSharedByClient)
      "
      [activity]="activity"
      [isSeries]="isSeries"
    ></wilson-profession-tag>
  </div>
  <div class="col-1 ps-0">
    <div class="d-flex align-items-center action-icons">
      <wilson-activity-edit-button
        *ngIf="!isSeries"
        [activity]="activity"
        [shift]="shift"
      ></wilson-activity-edit-button>
      <wilson-activity-qualifications
        class="mx-1"
        [activity]="!isSeries ? activity : null"
        [activityTemplate]="isSeries ? activity : null"
        [size]="'sm'"
        [disabled]="
          isSeries
            ? (activity | activityTemplateIsSharedByClient)
            : (activity | activityIsSharedByClient)
        "
      ></wilson-activity-qualifications>
      <wilson-activity-comment-button
        *ngIf="!isSeries"
        class="mx-1"
        [activity]="activity"
        [shift]="null"
      >
      </wilson-activity-comment-button>
      <wilson-share-activity
        *ngIf="
          showWilsonShareIcon &&
          ('wilson-share-feature' | featureFlagPure | async) &&
          (RoleAction.Create
            | ablePure : RolePermissionSubject.WilsonShare
            | async) &&
          (FeatureName.WilsonShare | featurePure | async)
        "
        class="mx-1"
        [activity]="activity"
      ></wilson-share-activity>
      <wilson-agreement-icon
        class="mx-1"
        [isEditable]="true"
        [activity]="activity"
        [isSeries]="isSeries"
      ></wilson-agreement-icon>
    </div>
  </div>
  <!-- W3.0 TODO - This section will need in future -->
  <!-- <div class="col-2 d-flex align-items-center">
    <span
      *ngIf="!isSeries"
      class="badge rounded-pill ms-2"
      [class]="operationalStatusBgColor"
    >
      <span class="fw-bold">
        {{
          'service.operational_status.' + activity?.operationalStatus
            | translate
        }}
      </span>
    </span>
  </div> -->
</div>

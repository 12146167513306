import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { ActivityCategoryAndNameFormComponent } from './activity-category-and-name-form.component';
import { TranslateService } from '@ngx-translate/core';

export interface UpdateEvent {
  activityCategory: ActivityCategoryAndNameFormComponent['selectedActivityCategory'];
  activityName: string | undefined;
}

@Component({
  selector: 'wilson-activity-category-and-name',
  template: `
    <div>
      <div class="text-dark text-truncate">
        {{
          activityCategoryName
            ? ('activity_category.' + activityCategoryName | translate)
            : '---'
        }}
      </div>
      <div class="lh-m">
        <span *ngIf="activityName" class="list-header">{{ activityName }}</span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCategoryAndNameComponent {
  @Input() activityName!: string;
  @Input() activityCategoryName!: string;
  @Input() editable!: boolean;

  @Output() updated = new EventEmitter<UpdateEvent>();

  @HostListener('dblclick') onDblClick() {
    if (this.editable) {
      const modalRef: NzModalRef<ActivityCategoryAndNameFormComponent> =
        this.nzModalService.create<ActivityCategoryAndNameFormComponent>({
          nzTitle: this.translateService.instant('general.name'),
          nzContent: ActivityCategoryAndNameFormComponent,
          nzClosable: true,
          nzCentered: true,
          nzWidth: 400,
          nzOnOk: () => {
            const updateEvent = {
              activityCategory: (
                modalRef.componentInstance as ActivityCategoryAndNameFormComponent
              ).selectedActivityCategory,
              activityName: (
                modalRef.componentInstance as ActivityCategoryAndNameFormComponent
              ).chosenActivityName,
            };

            this.updated.emit(updateEvent);
          },
        });
      (
        modalRef.componentInstance as ActivityCategoryAndNameFormComponent
      ).activityCategoryName = this.activityCategoryName;
      (
        modalRef.componentInstance as ActivityCategoryAndNameFormComponent
      ).activityName = this.activityName;
    }
  }

  constructor(
    private nzModalService: NzModalService,
    private translateService: TranslateService,
  ) {}
}

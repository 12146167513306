<div
  (click)="shiftTemplateSelected.next(shiftTemplate)"
  [ngClass]="isSelected ? 'selected-template' : ''"
  class="col-12 card-text text-dark list-item"
>
  <h2 class="list-item-title">{{ shiftTemplate?.name }}</h2>
  <span class="list-item-time">
    {{ shiftTemplate?.shiftTemplateActivityTemplates | shiftDuration }}
    <span class="middle-dot">&middot;</span>
    {{ shiftTemplate?.shiftTemplateActivityTemplates.length }}
    {{ 'shift_template_picker.activities' | translate }}
  </span>
</div>

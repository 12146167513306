import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftPlanService } from './shift-plan.service';
import { ShiftPlanStoreService } from './shift-plan-store.service';

@NgModule({
  imports: [CommonModule],
  providers: [ShiftPlanService, ShiftPlanStoreService],
})
export class ShiftPlanServicesModule {}

import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ActivityLocationFormCorrectionsService {
  changeEndLocationOfFirstActivity(
    activityFormArray: FormArray,
    currentIndex: number,
  ) {
    const nextActivityStartLocation = activityFormArray
      .at(currentIndex + 1)
      .get('startLocation')?.value;

    activityFormArray
      .at(currentIndex)
      .get('endLocationId')
      ?.setValue(nextActivityStartLocation.id);

    activityFormArray
      .at(currentIndex)
      .get('endLocation')
      ?.setValue(nextActivityStartLocation);
  }

  changeStartLocationOfNextActivity(
    activityFormArray: FormArray,
    currentIndex: number,
  ) {
    const firstActivityEndLocation = activityFormArray
      .at(currentIndex)
      .get('endLocation')?.value;

    activityFormArray
      .at(currentIndex + 1)
      .get('startLocationId')
      ?.setValue(firstActivityEndLocation.id);

    activityFormArray
      .at(currentIndex + 1)
      .get('startLocation')
      ?.setValue(firstActivityEndLocation);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';

@Component({
  selector: 'wilson-op-report-occured-time-renderer',
  template: `
    <div>
      <span class="d-block text-dark text-info fw-normal">
        {{ occuredAtTime ? occuredAtTime : '' }}
        {{ occuredAtTime ? ('general.hour' | translate) : '' }}
      </span>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpReportOccuredTimeRendererComponent
  implements AgRendererComponent
{
  occuredAtTime!: string;

  agInit(params: ICellRendererParams): void {
    this.occuredAtTime = format(
      new Date(params?.data.occurredAt),
      'dd.MM.yy, HH:mm',
    );
  }

  refresh(params: ICellRendererParams) {
    this.occuredAtTime = format(
      new Date(params?.data.occurredAt),
      'dd.MM.yy, HH:mm',
    );
    return true;
  }
}

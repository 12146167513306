import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMagic } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

export enum FindStaffButtonType {
  Primary = 'primary',
  Default = 'default',
}
@Component({
  selector: 'wilson-find-staff-button',
  templateUrl: './find-staff-button.component.html',
  styleUrls: ['./find-staff-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzButtonModule,
    FontAwesomeModule,
    NzToolTipModule,
  ],
})
export class FindStaffButtonComponent {
  @Input() isDisabled = false;
  @Input() showWarning = false;
  @Input() warningText!: string | null;
  @Input() type: FindStaffButtonType = FindStaffButtonType.Primary;
  @Input() title!: string;
  @Output() openPopup = new EventEmitter();

  faMagic = faMagic;
  FindStaffButtonType = FindStaffButtonType;
}

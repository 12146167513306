import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { OperationStatus, ResolvedActivity } from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';

@Component({
  selector: 'wilson-activity-icon',
  templateUrl: './activity-icon.component.html',
  styleUrls: ['./activity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityIconComponent implements OnInit {
  @Input() activity: ResolvedActivity;
  public colorClass: 'default-icon' | 'active-icon' | 'skip-icon' | 'add-icon' =
    'default-icon';
  public icon: 'cog' | 'subway' | 'user-plus' | 'user-times' = 'cog';
  public isMobileActivity = false;
  public isActivitySkipByUser = false;

  ngOnInit(): void {
    if (this.activity) {
      this.isMobileActivity = this.activity.createdFrom === WilsonApp.Mobile;
      this.isActivitySkipByUser =
        this.activity.operationalStatus === OperationStatus.SkippedByUser ||
        this.activity.operationalStatus === OperationStatus.Cancelled;
      this.icon = this.isMobileActivity
        ? 'user-plus'
        : this.isActivitySkipByUser
        ? 'user-times'
        : this.activity.serviceId
        ? 'subway'
        : 'cog';
      this.colorClass = this.isActivitySkipByUser
        ? 'skip-icon'
        : this.isMobileActivity
        ? 'add-icon'
        : this.activity.operationalStatus === OperationStatus.Completed ||
          this.activity.operationalStatus === OperationStatus.Ongoing
        ? 'active-icon'
        : 'default-icon';
    }
  }
}

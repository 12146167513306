import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTimeFormat } from '@wilson/utils';
import { format, isAfter, isBefore } from 'date-fns';

export function SplitActivityTimeValidator(
  startDate: string,
  endDate: string,
): ValidatorFn {
  return (timeControl): ValidationErrors | null => {
    if (!timeControl.value) {
      return {
        isInvalid: true,
      };
    }
    const date: string = format(
      timeControl.parent?.value.date,
      DateTimeFormat.DatabaseDateFormat,
    );
    const time: string = format(timeControl.value, DateTimeFormat.TimeFormat);
    const dateTime = new Date(`${date} ${time}`);

    if (
      isAfter(dateTime, new Date(startDate)) &&
      isBefore(dateTime, new Date(endDate))
    ) {
      return null;
    }
    return {
      isInvalid: true,
    };
  };
}

@if(user?.id){
<div class="d-flex align-items-center mt-2">
  <span class="h2 mb-0">
    <wilson-user-avatar
      [s3Urn]="user?.profileImageUrl"
      [fallbackIcon]="'fas fa-user-circle text-secondary'"
      class="staff-icon-square"
      (click)="openDriverDetail()"
      [class.cursor-pointer]="user?.id"
    ></wilson-user-avatar>
  </span>
  <span
    (click)="openDriverDetail()"
    [class.cursor-pointer]="user?.id"
    class="ms-2 text-truncate"
    >{{ user?.lastName }}, {{ user?.firstName }}</span
  >
</div>
<ng-content></ng-content>
<div *ngIf="renderReassignButton" class="mt-2">
  <ng-container *ngTemplateOutlet="staffSearchButtons"></ng-container>
</div>
}@else{
<div class="d-flex align-items-center mt-2">
  <span class="h2 mb-0"><i class="fas fa-user-circle text-danger"></i></span>
  <span class="ms-2 text-danger">{{
    'service.activity.no_user_assigned' | translate
  }}</span>
</div>
<div *ngIf="renderReassignButton" class="mt-2">
  <ng-container *ngTemplateOutlet="staffSearchButtons"></ng-container>
</div>
}

<ng-template #staffSearchButtons>
  <wilson-find-staff-button
    [isDisabled]="!canOpenModal"
    (openPopup)="clicked.emit()"
  ></wilson-find-staff-button>
</ng-template>

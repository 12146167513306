import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-planned-and-reported-time-renderer',
  templateUrl: './planned-and-reported-time-renderer.component.html',
  styleUrls: ['./planned-and-reported-time-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedAndReportedTimeRendererComponent
  implements ICellRendererAngularComp
{
  public dates: Date[] = [];

  agInit(params: ICellRendererParams): void {
    this.dates = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.dates = params.value;
    return true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivityAdjustmentGuardService } from '../../services/activity-adjustment-guard/activity-adjustment-guard.service';

@Pipe({
  name: 'areActivitiesEditable',
})
export class AreActivitiesEditablePipe implements PipeTransform {
  constructor(
    private activityAdjustmentGuardService: ActivityAdjustmentGuardService,
  ) {}
  transform(activityForm: FormArray, index: number): boolean {
    const [firstActivityForm, secondActivityForm] = [
      activityForm.at(index),
      activityForm.at(index + 1),
    ];

    const canAdjustFirstActivity =
      this.activityAdjustmentGuardService.canBeAdjusted(
        firstActivityForm.value,
      );
    const canAdjustSecondActivity =
      this.activityAdjustmentGuardService.canBeAdjusted(
        secondActivityForm.value,
      );

    return canAdjustFirstActivity || canAdjustSecondActivity;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Shift } from '@wilson/interfaces';
import { ShiftUtilityService } from '@wilson/shifts';

@Pipe({
  name: 'isShiftSelfDispatched',
})
export class IsShiftSelfDispatchedPipe implements PipeTransform {
  constructor(private readonly shiftUtilityService: ShiftUtilityService) {}

  transform(shift: Shift): boolean {
    return this.shiftUtilityService.isSelfDispatchedShift(shift);
  }
}

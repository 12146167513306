import { Injectable } from '@angular/core';
import {
  ActivityCategory,
  GeoLocation,
  JobActivityTemplate,
  ResolvedActivity,
} from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { add, format, getHours, getMinutes } from 'date-fns';

interface SplitActivityForm {
  location: GeoLocation;
  date: Date;
  time: Date;
  insertBreak: boolean;
  activityCategory?: ActivityCategory;
  breakLocation?: GeoLocation;
  breakTime?: Date;
}
@Injectable({
  providedIn: 'root',
})
export class SplitActivityPopupHelperService {
  public generateNewActivities(
    formValue: SplitActivityForm,
    activity: ResolvedActivity,
  ): (ResolvedActivity & { jobActivityTemplates?: JobActivityTemplate[] })[] {
    const pauseStartDateTime = this.getISODateTime(
      formValue.date,
      formValue.time,
    );
    if (formValue.insertBreak && formValue.breakTime) {
      const pauseEndDateTime = add(new Date(pauseStartDateTime), {
        hours: getHours(formValue.breakTime),
        minutes: getMinutes(formValue.breakTime),
      }).toISOString();
      return [
        {
          ...activity,
          id: undefined,
          serviceId: null,
          job: null,
          jobId: null,
          professionId: null,
          profession: null,
          jobActivityTemplates: [],
          activityCategory: formValue.activityCategory ?? null,
          activityCategoryId: formValue.activityCategory?.id ?? null,
          startDatetime: pauseStartDateTime,
          startLocation: formValue.location,
          startLocationId: formValue.location?.id ?? null,
          endDatetime: pauseEndDateTime,
          endLocation: formValue.breakLocation ?? null,
          endLocationId: formValue.breakLocation?.id ?? null,
        },
        {
          ...activity,
          id: undefined,
          startDatetime: pauseEndDateTime,
          startLocation: formValue.breakLocation ?? null,
          startLocationId: formValue.breakLocation?.id ?? null,
        },
      ];
    } else {
      return [
        {
          ...activity,
          id: undefined,
          startDatetime: pauseStartDateTime,
          startLocation: formValue.location,
          startLocationId: formValue.location?.id ?? null,
        },
      ];
    }
  }

  public getISODateTime(date: Date, time: Date): string {
    return new Date(
      `${format(date, DateTimeFormat.DatabaseDateFormat)} ${format(
        time,
        DateTimeFormat.TimeFormat,
      )}`,
    ).toISOString();
  }
}

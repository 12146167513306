import { Activity } from '@wilson/interfaces';
import {
  InvoicedActivityStatus,
  InvoicingGridPageSizes,
  SelectedActivityChecks,
} from '../interfaces/invoicing-custom-types';

export class InvoicingSetPageSize {
  static readonly type = '[Invoicing] Invoicing set page size';
  constructor(public newPageSize: number | InvoicingGridPageSizes) {}
}

export class InvoicingSetCacheBlockSize {
  static readonly type = '[Invoicing] Set cache block size';
  constructor(public cacheBlockSize: number | InvoicingGridPageSizes) {}
}

export class InvoicingSetPagination {
  static readonly type = '[Invoicing] Invoicing set pagination';
  constructor(public pageNumber: number) {}
}

export class InvoicingFetchShifts {
  static readonly type = '[Invoicing] Invoicing Fetch Shifts';
  constructor(
    public hasWilsonShareAccess: boolean,
    public startRow: number,
    public endRow: number,
  ) {}
}

export class InvoicingSetDateTypeFilterIndex {
  static readonly type = '[Invoicing] Invoicing Set DateTypeFilterIndex ';
  constructor(public index: 0 | 1) {}
}

export class InvoicingSetTimeRange {
  static readonly type = '[Invoicing] Invoicing Set TimeRange ';
  constructor(public date: Interval) {}
}

export class InvoicingSetAgreements {
  static readonly type = '[Invoicing] Invoicing Set Agreements ';
  constructor(public agreements: string[]) {}
}

export class InvoicingSetEmployees {
  static readonly type = '[Invoicing] Invoicing Set Employees ';
  constructor(public employees: string[]) {}
}

export class InvoicingSetShiftCategories {
  static readonly type = '[Invoicing] Invoicing Set ShiftCategories ';
  constructor(public shiftCategories: string[]) {}
}

export class InvoicingSetActivityCategories {
  static readonly type = '[Invoicing] Invoicing Set ActivityCategories ';
  constructor(public activityCategories: string[]) {}
}

export class InvoicingSetPartners {
  static readonly type = '[Invoicing] Invoicing Set Partners ';
  constructor(public partners: string[]) {}
}

export class InvoicingSetJobs {
  static readonly type = '[Invoicing] Invoicing Set Jobs ';
  constructor(public jobIds: string[]) {}
}
export class InvoicingSetUserLabels {
  static readonly type = '[Invoicing] Invoicing Set User labels ';
  constructor(public shiftUserLabelIds: string[]) {}
}
export class InvoicingSetShiftLabels {
  static readonly type = '[Invoicing] Invoicing Set Shift labels ';
  constructor(public shiftLabelIds: string[]) {}
}

export class InvoicingSetShiftProjects {
  static readonly type = '[Invoicing] Invoicing Set Shift projects ';
  constructor(public shiftProjectIds: string[]) {}
}

export class InvoicingSetShiftProfessions {
  static readonly type = '[Invoicing] Invoicing Set Shift professions ';
  constructor(public professions: string[]) {}
}

export class InvoicingSetEmployeeOrgUnits {
  static readonly type = '[Invoicing] Invoicing Set Employee org unit ';
  constructor(public orgUnit: string[]) {}
}

export class InvoicingSetSearchString {
  static readonly type = '[Invoicing] Invoicing Set SearchSering ';
  constructor(public searchString: string) {}
}

export class InvoicingFilterOnlyServiceActivities {
  static readonly type =
    '[Invoicing] Invoicing Filter Only Services Activities';
  constructor(public showServiceActivities: boolean) {}
}

export class InvoicingSetSelectedActivity {
  static readonly type = '[Invoicing] Invoicing Set Selected Activity Ids ';
  constructor(public activities: Activity[]) {}
}

export class SetSelectedActivities {
  static readonly type =
    '[Invoicing] Invoicing Add state of selected activities ';
  constructor(public activitiesStates: SelectedActivityChecks) {}
}

export class InvoicingSetActivityStatus {
  static readonly type = '[Invoicing] Invoicing Set ActivityStatus ';
  constructor(public status: InvoicedActivityStatus) {}
}

export class InvoicingSetClient {
  static readonly type = '[Invoicing] Invoicing Set Client ';
  constructor(public client: { id: string; name: string }) {}
}

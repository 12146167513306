import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-share-job-created-at-renderer',
  template: `
    <wilson-share-job-created-at
      [dateValue]="dateValue"
    ></wilson-share-job-created-at>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareJobCreatedAtRendererComponent
  implements ICellRendererAngularComp
{
  dateValue!: Date;

  agInit(params: ICellRendererParams): void {
    this.setValues(params);
  }

  refresh(params: ICellRendererParams) {
    this.setValues(params);
    return true;
  }

  private setValues(params: ICellRendererParams) {
    this.dateValue = new Date(params.value);
  }
}

/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Activity, ActivityTemplate } from '@wilson/interfaces';
import { BucketType } from '@wilson/share/interfaces';
import { ConfirmationDialogueComponent } from '@wilson/shared-components';
import { isBefore } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import {
  ClearBucket,
  DeleteItemFromBucket,
  InsertToBucket,
} from './new-wilson-job-bucket.actions';
import { NewWilsonJobBucketState } from './new-wilson-job-bucket.state';

@Injectable({
  providedIn: 'root',
})
export class ShareRequestStateHelperService {
  constructor(
    private readonly store: Store,
    private modalService: NgbModal,
    private readonly translate: TranslateService,
  ) {}

  private async getBucketType(): Promise<BucketType> {
    return await firstValueFrom(
      this.store.select(NewWilsonJobBucketState.bucketType),
    );
  }

  private async getBucketItemCount(): Promise<number> {
    return (
      await firstValueFrom(this.store.select(NewWilsonJobBucketState.itemIds))
    ).length;
  }

  insertToBucket(bucketId: string, itemIds: string[], bucketType: BucketType) {
    this.store.dispatch(new InsertToBucket(bucketId, itemIds, bucketType));
  }

  removeItemFromBucket(id: string, itemId: string) {
    this.store.dispatch(new DeleteItemFromBucket(id, itemId));
  }

  async verifyWithUserAndAddItemsToBucket(
    bucketId: string,
    activities: ActivityTemplate[] | Activity[],
    bucketType: BucketType,
  ): Promise<boolean> {
    const currentBucketType = await this.getBucketType();
    const bucketLength = await this.getBucketItemCount();
    const isBucketTheSameTypeAsItem = currentBucketType === bucketType;
    const itemIds = activities.map((activity) => activity.id ?? '');

    if (
      (currentBucketType === BucketType.ServiceSeries &&
        bucketType === BucketType.ServiceSeries) ||
      (currentBucketType === BucketType.Service &&
        bucketType === BucketType.ServiceSeries &&
        bucketLength === 0)
    ) {
      const userConfirmedPastActivities = await this.verifyAboutPastActivities(
        activities,
      );
      if (!userConfirmedPastActivities) {
        return false;
      }
    }

    if (isBucketTheSameTypeAsItem || bucketLength === 0) {
      this.insertToBucket(bucketId, itemIds, bucketType);
    } else {
      const userAgreed: boolean = await this.openClearBucketConfirmationModal();
      if (userAgreed) {
        this.store.dispatch(new ClearBucket());
        this.insertToBucket(bucketId, itemIds, bucketType);
      } else {
        return false;
      }
    }

    return true;
  }

  async verifyAboutPastActivities(
    activityTemplates: ActivityTemplate[] | Activity[],
  ) {
    const dates = activityTemplates.map((activity) => activity.startDatetime);
    const isAtLeastOneDateInThePast = dates.some((date) =>
      isBefore(new Date(date), new Date()),
    );

    if (!isAtLeastOneDateInThePast) {
      return true;
    }

    const userAccepted: boolean =
      await this.openActivityTemplatePastStartDateModal();

    if (!userAccepted) {
      return false;
    }
    return true;
  }

  public async openClearBucketConfirmationModal(): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogueComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = this.translate.instant(
      'page.wilson_share.switch_selection_modal.title',
    );
    modalRef.componentInstance.message = this.translate.instant(
      'page.wilson_share.switch_selection_modal.message',
    );

    modalRef.componentInstance.btnOkText = this.translate.instant(
      'page.wilson_share.add_to_share.button.text',
    );
    modalRef.componentInstance.btnCancelText =
      this.translate.instant('general.cancel');

    modalRef.componentInstance.icon = 'fa fa-exclamation-circle';

    modalRef.componentInstance.showDangerConfirmation = true;

    return await modalRef.result;
  }

  public async openActivityTemplatePastStartDateModal(): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogueComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = this.translate.instant(
      'page.wilson_share.switch_selection_modal.title',
    );
    modalRef.componentInstance.message = this.translate.instant(
      'page.wilson_share.past_activity_template_modal.message',
    );

    modalRef.componentInstance.btnOkText = this.translate.instant(
      'page.wilson_share.add_to_share.button.text',
    );
    modalRef.componentInstance.btnCancelText =
      this.translate.instant('general.cancel');

    modalRef.componentInstance.icon = 'fa fa-exclamation-circle';

    return await modalRef.result;
  }
}

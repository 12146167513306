import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import { GridService } from '@wilson/ag-grid/service';
import { Activity } from '@wilson/interfaces';
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community';
import { TemplateActivityEndTimeRendererComponent } from './template-activity-name-renderer/template-activity-end-time-renderer.component';
import { TemplateActivityNameRendererComponent } from './template-activity-name-renderer/template-activity-name-renderer.component';
import { TemplateActivityStartTimeRendererComponent } from './template-activity-name-renderer/template-activity-start-time-renderer.component';
import { MatomoTracker } from 'ngx-matomo-client';
import { EventCategory } from '@wilson/matomo';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wilson-shift-activities',
  templateUrl: './shift-activities.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftActivitiesComponent implements OnChanges {
  public isLoading = false;
  public readonly defaultColDef = defaultColDef;

  public readonly gridOptions: GridOptions = {
    ...defaultGridOptionsFactory(),
    pagination: false,
    paginationAutoPageSize: false,
    domLayout: 'autoHeight',
  };
  public readonly components = {
    templateActivityNameRendererComponent:
      TemplateActivityNameRendererComponent,
    templateActivityStartTimeRendererComponent:
      TemplateActivityStartTimeRendererComponent,
    templateActivityEndTimeRendererComponent:
      TemplateActivityEndTimeRendererComponent,
  };

  @Input() activities: Activity[] = [];
  @Output() selectionChangedEvent = new EventEmitter();

  protected columnDefs: ColDef[] = [
    {
      flex: 1,
      cellRenderer: 'templateActivityNameRendererComponent',
      valueGetter: this.activityNameValueGetter,
      headerName: this.translateService.instant(
        'shift_template_picker.activity.type',
      ),
    },
    {
      flex: 1,
      cellRenderer: 'templateActivityStartTimeRendererComponent',
      valueGetter: this.activityTimeValueGetter,
      headerName: this.translateService.instant(
        'shift_template_picker.activity.start',
      ),
    },
    {
      flex: 1,
      cellRenderer: 'templateActivityEndTimeRendererComponent',
      valueGetter: this.activityTimeValueGetter,
      headerName: this.translateService.instant(
        'shift_template_picker.activity.end',
      ),
    },
    {
      resizable: false,
      sortable: false,
      width: 60,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
  ];

  constructor(
    private tracker: MatomoTracker,
    public readonly gridService: GridService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    if (!this.activities) this.isLoading = false;
    if (this.activities?.length >= 0) this.isLoading = false;
  }

  onSelectionChanged() {
    this.tracker.trackEvent(
      EventCategory.Shift,
      'add_activity_from_template_to_shift',
    );
    const selectedData = this.gridService.gridApi?.getSelectedRows();
    this.selectionChangedEvent.emit(selectedData);
  }

  public activityNameValueGetter(params: ValueGetterParams): string {
    return params.data.activityTemplate.activityCategory.name;
  }

  public activityTimeValueGetter(params: ValueGetterParams): string {
    return params.data.activityTemplate;
  }
}

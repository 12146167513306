import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TranslateQualificationPipeModule } from '@wilson/qualifications/pipes';
import { QualificationsState } from './qualifications.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([QualificationsState]),
    TranslateQualificationPipeModule,
  ],
  providers: [],
})
export class QualificationStateModule {}

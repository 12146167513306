<button
  nz-button
  nz-type="primary"
  class="w-100 fw-bold"
  (click)="updateShiftState()"
  *ngIf="
    ('portal_accept_shift_from_detail_page' | featureFlagPure | async) &&
    (RoleAction.Update
      | ablePure : RolePermissionSubject.WorkTimeManagement
      | async) &&
    (FeatureName.WorkTimeManagement | featurePure | async) &&
    shift?.state === ShiftState.Submitted
  "
>
  <span class="fas fa-check-circle me-1"></span>
  {{ 'page.work_time_management.accept' | translate }}
</button>

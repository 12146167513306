import { Injectable } from '@angular/core';
import {
  ActivityReportCategory,
  OperationStatus,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { addDays, format } from 'date-fns';
import { ActivityTimeOverday, ReportedTimeService } from '../../..';

@Injectable({
  providedIn: 'root',
})
export class ShiftDurationService {
  private startTime: string = null;
  private endTime: string = null;

  constructor(
    private readonly reportedTimeService: ReportedTimeService,
    private readonly activityTimeOverday: ActivityTimeOverday,
  ) {}

  public calculateDurationForPlannedTimes(
    activities: ResolvedActivityWithReports[],
    actualStartDate: string,
    disabled: boolean,
    shiftSeries: boolean,
  ) {
    activities = activities.filter(
      (activity) => activity.createdFrom !== WilsonApp.Mobile,
    );
    this.startTime = activities[0]?.startDatetime;
    this.endTime = activities[activities.length - 1]?.endDatetime;

    const date = actualStartDate
      ? format(
          addDays(
            new Date(actualStartDate),
            this.activityTimeOverday.transform(
              activities,
              actualStartDate,
              activities.length - 1,
              false,
              disabled,
              shiftSeries,
            ),
          ),
          'yyyy-MM-dd',
        )
      : null;
    return this.calculateDuration(
      date,
      this.startTime,
      this.endTime,
      actualStartDate,
    );
  }

  public calculateDurationForReportedTimes(
    activities: ResolvedActivityWithReports[],
    actualStartDate: string,
    disabled: boolean,
    shiftSeries: boolean,
  ) {
    activities = activities.filter(
      (activity) =>
        activity.operationalStatus !== OperationStatus.SkippedByUser &&
        activity.operationalStatus !== OperationStatus.Cancelled,
    );
    const activitiesWithReportedTimes = activities.filter(
      (activity) =>
        activity.activityReports?.length &&
        this.reportedTimeService.reportedEndTime(activity.activityReports),
    );
    if (!activitiesWithReportedTimes.length) {
      return 0;
    }
    activitiesWithReportedTimes.sort(
      (a, b) =>
        new Date(
          this.reportedTimeService.reportedStartTime(a.activityReports),
        ).getTime() -
        new Date(
          this.reportedTimeService.reportedStartTime(b.activityReports),
        ).getTime(),
    );
    this.startTime = this.reportedTimeService.reportedStartTime(
      activitiesWithReportedTimes.find((activity) =>
        activity.activityReports?.find(
          (report) => report.reportCategory === ActivityReportCategory.Start,
        ),
      )?.activityReports,
    );

    activitiesWithReportedTimes.sort(
      (a, b) =>
        new Date(
          this.reportedTimeService.reportedEndTime(a.activityReports),
        ).getTime() -
        new Date(
          this.reportedTimeService.reportedEndTime(b.activityReports),
        ).getTime(),
    );
    activitiesWithReportedTimes.forEach((activity) => {
      const reportedEndTimes = this.reportedTimeService.reportedEndTime(
        activity.activityReports,
      );
      if (reportedEndTimes) {
        this.endTime = reportedEndTimes;
      } else {
        this.endTime = new Date().toISOString();
      }
    });

    const date = actualStartDate
      ? format(
          addDays(
            new Date(actualStartDate),
            this.activityTimeOverday.transform(
              activities,
              actualStartDate,
              activities.length - 1,
              false,
              disabled,
              shiftSeries,
            ),
          ),
          'yyyy-MM-dd',
        )
      : null;
    return this.calculateDuration(
      date,
      this.startTime,
      this.endTime,
      actualStartDate,
    );
  }

  public calculateDuration(
    startDate: string,
    startTime: string,
    endTime: string,
    actualStartDate: string,
  ): number {
    let newStartTime: string;
    let newEndTime: string;

    if (startTime && endTime) {
      newStartTime =
        startTime && (startTime.includes('Z') || startTime.includes('T'))
          ? startTime
          : `${actualStartDate} ${startTime}`;
      newEndTime =
        endTime && (endTime.includes('Z') || endTime.includes('T'))
          ? endTime
          : `${startDate} ${endTime}`;

      try {
        newStartTime = new Date(newStartTime).toISOString();
        newEndTime = new Date(newEndTime).toISOString();
      } catch (error) {
        console.error('Error parsing date: ', error);
        return 0.0;
      }
    }
    if (startDate && newStartTime < newEndTime) {
      const startDateTime = new Date(newStartTime).setSeconds(0);
      const endDateTime = new Date(newEndTime).setSeconds(0);
      return Math.round((endDateTime - startDateTime) / 60000) / 60;
    } else {
      return 0.0;
    }
  }
}

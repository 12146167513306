import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
  selector: 'wilson-stay-pdf-note',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, NzInputModule],
  templateUrl: './stay-pdf-note.component.html',
  styleUrl: './stay-pdf-note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StayPDFNoteComponent {
  public note!: string;
}

import { Activity, AssignmentTimeRange, Shift } from '@wilson/interfaces';
import { MODAL_VIEW } from './shift-assignment-modal.state';

export class SetShiftToAssign {
  static readonly type = '[ShiftAssignmentModal] Set Shift To Assign';
  constructor(
    public payload: {
      shift: Shift & { activities: Activity[] };
    },
  ) {}
}

export class SetWindowWidth {
  static readonly type = '[ShiftAssignmentModal] Set Window Width';
  constructor(
    public payload: {
      width: number;
    },
  ) {}
}

export class SetActivitiesAssignmentWindowWidth {
  static readonly type =
    '[ActivitiesAssignmentModal] Set ActivitiesAssignment Window Width';
  constructor(
    public payload: {
      width: number;
    },
  ) {}
}

export class ResetShiftAssignmentModalState {
  static readonly type = '[Shift Assignment] Reset Interactions State';
}

export class ToggleFilterLoading {
  static readonly type = '[ShiftAssignment] ToggleFilterLoading';
  constructor(public isLoading: boolean) {}
}

export class TimelineModalFetchUsers {
  static readonly type = '[ShiftTimelineModal] Timeline Fetch Users For Modals';
  constructor(
    public timeframe: Interval,
    public assignmentTimeRange: AssignmentTimeRange,
  ) {}
}

export class TimelineFetchShiftAssignments {
  static readonly type = '[ShiftTimelineModal] Timeline Fetch ShiftAssignments';
  constructor(public shiftToAssignId: string) {}
}

export class TimelineFetchActivitiesAssignment {
  static readonly type =
    '[ShiftTimelineModal] Timeline Fetch Assignment For Selected Activities';
  constructor(public selectedActivityIds: string[]) {}
}

export class TimelineModalFetchUserValidations {
  static readonly type =
    '[ShiftTimelineModal] Timeline Set User Shift Validations';
  constructor(public timeframe: Interval) {}
}

export class TimelineModalSetDaysRange {
  static readonly type = '[ShiftTimelineModal] Timeline Set Days Range';
  constructor(public dates: Date[]) {}
}

export class TimelineModalSetTimeframeWidth {
  static readonly type = '[ShiftTimelineModal] Timeline Set Timeframe width';
  constructor(public value: MODAL_VIEW) {}
}

export class SetActivitiesToAssign {
  static readonly type = '[ShiftTimelineModal] Set Activities To Assign';
  constructor(public payload: Activity[]) {}
}

<h6 *ngIf="!hideTitle" class="px-2 py-2 title">
  {{ 'qualification_category.route' | translate }}
</h6>
<div
  class="py-2 pe-2 tool-tip"
  [ngClass]="{
    'tool-tip--no-height-limit': noHeightLimit,
    'ps-2': !noLeftPadding
  }"
>
  <!-- Start location -->
  <div class="icon-wrapper">
    <span class="fas fa-map-marker-alt"></span>
  </div>
  <div class="fw-bold ms-2">
    {{ startLocation | locationAbbreviationName }}
  </div>
  <ng-template [ngTemplateOutlet]="spacerRow"></ng-template>

  <!-- Via locations -->
  <ng-container *ngFor="let location of viaLocations">
    <div class="icon-wrapper">
      <span class="fas fa-circle"></span>
    </div>
    <div class="ms-2">
      {{ location | locationAbbreviationName }}
    </div>
    <ng-template [ngTemplateOutlet]="spacerRow"></ng-template>
  </ng-container>

  <!-- End location -->
  <div class="icon-wrapper">
    <span class="fas fa-flag"></span>
  </div>
  <div class="fw-bold ms-2">
    {{ endLocation | locationAbbreviationName }}
  </div>
</div>

<ng-template #spacerRow>
  <div class="vertical-spacer"></div>
  <div>&nbsp;</div>
</ng-template>

import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { AccountService } from '@wilson/account';
import {
  isOneActivityStarted,
  shouldAllActivitiesHaveEnded,
  shouldAtLeastOneActivityStarted,
} from '@wilson/activities/pipes';
import { OperativeReportsGateway } from '@wilson/api/gateway';
import {
  OperativeReport,
  OperativeReportStatus,
  ResolvedShiftValidation,
  Shift,
  ShiftValidationDetails,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { addHours, isAfter, isBefore, subHours } from 'date-fns';
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { OperationsBoardShiftStatus } from '../interfaces/custom-operative-interface';
import {
  InvalidShiftsState,
  OperativeReportState,
  SetSelectedOrgUnits,
} from './operations.action';

interface OperationsStateModel {
  shiftValidation: ResolvedShiftValidation[];
  filteredShiftValidations: ResolvedShiftValidation[];
  operativeReport: OperativeReport[];
  filteredOperativeReports: OperativeReport[];
  isLoading: boolean;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  selectedOrgUnitIds: string[];
}
export const OPERATIONS_STATE_NAME = 'operations';
const defaultOperationsState: OperationsStateModel = {
  shiftValidation: [],
  filteredShiftValidations: [],
  operativeReport: [],
  filteredOperativeReports: [],
  isLoading: true,
  filterStartDate: null,
  filterEndDate: null,
  selectedOrgUnitIds: [],
};

@State<OperationsStateModel>({
  name: OPERATIONS_STATE_NAME,
  defaults: defaultOperationsState,
})
@Injectable()
export class OperationsState {
  private unsubInvalidShiftsSubject = new Subject();
  constructor(
    private readonly shiftsService: ShiftsService,
    private readonly operativeReportsGateway: OperativeReportsGateway,
    private readonly accountService: AccountService,
  ) {}

  @Selector()
  static counter(state: OperationsStateModel): number {
    return this.shifts(state).length;
  }

  @Selector()
  static isLoading(state: OperationsStateModel): boolean {
    return state.isLoading;
  }

  static getShiftsOverviewPageUrl(
    operationsBoardShiftStatus?: Extract<
      OperationsBoardShiftStatus,
      | OperationsBoardShiftStatus.RUNNING
      | OperationsBoardShiftStatus.NOT_STARTED
      | OperationsBoardShiftStatus.NOT_FINISHED
    >,
  ) {
    return createSelector(
      [OperationsState],
      (state: OperationsStateModel): string => {
        if (!state.filteredShiftValidations.length) {
          return '/shifts';
        }
        const sortedShifts = [...state.filteredShiftValidations];
        sortedShifts.sort((a, b) => {
          if (
            a.shift.startDatetime &&
            b.shift.startDatetime &&
            isBefore(
              new Date(a.shift.startDatetime),
              new Date(b.shift.startDatetime),
            )
          ) {
            return -1;
          } else {
            return 1;
          }
        });
        const firstAndLastStartDate = {
          firstStartDate:
            sortedShifts[0].shift.startDatetime ??
            sortedShifts[0].shift.startDate,
          lastStartDate:
            sortedShifts[sortedShifts.length - 1].shift.startDatetime ??
            sortedShifts[sortedShifts.length - 1].shift.startDate,
        };
        return (
          `/shifts?dates=${firstAndLastStartDate.firstStartDate},${firstAndLastStartDate.lastStartDate}` +
          `${
            operationsBoardShiftStatus
              ? `&operationsBoardShiftStatus=${operationsBoardShiftStatus}`
              : ''
          }` +
          `&operationBoardShiftStatusTimeRange=${state.filterStartDate?.toISOString()},${state.filterEndDate?.toISOString()}` +
          `${
            state.selectedOrgUnitIds.length > 0
              ? `&orgUnit=${state.selectedOrgUnitIds.toString()}`
              : ''
          }`
        );
      },
    );
  }

  static getOperativeReportsOverviewPageUrl() {
    return createSelector(
      [OperationsState],
      (state: OperationsStateModel): string => {
        if (!state.filteredOperativeReports.length) {
          return '/operative-reports';
        }
        const sortedOperativeReports = [...state.filteredOperativeReports];
        sortedOperativeReports.sort((a, b) => {
          if (
            a.occurredAt &&
            b.occurredAt &&
            isBefore(new Date(a.occurredAt), new Date(b.occurredAt))
          ) {
            return -1;
          } else {
            return 1;
          }
        });
        const firstAndLastRecievedAtDate = {
          firstRecievedAtDate: sortedOperativeReports[0].occurredAt,
          lastRecievedAtDate:
            sortedOperativeReports[sortedOperativeReports.length - 1]
              .occurredAt,
        };
        return (
          `/operative-reports?dates=${firstAndLastRecievedAtDate.firstRecievedAtDate},${firstAndLastRecievedAtDate.lastRecievedAtDate}` +
          `&opReportStatus=${OperativeReportStatus.Open}`
        );
      },
    );
  }

  @Selector()
  static getFilterStartDate(
    state: OperationsStateModel,
  ): OperationsStateModel['filterStartDate'] {
    return state.filterStartDate;
  }

  @Selector()
  static getFilterEndDate(
    state: OperationsStateModel,
  ): OperationsStateModel['filterEndDate'] {
    return state.filterEndDate;
  }

  @Selector()
  static amountOfTotalShifts(state: OperationsStateModel): number {
    return state.filteredShiftValidations.length;
  }

  @Selector()
  static amountOfRunningShifts(state: OperationsStateModel): number {
    return state.filteredShiftValidations.filter((shiftsWithValidations) => {
      return isOneActivityStarted(shiftsWithValidations.shift.activities);
    }).length;
  }

  @Selector()
  static amountOfShiftsThatShouldHaveStarted(
    state: OperationsStateModel,
  ): number {
    return state.filteredShiftValidations.filter((shiftsWithValidations) => {
      return shouldAtLeastOneActivityStarted(
        shiftsWithValidations.shift.activities,
      );
    }).length;
  }

  @Selector()
  static amountOfShiftsThatShouldHaveEnded(
    state: OperationsStateModel,
  ): number {
    return state.filteredShiftValidations.filter((shiftsWithValidations) => {
      return shouldAllActivitiesHaveEnded(
        shiftsWithValidations.shift.activities,
      );
    }).length;
  }

  @Selector()
  static shifts(
    state: OperationsStateModel,
  ): (Shift & { validations: ShiftValidationDetails[] })[] {
    return state.filteredShiftValidations.map((shiftsWithValidations) => {
      return {
        ...shiftsWithValidations.shift,
        validations: shiftsWithValidations.validations,
      };
    });
  }

  @Selector()
  static reports(state: OperationsStateModel): OperativeReport[] {
    return state.filteredOperativeReports;
  }

  @Action(InvalidShiftsState)
  async invalidShiftsState(
    ctx: StateContext<OperationsStateModel>,
    { payload }: InvalidShiftsState,
  ): Promise<Observable<ResolvedShiftValidation[]>> {
    const payloadDates = this.calculatePayloadDates(
      payload.hoursToSubtract,
      payload.hoursToAdd,
    );

    ctx.patchState({
      filterStartDate: payloadDates.startDate,
      filterEndDate: payloadDates.endDate,
    });
    this.unsubInvalidShiftsSubject.next(null);
    ctx.patchState({ isLoading: true });
    const users = await firstValueFrom(this.accountService.getAll());

    return this.shiftsService
      .getInDateRangeWithValidations(
        payloadDates.startDate,
        payloadDates.endDate,
      )
      .pipe(
        take(1),
        takeUntil(this.unsubInvalidShiftsSubject),
        map((shifts) => {
          shifts.data.map((shiftValidation) => {
            const shift = shiftValidation.shift;
            shift.user = users.find((u) => u.id === shift.userId) ?? null;
            return {
              ...shiftValidation,
              shift: shift,
            };
          });
          return shifts.data;
        }),
        tap((shiftsData) => {
          ctx.patchState({
            shiftValidation: shiftsData,
            isLoading: false,
          });
          this.filterShiftValidationsByOrgUnit(ctx);
        }),
      );
  }

  @Action(OperativeReportState)
  operativeReportState(
    ctx: StateContext<OperationsStateModel>,
  ): Observable<OperativeReport[]> {
    return this.operativeReportsGateway.getOperativeReports().pipe(
      map((operativeReports) =>
        operativeReports.filter(
          (report) => report.status !== OperativeReportStatus.Done,
        ),
      ),
      tap((operativeReports) => {
        ctx.patchState({
          operativeReport: operativeReports,
          isLoading: false,
        });
        this.filterOperativeReports(ctx);
      }),
    );
  }

  @Action(SetSelectedOrgUnits)
  setSelectedOrgUnits(
    ctx: StateContext<OperationsStateModel>,
    { selectedOrgUnitIds }: SetSelectedOrgUnits,
  ) {
    ctx.patchState({
      selectedOrgUnitIds: selectedOrgUnitIds,
    });
    this.filterShiftValidationsByOrgUnit(ctx);
    this.filterOperativeReports(ctx);
  }

  private calculatePayloadDates(hoursToSubtract: number, hoursToAdd: number) {
    const today = new Date();
    const startDate = subHours(today, hoursToSubtract);
    const endDate = addHours(today, hoursToAdd);
    const result = {
      startDate,
      endDate,
    };
    return result;
  }

  protected filterShiftValidationsByOrgUnit(
    ctx: StateContext<OperationsStateModel>,
  ) {
    const state = ctx.getState();
    if (state.selectedOrgUnitIds.length) {
      const clonedShiftValidations = [...state.shiftValidation];
      ctx.patchState({
        filteredShiftValidations: clonedShiftValidations.filter(
          (shiftValidation) => {
            return state.selectedOrgUnitIds.some(
              (id) => shiftValidation.shift.organizationalUnitId === id,
            );
          },
        ),
      });
    } else {
      ctx.patchState({
        filteredShiftValidations: state.shiftValidation,
      });
    }
  }

  protected filterOperativeReports(ctx: StateContext<OperationsStateModel>) {
    const state = ctx.getState();
    const filteredByOrgUnit = this.filterOperativeReportsByOrgUnit(
      state.selectedOrgUnitIds,
      state.operativeReport,
    );
    const filteredByOrgUnitAndDateRange =
      this.filterOperativeReportsByDateRange(
        state.filterStartDate,
        state.filterEndDate,
        filteredByOrgUnit,
      );
    ctx.patchState({
      filteredOperativeReports: filteredByOrgUnitAndDateRange,
    });
  }

  private filterOperativeReportsByOrgUnit(
    selectedOrgUnitIds: string[],
    operativeReports: OperativeReport[],
  ) {
    let operativeReportsFilteredByOrgUnit = [];
    if (selectedOrgUnitIds.length) {
      const clonedOperativeReports = [...operativeReports];
      operativeReportsFilteredByOrgUnit = clonedOperativeReports.filter(
        (operativeReport) => {
          if (operativeReport.shift?.organizationalUnitId) {
            return selectedOrgUnitIds.some(
              (id) => operativeReport.shift?.organizationalUnitId === id,
            );
          } else {
            return true;
          }
        },
      );
    } else {
      operativeReportsFilteredByOrgUnit = operativeReports;
    }
    return operativeReportsFilteredByOrgUnit;
  }

  private filterOperativeReportsByDateRange(
    filterStartDate: Date | null,
    filterEndDate: Date | null,
    operativeReports: OperativeReport[],
  ) {
    let operativeReportsFilteredByDateRange = [];
    if (operativeReports.length && filterStartDate && filterEndDate) {
      const clonedOperativeReports = [...operativeReports];
      operativeReportsFilteredByDateRange = clonedOperativeReports.filter(
        (operativeReport) => {
          return (
            isAfter(
              new Date(operativeReport.occurredAt),
              filterStartDate as Date,
            ) &&
            isBefore(
              new Date(operativeReport.occurredAt),
              filterEndDate as Date,
            )
          );
        },
      );
    } else {
      operativeReportsFilteredByDateRange = operativeReports;
    }
    return operativeReportsFilteredByDateRange;
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';

import { Activity, Shift } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { ActivityEditService } from '../../services/activity-edit.service';

@Component({
  selector: 'wilson-activity-edit-button',
  templateUrl: './activity-edit-button.component.html',
  styleUrls: ['./activity-edit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEditButtonComponent implements OnChanges {
  @Input() activity: Activity;
  @Input() shift: Shift;

  readonly loading$ = this.activityEditService.loadingActivityCategories$;
  canBeEdited$: Observable<boolean>;

  constructor(private readonly activityEditService: ActivityEditService) {}

  ngOnChanges(): void {
    this.canBeEdited$ = this.activityEditService.canBeEdited(this.shift);
  }

  openEditModal() {
    this.activityEditService.openEditModal({ ...this.activity }, this.shift);
  }
}

import { Injectable } from '@angular/core';
import { PlannedAndReportedTimeService } from '@wilson/activities/reports';
import {
  Activity,
  ActivityReport,
  ActivityReportCategory,
  OperationStatus,
  ResolvedActivityReport,
} from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportedTimeService {
  constructor(
    private readonly plannedAndReportedTimeService: PlannedAndReportedTimeService,
  ) {}

  /**
   * @deprecated use PlannedAndReportedTimeService.reportedStartTime instead
   */
  public reportedStartTime(activityReports: ActivityReport[]): string | null {
    return this.plannedAndReportedTimeService.reportedStartTime(
      activityReports,
    );
  }

  /**
   * @deprecated use PlannedAndReportedTimeService.reportedEndTime instead
   */
  public reportedEndTime(
    activityReports: ResolvedActivityReport[],
  ): string | null {
    return this.plannedAndReportedTimeService.reportedEndTime(activityReports);
  }

  public reportedActivities<T extends Activity>(activities: T[]): T[] {
    if (!activities?.length) return [];
    return (activities = activities.filter(
      (activity) =>
        activity.operationalStatus !== OperationStatus.SkippedByUser &&
        activity.operationalStatus !== OperationStatus.Cancelled &&
        activity.activityReports.some(
          (report) => report.reportCategory === ActivityReportCategory.Start,
        ),
    ));
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  ExpirationSettingParams,
  ClientJobListItem,
  ClientProposalResponse,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isDate } from 'date-fns';

@Component({
  selector: 'wilson-share-job-expires-in-renderer',
  template: `
    <ng-container *ngIf="dateValue && (!hiddenIfReleased || !isReleased)">
      <wilson-share-job-expired-in
        [date]="dateValue"
        [highlightDanger]="highlightDanger"
      ></wilson-share-job-expired-in>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareJobExpiresInRendererComponent
  implements ICellRendererAngularComp
{
  dateValue!: Date;
  isReleased = false;

  highlightDanger = true;
  hiddenIfReleased = true;

  agInit(params: ICellRendererParams & ExpirationSettingParams): void {
    this.setValues(params);
  }

  refresh(params: ICellRendererParams & ExpirationSettingParams) {
    this.setValues(params);
    return true;
  }

  private setValues(params: ICellRendererParams & ExpirationSettingParams) {
    this.dateValue = params.value ? new Date(params.value) : null;
    if (isDate(this.dateValue)) {
      this.isReleased =
        (params.data as ClientJobListItem).clientProposalResponse ===
        ClientProposalResponse.Accepted;
    } else {
      this.isReleased = false;
    }

    this.highlightDanger = params.highlightDanger;
    this.hiddenIfReleased = params.hiddenIfReleased;
  }
}

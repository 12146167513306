import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Activity, ActivityComment, Shift } from '@wilson/interfaces';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
} from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ActivityCommentsService } from '../../activity-comments.service';
import { AuthState } from '@wilson/auth/core';

@Component({
  selector: 'wilson-activity-comments',
  templateUrl: './activity-comments.component.html',
  styleUrls: ['./activity-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCommentsComponent implements OnInit {
  @Input() activity: Activity;
  @Input() shift: Shift;
  @ViewChild('comments') comments: ElementRef<HTMLDivElement> | undefined;

  text = '';
  readonly userId$ = this.store.select(AuthState.userId);
  readonly refreshCommentsSubject = new BehaviorSubject<boolean>(true);

  comments$: Observable<ActivityComment[]>;

  loading = false;

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly commentService: ActivityCommentsService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.comments$ = this.refreshCommentsSubject.pipe(
      tap(() => {
        this.loading = true;
      }),
      switchMap(() =>
        this.commentService.getAllResolvedForActivity(
          this.activity.id as string,
        ),
      ),
      tap((comments) => {
        this.scrollToBottom();
        this.updateCommentsSeenBy(comments);
        this.loading = false;
      }),
    );
  }

  scrollToBottom() {
    if (this.comments) {
      setTimeout(() => {
        this.comments.nativeElement.scrollTop =
          this.comments.nativeElement.scrollHeight;
      }, 0);
    }
  }

  refreshComments() {
    this.refreshCommentsSubject.next(true);
  }

  trackById(index: number, item: ActivityComment) {
    return item.id;
  }

  updateCommentsSeenBy(comments: ActivityComment[]) {
    return firstValueFrom(
      this.userId$.pipe(
        switchMap((userId) => {
          const updates: Observable<ActivityComment>[] = comments.map(
            (comment) => {
              if (
                comment.seenByIds?.includes(userId) ||
                comment.senderId === userId
              ) {
                return of();
              }
              if (!comment.seenByIds) {
                comment.seenByIds = [];
              }
              comment.seenByIds?.push(userId);
              return from(
                this.commentService.update({
                  id: comment.id as string,
                  seenByIds: comment.seenByIds,
                }),
              );
            },
          );

          return combineLatest(updates);
        }),
      ),
    );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Shift, ShiftTemplate } from '@wilson/interfaces';
import { ShiftTemplateToShiftService } from '@wilson/non-domain-specific/shift-helpers/core';
import { ShiftTemplateService } from '@wilson/shift-templates';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ShiftFromShiftTemplateService {
  constructor(
    private readonly shiftTemplateService: ShiftTemplateService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly shiftTemplateToShift: ShiftTemplateToShiftService,
  ) {}

  public async createShiftFromShiftTemplate(
    shiftTemplateId: string,
  ): Promise<Shift | null> {
    const shiftTemplate: ShiftTemplate = (
      await firstValueFrom(
        this.shiftTemplateService.getAll({
          where: {
            id: shiftTemplateId,
          },
          relations: [
            'organizationalUnit',
            'shiftCategory',
            'createdBy',
            'shiftTemplateActivityTemplates',
            'shiftTemplateActivityTemplates.activityTemplate',
            'shiftTemplateActivityTemplates.activityTemplate.startLocation',
            'shiftTemplateActivityTemplates.activityTemplate.endLocation',
            'shiftTemplateActivityTemplates.activityTemplate.activityCategory',
          ],
        }),
      )
    )[0];

    if (!shiftTemplate) {
      this.toastr.error(this.translate.instant('general.errorMessage'));
      this.router.navigate(['/shift-templates']);
      return null;
    }

    return this.shiftTemplateToShift.createShiftFromTemplateAndActivities(
      shiftTemplate,
    );
  }

  public async getAllShiftTemplates(): Promise<ShiftTemplate[]> {
    return firstValueFrom(
      this.shiftTemplateService
        .getAll({
          relations: [
            'organizationalUnit',
            'shiftCategory',
            'shiftTemplateActivityTemplates',
            'shiftTemplateActivityTemplates.activityTemplate',
            'shiftTemplateActivityTemplates.activityTemplate.startLocation',
            'shiftTemplateActivityTemplates.activityTemplate.endLocation',
            'shiftTemplateActivityTemplates.activityTemplate.activityCategory',
          ],
          limit: 0,
        })
        .pipe(
          map((shiftTemplates) => {
            shiftTemplates.map((shiftTemplate) => {
              shiftTemplate.shiftTemplateActivityTemplates?.sort((a, b) =>
                a.activityTemplate.startDatetime.localeCompare(
                  b.activityTemplate.startDatetime,
                ),
              );
              return shiftTemplate;
            });
            return shiftTemplates;
          }),
        ),
    );
  }
}

import { JwtConfig } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { ConfigOptions } from '@wilson/config';
import { firstValueFrom } from 'rxjs';

export function JwtOptionsFactory(store: Store, config: ConfigOptions) {
  const url = new URL(config.host);

  return {
    tokenGetter: () => {
      return firstValueFrom(
        store.select<string>((state) => state.auth.accessToken),
      );
    },
    allowedDomains: [url.host],
    disallowedRoutes: [/\/assets\/.+\.json/],
  } as JwtConfig;
}

import { Pipe, PipeTransform } from '@angular/core';
import { ShiftState } from '@wilson/interfaces';

@Pipe({
  name: 'isShiftSubmittedOrAccepted',
  standalone: true,
})
export class IsShiftSubmittedOrAcceptedPipe implements PipeTransform {
  transform(state: ShiftState | undefined): boolean {
    if (state) {
      return [
        ShiftState.Submitted,
        ShiftState.AcceptedTimes,
        ShiftState.SubmittedToPayrollProvider,
      ].includes(state);
    } else {
      return false;
    }
  }
}

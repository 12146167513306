import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PayrollSetting } from '@wilson/payroll/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-redirecting-payroll-setting-link-renderer',
  template: `
    <a
      *ngIf="title; else noValue"
      (click)="openInNewTabAndStopBubbling($event)"
      class="link-dark"
      >{{ title }}</a
    >

    <ng-template #noValue>{{ '---' }} </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectingPayrollSettingLinkRendererComponent
  implements ICellRendererAngularComp
{
  public title!: string;
  public propertyId: string;

  async agInit(params: ICellRendererParams) {
    this.initValues(params);
  }

  refresh(params: ICellRendererParams) {
    this.initValues(params);
    return true;
  }

  private initValues(params: ICellRendererParams) {
    const data: PayrollSetting =
      params.data?.userPayrollSettings[0]?.payrollSetting;
    this.propertyId = data?.id;
    this.title = data?.name;
  }

  openInNewTabAndStopBubbling(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    const baseUrl = window.location.origin;
    const path = `/settings/payroll-settings/${this.propertyId}`;
    const url = baseUrl + path;

    window.open(url, '_blank');
  }
}

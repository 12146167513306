import { Injectable } from '@angular/core';
import { Shift } from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable()
export class ShiftNameCheckService {
  constructor(private readonly shiftsService: ShiftsService) {}

  public checkShiftNameAlreadyExists(
    shiftName: string,
    shiftDate: string,
    shiftId: string | null,
  ): Observable<Shift | null> {
    const sanitizedShiftName = shiftName.trim();
    if (sanitizedShiftName.length > 3) {
      const selectedDate = shiftDate ? new Date(shiftDate) : new Date();
      return this.shiftsService
        .getShifts({
          where: {
            startDate: selectedDate.toISOString(),
            name: sanitizedShiftName,
          },
          limit: 0,
          offset: 0,
          order: {},
        })
        .pipe(
          map((result) => {
            const shifts = result.data;
            if (shifts.length) {
              return shifts.find(
                (shift) =>
                  shift.name === sanitizedShiftName && shift.id !== shiftId,
              );
            } else {
              return null;
            }
          }),
          catchError(() => of(null)),
        );
    } else {
      return of(null);
    }
  }
}

import { Injectable } from '@angular/core';
import { User } from '@wilson/interfaces';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  private isOpen = new ReplaySubject<boolean>(1);
  isOpen$: Observable<boolean> = this.isOpen.asObservable();
  private otherUser = new ReplaySubject<User>(1);
  otherUser$: Observable<User> = this.otherUser.asObservable();
  private badgeCounter = new ReplaySubject<number>(1);
  badgeCounter$: Observable<number> = this.badgeCounter.asObservable();

  async openInbox() {
    this.isOpen.next(true);
  }

  async closeInbox() {
    this.isOpen.next(false);
  }

  async updateOtherUser(user: User) {
    this.otherUser.next(user);
  }

  async passBadgeNumber(no: number) {
    this.badgeCounter.next(no);
  }
}

import { ValidationErrors, ValidatorFn } from '@angular/forms';

export function MustMatchValidator(matchingControlName: string): ValidatorFn {
  return (control): ValidationErrors | null => {
    const match: string = control.parent?.controls[matchingControlName]?.value;

    if (control.value === match) {
      return null;
    }

    return {
      mustmatch: true,
    };
  };
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { PublicationStatus, Shift } from '@wilson/interfaces';
import { formatDistanceToNow } from 'date-fns';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { Subject, map, takeUntil } from 'rxjs';

type Payload = Pick<Shift, 'userId' | 'lastSeenAt' | 'publicationStatus'>;

@Component({
  selector: 'wilson-shift-last-seen',
  standalone: true,
  imports: [CommonModule, FeatureFlagsModule],
  templateUrl: './shift-last-seen.component.html',
  styleUrl: './shift-last-seen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftLastSeenComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private _payload: Payload;
  public trackShiftLastSeenSetting$ = this.store
    .select(AuthState.mobileSettings)
    .pipe(
      takeUntil(this.destroyed$),
      map((res) => res.trackShiftLastSeen),
    );
  public formattedLastSeenAt!: string;
  public hideLastSeenAt = false;
  @Input() set payload(newValue: Payload) {
    this._payload = newValue;
    this.setHideLastSeenAt(newValue);
    this.setFormattedLastSeenAt(newValue);
    this.cdr.detectChanges();
  }

  get payload() {
    return this._payload;
  }

  constructor(
    private readonly translateService: TranslateService,
    private readonly dateConfig: DateFnsConfigurationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.lang.lang)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setFormattedLastSeenAt(this.payload);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setHideLastSeenAt(newValue: Payload) {
    if (
      !newValue ||
      !newValue.userId ||
      newValue.publicationStatus === PublicationStatus.NotPublished ||
      newValue.publicationStatus === PublicationStatus.NotPublishedAgain
    ) {
      this.hideLastSeenAt = true;
    } else {
      this.hideLastSeenAt = false;
    }
  }

  private setFormattedLastSeenAt(newValue: Payload) {
    if (newValue?.lastSeenAt) {
      this.formattedLastSeenAt = this.translateService.instant(
        'shift.last_seen_at.seen',
        {
          formattedDistance: formatDistanceToNow(
            new Date(newValue.lastSeenAt),
            {
              includeSeconds: false,
              addSuffix: true,
              locale: this.dateConfig.locale(),
            },
          ),
        },
      );
    } else {
      this.formattedLastSeenAt = this.translateService.instant(
        'shift.last_seen_at.not_seen',
      );
    }
  }
}

import { DemarcatorRange } from '@wilson/interfaces';

export class ToggleRenderTimelineInModal {
  static readonly type = '[ShiftAssignment] Set IsTimelineModal';
}

export class ToggleRadioButtonInTimelineModal {
  static readonly type =
    '[ShiftAssignment] Toggle Radio Button In Timeline Modal';
}

export class ResetTimelineInteractionsState {
  static readonly type = '[Shift Assignment] Reset Interactions State';
}

export class SetDemarcatorRange {
  static readonly type = '[ActivityAssignment] Set Drop Zone';
  constructor(public demarcatorRange: DemarcatorRange[]) {}
}

<div class="card">
  <div class="card-body">
    <h5 class="card-title text-dark">{{ title }}</h5>
    <div
      class="row alert-msg pr-5 py-2"
      [ngClass]="highlightTextColor + ' ' + highlightClass"
    >
      <div class="col-11 ms-2">
        <p class="my-2 fw-bold">
          {{ primaryMessage }}
        </p>
      </div>
    </div>
    <div class="row my-3 ps-1">
      <p class="mb-0">{{ secondaryMessage }}</p>
    </div>
    <hr />
    <div class="d-flex justify-content-end mt-4">
      <button
        type="button"
        class="btn btn-dark p-1 me-2 fw-bold"
        (click)="decline()"
      >
        {{ cancelButtonText }}
      </button>
      <button
        type="button"
        class="btn p-1 fw-bold"
        [class]="confirmButtonClass + ' ' + highlightTextColor"
        (click)="accept()"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitiesAgGridModule } from '@wilson/activities/ag-grid';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { EmptyStateModule } from '@wilson/shared-components';
import { AgGridModule } from 'ag-grid-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { CorrectiveActionBarComponent } from './components/corrective-action-bar/corrective-action-bar.component';
import { UpdateTimePopoverComponent } from './components/corrective-action-bar/update-time-popover/update-time-popover.component';
import { ShiftActivitiesComponent } from './components/shift-activities/shift-activities.component';
import { TemplateActivityEndTimeRendererComponent } from './components/shift-activities/template-activity-name-renderer/template-activity-end-time-renderer.component';
import { TemplateActivityNameRendererComponent } from './components/shift-activities/template-activity-name-renderer/template-activity-name-renderer.component';
import { TemplateActivityStartTimeRendererComponent } from './components/shift-activities/template-activity-name-renderer/template-activity-start-time-renderer.component';
import { ShiftTemplateListItemComponent } from './components/shift-template-list-item/shift-template-list-item.component';
import { ShiftTemplatePickerComponent } from './components/shift-template-picker-popup/shift-template-picker-popup.component';
import { ActivityTimeDifferenceCheckPipe } from './pipes/activity-time-difference-check/activity-time-difference-check.pipe';
import { FilterTemplateByName } from './pipes/filter-shift-template-by-name/filter-template-by-name.pipe';
import { IsActivityAdjustablePipe } from './pipes/is-activity-adjustable/is-activity-adjustable.pipe';
import { TotalPushableActivitiesPipe } from './pipes/total-pushable-activities/total-pushable-activities.pipe';
import { TotalPullableActivitiesPipe } from './pipes/total-pullable-activities/total-pullable-activities.pipe';
import { ShiftDurationPipe } from './pipes/shift-duration/shift-duration.pipe';
import { ShiftFromShiftTemplateService } from './services/shift-from-shift-template/shift-from-shift-template-service';
import { UpdateLocationPopoverComponent } from './components/corrective-action-bar/update-location-popover/update-location-popover.component';
import { AreActivityLocationsSetPipe } from './pipes/are-activity-locations-set/are-activity-locations-set.pipe';
import { AreActivitiesEditablePipe } from './pipes/are-activities-editable/are-activities-editable.pipe';
import { PipesModule } from '@wilson/pipes';
import { TotalNumberOfGapIssuesPipe } from './pipes/total-number-of-gap-issues/total-number-of-gap-issues.pipe';
import { IsActivityLocationMismatchPipe } from './pipes/activity-location-check/is-activity-location-mismatch.pipe';
import { ShiftScoresService } from './services/shift-scores/shift-scores.service';
import { ShiftStatusTagsComponent } from './components/shift-status-tags/shift-status-tags.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ActivitiesPipesModule } from '@wilson/activities/pipes';
import {
  ActivityTimeOverday,
  IsShiftConfirmedAtStatusPipe,
  IsShiftDeclinedAtStatusPipe,
} from '@wilson/non-domain-specific/shift-helpers/core';
import { DateFnsModule } from 'ngx-date-fns';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    FilterTemplateByName,
    ShiftTemplatePickerComponent,
    ShiftTemplateListItemComponent,
    ShiftDurationPipe,
    ShiftActivitiesComponent,
    TemplateActivityEndTimeRendererComponent,
    TemplateActivityNameRendererComponent,
    TemplateActivityStartTimeRendererComponent,
    CorrectiveActionBarComponent,
    ActivityTimeDifferenceCheckPipe,
    AreActivityLocationsSetPipe,
    UpdateTimePopoverComponent,
    IsActivityAdjustablePipe,
    TotalPushableActivitiesPipe,
    TotalPullableActivitiesPipe,
    UpdateLocationPopoverComponent,
    AreActivitiesEditablePipe,
    TotalNumberOfGapIssuesPipe,
    IsActivityLocationMismatchPipe,
    ShiftStatusTagsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    EmptyStateModule,
    AgGridModule,
    ActivitiesAgGridModule,
    NzButtonModule,
    FontAwesomeModule,
    NzPopoverModule,
    NzDividerModule,
    FeatureFlagsModule,
    PipesModule,
    ActivitiesPipesModule,
    ActivityTimeOverday,
    NzTagModule,
    IsShiftConfirmedAtStatusPipe,
    IsShiftDeclinedAtStatusPipe,
    DateFnsModule,
    NzToolTipModule,
  ],
  exports: [
    FilterTemplateByName,
    ShiftDurationPipe,
    CorrectiveActionBarComponent,
    ActivityTimeDifferenceCheckPipe,
    AreActivityLocationsSetPipe,
    AreActivitiesEditablePipe,
    TotalNumberOfGapIssuesPipe,
    IsActivityLocationMismatchPipe,
    ShiftStatusTagsComponent,
  ],
  providers: [
    ShiftFromShiftTemplateService,
    IsActivityLocationMismatchPipe,
    TotalPushableActivitiesPipe,
    TotalPullableActivitiesPipe,
    AreActivityLocationsSetPipe,
    AreActivitiesEditablePipe,
    ShiftScoresService,
  ],
})
export class ShiftCoreModule {}

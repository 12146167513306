<span
  *ngIf="shift?.operationalStatus === OperationStatus.Ongoing"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-primary-dark"
>
  {{ 'page.shifts.started' | translate }}
</span>
<span
  *ngIf="shift?.operationalStatus === OperationStatus.Completed"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-primary-dark"
>
  <i class="fas fa-check-double"></i>
  {{ 'page.shifts.completed' | translate }}
</span>
<span
  *ngIf="shift?.operationalStatus === OperationStatus.NotStarted"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-secondary-dark"
>
  {{ 'page.shifts.not_started' | translate }}
</span>
<span
  *ngIf="shift | isShiftPublished"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-primary-dark"
>
  {{ 'page.work_time_management.' + shift?.state | translate }}
</span>
<span
  *ngIf="!(shift | isShiftPublished)"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-secondary-dark"
>
  {{ 'page.shifts.not_released' | translate }}
</span>
<span
  *ngIf="shift | isShiftSelfDispatched"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-secondary-dark"
>
  {{ 'page.shifts.self_dispatched' | translate }}
</span>
<span
  *ngIf="shift | isShiftConfirmedAtStatus | async"
  nz-tooltip
  [nzTooltipTitle]="shift?.confirmedAt | dfnsParseIso | dfnsFormat : 'Pp'"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-white"
>
  {{ 'shift.status.confirmed_at' | translate }}
</span>
<span
  *ngIf="shift | isShiftDeclinedAtStatus | async"
  nz-tooltip
  [nzTooltipTitle]="tooltipDeclineddReason"
  class="ms-2 px-3 mb-2 badge rounded-1 badge-danger"
>
  {{ 'shift.status.declined_at' | translate }}
</span>
<ng-template #tooltipDeclineddReason>
  {{ shift?.declinedAt | dfnsParseIso | dfnsFormat : 'Pp' }}
  <p>
    {{
      shift?.declineReason
        ? shift?.declineReason
        : ('shift.declined_at.no_reason' | translate)
    }}
  </p>
</ng-template>

import { Base } from '@wilson/base';

export interface SeriesRule extends Base {
  serviceSeriesId: string | null;
  shiftSeriesId: string | null;
  startDate: string;
  endDate: string;
  type: SeriesRuleType;
  frequency: SeriesRuleFrequency;
}

export enum SeriesRuleType {
  Exclude = 'exclude',
  Include = 'include',
}

export enum SeriesRuleFrequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  HOURLY = 4,
  MINUTELY = 5,
  SECONDLY = 6,
}

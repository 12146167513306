import { Pipe, PipeTransform } from '@angular/core';
import { ResolvedActivity } from '@wilson/interfaces';
import { AnyActivityCanBeAddedToShareService } from '../services/activity-can-be-added-to-share.service';

@Pipe({
  name: 'anyActivityCanBeAddedToShare',
})
export class AnyActivityCanBeAddedToSharePipe implements PipeTransform {
  constructor(
    private readonly anyActivityCanBeAddedToShareService: AnyActivityCanBeAddedToShareService,
  ) {}

  transform(
    value: ResolvedActivity | ResolvedActivity[] | undefined,
    allShouldBeAddable = false,
  ): boolean {
    return this.anyActivityCanBeAddedToShareService.anyActivityCanBeAddedToShare(
      value,
      allShouldBeAddable,
    );
  }
}

import {
  Shift,
  ShiftDispositionAssignable,
  ShiftDispositionAssignableShifts,
  ShiftDispositionFilters,
  User,
} from '@wilson/interfaces';

export class SetDateRange {
  static readonly type = '[ShiftDisposition] Set date range';
  constructor(public payload: [Date, Date]) {}
}

export class FetchShiftValidations {
  static readonly type = '[ShiftDisposition] FetchShiftValidations';
}

export class UpdateAssignedShiftsKPIs {
  static readonly type = '[ShiftDisposition] UpdateAssignedShiftsKPIs';
}

export class FetchAssignedShiftsInView {
  static readonly type = '[ShiftDisposition] FetchAssignedShiftsInView';
}

export class FetchAssignedAbsences {
  static readonly type = '[ShiftDisposition] FetchAssignedAbsences';
}

export class FetchUnassignedShifts {
  static readonly type = '[ShiftDisposition] FetchUnassignedShifts';
}

export class FetchAllDataInView {
  static readonly type = '[ShiftDisposition] Fetch All Data In View';
}

export class Reset {
  static readonly type = '[ShiftDisposition] Reset';
}

export class Initialize {
  static readonly type = '[ShiftDisposition] Initialize';
}

export class UpdateViewableRowIndex {
  static readonly type = '[ShiftDisposition] Update Viewable Row Index';
  constructor(
    public payload: {
      index: number;
    },
  ) {}
}

export class UpdateViewableRowCount {
  static readonly type = '[ShiftDisposition] Update Viewable Row Count';
  constructor(
    public payload: {
      count: number;
    },
  ) {}
}

export class SetFilters {
  static readonly type = '[ShiftDisposition] SetFilters';
  constructor(public payload: Partial<ShiftDispositionFilters>) {}
}

export class AssignShift {
  static readonly type = '[ShiftDisposition] AssignShift';
  constructor(
    public payload: {
      user: User;
      shiftToAssign: ShiftDispositionAssignable;
    },
  ) {}
}

export class UnassignShift {
  static readonly type = '[ShiftDisposition] UnassignShift';
  constructor(
    public payload: {
      shiftsToUnassign: ShiftDispositionAssignableShifts[];
    },
  ) {}
}
export class PublishShifts {
  static readonly type = '[ShiftDisposition] PublishShifts';
  constructor(
    public payload: {
      shifts: Shift[];
    },
  ) {}
}

export class UnpublishShifts {
  static readonly type = '[ShiftDisposition] unpublish Shifts';
  constructor(
    public payload: {
      shifts: Shift[];
    },
  ) {}
}

export class CleanOptimisticallyUpdatedShiftCache {
  static readonly type =
    '[ShiftDisposition] Clean Optimistically Updated Shift Cache';
  constructor(
    public payload: {
      shifts: Shift[];
    },
  ) {}
}

import { Component } from '@angular/core';
import { ActivityTemplate } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-planing-status-renderer',
  template: `
    <div>
      @if (planingStatus === 'partial') {
      <nz-tag [nzColor]="'orange'">
        {{ 'page.service_details.partial_assigned' | translate }}</nz-tag
      >
      } @else if (planingStatus) {
      <nz-tag [nzColor]="'green'">
        {{ 'page.service_details.completely_assigned' | translate }}
      </nz-tag>
      } @else if (!planingStatus) {
      <nz-tag [nzColor]="'red'">
        {{ 'page.service_details.missing_assigned' | translate }}
      </nz-tag>
      }
    </div>
  `,
})
export class ActivityPlaningStatusRendererComponent
  implements AgRendererComponent
{
  public planingStatus!: string | boolean;

  agInit(params: ICellRendererParams): void {
    this.planingStatus = this.checkShiftSeriesIdStatus(
      params.data.activityTemplates,
    );
  }

  refresh(params: ICellRendererParams) {
    this.planingStatus = this.checkShiftSeriesIdStatus(
      params.data.activityTemplates,
    );
    return true;
  }

  checkShiftSeriesIdStatus(
    activityTemplates: ActivityTemplate[],
  ): boolean | string {
    let nullCount = 0;
    let notNullCount = 0;

    for (const template of activityTemplates) {
      if (template.shiftSeriesId === null) {
        nullCount++;
      } else {
        notNullCount++;
      }
    }

    if (nullCount === activityTemplates.length) {
      return false;
    } else if (notNullCount === activityTemplates.length) {
      return true;
    } else {
      return 'partial';
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'wilson-token-icon-renderer',
  template: `
    <span class="d-flex mt-2 justify-content-center align-items-center">
      <img src="assets/img/svg-icons/token-icon.svg" />
    </span>
  `,
  styles: [
    `
      span {
        background-color: var(--background);
        border-radius: 50%;
        height: 40px;
        width: 40px;
        font-size: 20px;
        color: var(--light);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenIconRendererComponent implements AgRendererComponent {
  agInit() {
    // This is intentionally empty
  }

  refresh() {
    return true;
  }
}

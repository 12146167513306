import { LinkRendererService } from '@wilson/ag-grid/service';
import { LinkRendererParams, LinkType } from '@wilson/interfaces';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-shift-name-renderer',
  template: `
    <ng-container
      *ngIf="{
        title: linkData?.title ? linkData?.title : ''
      } as data"
    >
      <a
        *ngIf="linkData?.link; else noLink"
        [routerLink]="linkData?.link"
        [queryParams]="linkData?.params"
        [class]="linkData?.linkClass"
        nz-tooltip
        [nzTooltipTitle]="data.title | translate"
        [nzTooltipColor]="'#fff'"
        >{{ data.title }}</a
      >
      <ng-template #noLink
        ><span [class]="emptyClass">{{ data.title }}</span></ng-template
      >
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftNameRendererComponent implements ICellRendererAngularComp {
  emptyClass = '';
  linkData: LinkType | undefined;

  constructor(private linkRenderer: LinkRendererService) {}

  agInit(params: ICellRendererParams & LinkRendererParams): void {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
  }

  refresh(params: ICellRendererParams & LinkRendererParams) {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
    return true;
  }
}

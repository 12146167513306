export class ToggleShiftTimelineDrawerVisibility {
  static readonly type = '[ShiftTimelineDrawer] Toggle Visibility';
  constructor(public payload: boolean) {}
}

export class SelectedTimelineShiftId {
  static readonly type = '[ShiftTimelineDrawer] Selected Shift';
  constructor(public shiftId: string, public isUnAssignedShift: boolean) {}
}

export class ResetTimelineDrawerState {
  static readonly type =
    '[ShiftTimelineDrawer] Reset the timeline drawer state';
}

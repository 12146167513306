import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { faEnvelopeOpenDollar } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'wilson-working-time-model-icon-renderer',
  template: `
    <div
      class="icon-square bg-light text-white flex-shrink-0 me-3 d-flex justify-content-center"
    >
      <fa-icon
        [icon]="faEnvelopeOpenDollar"
        class="d-flex justify-content-center align-items-center"
      ></fa-icon>
    </div>
  `,
  styles: [
    `
      .icon-square {
        padding: 11px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
      }
      fa-icon {
        font-size: 20px;
      }
    `,
  ],
})
export class PayrollSettingsIconRendererComponent
  implements AgRendererComponent
{
  public readonly faEnvelopeOpenDollar = faEnvelopeOpenDollar;

  agInit(): void {
    this.refresh();
  }

  refresh() {
    return true;
  }
}

import { ZOOM_LEVEL } from '@wilson/interfaces';

export class SetZoomLevel {
  static readonly type = '[ShiftTimeline] SetZoomLevel';
  constructor(public zoomLevel: ZOOM_LEVEL) {}
}

export class SetDefaultRowHeight {
  static readonly type = '[ShiftAssignment] SetDefaultRowHeight';
  constructor(public height: number) {}
}

export class SetDefaultShiftHeight {
  static readonly type = '[ShiftAssignment] SetDefaultShiftHeight';
  constructor(public height: number) {}
}

export class ResetShiftTimelineWidth {
  static readonly type =
    '[Shift Assignment] Reset Timeline Settings State State';
}

export class ResetRowHeight {
  static readonly type = '[Shift Assignment] Reset Row Height';
}

export class SetAreActivitiesVisible {
  static readonly type = '[Shift Assignment] Set Are Activities Visible';
  constructor(public isVisible: boolean) {}
}

export class SetAreStaysVisible {
  static readonly type = '[Shift Assignment] Set Are Stays Visible';
  constructor(public isVisible: boolean) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ResolvedActivity } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { addDays, differenceInMinutes, format } from 'date-fns';

@Pipe({
  name: 'activityTimeDifferenceCheck',
})
export class ActivityTimeDifferenceCheckPipe implements PipeTransform {
  transform(
    activities: (Activity | ResolvedActivity)[],
    {
      index,
      shiftStartDate,
      overlapDaysCurrent,
      overlapDaysNext,
    }: {
      index: number;
      shiftStartDate: string;
      overlapDaysCurrent: number;
      overlapDaysNext: number;
    },
  ): number {
    if (index === activities.length - 1) return 0;

    const endDateTimeString = activities[index]?.endDatetime;
    const nextStartDateTimeString = activities[index + 1]?.startDatetime;

    if (!endDateTimeString || !nextStartDateTimeString) {
      return 0;
    }

    const getCurrentDateTime = (
      datetime: string,
      overlapDays: number,
    ): Date => {
      if (datetime.length === 5) {
        const activityDate = format(
          addDays(new Date(shiftStartDate), overlapDays),
          DateTimeFormat.DatabaseDateFormat,
        );
        const completeDateTimeString = `${activityDate} ${datetime}`;
        return new Date(completeDateTimeString);
      } else {
        const date = new Date(datetime);
        date.setSeconds(0);
        return date;
      }
    };

    const endDateTime = getCurrentDateTime(
      endDateTimeString,
      overlapDaysCurrent,
    );

    const nextStartDateTime = getCurrentDateTime(
      nextStartDateTimeString,
      overlapDaysNext,
    );

    return differenceInMinutes(nextStartDateTime, endDateTime as Date);
  }
}

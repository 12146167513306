import { addDays, isBefore, isEqual } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

// Define types for validation and validations object
export type DataCacheRecords = Record<string, string[]>;

function getDateKey(date: Date) {
  return date.toISOString().split('T')[0];
}

export function filterAndUpdateDataRecords(
  validationRecords: DataCacheRecords,
  userIds: string[],
  startDate: Date,
  endDate: Date,
): {
  unvalidatedUsers: string[];
  updatedValidatedUsersRecords: DataCacheRecords;
} {
  const clonedRecords = cloneDeep(validationRecords);
  const unvalidatedUsers = [];
  const dateKeys = getDateKeysRange(startDate, endDate);

  for (const userId of userIds) {
    const validatedDates = new Set(clonedRecords[userId] || []);
    let hasRangeBeenValidated = null;

    for (const dateKey of dateKeys) {
      if (validatedDates.has(dateKey) && hasRangeBeenValidated !== false) {
        hasRangeBeenValidated = true;
      } else {
        hasRangeBeenValidated = false;
        validatedDates.add(dateKey);
      }
    }
    clonedRecords[userId] = Array.from(validatedDates).sort();

    if (!hasRangeBeenValidated) {
      unvalidatedUsers.push(userId);
    }
  }

  return {
    unvalidatedUsers,
    updatedValidatedUsersRecords: clonedRecords,
  };
}

function getDateKeysRange(startDate: Date, endDate: Date): string[] {
  let dateIterator = startDate;
  const dayKeys: string[] = [];
  do {
    dayKeys.push(getDateKey(dateIterator));
    dateIterator = addDays(dateIterator, 1);
  } while (isBefore(dateIterator, endDate) || isEqual(dateIterator, endDate));

  return dayKeys;
}

export function isUserAndDateInRecord(
  validationRecords: DataCacheRecords,
  userId: string,
  shiftStartDate: Date,
) {
  const shiftDateKey = getDateKey(shiftStartDate);
  return validationRecords[userId]?.includes(shiftDateKey) || false;
}

export function trackAndMaintainMaxRequestCount({
  cancellationSubject,
  requestReferences,
  maxParallelRequestCount,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancellationSubject: Subject<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestReferences: Subject<any>[];
  maxParallelRequestCount: number;
}) {
  if (requestReferences.length >= maxParallelRequestCount) {
    const earliestCancellationSubject = requestReferences.shift();

    earliestCancellationSubject?.next(null);
    earliestCancellationSubject?.complete();
  }

  requestReferences.push(cancellationSubject);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateQualificationPipe } from './translate-qualification.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [TranslateQualificationPipe],
  providers: [TranslatePipe, TranslateQualificationPipe],
  exports: [TranslateQualificationPipe],
})
export class TranslateQualificationPipeModule {}

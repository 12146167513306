import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ResolvedActivity } from '@wilson/interfaces';

@Pipe({
  name: 'areActivityLocationsSetPipe',
  pure: false,
})
export class AreActivityLocationsSetPipe implements PipeTransform {
  transform(
    activities: (Activity | ResolvedActivity)[],
    index: number,
  ): boolean {
    const interestedActivities = [activities[index], activities[index + 1]];

    return interestedActivities.every(
      (activity) =>
        activity.startLocation &&
        activity.endLocation &&
        activity.startLocation &&
        activity.endLocation,
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncrementDecrementButtonComponent } from './increment-decrement-button.component';

@NgModule({
  declarations: [IncrementDecrementButtonComponent],
  imports: [CommonModule],
  exports: [IncrementDecrementButtonComponent],
})
export class IncrementDecrementButtonModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { Shift, WithPreparedAttributes } from '@wilson/interfaces';
import { determineShiftRenderDatetime } from './determine-shift-render-datetime-fn';

@Pipe({
  name: 'determineShiftRenderDatetime',
  standalone: true,
})
export class DetermineShiftRenderDatetimePipe implements PipeTransform {
  transform(
    shift: Shift &
      WithPreparedAttributes & {
        id: string;
      },
  ) {
    return determineShiftRenderDatetime(shift);
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ShiftQuickCheckComponent } from '../../shift-quick-check/shift-quick-check.component';
import { ShiftValidationDetails } from '@wilson/interfaces';

@Component({
  selector: 'wilson-collapsible-shift-quick-check',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    AuthorizationModule,
    FeatureFlagsModule,
    TranslateModule,
    ShiftQuickCheckComponent,
  ],
  templateUrl: './collapsible-shift-quick-check.component.html',
  styleUrl: './collapsible-shift-quick-check.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftQuickCheckComponent {
  @Input() shiftValidationDetails!: ShiftValidationDetails[];
  @Input() userId: string | undefined;
  @Input() isExpandedOnOpen = false;
}

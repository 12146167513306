import { Injectable } from '@angular/core';
import { Score, ShiftScoreName, ShiftSmartScoreName } from '@wilson/interfaces';

export interface ScoreDescription {
  value: string;
  widthPercentage: number;
  backgroundColor: string;
}

export type ScoreMap = Record<ShiftScoreName, ScoreDescription>;

export type SmartScoreMap = Record<ShiftSmartScoreName, ScoreDescription>;

@Injectable({
  providedIn: 'root',
})
export class ShiftScoresService {
  calculateSmartScore(score: Score[]) {
    const smartScore = score.find((res) => res.name === 'shift-smart-score');
    return smartScore?.score ? (smartScore.score * 5).toFixed(1) : '0';
  }

  calculatePrefScore(score: Score[]) {
    const prefScore = score.find(
      (res) => res.name === 'shift-preference-score',
    );
    const derivedScore = prefScore?.score ?? 0;
    return derivedScore.toFixed(1);
  }

  convertToPrefScoreMap(scores: Score[]): ScoreMap {
    const mappedPrefScores: ScoreMap = {} as ScoreMap;

    scores?.forEach((singleScore) => {
      if (
        Object.values(ShiftScoreName).includes(
          singleScore.name as ShiftScoreName,
        )
      ) {
        const scoreValue = singleScore?.score?.toFixed(1) || '0';
        const visualProgress = this.calculateVisualProgress(scoreValue);

        mappedPrefScores[singleScore.name as keyof ScoreMap] = {
          value: scoreValue,
          ...visualProgress,
        };
      }
    });

    if (!mappedPrefScores['shift-preference-score']) {
      const mainPreferenceScore = this.calculatePrefScore(scores);
      mappedPrefScores[ShiftScoreName.ShiftPreferenceScore] = {
        value: mainPreferenceScore,
        ...this.calculateVisualProgress(mainPreferenceScore),
      };
    }

    return mappedPrefScores;
  }

  getScoreForProfitabilityScore(scores: Score[]): number {
    const profitabilityScore = scores.find(
      (res) => res.name === 'shift-profitability-score',
    );

    return profitabilityScore?.score;
  }

  convertToMappedSmartScore(scores: Score[]): SmartScoreMap {
    const mappedSmartScores: SmartScoreMap = {} as SmartScoreMap;

    scores?.forEach((singleScore) => {
      if (
        Object.values(ShiftSmartScoreName).includes(
          singleScore.name as ShiftSmartScoreName,
        )
      ) {
        const scoreValue = (singleScore?.score * 5)?.toFixed(1) || '0';
        const visualProgress = this.calculateVisualProgress(scoreValue);

        mappedSmartScores[singleScore.name as keyof SmartScoreMap] = {
          value: scoreValue,
          ...visualProgress,
        };
      }
    });

    return mappedSmartScores;
  }

  private calculateVisualProgress(
    scoreValue: string,
  ): Pick<ScoreDescription, 'backgroundColor' | 'widthPercentage'> {
    const width = (Number(scoreValue) * 100) / 5;
    const backgroundColor = this.getColorByValue(parseFloat(scoreValue));
    return {
      widthPercentage: width,
      backgroundColor,
    };
  }

  getColorByValue(value: number) {
    if (value < 2.5) return 'var(--red-5)';
    if (value < 3.5) return 'var(--gold-4)';
    else return 'var(--primary)';
  }
}

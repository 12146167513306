import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { isBefore, isDate, subHours } from 'date-fns';

@Component({
  selector: 'wilson-share-job-expired-in',
  templateUrl: './share-job-expired-in.component.html',
  styleUrls: ['./share-job-expired-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareJobExpiredInComponent {
  private expiringInTwelveHours = 12;
  dateValue!: Date;
  isExpiringInUnder12Hours = false;
  isExpired = false;

  @Input()
  set date(value: Date | string) {
    this.dateValue = typeof value === 'string' ? new Date(value) : value;
    if (isDate(this.dateValue)) {
      this.isExpiringInUnder12Hours = isBefore(
        subHours(this.dateValue, this.expiringInTwelveHours),
        new Date(),
      );
      this.isExpired = isBefore(this.dateValue, new Date());
    } else {
      this.isExpiringInUnder12Hours = false;
      this.isExpired = false;
    }
  }

  @Input() highlightDanger: boolean;
  @Input() showDate = true;
  @Input() isDateBold = false;
  @Input() isExpiryTextLight = false;
  @Input() showHourText = false;
  @Input() showFullText = false;
}

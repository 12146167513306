import { Client } from '@wilson/clients/interfaces';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';

export function generateOptions(clients: Client[]): NzCascaderOption[] {
  const result: Record<string, string | object>[] = [];
  clients.forEach((client: Client) => {
    if (client.agreements?.length) {
      result.push({
        value: client.id || '',
        label: client.name,
        children: client.agreements.map((agreement) => {
          return {
            value: agreement.id,
            label: agreement.name,
            isLeaf: true,
          };
        }),
      });
    }
  });
  return result;
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityLikeBulkEditShareInput } from '@wilson/activities/symbols';
import { ResolvedActivity } from '@wilson/interfaces';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root',
})
export class AnyActivityCanBeAddedToShareService {
  constructor(
    private readonly translate: TranslateService,
    private readonly nzModalService: NzModalService,
  ) {}

  public activityCanBeAddedToShare(
    activity: ResolvedActivity | ActivityLikeBulkEditShareInput,
  ): boolean {
    return !!activity && activity.agreementId === null;
  }

  public anyActivityCanBeAddedToShare(
    value: ResolvedActivity | ResolvedActivity[] | undefined,
    allShouldBeAddable = false,
  ): boolean {
    if (!value) {
      return false;
    } else if (Array.isArray(value)) {
      if (allShouldBeAddable) {
        return value.every((activity) =>
          this.activityCanBeAddedToShare(activity),
        );
      } else {
        return value.some((activity) =>
          this.activityCanBeAddedToShare(activity),
        );
      }
    } else {
      return this.activityCanBeAddedToShare(value);
    }
  }

  public countActivitiesCantBeAddedToShare(value: ResolvedActivity[]): number {
    let cantBeAdded = 0;
    if (!value || !value.length) {
      return cantBeAdded;
    } else {
      value.forEach((activity) => {
        if (!this.anyActivityCanBeAddedToShare(activity)) {
          ++cantBeAdded;
        }
      });
      return cantBeAdded;
    }
  }

  public getActivitiesCanBeAddedToShare(
    value: ResolvedActivity[],
  ): ResolvedActivity[] {
    return value.filter((activity) => this.activityCanBeAddedToShare(activity));
  }

  public addToShareBucket(
    activities: ResolvedActivity[],
    nzOnOkCallback: () => void,
  ) {
    if (!this.anyActivityCanBeAddedToShare(activities, true)) {
      let title,
        description = '';
      const amountOfActivitiesThatCantBeAddedToShare =
        this.countActivitiesCantBeAddedToShare(activities);
      if (amountOfActivitiesThatCantBeAddedToShare === 1) {
        title = this.translate.instant(
          'activities.add_to_share.error.header.one',
          { number: amountOfActivitiesThatCantBeAddedToShare },
        );
        description = this.translate.instant(
          'activities.add_to_share.error.body.one',
          { number: amountOfActivitiesThatCantBeAddedToShare },
        );
      } else {
        title = this.translate.instant(
          'activities.add_to_share.error.header.other',
          { number: amountOfActivitiesThatCantBeAddedToShare },
        );
        description = this.translate.instant(
          'activities.add_to_share.error.body.other',
          { number: amountOfActivitiesThatCantBeAddedToShare },
        );
      }
      this.nzModalService.confirm({
        nzTitle: title,
        nzContent: description,
        nzClosable: false,
        nzOnOk: () => {
          nzOnOkCallback();
        },
      });
    } else {
      nzOnOkCallback();
    }
  }
}

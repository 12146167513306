import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivityAdjustmentGuardService } from '../../services/activity-adjustment-guard/activity-adjustment-guard.service';

@Pipe({
  name: 'totalPushableActivities',
})
export class TotalPushableActivitiesPipe implements PipeTransform {
  constructor(
    private activityAdjustmentGuardService: ActivityAdjustmentGuardService,
  ) {}

  transform(activityFormArray: FormArray, index: number): number {
    let totalPushableActivities = 0;
    for (let i = index; i >= 0; i--) {
      if (
        !this.activityAdjustmentGuardService.canBeAdjusted(
          activityFormArray?.at(i).value,
        )
      ) {
        break;
      } else {
        totalPushableActivities++;
      }
    }
    return totalPushableActivities;
  }
}

<div class="corrective-container row">
  <div
    class="corrective-text d-flex flex-column justify-content-center"
    [ngClass]="newOrSeries ? 'col-two-half' : 'col-2'"
  >
    <div *ngIf="shouldUpdateLocation">
      {{ 'page.shifts.corrective_bar.location_mismatch' | translate }}
    </div>
    <div *ngIf="shouldUpdateTime">
      {{ 'page.shifts.corrective_bar.times_mismatch' | translate }} ({{
        timeGapDifference / 60 | decimalToTime
      }})
    </div>
    <div *ngIf="!shouldUpdateTime && hasTimeOverlap">
      {{ 'page.shifts.corrective_bar.times_overlap' | translate }}
    </div>
  </div>

  <div class="col-two-half d-flex align-items-center">
    <div
      *ngIf="
        shouldUpdateLocation &&
        ('portal-shift-quick-location-and-time-adjustment'
          | featureFlagPure
          | async)
      "
    >
      <div>
        <button
          nz-button
          nz-popover
          nzType="text"
          [ngClass]="displayLocations ? 'action-btn' : 'disable-action'"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          [nzPopoverContent]="updateLocationsTemplate"
          [(nzPopoverVisible)]="isLocationPopOverVisible"
        >
          <div [ngClass]="displayLocations ? 'text-dark' : 'text-secondary'">
            <span>
              {{
                'page.shifts.corrective_bar.location_mismatch_button_text'
                  | translate
              }}
            </span>
            <fa-icon [icon]="faChevronDown" class="ms-3"></fa-icon>
          </div>
        </button>
      </div>
    </div>
  </div>

  <div
    class="col-3 d-flex align-items-center"
    *ngIf="
      shouldUpdateTime &&
      ('portal-shift-quick-location-and-time-adjustment'
        | featureFlagPure
        | async)
    "
  >
    <button
      class="action-btn"
      nz-button
      nz-popover
      [nzPopoverContent]="updateTimeTemplate"
      nzPopoverTrigger="click"
      nzPopoverPlacement="right"
      (nzPopoverVisibleChange)="isTimePopOverVisible = $event"
      [nzPopoverVisible]="isTimePopOverVisible"
    >
      <span class="text-dark">
        {{ 'shift.activity_time_gap.fix_time_gap' | translate }}
      </span>
      <fa-icon [icon]="faChevronDown" class="text-dark ms-3"></fa-icon>
    </button>
  </div>
</div>

<ng-template #updateTimeTemplate>
  <wilson-update-time-popover
    [activityFormArray]="activityFormArray"
    [currentIndex]="currentIndex"
    [canEditServiceActivity]="canEditServiceActivity"
    (pushActivity)="handlePushActivity()"
    (pullActivity)="handlePullActivity()"
    (insertActivity)="handleInsertActivity()"
    (pushActivities)="handlePushActivities()"
    (pullActivities)="handlePullActivities()"
    (prolongActivityEndTime)="handleProlongActivityEndTime()"
    (prolongActivityStartTime)="handleProlongActivityStartTime()"
  ></wilson-update-time-popover>
</ng-template>

<ng-template #updateLocationsTemplate>
  <wilson-update-location-popover
    [activityFormArray]="activityFormArray"
    [currentIndex]="currentIndex"
    [canEditServiceActivity]="canEditServiceActivity"
    (changeEndLocationOfFirstActivity)="changeEndLocationOfStartActivity()"
    (changeStartLocationOfSecondActivity)="changeStartLocationOfEndActivity()"
  ></wilson-update-location-popover>
</ng-template>

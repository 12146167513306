import { OperationsTimeAdjustment } from '../interfaces/custom-operative-interface';

export class InvalidShiftsState {
  static readonly type = '[OperationsState] InvalidShiftsState';
  constructor(public payload: OperationsTimeAdjustment) {}
}

export class OperativeReportState {
  static readonly type = '[OperationsState] OperativeReportState';
}

export class SetSelectedOrgUnits {
  static readonly type = '[OperationsState] set selected orgunits';
  constructor(public selectedOrgUnitIds: string[]) {}
}

<span
  [class]="'activity-icon my-auto ' + colorClass"
  nz-tooltip
  [nzTooltipTitle]="
    isMobileActivity
      ? ('shift.activity.added_by_user' | translate)
      : isActivitySkipByUser
      ? ('shift.activity.skip_by_user' | translate)
      : ''
  "
  [nzTooltipColor]="'#fff'"
>
  <i [class]="'fas fa-' + icon"></i>
</span>

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-shift-template-info-icon-renderer',
  template: `
    <span class="d-flex">
      <i
        class="fas fa-info-circle"
        nz-tooltip
        [nzTooltipTitle]="tooltipContent"
        [nzTooltipColor]="'#fff'"
      ></i>
      <ng-template #tooltipContent>
        <div class="px-2 mb-1">
          <br />
          <span>
            <b>{{ 'page.shift_template.number_of_activities' | translate }}:</b>
            <br />
            {{ numberOfActivities }}
          </span>
          <br />
          <span>
            <b>{{ 'service.lastModify' | translate }}:</b>
            <br />
            {{ lastEdited | date : 'dd.MM.yy, HH:mm' }}
            {{ 'general.hour' | translate }}
          </span>
          <br />
          <span>
            <b>{{ 'general.created_by' | translate }}:</b>
            <br />
            {{ createdby }}
          </span>
        </div>
      </ng-template>
    </span>
  `,
  styles: [
    `
      i {
        font-size: 18px;
        color: var(--grey-6);
      }
    `,
  ],
})
export class ShiftTemplateInfoIconRendererComponent
  implements AgRendererComponent
{
  public numberOfActivities!: string;
  public lastEdited!: string;
  public createdby!: string;

  agInit(params: ICellRendererParams) {
    this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    this.numberOfActivities =
      params?.data?.shiftTemplateActivityTemplates?.length ?? 0;
    this.lastEdited = params?.data?.updatedAt;
    this.createdby = params?.data?.createdBy
      ? `${params.data.createdBy.firstName} ${params.data.createdBy.lastName}`
      : '---';
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wilson-delete-dialogue',
  templateUrl: './delete-dialogue.component.html',
  styleUrls: ['./delete-dialogue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDialogueComponent {
  public loading = false;
  @Input() title!: string;
  @Input() message!: string;
  @Input() alertMessage!: string;
  @Input() deleteButtonText!: string;
  @Input() twoStep!: boolean;
  @Input() objectToDelete!: string;
  @Input() multiDelete!: boolean;
  @Input() customTexts!: boolean;

  public deleteForm = this.fb.group({
    object: ['', [Validators.required]],
  });
  public isTextValid = false;

  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) {}

  public decline(): void {
    this.activeModal.close(false);
  }

  public accept(): void {
    this.activeModal.close(true);
  }

  public checkInput() {
    if (this.deleteForm.get('object')?.value === this.objectToDelete) {
      this.isTextValid = true;
    } else {
      this.isTextValid = false;
    }
  }
}

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TimelineStayGateway } from '@wilson/api/gateway';
import { Stay } from '@wilson/interfaces';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  BehaviorSubject,
  debounceTime,
  firstValueFrom,
  map,
  switchMap,
} from 'rxjs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { StaysDrawerHelperService } from '../services/stays-drawer-helper.service';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
@Component({
  selector: 'wilson-accomodation-prefill',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzAutocompleteModule,
    NzToolTipModule,
  ],
  templateUrl: './accomodation-prefill.component.html',
})
export class AccomodationPrefillComponent implements OnChanges {
  @Input() disabled = false;
  @Input({ required: true })
  locationId: string | null = '';

  @Input()
  accomodationName: string | null = '';

  @Output()
  selectedStay = new EventEmitter();

  @Output()
  userInput = new EventEmitter();

  protected isLoadingSignal = signal(false);
  protected searchChangeSubject = new BehaviorSubject('');
  protected stays$ = new BehaviorSubject<Stay[]>([]);
  protected accomodation: Stay | undefined;

  protected filteredStays$ = this.searchChangeSubject.pipe(
    debounceTime(200),
    switchMap((searchInput) =>
      this.stays$.pipe(
        map((stays) => {
          if (searchInput.length >= 1) {
            return stays.filter((stay) =>
              stay.accommodationName
                ?.toLowerCase()
                .includes(searchInput.toLowerCase()),
            );
          } else {
            return stays;
          }
        }),
      ),
    ),
  );

  constructor(
    private translate: TranslateService,
    private nzMessageService: NzMessageService,
    private readonly timelineStayGateway: TimelineStayGateway,
    private staysDrawerHelperService: StaysDrawerHelperService,
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      changes['locationId'] &&
      changes['locationId'].currentValue &&
      changes['locationId'].currentValue !== changes['locationId'].previousValue
    ) {
      try {
        this.isLoadingSignal.set(true);
        const staysFromLocationId = await firstValueFrom(
          this.timelineStayGateway.getStaysFromLocationId({
            locationId: changes['locationId'].currentValue,
          }),
        );
        this.stays$.next(staysFromLocationId);
      } catch (error) {
        console.error('Error caught:', error);
        this.nzMessageService.error(this.translate.instant('general.error'));
      } finally {
        this.isLoadingSignal.set(false);
      }
    }
  }

  protected onSearch(value: string): void {
    this.searchChangeSubject.next(value);
  }

  protected prefillSelectedStay(value: Stay | string) {
    if (typeof value === 'string') {
      this.userInput.emit(value);
    } else {
      const formValue =
        this.staysDrawerHelperService.prepareFormFillValuesFromPreviousAccomodations(
          value,
        );
      if (formValue) {
        this.selectedStay.emit(formValue);
      }
    }
  }
}

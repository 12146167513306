import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { DateTimeFormat } from '@wilson/utils';
import { addMinutes, format, subMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ActivityExtensionService {
  prolongActivityEndTime(
    activityFormArray: FormArray,
    index: number,
    differenceInMinute: number,
  ) {
    const endTimeControl = activityFormArray.at(index)?.get('endDatetime');
    if (endTimeControl) {
      const newEndDatetime = addMinutes(
        new Date(
          `${format(new Date(), DateTimeFormat.DatabaseDateFormat)} ${
            endTimeControl.value
          }`,
        ),
        differenceInMinute,
      );

      endTimeControl.setValue(
        format(newEndDatetime, DateTimeFormat.TimeFormat),
      );
    }
  }

  prolongActivityStartTime(
    activityFormArray: FormArray,
    index: number,
    differenceInMinute: number,
  ) {
    const startTimeControl = activityFormArray
      .at(index + 1)
      ?.get('startDatetime');

    if (startTimeControl) {
      const newStartDatetime = subMinutes(
        new Date(
          `${format(new Date(), DateTimeFormat.DatabaseDateFormat)} ${
            startTimeControl.value
          }`,
        ),
        differenceInMinute,
      );

      startTimeControl.setValue(
        format(newStartDatetime, DateTimeFormat.TimeFormat),
      );
    }
  }
}

<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    #quickShiftPanel
    [nzHeader]="quickShiftSectionHeader"
    [nzActive]="isExpandedOnOpen"
    [nzShowArrow]="false"
    class="collapse-panel"
    [nzExtra]="quickShiftCollapseIcon"
  >
    <wilson-shift-quick-check
      *ngIf="shiftValidationDetails"
      [shiftValidationDetails]="shiftValidationDetails"
      [isUserAssigned]="!!userId"
    ></wilson-shift-quick-check>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #quickShiftSectionHeader>
  <span class="header-text">
    {{ 'page.shifts.quick_check' | translate }}
  </span>
</ng-template>
<ng-template #quickShiftCollapseIcon>
  <i
    class="fas fa-chevron-up"
    [ngClass]="quickShiftPanel.nzActive ? 'rotate-180' : 'rotate-0'"
  ></i>
</ng-template>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
  selector: 'wilson-shift-notes-popup',
  standalone: true,
  imports: [NzInputModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './shift-notes-popup.component.html',
  styleUrl: './shift-notes-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftNotesPopupComponent implements OnInit {
  form: FormGroup<{ note: FormControl<string> }>;

  formControls = {
    note: new FormControl<string>('', {
      validators: [Validators.required],
    }),
  };

  ngOnInit(): void {
    this.form = new FormGroup(this.formControls);
    if (this.form) {
      this.form.addValidators([this.checkValidNoteLength()]);
    }
  }

  private checkValidNoteLength(): ValidatorFn {
    return (): ValidationErrors | null => {
      const isValidNoteLength =
        this.form.controls['note']?.value?.trim().length > 420;
      return isValidNoteLength ? { maxNotes: true } : null;
    };
  }
}

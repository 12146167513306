import { Injectable } from '@angular/core';
import { ShiftPlan } from '@wilson/interfaces';
import { ReplaySubject } from 'rxjs';
import { ShiftPlanService } from './shift-plan.service';

@Injectable()
export class ShiftPlanStoreService {
  private shiftPlansSubject = new ReplaySubject<ShiftPlan[]>(1);

  get shiftPlans$() {
    return this.shiftPlansSubject.asObservable();
  }

  constructor(private shiftPlanService: ShiftPlanService) {}

  fetchAndCacheAllShiftPlans() {
    this.shiftPlanService
      .getMany({
        limit: 0,
      })
      .subscribe((manyPlans) => {
        this.shiftPlansSubject.next(manyPlans.data);
      });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResolvedActivity } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface DeleteIconAction<T> {
  faIcon: IconDefinition;
  // eslint-disable-next-line
  action: (entity: T) => void | Promise<any>;
}

// eslint-disable-next-line
export interface DeleteIconActionRendererParams<T = any> {
  icon: DeleteIconAction<T>;
  isOnlyVisibleOnHover?: boolean;
}

@Component({
  selector: 'wilson-activity-delete-renderer',
  template: `
    <div
      class="d-flex flex-row h-100 align-items-center justify-content-end pe-3"
    >
      <fa-icon
        *ngIf="icon"
        (click)="icon.action(activity)"
        class="cursor-pointer text-dark fa-lg px-2"
        [icon]="icon.faIcon"
      ></fa-icon>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDeleteRendererComponent
  implements ICellRendererAngularComp
{
  public icon!: DeleteIconAction<ResolvedActivity>;
  public activity!: ResolvedActivity;

  async agInit(params: ICellRendererParams & DeleteIconActionRendererParams) {
    this.activity = params.data;
    this.icon = params.icon;
  }

  refresh(params: ICellRendererParams & DeleteIconActionRendererParams) {
    this.activity = params.data;
    this.icon = params.icon;
    return true;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivityDeviationStatus } from '@wilson/interfaces';
import { DeviationTime } from '@wilson/service-deviation/pipes';

@Component({
  selector: 'wilson-activity-time-and-location-with-deviation',
  template: `
    <div>
      <div class="text-dark text-truncate" *ngIf="date && hour; else noData">
        <span *ngIf="date">{{ date }}</span>
        <span> {{ hour }} {{ 'general.hour' | translate }} </span>
        <span
          class="deviation-time"
          *ngIf="'portal-delays-on-services' | featureFlagPure | async"
        >
          <wilson-activity-time-tag
            [activityTimeDifference]="deviation.deviationInMinutes"
            [activityTimeStatus]="ActivityDeviationStatus.Deviated"
            [accurateTime]="deviation.timeWithDeviation"
          ></wilson-activity-time-tag>
        </span>
      </div>
      <ng-template #noData>---</ng-template>
      <div class="list-header lh-m">
        {{ location }}
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTimeAndLocationWithDeviationComponent {
  @Input() date: string;
  @Input() hour: string;
  @Input() location: string;
  @Input() deviation: DeviationTime;
  ActivityDeviationStatus = ActivityDeviationStatus;
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LocationsStoreService } from '@wilson/locations';
import { DateTimeFormat } from '@wilson/utils';
import { format } from 'date-fns';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'wilson-find-hotel',
  standalone: true,
  imports: [CommonModule, TranslateModule, NzButtonModule],
  templateUrl: './find-hotel.component.html',
  styleUrl: './find-hotel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindHotelComponent {
  @Input() locationId!: string | null;
  @Input() checkInDatetime!: Date | null;
  @Input() checkOutDatetime!: Date | null;

  constructor(private readonly locationsStoreService: LocationsStoreService) {}

  async openBooking() {
    const locationName = await this.getLocationName();
    let checkInDate = '';
    let checkOutDate = '';
    if (this.checkInDatetime) {
      checkInDate = format(
        this.checkInDatetime,
        DateTimeFormat.DatabaseDateFormat,
      );
    }
    if (this.checkOutDatetime) {
      checkOutDate = format(
        this.checkOutDatetime,
        DateTimeFormat.DatabaseDateFormat,
      );
    }

    window.open(
      `https://www.booking.com/searchresults.de.html?ss=${locationName}&checkin=${checkInDate}&checkout=${checkOutDate}&group_adults=1&no_rooms=1&group_children=0`,
      '_blank',
    );
  }

  async openGoogle() {
    const locationName = await this.getLocationName();

    window.open(
      `https://www.google.de/travel/search?q=${locationName}&qs=OAA`,
      '_blank',
    );
  }

  private async getLocationName() {
    let locationName = '';
    if (this.locationId) {
      const location = await firstValueFrom(
        this.locationsStoreService.getLocationFromCache(this.locationId),
      );
      locationName = location.name;
    }
    return locationName;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaysAttachmentsHelperService } from '@wilson/non-domain-specific/stays-form-and-drawer';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ShiftAttachmentsDrawerStateService } from './shift-attachments-drawer.service';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'wilson-shift-attachments-drawer',
  standalone: true,
  imports: [
    CommonModule,
    NzDrawerModule,
    TranslateModule,
    NzButtonModule,
    NzUploadModule,
    NzIconModule,
  ],
  providers: [StaysAttachmentsHelperService],
  templateUrl: './shift-attachments-drawer.component.html',
  styleUrl: './shift-attachments-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftAttachmentsDrawerComponent {
  @Input() fileList: NzUploadFile[] = [];

  @Output() showFilePreview = new EventEmitter<NzUploadFile>();
  @Output() deleteFile = new EventEmitter<NzUploadFile>();

  constructor(
    protected shiftAttachmentsDrawerStateService: ShiftAttachmentsDrawerStateService,
  ) {}

  protected handlePreview = (file: NzUploadFile): void => {
    this.showFilePreview.emit(file);
  };

  protected handleDelete = (file: NzUploadFile) => {
    this.deleteFile.emit(file);
  };

  protected downloadFile = async (file: NzUploadFile) => {
    window.open(file.url, '_blank');
  };

  closeDrawer() {
    this.shiftAttachmentsDrawerStateService.isShiftAttachmentDrawerOpened =
      false;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserWithVacationStatus } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { VacationDayEditService } from './vacation-day-edit.service';

@Component({
  selector: 'wilson-vacation-days-edit-renderer',
  template: `
    <div class="d-flex align-items-center">
      <input
        type="number"
        min="0"
        oninput="validity.valid || (value='')"
        class="form-control me-2"
        [value]="cellValue"
        (keyup)="
          vacationDayEditService.changeUserVacation(
            userWithVacationStatus.id,
            $any($event.target).value
          )
        "
        placeholder="--"
      />
      {{ 'page.settings.contract.unit_days' | translate }}
    </div>
  `,
  styles: [
    `
      input {
        width: 50px;
        text-align: center;
      }
      div {
        height: 48px !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationDaysEditRendererComponent implements AgRendererComponent {
  public cellValue: string | undefined;
  public userWithVacationStatus!: UserWithVacationStatus;

  constructor(public readonly vacationDayEditService: VacationDayEditService) {}
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams): string {
    this.userWithVacationStatus = params.data;
    return params.value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'dateIsHovered',
})
export class DateIsHoveredPipe implements PipeTransform {
  transform(d: NgbDate, [fromDate, toDate, hoveredDate]: NgbDate[]): boolean {
    return (
      fromDate &&
      !toDate &&
      hoveredDate &&
      d.after(fromDate) &&
      d.before(hoveredDate)
    );
  }
}

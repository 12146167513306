import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ResolvedActivity } from '@wilson/interfaces';

@Pipe({
  name: 'isActivityLocationMismatch',
})
export class IsActivityLocationMismatchPipe implements PipeTransform {
  transform(
    activities: Activity[] | ResolvedActivity[],
    index: number,
    isStartLocation = true,
  ): boolean {
    if (isStartLocation) {
      if (index === 0) return false;
      return (
        activities[index].startLocationId !==
        activities[index - 1].endLocationId
      );
    } else {
      if (index === activities.length - 1) return false;
      return (
        activities[index].endLocationId !==
        activities[index + 1].startLocationId
      );
    }
  }
}

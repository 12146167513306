import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { Base } from '@wilson/base';
import { Client } from '@wilson/clients/interfaces';
import {
  ClientFindOneOptions,
  ClientsV2Service,
} from '@wilson/clients/services';
import { Address } from '@wilson/interfaces';
import { BehaviorSubject, firstValueFrom, Observable, of, tap } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

export abstract class ClientFormService<T extends Base> {
  protected abstract readonly datasource: ClientsV2Service;
  protected id!: string;
  public abstract readonly form: FormGroup<ControlsOf<T>>;
  public data$: Observable<Client> = of();
  public address$: Observable<Address> = of();
  public isSaving$ = new BehaviorSubject(false);
  public isLoading$ = new BehaviorSubject(false);

  initialize(id: string, options?: ClientFindOneOptions): void {
    this.id = id;
    this.isLoading$.next(true);

    this.data$ = this.datasource.get(id, options).pipe(shareReplay(1));

    this.data$
      .pipe(
        take(1),
        // eslint-disable-next-line
        tap((date: any) => {
          const formValues = {
            ...date,
            partnershipId: date.clientPartnership?.partnershipId,
            canEditActivity: date.clientPartnership?.canEditActivity,
            canSplitActivity: date.clientPartnership?.canSplitActivity,
          };

          if (date.clientPartnership?.partnershipId) {
            this.form.controls['partnershipId'].disable();
          }

          this.form.patchValue(formValues);
          this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }

  initializeWithData(entity: T): void {
    this.id = entity.id;
    this.form.patchValue(entity);
  }

  submit(): Promise<Client> {
    this.isSaving$.next(true);
    this.form.disable();

    // eslint-disable-next-line
    let InitFormValues = this.form.getRawValue() as any;

    const formValues = this.clearFormAndRemoveEmptyValues(InitFormValues);

    if (this.isEmptyAddress(InitFormValues.address)) {
      formValues.addressId = null;
    }

    const clientPartnership = formValues.partnershipId
      ? {
          partnershipId: formValues.partnershipId,
          canEditActivity: formValues.canEditActivity,
          canSplitActivity: formValues.canSplitActivity,
        }
      : null;

    delete formValues.partnershipId;
    delete formValues.canEditActivity;
    delete formValues.canSplitActivity;

    const client = {
      ...formValues,
      clientPartnership,
    };

    let promise: Promise<Client> | Promise<Client[]>;

    if (this.id) {
      promise = firstValueFrom(
        this.datasource.update({
          id: this.id,
          ...(client as T),
        }),
      );
    } else {
      promise = firstValueFrom(this.datasource.create(client));
    }

    return promise.then((res) => {
      this.form.enable();
      this.isSaving$.next(false);
      return res;
    });
  }

  private clearFormAndRemoveEmptyValues(formValues: any) {
    return Object.entries(formValues).reduce((acc, [key, value]) => {
      if (key === 'address' && this.isEmptyAddress(value)) {
        return acc;
      }

      if (key !== 'invoiceLogo') {
        acc[key] = value;
      }
      return acc;
    }, {} as typeof formValues);
  }

  private isEmptyAddress(address: any): boolean {
    return address && Object.values(address).every((field) => field === '');
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkType } from '@wilson/interfaces';

@Component({
  selector: 'wilson-link-renderer-content',
  template: `
    <ng-container
      *ngIf="{
        title: linkData?.title ? linkData.title : ''
      } as data"
    >
      <a
        *ngIf="linkData?.link; else noLink"
        [routerLink]="linkData?.link"
        [queryParams]="linkData?.params"
        [class]="linkData?.linkClass"
        [target]="linkData?.openInNewTab ? '_blank' : '_self'"
        [rel]="linkData?.openInNewTab ? 'noopener' : ''"
        >{{ data.title }}</a
      >
      <ng-template #noLink
        ><span [class]="emptyClass">{{ data.title }}</span></ng-template
      >
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkRendererContentComponent {
  @Input()
  public linkData!: LinkType;
  public emptyClass = '';
}

<div class="mb-2">
  <div class="d-flex align-items-center title-text">
    {{ 'general.notes' | translate }}
  </div>
  <nz-textarea-count [nzMaxCharacterCount]="420">
    <textarea
      nz-input
      type="text"
      rows="4"
      [(ngModel)]="note"
      [placeholder]="'general.add_comment' | translate"
    ></textarea>
  </nz-textarea-count>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ShiftActivityStatus,
  ShiftStatusTranslationKey,
  ShiftWithActivityStatus,
  User,
} from '@wilson/interfaces';
import { UserDetailsModule } from '@wilson/shared-components';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { IsShiftConfirmedAtStatusPipe } from '../../pipes/is-shift-confirmed-at-status/is-shift-confirmed-at-status.pipe';
import { IsShiftDeclinedAtStatusPipe } from '../../pipes/is-shift-declined-at-status/is-shift-declined-at-status.pipe';

@Component({
  selector: 'wilson-shift-assignment-status-renderer',
  standalone: true,
  imports: [
    UserDetailsModule,
    NzToolTipModule,
    CommonModule,
    TranslateModule,
    NzTagModule,
    IsShiftConfirmedAtStatusPipe,
    IsShiftDeclinedAtStatusPipe,
    NzToolTipModule,
    DateFnsModule,
  ],
  template: `
    <span
      *ngIf="userName"
      class="d-block text-dark"
      nz-tooltip
      [nzTooltipTitle]="userDetails"
      [nzTooltipOverlayStyle]="{
        'max-width': '600px',
        'min-width': '250px'
      }"
      [nzTooltipColor]="'#fff'"
    >
      {{ userName }}</span
    >
    <div class="mt-1">
      <span class="d-block fw-bold text-info">
        <nz-tag [nzColor]="badgeColor" class="ag-grid-tag status-tag">{{
          statusValue ? (statusValue | translate) : '---'
        }}</nz-tag>
        <nz-tag
          *ngIf="
            (shift | isShiftConfirmedAtStatus | async) ||
            (shift | isShiftDeclinedAtStatus | async)
          "
          [nzColor]="shiftConfirmDeclineStatus.color"
          class="ag-grid-tag status-tag"
          nz-tooltip
          [nzTooltipTitle]="tooltipDeclineddReason"
        >
          {{
            'shift.status.' + shiftConfirmDeclineStatus.partialTranslationKey
              | translate
          }}
        </nz-tag>
        <ng-template #tooltipDeclineddReason>
          {{
            shiftConfirmDeclineStatus.timestamp
              | dfnsParseIso
              | dfnsFormat : 'Pp'
          }}
          <p>
            {{
              shift?.declineReason
                ? shift?.declineReason
                : ('shift.declined_at.no_reason' | translate)
            }}
          </p>
        </ng-template>
      </span>
    </div>

    <ng-template #userDetails>
      <wilson-user-details [user]="user"></wilson-user-details>
    </ng-template>
  `,
  styles: [
    `
      .badge {
        min-width: 80px;
      }

      .status-tag {
        border-radius: 2px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftAssignmentStatusRendererComponent
  implements AgRendererComponent
{
  public ShiftStatusTranslationKey = ShiftStatusTranslationKey;
  public badgeColor = '';
  public statusValue: ShiftStatusTranslationKey =
    ShiftStatusTranslationKey.NotOccupied;
  userName: string | null = null;
  user: User | null = null;
  public shiftConfirmDeclineStatus!: {
    partialTranslationKey: string;
    color: string;
    timestamp: string;
  };
  public shift!: ShiftWithActivityStatus;

  agInit(params: ICellRendererParams): void {
    this.setCellValueAndTextColor(params);
  }

  refresh(params: ICellRendererParams) {
    this.setCellValueAndTextColor(params);
    return true;
  }

  private setCellValueAndTextColor(params: ICellRendererParams) {
    const activityStatus: ShiftActivityStatus = (
      params.data as ShiftWithActivityStatus
    ).activityStatus;
    this.user = (params.data as ShiftWithActivityStatus).user;

    if (this.user) {
      this.userName = `${this.user.lastName}, ${this.user.firstName}`;
    }
    this.statusValue = ShiftStatusTranslationKey[activityStatus];
    this.badgeColor = this.getBadgeColor(activityStatus);
    this.shiftConfirmDeclineStatus = this.setShiftConfirmDeclineStatus(
      params.data,
    );
    this.shift = params.data;
  }

  private setShiftConfirmDeclineStatus(
    payload: Pick<ShiftWithActivityStatus, 'confirmedAt' | 'declinedAt'>,
  ) {
    if (payload?.confirmedAt) {
      return {
        timestamp: payload.confirmedAt,
        partialTranslationKey: 'confirmed_at',
        color: '',
      };
    } else if (payload?.declinedAt) {
      return {
        timestamp: payload.declinedAt,
        partialTranslationKey: 'declined_at',
        color: 'red',
      };
    }
    return {
      timestamp: '',
      partialTranslationKey: '',
      color: '',
    };
  }

  private getBadgeColor(activityStatus: ShiftActivityStatus) {
    if (activityStatus === ShiftActivityStatus.Occupied) {
      return 'green';
    } else if (activityStatus === ShiftActivityStatus.NotOccupied) {
      return 'red';
    } else {
      return '';
    }
  }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-webhook-events-renderer',
  template: `
    <div>
      <div *ngIf="cellValue" class="text-dark text-truncate">
        <span *ngFor="let event of cellValue; let i = index"
          >{{ i !== 0 ? ',' : '' }}
          {{ 'page.integration.events.' + event | translate }}</span
        >
      </div>
      <div *ngIf="!cellValue" class="text-dark text-truncate">---</div>
    </div>
  `,
})
export class WebhookEventsRendererComponent implements AgRendererComponent {
  public cellValue: string | undefined;
  public headerValue: string | undefined;

  agInit(params: ICellRendererParams): void {
    this.cellValue = params.value;
    this.headerValue = params.colDef?.headerName;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    this.headerValue = params.colDef?.headerName;
    return true;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class VacationDayEditService {
  public get userVacation$() {
    return this.userVacationEditSubject.asObservable();
  }
  private userVacationEditSubject = new BehaviorSubject<{
    id: string | undefined;
    days: string;
  } | null>(null);

  public changeUserVacation(id: string | undefined, days: string): void {
    this.userVacationEditSubject.next({ id, days });
  }
}

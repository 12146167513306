import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFileName',
  standalone: true,
})
export class GetFileNamePipe implements PipeTransform {
  transform(url: string): string {
    return url.substring(url.lastIndexOf('/') + 1);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityInvoiceModel } from '@wilson/invoicing/state';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-status-renderer',
  template: `
    <nz-tag
      *ngIf="activityInvoices; else noValue"
      nz-tooltip
      [nzTooltipTitle]="activityInvoices.length ? tooltipInformation : ''"
      [nzTooltipColor]="'#fff'"
      [nzTooltipMouseEnterDelay]="0.3"
      nzTooltipPlacement="top"
      [nzTooltipOverlayStyle]="{ 'min-width': '120px' }"
      [nzColor]="activityInvoices.length ? 'success' : 'default'"
      >{{
        activityInvoices.length
          ? ('page.invoicing.invoiced' | translate) +
            ' (' +
            activityInvoices.length +
            ')'
          : ('page.invoicing.not_invoiced' | translate)
      }}</nz-tag
    >

    <ng-template #tooltipInformation>
      <div *ngFor="let invoice of activityInvoices">
        <a
          target="_blank"
          [routerLink]="['/', 'invoices', 'detail', invoice.id]"
        >
          {{ invoice.invoiceNumber ? invoice.invoiceNumber : '---' }}
        </a>
      </div>
    </ng-template>

    <ng-template #noValue>{{ '---' }} </ng-template>
  `,
  styles: [
    `
      a {
        color: var(--grey-11);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStatusRendererComponent
  implements ICellRendererAngularComp
{
  public activityInvoices: ActivityInvoiceModel[];

  async agInit(params: ICellRendererParams) {
    this.initValues(params);
  }

  refresh(params: ICellRendererParams) {
    this.initValues(params);
    return true;
  }

  private initValues(params: ICellRendererParams) {
    this.activityInvoices = params.value;
  }
}

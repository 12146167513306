import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity } from '@wilson/interfaces';
import { ShiftSeriesService } from '@wilson/shifts';
import { format, isValid } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { IsActivityLocationMismatchPipe } from '../../pipes/activity-location-check/is-activity-location-mismatch.pipe';
import {
  ActivityConflictPopupComponent,
  ActivityTimePipe,
} from '@wilson/non-domain-specific/shift-helpers/core';

@Injectable({
  providedIn: 'root',
})
export class ShiftFormService {
  constructor(
    private readonly modalService: NgbModal,
    public readonly shiftSeriesService: ShiftSeriesService,
  ) {}

  public sortActivitiesForm(form: FormGroup) {
    const activities = form.get('activities') as FormArray;
    const sortedActivities = activities.value;
    sortedActivities.sort((a, b) => {
      const firstDate = a.startDatetime?.includes('T')
        ? format(new Date(a.startDatetime), 'HH:mm')
        : a.startDatetime;
      const secondDate = b.startDatetime?.includes('T')
        ? format(new Date(b.startDatetime), 'HH:mm')
        : b.startDatetime;
      return firstDate < secondDate ? -1 : 0;
    });
    sortedActivities.map((activity) => {
      activity.activityReports = activity.activityReports
        ? activity.activityReports
        : [];
    });
    activities.patchValue(sortedActivities);
  }

  public checkShiftActivityWarning(activities: Activity[]): boolean {
    const activityTimePipe = new ActivityTimePipe();
    const isActivityLocationMismatchPipe = new IsActivityLocationMismatchPipe();
    let isWarning = false;
    for (let index = 0; index < activities.length; index++) {
      if (
        isActivityLocationMismatchPipe.transform(activities, index) ||
        isActivityLocationMismatchPipe.transform(activities, index, false) ||
        activityTimePipe.transform(activities, index) ||
        isActivityLocationMismatchPipe.transform(activities, index, false)
      ) {
        isWarning = true;
        break;
      }
    }
    return isWarning;
  }

  public activityConflictPopup(
    serviceActivities: Activity[],
    canEditServiceActivities?: boolean,
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ActivityConflictPopupComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.serviceActivities = serviceActivities;
    modalRef.componentInstance.canEditServiceActivities =
      canEditServiceActivities;
    return modalRef.result;
  }

  async getShiftSeriesName(shiftSeriesId: string): Promise<string> {
    if (!shiftSeriesId) {
      return '';
    }

    try {
      const shiftSeries = await firstValueFrom(
        this.shiftSeriesService.getShiftSeries(shiftSeriesId),
      );
      return shiftSeries ? shiftSeries.name : '';
    } catch {
      return '';
    }
  }

  public resetFormatOfActivityDateTimes(activities: Activity[]) {
    activities.map((activity) => {
      if (!activity.serviceId) {
        const startDatetime = new Date(activity.startDatetime);
        const endDatetime = new Date(activity.endDatetime);
        if (isValid(startDatetime))
          activity.startDatetime = format(startDatetime, 'HH:mm');
        if (isValid(endDatetime))
          activity.endDatetime = format(endDatetime, 'HH:mm');
      }
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { SafetyCertificateQualification } from '@wilson/interfaces';

@Injectable()
export class SafetyCertificateQualificationService extends BackendService<SafetyCertificateQualification> {
  protected readonly path = 'safety-certificate-qualifications';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }
}

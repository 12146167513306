import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  ActivitiesAssignment,
  AssignmentAvailabilityStatus,
  AssignmentTimeRange,
  ShiftAssignment,
  UserTimelinesModal,
} from '@wilson/interfaces';
import { ShiftOverlappingService } from '@wilson/shift-timeline/services';
import {
  TimelineAssignmentModalFilters,
  TimelineModalFilterStateModel,
} from '../interfaces';
import {
  ShiftAssignmentModalState,
  ShiftAssignmentModalStateModel,
} from '../shift-assignment-modal/shift-assignment-modal.state';
import { TimelineFilterHelperService } from '../shift-timeline-filters/timeline-filter-helper.service';
import {
  ResetTimelineAssignmentModalFiltersState,
  SetTimelineAssignmentModalFilters,
  SetTimelineModalFilterUsers,
  TimelineModalSetSearchTerm,
} from './timeline-modal-filters.actions';

const defaults: TimelineModalFilterStateModel = {
  users: [],
  filters: {
    orgUnit: [],
    searchTerm: null,
    showOnlyQualifiedStaff: false,
    showOnlyQualifiedWorkingTimeStaff: false,
    staffAvailability: AssignmentAvailabilityStatus.Available,
  },
};
@State({
  name: 'timelineModalFilter',
  defaults,
})
@Injectable()
export class TimelineModalFilterState {
  static shiftAssignmentModalFilteredUsers(
    assignmentTimeRange?: AssignmentTimeRange | null,
  ) {
    return createSelector(
      [TimelineModalFilterState, ShiftAssignmentModalState],
      (
        timelineModalFilterState: TimelineModalFilterStateModel,
        shiftAssignmentModalState: ShiftAssignmentModalStateModel,
      ) => {
        const shiftAssignments = shiftAssignmentModalState.shiftAssignment;

        let usersWithShiftAssignment: (UserTimelinesModal & {
          shiftAssignment: ShiftAssignment | ActivitiesAssignment | undefined;
        })[] = timelineModalFilterState.users.map((user) => ({
          ...user,
          shiftAssignment: (shiftAssignments as ShiftAssignment[]).find(
            (assignment) => assignment.user.id === user.id,
          ),
        }));

        const filters: TimelineAssignmentModalFilters =
          timelineModalFilterState.filters;

        if (filters.orgUnit.length) {
          usersWithShiftAssignment = TimelineFilterHelperService.filterOrgUnit(
            usersWithShiftAssignment,
            filters,
          );
        }

        if (filters.searchTerm) {
          const searchTerm = filters.searchTerm.toLocaleLowerCase();
          usersWithShiftAssignment =
            TimelineFilterHelperService.filterSearchTerm(
              usersWithShiftAssignment,
              searchTerm,
            );
        }

        if (filters.showOnlyQualifiedStaff) {
          usersWithShiftAssignment =
            TimelineFilterHelperService.showOnlyQualifiedStaff(
              usersWithShiftAssignment,
            );
        }

        if (filters.showOnlyQualifiedWorkingTimeStaff) {
          usersWithShiftAssignment =
            TimelineFilterHelperService.showOnlyQualifiedWorkingTimeStaff(
              usersWithShiftAssignment,
            );
        }

        if (
          filters.staffAvailability ===
            AssignmentAvailabilityStatus.Available &&
          assignmentTimeRange
        ) {
          usersWithShiftAssignment = usersWithShiftAssignment.filter((user) => {
            if (shiftAssignmentModalState.hasFetchedShiftAssignmentSuccessful) {
              return (
                user.shiftAssignment &&
                !ShiftOverlappingService.isShiftToAssignOverlappingWithNonDispoOrNonDepartureShift(
                  user as UserTimelinesModal,
                  assignmentTimeRange,
                )
              );
            } else {
              return !ShiftOverlappingService.isShiftToAssignOverlappingWithNonDispoOrNonDepartureShift(
                user as UserTimelinesModal,
                assignmentTimeRange,
              );
            }
          });
        }

        return usersWithShiftAssignment;
      },
    );
  }

  static activityAssignmentModalFilteredUsers() {
    return createSelector(
      [TimelineModalFilterState, ShiftAssignmentModalState],
      (
        timelineModalFilterState: TimelineModalFilterStateModel,
        shiftAssignmentModalState: ShiftAssignmentModalStateModel,
      ) => {
        const shiftAssignments = shiftAssignmentModalState.shiftAssignment;

        let usersWithShiftAssignment: (UserTimelinesModal & {
          shiftAssignment: ShiftAssignment | ActivitiesAssignment | undefined;
        })[] = timelineModalFilterState.users.map((user) => ({
          ...user,
          shiftAssignment: (shiftAssignments as ShiftAssignment[]).find(
            (assignment) => assignment.user.id === user.id,
          ),
        }));

        const filters: TimelineAssignmentModalFilters =
          timelineModalFilterState.filters;

        if (filters.orgUnit.length) {
          usersWithShiftAssignment = TimelineFilterHelperService.filterOrgUnit(
            usersWithShiftAssignment,
            filters,
          );
        }

        if (filters.searchTerm) {
          const searchTerm = filters.searchTerm.toLocaleLowerCase();
          usersWithShiftAssignment =
            TimelineFilterHelperService.filterSearchTerm(
              usersWithShiftAssignment,
              searchTerm,
            );
        }

        if (filters.showOnlyQualifiedStaff) {
          usersWithShiftAssignment =
            TimelineFilterHelperService.showOnlyQualifiedStaff(
              usersWithShiftAssignment,
            );
        }

        if (filters.showOnlyQualifiedWorkingTimeStaff) {
          usersWithShiftAssignment =
            TimelineFilterHelperService.showOnlyQualifiedWorkingTimeStaff(
              usersWithShiftAssignment,
            );
        }

        if (filters.staffAvailability) {
          usersWithShiftAssignment =
            TimelineFilterHelperService.filterStaffAvailability(
              usersWithShiftAssignment,
              filters,
            );
        }

        return usersWithShiftAssignment;
      },
    );
  }

  @Action(SetTimelineModalFilterUsers)
  filterFetchUsers(
    ctx: StateContext<TimelineModalFilterStateModel>,
    action: SetTimelineModalFilterUsers,
  ) {
    ctx.patchState({
      users: action.users,
    });
  }

  @Action(SetTimelineAssignmentModalFilters)
  setFilters(
    ctx: StateContext<TimelineModalFilterStateModel>,
    action: SetTimelineAssignmentModalFilters,
  ) {
    const state = ctx.getState();

    ctx.setState(
      patch<TimelineModalFilterStateModel>({
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }),
    );
  }

  @Action(TimelineModalSetSearchTerm)
  timelineSetSearchTerm(
    ctx: StateContext<TimelineModalFilterStateModel>,
    action: TimelineModalSetSearchTerm,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      filters: {
        ...state.filters,
        searchTerm: action.searchTerm,
      },
    });
  }
  @Action(ResetTimelineAssignmentModalFiltersState)
  resetModalFilters(ctx: StateContext<TimelineModalFilterStateModel>) {
    ctx.patchState(defaults);
  }
}

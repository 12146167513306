import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AccountService } from '@wilson/account';
import { ResolvedUser, WorkingTimeModel } from '@wilson/interfaces';
import { WorkingTimeModelService } from '@wilson/working-time-model';
import { format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, shareReplay, switchMap, take } from 'rxjs/operators';
import { ModalService } from '../../../shared/services/modal-service/modal.service';

@Injectable()
export class WorkingTimeModelReuseService {
  constructor(
    private readonly store: Store,
    private readonly accountService: AccountService,
    private readonly workingTimeModelService: WorkingTimeModelService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly modalService: ModalService,
  ) {}

  getStoreUserId(): Observable<string> {
    return this.store.select((state) => state.auth?.userId);
  }

  convertDateToMinutes(date: Date | number) {
    if (!date) return 0;
    const newTime = format(new Date(date), 'HH:mm');
    const convertedValue = newTime.split(':');

    return Number(convertedValue[0]) * 60 + Number(convertedValue[1]);
  }

  getResolvedUser(): Observable<ResolvedUser[]> {
    return this.getStoreUserId().pipe(
      switchMap((userId) => {
        return this.accountService.getResolvedUser(userId).pipe(
          take(1),
          catchError(() => of([])),
        );
      }),
      shareReplay(),
    );
  }

  getWorkingTimeModel(
    workingTimeModelId: string,
  ): Observable<WorkingTimeModel> {
    return this.getResolvedUser().pipe(
      switchMap((users) => {
        const resolvedUser = users[0];
        if (resolvedUser?.organizationalUnitId) {
          const relations = ['organizationalUnit'];
          const workingTimeModel = this.workingTimeModelService
            .getOrganizationalUnitWorkingTimeModelById(
              workingTimeModelId,
              resolvedUser.organizationalUnitId,
              relations,
            )
            .pipe(
              catchError(() => {
                this.toastr.error(
                  this.translate.instant('general.errorMessage'),
                );
                this.router.navigate(['settings/working-time-model']);
                return throwError(null);
              }),
            );
          return workingTimeModel;
        }
      }),
    );
  }

  initWorkingTimeModelForm(
    workingTimeModel: WorkingTimeModel | null,
    organizationalUnitId: string,
  ): FormGroup {
    return new FormGroup({
      name: new FormControl(
        workingTimeModel?.name ? workingTimeModel.name : '',
        [Validators.required],
      ),
      organizationalUnitId: new FormControl(
        workingTimeModel?.organizationalUnitId
          ? workingTimeModel.organizationalUnitId
          : organizationalUnitId,
        [Validators.required],
      ),
      monday: new FormControl(
        workingTimeModel?.monday ? workingTimeModel.monday : 0,
      ),
      tuesday: new FormControl(
        workingTimeModel?.tuesday ? workingTimeModel.tuesday : 0,
      ),
      wednesday: new FormControl(
        workingTimeModel?.wednesday ? workingTimeModel.wednesday : 0,
      ),
      thursday: new FormControl(
        workingTimeModel?.thursday ? workingTimeModel.thursday : 0,
      ),
      friday: new FormControl(
        workingTimeModel?.friday ? workingTimeModel.friday : 0,
      ),
      saturday: new FormControl(
        workingTimeModel?.saturday ? workingTimeModel.saturday : 0,
      ),
      sunday: new FormControl(
        workingTimeModel?.sunday ? workingTimeModel.sunday : 0,
      ),
      monthlyWorkingTimeInMinutes: new FormControl(
        workingTimeModel?.monthlyWorkingTimeInMinutes
          ? workingTimeModel.monthlyWorkingTimeInMinutes / 60
          : null,
      ),
    });
  }

  async cancelModal() {
    const confirmation = await this.modalService.confirm(
      this.translate.instant('can_deactive.modalConfirm.header'),
      this.translate.instant('can_deactive.modalConfirm.text'),
      this.translate.instant('can_deactive.modalConfirm.discard'),
    );
    return confirmation;
  }

  async applyChange(message: string) {
    const confirmation = await this.modalService.confirm(
      this.translate.instant('general.apply_changes'),
      message,
      this.translate.instant('general.accept'),
    );
    return confirmation;
  }
}

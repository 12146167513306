import { FormArray } from '@ngneat/reactive-forms';
import { ShiftPlanTemplate } from './shift-plan-template';

export interface CalendarForm<T extends FormArray<ShiftPlanTemplate>> {
  [CalendarFormKey.Users]: FormArray<string | null>;
  [CalendarFormKey.Templates]: T;
}

export enum CalendarFormKey {
  Users = 'shiftPlanUsers',
  Templates = 'shiftPlanTemplates',
}

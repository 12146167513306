import { Injectable } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { DisabledTimeFn } from 'ng-zorro-antd/date-picker';

@Injectable()
export class DateTimeRangeHelperService {
  public maxDate!: Date;
  public minDate!: Date;

  disabledDate = (current: Date): boolean => {
    return (
      differenceInCalendarDays(current, this.maxDate) > 0 ||
      differenceInCalendarDays(this.minDate, current) > 0
    );
  };

  disabledTime: DisabledTimeFn = (current: Date | Date[]) => {
    if (current instanceof Date) {
      if (differenceInCalendarDays(current, this.maxDate) === 0) {
        return {
          nzDisabledHours: () => this.range(this.maxDate.getHours() + 1, 24),
          nzDisabledMinutes: () =>
            this.maxDate.getHours() === current.getHours()
              ? this.range(this.maxDate.getMinutes() + 1, 60)
              : [],
          nzDisabledSeconds: () => [],
        };
      } else if (differenceInCalendarDays(current, this.minDate) === 0) {
        return {
          nzDisabledHours: () => this.range(0, this.minDate.getHours()),
          nzDisabledMinutes: () =>
            this.minDate.getHours() === current.getHours()
              ? this.range(0, this.minDate.getMinutes())
              : [],
          nzDisabledSeconds: () => [],
        };
      }
    }
    return undefined;
  };

  private range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, Base } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Job, RequestForProposal } from '@wilson/interfaces';
import { CreateJob } from '@wilson/share/interfaces';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobGateway extends BackendService<Job> {
  protected readonly path = 'jobs';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public createJob(payload: CreateJob): Observable<(CreateJob & Base)[]> {
    return this.http.post<(CreateJob & Base)[]>(
      `${this.config.host}/${this.path}`,
      {
        items: [payload],
      },
    );
  }

  public updateClientProposalResponse(
    jobId: string,
    requestForProposalId: string,
    payload: Partial<RequestForProposal>,
  ): Promise<Partial<RequestForProposal>> {
    return firstValueFrom(
      this.http.patch<Partial<RequestForProposal>>(
        `${this.config.host}/${this.path}/${jobId}/requests-for-proposals/${requestForProposalId}`,
        payload,
      ),
    );
  }

  public withdrawJob(id: string): Observable<Job> {
    return this.http.post<Job>(
      `${this.config.host}/${this.path}/${id}/withdraw`,
      {},
    );
  }

  public cancelActivities(ids: string[], jobId: string) {
    return firstValueFrom(
      this.http.patch<Partial<RequestForProposal>>(
        `${this.config.host}/${this.path}/${jobId}/cancel-activities`,
        { activityIds: ids },
      ),
    );
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapboxToken } from './mapbox.config';

@NgModule({
  imports: [CommonModule],
})
export class MapboxConfigModule {
  static forRoot(config: {
    token: string;
  }): ModuleWithProviders<MapboxConfigModule> {
    return {
      ngModule: MapboxConfigModule,
      providers: [
        {
          provide: MapboxToken,
          useValue: config.token,
        },
      ],
    };
  }
}

import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { PartnershipsState } from '@wilson/clients/states';
import {
  Activity,
  ActivitySelectionListItem,
  ActivityTemplate,
} from '@wilson/interfaces';

export function canSharedActivityBeEdited(
  activity: Activity | ActivityTemplate | ActivitySelectionListItem | string,
): boolean {
  const store = inject(Store);
  let canEditActivity = true;

  if (typeof activity === 'object') {
    if ('job' in activity && activity.job?.organizationalUnitId) {
      canEditActivity = store.selectSnapshot(
        PartnershipsState.canEditActivity(activity.job.organizationalUnitId),
      );
    } else if (
      'jobActivityTemplates' in activity &&
      activity.jobActivityTemplates?.length &&
      activity.jobActivityTemplates[0]?.job?.organizationalUnitId
    ) {
      canEditActivity = store.selectSnapshot(
        PartnershipsState.canEditActivity(
          activity.jobActivityTemplates[0].job.organizationalUnitId,
        ),
      );
    } else if (
      'jobOrganizationalUnitId' in activity &&
      activity.jobOrganizationalUnitId
    ) {
      canEditActivity = store.selectSnapshot(
        PartnershipsState.canEditActivity(activity.jobOrganizationalUnitId),
      );
    }
  } else if (typeof activity === 'string') {
    canEditActivity = store.selectSnapshot(
      PartnershipsState.canEditActivity(activity),
    );
  }

  return canEditActivity;
}

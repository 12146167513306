import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFileNameWithoutTimestamp',
  standalone: true,
})
export class GetFileNameWithoutTimestampPipe implements PipeTransform {
  transform(value: string): string {
    const regex = /-\d+(\.[^.]*)$/;
    return value.replace(regex, '$1');
  }
}

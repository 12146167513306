import { Injectable } from '@angular/core';
import {
  Activity,
  OperationStatus,
  ResolvedActivity,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ActivityAdjustmentGuardService {
  canBeAdjusted(activity: Activity | ResolvedActivity): boolean {
    if (
      activity.serviceId ||
      (activity.operationalStatus &&
        activity.operationalStatus !== OperationStatus.NotStarted) ||
      activity.createdFrom === WilsonApp.Mobile ||
      !activity.startLocation ||
      !activity.endLocation
    )
      return false;
    return true;
  }
}

<div class="d-flex">
  <span
    *ngFor="let day of weekDays | keyvalue : unsorted; let i = index"
    class="d-flex justify-content-center align-items-center badge rounded-pill text-dark"
    [class.cursor-pointer]="!disabled"
    [ngClass]="day.value ? 'bg-primary' : 'bg-light'"
    [class.disabled]="!disabledWeekDays[day.key] && checkDisabledWeekDay"
    (click)="
      (disabledWeekDays[day.key] || !checkDisabledWeekDay) &&
        toggleWeekDay(day.key)
    "
  >
    {{ 'dayShort.' + day.key | translate }}
  </span>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitiesGateway } from '@wilson/api/gateway';
import { ActivityCategory } from '@wilson/interfaces';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
} from 'rxjs';

@Component({
  selector: 'wilson-activity-category-and-name-form',
  imports: [
    CommonModule,
    NzInputModule,
    TranslateModule,
    FormsModule,
    NzSelectModule,
  ],
  template: `
    <div class="mb-2">
      <b>
        {{ 'wilson-activity-category-and-name-form.category' | translate }}
      </b>
      <nz-select
        required="true"
        [ngModel]="selectedActivityCategory$ | async"
        [nzPlaceHolder]="'---'"
        [nzMaxTagCount]="1"
        nzShowSearch
        [nzShowArrow]="true"
        [compareWith]="compareActivityFn"
        (ngModelChange)="setActivityCategory($event)"
      >
        <nz-option
          *ngFor="let category of activityCategories$ | async"
          [nzValue]="category"
          [nzLabel]="category.nameTranslationKey | translate"
        >
        </nz-option>
      </nz-select>
    </div>

    <b>
      {{ 'wilson-activity-category-and-name-form.name' | translate }}
    </b>
    <input
      nz-input
      [ngModel]="activityName"
      (ngModelChange)="setActivityName($event)"
      type="string"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ActivityCategoryAndNameFormComponent {
  private activityCategoryNameSubject = new BehaviorSubject<string | null>(
    null,
  );

  @Input() activityName!: string;
  @Input() set activityCategoryName(name: string) {
    this.activityCategoryNameSubject.next(name);
  }
  protected activityCategories$: Observable<
    (ActivityCategory & { nameTranslationKey: string })[]
  > = this.activitiesService
    .getEnabledActivityCategoriesWithTranslationKey()
    .pipe(catchError(() => of([])));

  protected selectedActivityCategory$ = combineLatest([
    this.activityCategoryNameSubject,
    this.activityCategories$,
  ]).pipe(
    map(([categoryName, activityCategories]) => {
      return activityCategories.find(
        (category) => category.name === categoryName,
      );
    }),
  );

  selectedActivityCategory:
    | (ActivityCategory & { nameTranslationKey: string })
    | null
    | undefined = undefined;
  chosenActivityName: string | undefined = undefined;

  constructor(private readonly activitiesService: ActivitiesGateway) {}

  protected compareActivityFn = (
    selectedCategory: ActivityCategory & { nameTranslationKey: string },
    activityCategory: ActivityCategory & { nameTranslationKey: string },
  ) => {
    return selectedCategory && activityCategory
      ? selectedCategory.id === activityCategory.id
      : selectedCategory === activityCategory;
  };

  protected setActivityCategory(
    event: ActivityCategory & { nameTranslationKey: string },
  ) {
    this.selectedActivityCategory = event;
  }

  protected setActivityName(name: string) {
    this.chosenActivityName = name;
  }
}

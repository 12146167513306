import { Component } from '@angular/core';
import { OperativeReport, OperativeReportStatus } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-op-report-actions-renderer',
  template: ` <a
      *ngIf="report?.status === operativeReportStatus.Open"
      class="mark-option"
      nz-popconfirm
      [nzPopconfirmTitle]="'operativeReport.action.confirmation' | translate"
      nzPopconfirmPlacement="top"
      [nzPopconfirmOverlayClassName]="'custom-popover'"
      (nzOnConfirm)="operation.action(report)"
      (nzOnCancel)="cancel()"
    >
      {{ 'operativeReport.action.markAsDone' | translate }}
    </a>
    <span
      class="resolved-option"
      *ngIf="report?.status === operativeReportStatus.Done"
    >
      {{ 'operativeReport.action.resolved_by' | translate }}
      {{ report.updatedBy?.firstName }}
      {{ report.updatedBy?.lastName }}
    </span>`,
  styles: [
    `
      .mark-option {
        text-decoration: none;
        color: var(--grey-7);
        &:hover {
          color: var(--grey-9);
        }
      }
      .resolved-option {
        color: var(--grey-5);
      }
    `,
  ],
})
export class OpReportActionsRendererComponent implements AgRendererComponent {
  public report: OperativeReport;
  public operation;
  operativeReportStatus = OperativeReportStatus;

  agInit(params: ICellRendererParams): void {
    this.report = params.data;
    this.operation = params['markAsDone'];
  }

  refresh(params: ICellRendererParams) {
    this.report = params.data;
    this.operation = params['markAsDone'];
    return true;
  }

  cancel() {
    return;
  }
}

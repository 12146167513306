import { Injectable } from '@angular/core';
import { ValuesOf } from '@ngneat/reactive-forms';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConflictSetting } from '@wilson/interfaces';

import { add } from 'date-fns';
import {
  ResetShiftPlanDuplicateForm,
  SetShiftPlanDuplicateFormAction,
} from './shift-planning-data.actions';
import { ShiftPlanFormWithTemplateGrid } from '@wilson/shift-plan/services';

export interface ShiftPlanningDataStateModel {
  duplicateShiftPlanForm: ValuesOf<ShiftPlanFormWithTemplateGrid>;
  isShiftPlanDuplicate: boolean;
}
export const defaults: ShiftPlanningDataStateModel = {
  duplicateShiftPlanForm: {
    totalDays: 0,
    header: {
      name: '',
      dateRange: [new Date(), add(new Date(), { days: 7 })],
      rolling: false,
      conflictSetting: ConflictSetting.Assign,
    },
    calendar: {
      shiftPlanUsers: [],
      shiftPlanTemplates: [],
    },
  },
  isShiftPlanDuplicate: false,
};

@State({
  name: 'shiftPlanningData',
  defaults,
})
@Injectable()
export class ShiftPlanningDataState {
  @Selector()
  static duplicateForm(state: ShiftPlanningDataStateModel) {
    return state.duplicateShiftPlanForm;
  }

  @Selector()
  static isShiftPlanDuplicate(state: ShiftPlanningDataStateModel) {
    return state.isShiftPlanDuplicate;
  }

  @Action(SetShiftPlanDuplicateFormAction)
  setDuplicateShiftPlanForm(
    ctx: StateContext<ShiftPlanningDataStateModel>,
    action: SetShiftPlanDuplicateFormAction,
  ) {
    ctx.patchState({
      duplicateShiftPlanForm: action.shiftPlanForm,
      isShiftPlanDuplicate: true,
    });
  }

  @Action(ResetShiftPlanDuplicateForm)
  resetShiftPlanDuplicateForm(ctx: StateContext<ShiftPlanningDataStateModel>) {
    ctx.patchState(defaults);
  }
}

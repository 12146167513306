import { Activity } from '@wilson/interfaces';
import {
  AffectedStop,
  ServiceDeviation,
} from '@wilson/service-deviation/interfaces';
import { addMinutes } from 'date-fns';

export function findLatestArrivalBasedOnDeviationTime(
  activities: Activity[] | null,
):
  | {
      endDatetime: string;
      arrivalDeviation: ServiceDeviation | undefined;
    }
  | undefined
  | null {
  if (activities) {
    const clonedActivities: {
      endDatetime: string;
      arrivalDeviation: ServiceDeviation | undefined;
    }[] = activities.map((activity) => ({
      endDatetime: activity.endDatetime,
      arrivalDeviation: activity.serviceDeviations?.find(
        (deviation: ServiceDeviation) =>
          deviation.affectedStop === AffectedStop.Arrival,
      ),
    }));

    clonedActivities.sort((a, b) => {
      const endDateOfA = a.arrivalDeviation
        ? addMinutes(
            new Date(a.endDatetime),
            a.arrivalDeviation.deviationInMinutes,
          ).toISOString()
        : a.endDatetime;

      const endDateOfB = b.arrivalDeviation
        ? addMinutes(
            new Date(b.endDatetime),
            b.arrivalDeviation.deviationInMinutes,
          ).toISOString()
        : b.endDatetime;

      return endDateOfB.localeCompare(endDateOfA); // Reverse the comparison to find the latest.
    });

    return clonedActivities[0];
  } else {
    return undefined;
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivitySelectionListItem } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-type-icon-renderer',
  template: `
    <div
      class="background-circle"
      [ngClass]="{
        handshake: isHandShakeIcon,
        network: !isHandShakeIcon
      }"
    >
      <i *ngIf="isHandShakeIcon; else networkIcon" class="fas fa-handshake"></i>

      <ng-template #networkIcon>
        <i class="fas fa-network-wired"></i>
      </ng-template>
    </div>
  `,
  styleUrls: ['./activity-type-icon-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTypeIconRendererComponent
  implements ICellRendererAngularComp
{
  isHandShakeIcon = false;

  agInit(params: ICellRendererParams): void {
    this.init(params);
  }

  refresh(params: ICellRendererParams) {
    this.init(params);
    return true;
  }

  private init(params: ICellRendererParams) {
    this.isHandShakeIcon = !!(params.data as ActivitySelectionListItem).jobId;
  }
}

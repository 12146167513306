import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitiesPipesModule } from '@wilson/activities/pipes';
import { OperationStatus, Shift } from '@wilson/interfaces';
import { ShiftHelpersPipesModule } from '@wilson/non-domain-specific/shift-helpers/pipes';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { IsShiftConfirmedAtStatusPipe } from '../../pipes/is-shift-confirmed-at-status/is-shift-confirmed-at-status.pipe';
import { IsShiftDeclinedAtStatusPipe } from '../../pipes/is-shift-declined-at-status/is-shift-declined-at-status.pipe';

@Component({
  selector: 'wilson-shift-status',
  templateUrl: './shift-status.component.html',
  styleUrls: ['./shift-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ShiftHelpersPipesModule,
    TranslateModule,
    ActivitiesPipesModule,
    IsShiftConfirmedAtStatusPipe,
    IsShiftDeclinedAtStatusPipe,
    NzToolTipModule,
    DateFnsModule,
  ],
})
export class ShiftStatusComponent {
  @Input() shift: Shift | null;
  public OperationStatus = OperationStatus;
}

import { ValuesOf } from '@ngneat/reactive-forms';
import { ShiftPlanFormWithTemplateGrid } from '@wilson/shift-plan/services';

export class SetShiftPlanDuplicateFormAction {
  static readonly type = '[ShiftPlanningDetail] Duplicate Shift Plan';
  constructor(public shiftPlanForm: ValuesOf<ShiftPlanFormWithTemplateGrid>) {}
}
export class ResetShiftPlanDuplicateForm {
  static readonly type = '[ShiftPlanningDetail] Reset Duplicate Shift Plan';
}

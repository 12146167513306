<nz-tag
  class="me-2 rounded-1"
  [nzColor]="'green'"
  *ngIf="shift.operationalStatus === OperationStatus.Ongoing"
>
  {{ 'page.shifts.started' | translate }}
</nz-tag>
<nz-tag
  class="me-2 rounded-1"
  [nzColor]="'green'"
  *ngIf="shift.operationalStatus === OperationStatus.Completed"
>
  {{ 'page.shifts.completed' | translate }}
</nz-tag>
<nz-tag
  class="me-2 rounded-1"
  [nzColor]="'red'"
  *ngIf="shift.operationalStatus === OperationStatus.NotStarted"
>
  {{ 'page.shifts.not_started' | translate }}
</nz-tag>
<nz-tag
  class="me-2 rounded-1"
  [nzColor]="'green'"
  *ngIf="
    shift?.publicationStatus === PublicationStatus.Published ||
      shift?.publicationStatus === PublicationStatus.Read;
    else notReleased
  "
>
  {{ 'page.work_time_management.' + shift?.state | translate }}
</nz-tag>
<ng-template #notReleased>
  <nz-tag class="me-2 rounded-1" [nzColor]="'red'">
    {{ 'page.shifts.not_released' | translate }}
  </nz-tag>
</ng-template>
<nz-tag
  class="me-2 rounded-1"
  [nzColor]="'red'"
  *ngIf="shift?.createdFrom === WilsonApp.Mobile"
>
  {{ 'page.shifts.self_dispatched' | translate }}
</nz-tag>
<nz-tag
  *ngIf="shift | isShiftConfirmedAtStatus | async"
  nz-tooltip
  [nzTooltipTitle]="shift?.confirmedAt | dfnsParseIso | dfnsFormat : 'Pp'"
  class="me-2 rounded-1"
>
  {{ 'shift.status.confirmed_at' | translate }}
</nz-tag>
<nz-tag
  *ngIf="shift | isShiftDeclinedAtStatus | async"
  nz-tooltip
  [nzTooltipTitle]="tooltipDeclineddReason"
  class="me-2 rounded-1"
  [nzColor]="'red'"
>
  {{ 'shift.status.declined_at' | translate }}
</nz-tag>
<ng-template #tooltipDeclineddReason>
  {{ shift?.declinedAt | dfnsParseIso | dfnsFormat : 'Pp' }}
  <p>
    {{
      shift?.declineReason
        ? shift?.declineReason
        : ('shift.declined_at.no_reason' | translate)
    }}
  </p>
</ng-template>

<h6 class="mt-2 mb-2 ms-2">
  <i class="fas fa-magic icon-style ml-2"></i>
  <span class="modal-title mx-2">
    {{ 'page.shift_disposition.wilson_ai' | translate }}
  </span>
</h6>
<hr />
<div class="row alert-msg pr-5 pt-1 mx-2">
  <div class="col-12 p-0 text-dark">
    <span
      [innerHTML]="
        'page.shift_disposition.wilson_ai_popup_header'
          | translate
            : {
                start: dateRange[0] | date : 'dd.MM.yyyy',
                end: dateRange[1] | date : 'dd.MM.yyyy'
              }
      "
    ></span>
  </div>
</div>

<wilson-assign-all-alert>
  <div class="title">
    <span class="fw-bold">{{
      'page.shift_disposition.wilson_ai_popup_detail_leading_text' | translate
    }}</span>
    <span>
      {{
        'page.shift_disposition.wilson_ai_popup_detail_text' | translate
      }}</span
    >
  </div>
</wilson-assign-all-alert>

<div class="row mx-2 text-dark mb-2">
  {{ 'page.shift_disposition.assignment_options' | translate }}
</div>
<div
  class="ms-2 form-check form-check-inline"
  *ngFor="let shiftValidOption of shiftValidOptions; let i = index"
>
  <input
    class="form-check-input custom-radio"
    type="radio"
    name="shiftValidOption"
    [id]="shiftValidOption.value"
    (change)="changeUserShiftvalidationOption(shiftValidOption.value)"
    [checked]="i === 0"
  />
  <label class="form-check-label" [for]="shiftValidOption.value">{{
    shiftValidOption.translate | translate
  }}</label>
</div>

import { LinkRendererService } from '@wilson/ag-grid/service';
import { LinkRendererParams, LinkType } from '@wilson/interfaces';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-link-renderer',
  template: `
    <wilson-link-renderer-content
      [linkData]="linkData"
    ></wilson-link-renderer-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkRendererComponent implements ICellRendererAngularComp {
  linkData: LinkType | undefined;

  constructor(private linkRenderer: LinkRendererService) {}

  agInit(params: ICellRendererParams & LinkRendererParams): void {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
  }

  refresh(params: ICellRendererParams & LinkRendererParams) {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
    return true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';

@Pipe({
  name: 'locationAbbreviationName',
})
export class LocationAbbreviationNamePipe implements PipeTransform {
  transform(location: GeoLocation): string {
    return location.locationCode
      ? location.locationCode + ' - ' + location.name
      : location.name;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityTemplate, ResolvedActivity } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-requirements-renderer',
  template: `
    <div *ngIf="isServiceSeries; else service">
      <wilson-activity-qualifications
        [activityTemplate]="activityTemplateValue"
        [disabled]="true"
        [size]="'sm'"
      ></wilson-activity-qualifications>
    </div>
    <ng-template #service>
      <wilson-activity-qualifications
        [activity]="activityValue"
        [disabled]="true"
        [size]="'sm'"
      ></wilson-activity-qualifications>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityRequirementsRendererComponent
  implements ICellRendererAngularComp
{
  public activityValue!: ResolvedActivity;
  public isServiceSeries: boolean;
  public activityTemplateValue: ActivityTemplate;

  async agInit(params: ICellRendererParams) {
    this.isServiceSeries = params.data.serviceSeriesId;

    if (this.isServiceSeries) {
      this.activityTemplateValue = params.data;
    } else {
      this.activityValue = params.data;
    }
  }

  refresh(params: ICellRendererParams) {
    this.isServiceSeries = params.data.serviceSeriesId;

    if (this.isServiceSeries) {
      this.activityTemplateValue = params.data;
    } else {
      this.activityValue = params.data;
    }
    return true;
  }
}

<div
  class="d-flex align-items-center"
  *ngIf="{ isItemInBucket: activity | isItemInBucket | async } as data"
>
  <ng-container *ngIf="loading; else assignmentStatus">
    <!-- Loading State -->
    <div class="rounded-circle flex-shrink-0 skeleton-avatar">
      <ngx-skeleton-loader
        appearance="circle"
        [theme]="{ width: '40px', height: '40px' }"
      >
      </ngx-skeleton-loader>
    </div>
    <div class="ms-2 skeleton-text">
      <ngx-skeleton-loader
        [theme]="{
          width: '150px',
          'border-radius': '0',
          height: '10px',
          'margin-bottom': '5px'
        }"
      ></ngx-skeleton-loader>
      <div>
        <ngx-skeleton-loader
          [theme]="{
            width: '120px',
            'border-radius': '0',
            height: '10px',
            'margin-bottom': '5px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>
  <ng-template #assignmentStatus>
    <div
      #tooltip="nzTooltip"
      nz-tooltip
      [nzTooltipOverlayClassName]="
        assignedUser ? 'light-sharp-corners' : 'dark'
      "
      nzTooltipPlacement="left"
      [nzTooltipOverlayStyle]="{
        'max-width': 'none',
        'min-width': showValidations ? 'none' : '325px'
      }"
      [nzTooltipArrowPointAtCenter]="true"
      [nzTooltipTitle]="
        (activity?.jobId || data.isItemInBucket) &&
        !isProvider &&
        !activity?.requestForProposalId &&
        !assignedUser
          ? ('wilson.share.cannot_assign' | translate)
          : !!assignedUser
          ? shiftValidationsTooltip
          : ''
      "
    >
      <div class="d-flex">
        <ng-container
          *ngIf="assignedUser || isAssignedToShift; else notAssigned"
        >
          <div
            [class.user-image]="
              (!isAssignedToProviderShift && !disableAssignment) ||
              activity.requestForProposalId ||
              isProvider
            "
          >
            <img
              [src]="
                assignedUser ? assignedUser.profileImageUrl : shiftAssignedUrl
              "
              alt="twbs"
              width="40"
              height="40"
              class="rounded-circle flex-shrink-0 me-2"
              [class.cursor-pointer]="
                (!isAssignedToProviderShift && !disableAssignment) ||
                activity?.requestForProposalId
              "
              content-type="template"
            />
            @if (!(activity?.jobId || data.isItemInBucket) &&
            (!isAssignedToProviderShift && !disableAssignment) ||
            activity?.requestForProposalId || isProvider) {
            <div
              (click)="searchForStaffOnActivity(activity)"
              class="badge me-2 cursor-pointer"
            >
              <i
                class="fas"
                [ngClass]="assignedUser ? 'fa-sync-alt' : 'fa-plus'"
              ></i>
            </div>
            }
          </div>
          <div class="w-100 d-flex flex-column justify-content-center">
            <div class="d-flex align-items-center">
              <p class="m-0" *ngIf="assignedUser; else noUser">
                {{ assignedUser.lastName + ', ' + assignedUser.firstName }}
              </p>
              <fa-icon
                *ngIf="!isShiftValid"
                class="inValidIcon mx-2"
                [icon]="faInValidIcon"
              ></fa-icon>
            </div>
            <ng-template #noUser>
              <p class="m-0">
                {{ 'service.activity.no_user_assigned' | translate }}
              </p>
            </ng-template>
            <ng-container
              *ngIf="
                !isAssignedToProviderShift || activity.requestForProposalId;
                else jobLinkProvider
              "
            >
              <a
                (click)="openInNewTab('/shifts/' + activity.shiftId)"
                class="link-success"
              >
                <small>
                  {{ 'page.shift' | translate }}
                  {{ shiftName ? shiftName : '' }}
                </small>
              </a>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #notAssigned>
          <div
            [class.user-image]="
              !(activity?.jobId || data.isItemInBucket) ||
              isProvider ||
              activity?.requestForProposalId
            "
          >
            <img
              src="../../../../assets/img/service/no-user-assigned.svg"
              alt="twbs"
              width="40"
              height="40"
              class="rounded-circle flex-shrink-0 me-2"
              content-type="template"
            />
            <ng-container
              *ngIf="
                !(activity?.jobId || data.isItemInBucket) ||
                isProvider ||
                activity?.requestForProposalId
              "
            >
              <div
                (click)="searchForStaffOnActivity(activity)"
                class="badge me-2 cursor-pointer"
              >
                <i class="fas fa-plus"></i>
              </div>
            </ng-container>
          </div>
          <div class="w-100 d-flex flex-column justify-content-center">
            <p class="m-0">
              {{ 'service.activity.no_user_assigned' | translate }}
            </p>
            <ng-container
              *ngIf="
                !(activity?.jobId || data.isItemInBucket) || isProvider;
                else jobLinkClient
              "
            >
              <a
                (click)="searchForStaffOnActivity(activity)"
                class="link-success"
              >
                <small>{{ 'page.services.service_fill' | translate }}</small></a
              >
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #jobLinkClient>
  @if (showJobLink) {
  <a
    (click)="openInNewTab('/share/jobs/client/' + activity.jobId)"
    class="link-success"
  >
    <small> {{ 'wilson.share.go_to_job' | translate }} </small>
  </a>
  }
</ng-template>
<ng-template #jobLinkProvider>
  @if (showJobLink) {
  <a
    (click)="
      openInNewTab('/share/jobs/provider/' + activity.requestForProposalId)
    "
    class="link-success"
  >
    <small> {{ 'wilson.share.go_to_job' | translate }} </small>
  </a>
  }
</ng-template>

<ng-template #shiftValidationsTooltip>
  <ng-container *ngIf="showValidations; else userTooltip">
    <wilson-shift-validations-tooltip
      (expanded)="onTooltipExpanded()"
      [workingTimeValidations]="workingTimeValidations"
      [shiftLogicValidations]="shiftLogicValidations"
      [qualificationNames]="qualificationNames"
      [professionValidations]="professionValidations"
    >
      <wilson-user-details
        [user]="assignedUser"
        [hideProfileIcon]="!!activity.jobId && !activity.requestForProposalId"
      ></wilson-user-details>
    </wilson-shift-validations-tooltip>
  </ng-container>
</ng-template>
<ng-template #userTooltip>
  <div class="p-4">
    <wilson-user-details
      [user]="assignedUser"
      [hideProfileIcon]="!!activity.jobId && !activity.requestForProposalId"
    ></wilson-user-details>
  </div>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import {
  convertWeekDayStringsInWeekdayObject,
  WeekDays,
  WeekDaysEnum,
} from '@wilson/interfaces';

@Pipe({
  name: 'convertWeekDayStringsInWeekdayObject',
  standalone: true,
})
export class ConvertWeekDayStringsInWeekdayObjectPipe implements PipeTransform {
  transform(weekDays: WeekDaysEnum[]): WeekDays {
    return convertWeekDayStringsInWeekdayObject(weekDays);
  }
}

<ng-template #selectedStatusTemplate let-selected>
  <div class="reposition-selected-tag">
    <nz-tag
      [nzColor]="isDisabled ? 'default' : StayStatusColor[selected.nzValue]"
      class="beta-tag"
    >
      {{ 'page.stays.status.' + selected.nzValue | translate }}
    </nz-tag>
  </div>
</ng-template>
<nz-select
  (ngModelChange)="stayStatusChanged($event)"
  [ngModel]="selectedStayStatus"
  [nzAllowClear]="true"
  [nzShowArrow]="true"
  [nzPlaceHolder]="'stay_status.select.placeholder' | translate"
  [nzCustomTemplate]="selectedStatusTemplate"
  [nzDisabled]="isDisabled"
  class="select-multiple-no-wrap"
>
  <nz-option
    *ngFor="let stayStatus of stayStatusArray"
    [nzValue]="stayStatus"
    nzCustomContent
  >
    <nz-tag [nzColor]="StayStatusColor[stayStatus]" class="beta-tag">
      {{ 'page.stays.status.' + stayStatus | translate }}
    </nz-tag>
  </nz-option>
</nz-select>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JobOrigin } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-job-type-renderer',
  template: `
    <div class="text-dark ">
      {{ 'wilson.share.my.jobs.origin.' + createdFrom | translate }}
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTypeRendererComponent implements ICellRendererAngularComp {
  createdFrom: JobOrigin;

  agInit(params: ICellRendererParams): void {
    this.createdFrom = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.createdFrom = params.value;
    return true;
  }
}

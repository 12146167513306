import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ActivityNameParams,
  SeriesRule,
  SeriesRuleType,
} from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';

@Component({
  selector: 'wilson-exception-renderer',
  templateUrl: './exception-renderer.component.html',
  styleUrls: ['./exception-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExceptionRendererComponent implements ICellRendererAngularComp {
  public seriesRules!: SeriesRule[];
  public displayValue!: string;
  public SeriesRuleType = SeriesRuleType;

  async agInit(params: ICellRendererParams & ActivityNameParams) {
    this.seriesRules = params.value;
    this.generateDisplayValue(params.value);
  }

  refresh(params: ICellRendererParams & ActivityNameParams) {
    this.seriesRules = params.value;
    this.generateDisplayValue(params.value);
    return true;
  }

  generateDisplayValue(seriesRules: SeriesRule[]) {
    this.displayValue = '';
    seriesRules?.forEach((rule, index) => {
      const startDate = format(
        new Date(rule.startDate),
        DateTimeFormat.GermanDateFormat,
      );
      const endDate = format(
        new Date(rule.endDate),
        DateTimeFormat.GermanDateFormat,
      );
      const comma = index === 0 ? '' : ', ';
      if (startDate === endDate) {
        this.displayValue = `${this.displayValue}${comma} ${startDate}`;
      } else {
        this.displayValue = `${this.displayValue}${comma} ${startDate} - ${endDate}`;
      }
    });
  }
}

import { OperativeReportsCategorySettings } from '@wilson/operative-reports/interfaces';

export class InitializeOperativeReportsState {
  static readonly type = '[OperativeReports] InitializeOperativeReportsState';
}
export class SelectedOperativeReportIssue {
  static readonly type = '[OperativeReports] SelectedOperativeReportIssue';
  constructor(public issues: OperativeReportsCategorySettings) {}
}
export class OpenOperativeReportIssueDrawer {
  static readonly type = '[OperativeReports] OpenOperativeReportIssueDrawer';
  constructor(public open: boolean) {}
}
export class UpdateOperativeReportIssue {
  static readonly type = '[OperativeReports] UpdateOperativeReportIssue';
  constructor(public issue: OperativeReportsCategorySettings) {}
}

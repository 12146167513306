<div
  nz-tooltip
  [nzTooltipTitle]="isUserAssigned ? tooltipInformation : ''"
  nzTooltipPlacement="left"
  nzTooltipOverlayClassName="dark"
  [nzTooltipOverlayStyle]="{ 'min-width': '625px' }"
>
  <ng-container *ngIf="!isLoading; else loading">
    <p *ngIf="!isUserAssigned; else validationDetails" class="form-text mt-2">
      {{ 'page.shifts.user_assigned_to_shift' | translate }}
    </p>
    <ng-template #validationDetails>
      <ul
        *ngIf="shiftValidationDetails?.length > 0; else validationEmptyState"
        class="list-group list-group-flush"
      >
        <li
          class="list-group-item text-muted border-0 pb-0 ps-0"
          *ngIf="areShiftContentsSufficient; else contentsTemplate"
        >
          <img
            class="me-2"
            src="assets/img/svg-icons/qualification_status_valid.svg"
          />
          <small>{{ 'page.shifts.content_ok' | translate }}</small>
        </li>
        <ng-template #contentsTemplate>
          <li class="list-group-item text-muted border-0 pb-0 ps-0">
            <img
              class="me-2"
              src="assets/img/svg-icons/qualification_status_expired.svg"
            />
            <small>{{ 'page.shifts.check_shift_content' | translate }}</small>
          </li>
        </ng-template>

        <li
          class="list-group-item text-muted border-0 pb-0 ps-0"
          *ngIf="areWorkingTimeRulesSufficient; else timeRulesTemplate"
        >
          <img
            class="me-2"
            src="assets/img/svg-icons/qualification_status_valid.svg"
          />
          <small>{{
            'page.shifts.working_time_rules_observed' | translate
          }}</small>
        </li>
        <ng-template #timeRulesTemplate>
          <li class="list-group-item text-muted border-0 pb-0 ps-0">
            <img
              class="me-2"
              src="assets/img/svg-icons/qualification_status_expired.svg"
            />
            <small>{{
              'page.shifts.working_time_rules_violated' | translate
            }}</small>
          </li>
        </ng-template>

        <li
          class="list-group-item text-muted border-0 pb-0 ps-0"
          *ngIf="areQualificationsSufficient; else qualificationsTemplate"
        >
          <img
            class="me-2"
            src="assets/img/svg-icons/qualification_status_valid.svg"
          />
          <small>{{
            'page.shifts.qualifications_sufficient' | translate
          }}</small>
        </li>
        <ng-template #qualificationsTemplate>
          <li class="list-group-item text-muted border-0 pb-0 ps-0">
            <img
              class="me-2"
              src="assets/img/svg-icons/qualification_status_expired.svg"
            />
            <small>{{
              'page.shifts.qualifications_not_met' | translate
            }}</small>
          </li>
        </ng-template>
      </ul>
      <ng-template #validationEmptyState>
        <p class="text-light mt-2">
          {{ 'page.shifts.quick_check_text' | translate }}
        </p>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="mt-2">
      <wilson-loading-spinner></wilson-loading-spinner>
    </div>
  </ng-template>
</div>
<!-- Tool Tip -->
<ng-template #tooltipInformation>
  <wilson-qualification-employee-tooltip
    *ngIf="isUserAssigned"
    [header]="'page.shifts.quick_check' | translate"
    [shiftValidationDetails]="workingTimeValidation$ | async"
    [qualificationNames]="qualificationNames"
    [professionValidations]="professionValidations$ | async"
  >
  </wilson-qualification-employee-tooltip>
</ng-template>

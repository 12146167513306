import { Injectable } from '@angular/core';
import { ReleaseStatus } from '../interfaces';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { UserTimelines } from '@wilson/interfaces';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';
import { TimelineFilterHelperService } from './timeline-filter-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftTimelineFilterStateService {
  @WilsonState<string[]>([])
  userIds!: string[];
  userIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  orgUnitIds!: string[];
  orgUnitIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  roleIds!: string[];
  roleIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  absencesCategoryIds!: string[];
  absencesCategoryIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  shiftCategoryIds!: string[];
  shiftCategoryIds$!: Observable<string[]>;

  @WilsonState<ReleaseStatus | null>(null)
  releaseStatus!: ReleaseStatus | null;
  releaseStatus$!: Observable<ReleaseStatus | null>;

  @WilsonState<string | null>(null)
  searchTerm!: string | null;
  searchTerm$!: Observable<string | null>;

  @WilsonState<string[] | null>([])
  priority!: string[] | null;
  priority$!: Observable<string[] | null>;

  @WilsonState<string[]>([])
  professionIds!: string[];
  professionIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  labels!: string[];
  labels$!: Observable<string[]>;

  @WilsonState<string[]>([])
  projectIds!: string[];
  projectIds$!: Observable<string[]>;

  @WilsonState<string[]>([])
  confirmedDeclined!: string[];
  confirmedDeclined$!: Observable<string[]>;

  @WilsonState(false)
  showFilterDrawer!: boolean;
  showFilterDrawer$!: Observable<boolean>;

  readonly areHighlightFiltersActive$ = combineLatest([
    this.absencesCategoryIds$,
    this.shiftCategoryIds$,
    this.releaseStatus$,
    this.priority$,
    this.labels$,
    this.projectIds$,
    this.confirmedDeclined$,
  ]).pipe(
    map(
      ([
        absences,
        shiftCategory,
        releaseStatus,
        priority,
        labels,
        projects,
        confirmedDeclined,
      ]) => {
        return !!(
          absences.length ||
          shiftCategory.length ||
          releaseStatus ||
          priority?.length ||
          labels?.length ||
          projects?.length ||
          confirmedDeclined?.length
        );
      },
    ),
    shareReplay(1),
  );

  resetFilters() {
    this.userIds = [];
    this.orgUnitIds = [];
    this.roleIds = [];
    this.absencesCategoryIds = [];
    this.shiftCategoryIds = [];
    this.releaseStatus = null;
    this.searchTerm = '';
    this.priority = [];
    this.professionIds = [];
    this.labels = [];
    this.projectIds = [];
    this.confirmedDeclined = [];
  }

  private usersDictionary$: Observable<Record<string, UserTimelines>> =
    this.store.select(ShiftTimelineDataState.usersDictionary).pipe(
      distinctUntilChanged(
        (
          previousValue: Record<string, UserTimelines>,
          currentValue: Record<string, UserTimelines>,
        ) => {
          return (
            Object.keys(previousValue).length ===
            Object.keys(currentValue).length
          );
        },
      ),
    );

  readonly filteredUsers$ = combineLatest([
    this.getUsersWithOrgUnitsStream(),
    this.getUsersWithUserIdsStream(),
    this.getUsersWithRoleStream(),
    this.getUsersMatchingSearchStream(),
    this.getUsersWithProfessionStream(),
    this.getUsersWithLabelsStream(),
  ]).pipe(
    map(
      ([
        usersWithOrgUnits,
        usersWithUserIds,
        usersWithRole,
        usersMatchingSearch,
        usersMatchingProfession,
        usersWithLabels,
      ]) => {
        const resultIds = Object.keys(usersWithOrgUnits).filter((id) => {
          return (
            usersWithUserIds[id] &&
            usersWithRole[id] &&
            usersMatchingSearch[id] &&
            usersMatchingProfession[id] &&
            usersWithLabels[id]
          );
        });

        return resultIds.map((id) => usersWithOrgUnits[id]);
      },
    ),
    shareReplay(1),
  );

  private defaultDebounceTime = 150;

  constructor(private store: Store) {}

  private getUsersWithOrgUnitsStream() {
    return combineLatest([
      this.usersDictionary$,
      this.orgUnitIds$.pipe(debounceTime(this.defaultDebounceTime)),
    ]).pipe(
      map(([usersDictionary, orgUnitIds]) => {
        if (orgUnitIds.length > 0) {
          const result: Record<string, UserTimelines> = {};
          Object.values(usersDictionary).forEach((userTimeline) => {
            if (orgUnitIds.includes(userTimeline.organizationalUnitId)) {
              result[userTimeline.id as string] = userTimeline;
            }
          });

          return result;
        } else {
          return usersDictionary;
        }
      }),
    );
  }

  private getUsersWithUserIdsStream() {
    return combineLatest([
      this.usersDictionary$,
      this.userIds$.pipe(debounceTime(this.defaultDebounceTime)),
    ]).pipe(
      map(([usersDictionary, userIds]) => {
        if (userIds.length) {
          const result: Record<string, UserTimelines> = {};
          userIds.forEach((userId) => {
            if (usersDictionary[userId]) {
              result[userId] = usersDictionary[userId];
            }
          });

          return result;
        } else {
          return usersDictionary;
        }
      }),
    );
  }

  private getUsersWithRoleStream() {
    return combineLatest([
      this.usersDictionary$,
      this.roleIds$.pipe(debounceTime(this.defaultDebounceTime)),
    ]).pipe(
      map(([usersDictionary, roleIds]) => {
        if (roleIds.length) {
          const result: Record<string, UserTimelines> = {};
          Object.values(usersDictionary).forEach((userTimeline) => {
            const isMatching = userTimeline.userRoles.some((ur) =>
              roleIds.includes(ur.roleId as string),
            );
            if (isMatching) {
              result[userTimeline.id as string] = userTimeline;
            }
          });

          return result;
        } else {
          return usersDictionary;
        }
      }),
    );
  }

  private getUsersMatchingSearchStream() {
    return combineLatest([
      this.usersDictionary$,
      this.searchTerm$.pipe(debounceTime(this.defaultDebounceTime)),
    ]).pipe(
      map(([usersDictionary, searchTerm]) => {
        if (searchTerm) {
          const searchTermLowerCase = searchTerm.toLocaleLowerCase();
          const result: Record<string, UserTimelines> = {};
          Object.values(usersDictionary).forEach((userTimeline) => {
            const isMatching =
              userTimeline.firstName
                .toLocaleLowerCase()
                .includes(searchTermLowerCase) ||
              userTimeline.lastName
                .toLocaleLowerCase()
                .includes(searchTermLowerCase);
            if (isMatching) {
              result[userTimeline.id as string] = userTimeline;
            }
          });

          return result;
        } else {
          return usersDictionary;
        }
      }),
    );
  }

  private getUsersWithProfessionStream() {
    return combineLatest([
      this.usersDictionary$,
      this.professionIds$.pipe(debounceTime(this.defaultDebounceTime)),
    ]).pipe(
      map(([usersDictionary, professionIds]) => {
        if (professionIds.length) {
          const result: Record<string, UserTimelines> = {};
          Object.values(usersDictionary).forEach((userTimeline) => {
            const isMatching = userTimeline.professionIds.some((professionId) =>
              professionIds.includes(professionId),
            );
            if (isMatching) {
              result[userTimeline.id as string] = userTimeline;
            }
          });

          return result;
        } else {
          return usersDictionary;
        }
      }),
    );
  }

  private getUsersWithLabelsStream() {
    return combineLatest([
      this.store.select(ShiftTimelineDataState.usersDictionary),
      this.labels$.pipe(debounceTime(this.defaultDebounceTime)),
    ]).pipe(
      map(([usersDictionary, labelsFilter]) => {
        return TimelineFilterHelperService.filterUsersWithLabels(
          usersDictionary,
          labelsFilter,
        );
      }),
    );
  }
}

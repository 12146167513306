<button
  *ngIf="canBeEdited$ | async"
  (click)="openEditModal()"
  [nzLoading]="loading$ | async"
  nz-button
  nzShape="circle"
  nzSize="large"
  nzType="text"
>
  <i class="far fa-edit"></i>
</button>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityTemplate, ResolvedActivity } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-profession-renderer',
  template: `
    <div class="col-2 ps-0">
      <div class="d-flex align-items-center action-icons">
        <wilson-profession-tag
          *ngIf="'portal-professions' | featureFlagPure | async"
          [activity]="cellValue"
          [isEditable]="isEditable"
          [isSeries]="isSeries"
        ></wilson-profession-tag>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityProfessionRendererComponent
  implements ICellRendererAngularComp
{
  cellValue: ResolvedActivity | ActivityTemplate | undefined;
  isEditable = false;
  isSeries = false;

  async agInit(
    params: ICellRendererParams<
      ResolvedActivity | ActivityTemplate,
      { isEditable: boolean; isSeries: boolean }
    >,
  ) {
    this.cellValue = params.data;
    this.isEditable = params.value?.isEditable;
    this.isSeries = params.value?.isSeries;
  }

  refresh(
    params: ICellRendererParams<
      ResolvedActivity | ActivityTemplate,
      { isEditable: boolean; isSeries: boolean }
    >,
  ) {
    this.cellValue = params.data;
    this.isEditable = params.value?.isEditable;
    this.isSeries = params.value?.isSeries;
    return true;
  }
}

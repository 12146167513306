import { Injectable } from '@angular/core';
import {
  ShiftAssignment,
  ActivitiesAssignment,
  ValidationCheckCategory,
  AssignmentAvailabilityStatus,
  UserTimelines,
  UserTimelinesModal,
} from '@wilson/interfaces';
import {
  TimelineAssignmentModalFilters,
  TimelineRenderingFilters,
} from '../interfaces';
import { ShiftValidationErrorService } from '@wilson/shifts';

@Injectable({
  providedIn: 'root',
})
export class TimelineFilterHelperService {
  static filterOrgUnit<T extends UserTimelines | UserTimelinesModal>(
    usersWithShiftAssignment: T[],
    filters: Pick<TimelineRenderingFilters, 'orgUnit'>,
  ) {
    return usersWithShiftAssignment.filter((u) =>
      filters.orgUnit.includes(u.organizationalUnitId),
    );
  }

  static filterSearchTerm<T extends UserTimelines | UserTimelinesModal>(
    usersWithShiftAssignment: T[],
    searchTerm: string,
  ) {
    return usersWithShiftAssignment.filter((u) => {
      return (
        u.firstName.toLocaleLowerCase().includes(searchTerm) ||
        u.lastName.toLocaleLowerCase().includes(searchTerm)
      );
    });
  }

  static showOnlyQualifiedStaff(
    usersWithShiftAssignment: (UserTimelinesModal & {
      shiftAssignment: ShiftAssignment | ActivitiesAssignment | undefined;
    })[],
  ) {
    return usersWithShiftAssignment.filter((user) => {
      const shiftQualificationValidationErrors =
        user.shiftAssignment?.shiftValidationErrors.filter(
          (validation) =>
            validation.category === ValidationCheckCategory.Qualifications,
        );
      return (
        !shiftQualificationValidationErrors?.length ||
        shiftQualificationValidationErrors.every(
          (qualification) => qualification.valid,
        )
      );
    });
  }

  static showOnlyQualifiedWorkingTimeStaff(
    usersWithShiftAssignment: (UserTimelinesModal & {
      shiftAssignment: ShiftAssignment | ActivitiesAssignment | undefined;
    })[],
  ) {
    return usersWithShiftAssignment.filter((user) => {
      if (user.shiftAssignment) {
        return ShiftValidationErrorService.getShiftWorkingTimeValidation(
          user.shiftAssignment?.shiftValidationErrors,
        ).every((item) => item.valid);
      }
      return [];
    });
  }

  static filterStaffAvailability(
    usersWithShiftAssignment: (UserTimelinesModal & {
      shiftAssignment: ShiftAssignment | ActivitiesAssignment | undefined;
    })[],
    filters: Pick<TimelineAssignmentModalFilters, 'staffAvailability'>,
  ) {
    return usersWithShiftAssignment.filter((user) => {
      const shiftAssignment = user.shiftAssignment;
      const availability = (shiftAssignment as ActivitiesAssignment)
        ?.availability;
      if (availability) {
        switch (filters.staffAvailability) {
          case AssignmentAvailabilityStatus.Available:
            return availability === AssignmentAvailabilityStatus.Available;

          case AssignmentAvailabilityStatus.PartlyAvailable:
            return (
              availability === AssignmentAvailabilityStatus.PartlyAvailable
            );

          case AssignmentAvailabilityStatus.NotAvailable:
            return availability === AssignmentAvailabilityStatus.NotAvailable;

          default:
            return true;
        }
      }
      return true;
    });
  }

  static filterUsersWithLabels(
    usersDictionary: Record<string, UserTimelines>,
    labelsFilter: string[],
  ) {
    if (labelsFilter.length) {
      const includesNoLabelFilter = labelsFilter.includes('no-label');
      const result: Record<string, UserTimelines> = {};
      Object.values(usersDictionary).forEach((user) => {
        let isUserLabelMatching = false;
        if (includesNoLabelFilter && user.labels?.length === 0) {
          isUserLabelMatching = true;
        } else {
          isUserLabelMatching = !!user.labels?.some((label) =>
            labelsFilter.includes(label.id),
          );
        }

        const userShifts = Object.values(
          user.shiftsWithoutActivitiesDictionary,
        );
        const shiftLabelMatch = includesNoLabelFilter
          ? userShifts?.some(
              (shift) =>
                (includesNoLabelFilter && shift.labels?.length === 0) ||
                shift.labels?.some((label) => labelsFilter.includes(label.id)),
            )
          : userShifts?.some((shift) =>
              shift.labels?.some((label) => labelsFilter.includes(label.id)),
            );

        if (isUserLabelMatching || shiftLabelMatch) {
          result[user.id as string] = user;
        }
      });

      return result;
    } else {
      return usersDictionary;
    }
  }
}

<div class="w-100 me-3">
  @if(!hideLabel) {
  <label class="form-text">
    {{ label || ('general.users' | translate) }}
  </label>
  }
  <nz-select
    [required]="isRequired"
    [nzPlaceHolder]="'general.users' | translate"
    nzShowSearch
    nzAllowClear
    [disabled]="isDisabled"
    [ngModel]="selectedUserId"
    (ngModelChange)="updateSelectedUserId($event)"
  >
    <nz-option
      *ngFor="let user of userOptions$ | async"
      [nzValue]="user.id"
      [nzLabel]="user.lastName + ', ' + user.firstName"
    >
    </nz-option>
  </nz-select>
</div>

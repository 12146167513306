import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivityTimePipe } from './pipes/activity-time-check/activity-time-check.pipe';
import { ActivitySplitPermissionPipe } from './pipes/activity-time-check/split-permission-check.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ActivityTimePipe, ActivitySplitPermissionPipe],
  exports: [ActivityTimePipe, ActivitySplitPermissionPipe],
})
export class ShiftHelpersModule {}

<nz-upload
  class="nz-upload-list-before"
  nzType="drag"
  [nzFileList]="fileList"
  [nzAccept]="'image/jpeg,image/png,application/pdf'"
  [nzBeforeUpload]="beforeUpload"
  [nzPreview]="handlePreview"
  [nzRemove]="handleDelete"
>
  <div class="mt-2">
    <fa-icon class="upload-icon" [icon]="faFileUpload"></fa-icon>
    <p class="upload-text mt-1">
      {{ 'general.upload.title' | translate }}
    </p>
    <p class="upload-hint">
      {{ 'general.upload.description' | translate }}
    </p>
  </div>
</nz-upload>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wilson-view-file-popup',
  templateUrl: './view-file-popup.component.html',
  styleUrls: ['./view-file-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewFilePopupComponent {
  @Input() file: string;
  @Input() name: string;

  zoomIn = false;

  constructor(private modalService: NgbModal) {}

  zoom() {
    this.zoomIn = !this.zoomIn;
  }

  close() {
    this.modalService.dismissAll();
  }
}

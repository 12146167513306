import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

@Component({
  selector: 'wilson-text-renderer',
  standalone: true,
  imports: [CommonModule, NzTypographyModule, TranslateModule],
  templateUrl: './text-renderer.component.html',
  styleUrls: ['./text-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextRendererComponent implements ICellRendererAngularComp {
  cellValue: string | null | undefined;

  async agInit(params: ICellRendererParams) {
    this.cellValue = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    return true;
  }
}

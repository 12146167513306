<div class="text-dark">
  <ng-container *ngIf="dates[0]; else noData">
    {{ dates[0] | date : 'HH:mm' }} {{ 'general.hour' | translate }}
  </ng-container>
</div>
<div class="d-block text-info">
  <ng-container *ngIf="dates[1]; else noData">
    {{ dates[1] | date : 'HH:mm - dd.MM.yy' }}
  </ng-container>
</div>

<ng-template #noData>---</ng-template>

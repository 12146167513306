<div class="share">
  @if (jobName) {
  <div class="d-flex">
    <a
      (click)="openInNewTabAndStopBubbling($event)"
      class="link-dark text-truncate"
    >
      {{ jobName }}
    </a>
  </div>
  <div class="text-truncate d-flex align-items-center">
    @if(orgUnitName) {
    <i
      class="fas me-1"
      [ngClass]="isMyActivity ? 'fa-arrow-left' : 'fa-arrow-right'"
    ></i>
    <ng-container [ngSwitch]="status">
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Open">
        <span class="text-secondary-dark">
          {{ 'wilson.share.status.open' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Accepted">
        <span class="text-primary-dark">
          {{ 'wilson.share.status.accepted' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Withdrawn">
        <span class="text-danger">
          {{ 'wilson.share.status.withdrawn' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Declined">
        <span class="text-danger">
          {{ 'wilson.share.status.declined' | translate }}
        </span>
      </div>
      <div
        class="text-dark"
        *ngSwitchCase="ClientProposalResponse.AutoDeclined"
      >
        <span class="text-danger">
          {{ 'wilson.share.status.declined' | translate }}
        </span>
      </div>
    </ng-container>
    <span class="ms-1">{{ orgUnitName }}</span>
    }
  </div>
  } @else {
  <span nz-typography nzType="secondary">
    {{ 'general.n/a' | translate }}
  </span>
  }
</div>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {
  constructor(
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status !== 403 || req.url.includes('/files'))
          return throwError(() => error);

        this.toastr.error(this.translate.instant('error.forbidden'));
        return throwError(() => error);
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { deepClone } from '@wilson/utils';
import { ShiftTimelineInteractionsModel } from '../interfaces';
import {
  ResetTimelineInteractionsState,
  SetDemarcatorRange,
  ToggleRadioButtonInTimelineModal,
  ToggleRenderTimelineInModal,
} from './shift-timeline-interactions.actions';

export const interactionDefaults: ShiftTimelineInteractionsModel = {
  renderRadioInput: false,
  showRadioButton: true,
  demarcatorRange: [],
};

@State<ShiftTimelineInteractionsModel>({
  name: 'shiftTimelineInteractions',
  defaults: interactionDefaults,
})
@Injectable()
export class ShiftTimelineInteractionsState {
  @Selector()
  static renderRadioInput(state: ShiftTimelineInteractionsModel) {
    return state.renderRadioInput;
  }

  @Selector()
  static showRadioButton(state: ShiftTimelineInteractionsModel) {
    return state.showRadioButton;
  }

  @Selector()
  static getDemarcatorRanges(state: ShiftTimelineInteractionsModel) {
    return state.demarcatorRange;
  }

  @Selector()
  static getDemarcatorRange(state: ShiftTimelineInteractionsModel) {
    const demarcatorRange = deepClone(state.demarcatorRange);
    const sortedDemarcatorRange = demarcatorRange.sort(
      (a, b) => (a.left as number) - (b.left as number),
    );
    if (sortedDemarcatorRange.length) {
      return {
        left: sortedDemarcatorRange[0].left,
        right: sortedDemarcatorRange[sortedDemarcatorRange.length - 1].right,
      };
    } else {
      return {
        left: null,
        right: null,
      };
    }
  }

  @Action(SetDemarcatorRange)
  setDemarcatorRange(
    ctx: StateContext<ShiftTimelineInteractionsModel>,
    action: SetDemarcatorRange,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      demarcatorRange: action.demarcatorRange,
    });
  }

  @Action(ToggleRenderTimelineInModal)
  toggleRenderTimelineInModal(
    ctx: StateContext<ShiftTimelineInteractionsModel>,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      renderRadioInput: !state.renderRadioInput,
    });
  }

  @Action(ToggleRadioButtonInTimelineModal)
  toggleRadioButtonInTimelineModal(
    ctx: StateContext<ShiftTimelineInteractionsModel>,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      showRadioButton: !state.showRadioButton,
    });
  }

  @Action(ResetTimelineInteractionsState)
  resetInteractionsState(ctx: StateContext<ShiftTimelineInteractionsModel>) {
    ctx.patchState(interactionDefaults);
  }
}

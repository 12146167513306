import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { Shift } from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ShiftAttachmentsComponent } from '../../shift-attachments/shift-attachments.component';

@Component({
  selector: 'wilson-collapsible-shift-attachments',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    AuthorizationModule,
    FeatureFlagsModule,
    TranslateModule,
    NzDividerModule,
    ShiftAttachmentsComponent,
  ],
  templateUrl: './collapsible-shift-attachments.component.html',
  styleUrl: './collapsible-shift-attachments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftAttachmentsComponent implements OnChanges {
  @Input() selectedShift!: Shift | undefined;
  @Input() isExpandedOnOpen = false;

  public shiftAttachments$: Observable<NzUploadFile[]> = of([]);
  private get selectedShiftId(): string {
    if (this.selectedShift?.id) {
      return this.selectedShift.id;
    } else {
      throw new Error(this.translate.instant('general.error'));
    }
  }

  constructor(
    private readonly shiftsService: ShiftsService,
    private cdr: ChangeDetectorRef,
    private nzMessage: NzMessageService,
    private translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedShift'].currentValue) {
      this.getShiftAttachments();
    }
  }

  getShiftAttachments(): void {
    try {
      this.shiftAttachments$ = this.shiftsService.getShiftAttachments(
        this.selectedShiftId,
      );
    } catch {
      this.nzMessage.error(this.translate.instant('general.error'));
    }
  }

  async updatedAttachments(file: NzUploadFile[]) {
    await firstValueFrom(
      this.shiftsService.addShiftAttachments(this.selectedShiftId, file),
    )
      .then(() => {
        this.getShiftAttachments();
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.nzMessage.error(this.translate.instant('general.error'));
      });
  }

  async deleteAttachments(fileId: string) {
    await firstValueFrom(
      this.shiftsService.deleteShiftAttachments(this.selectedShiftId, fileId),
    )
      .then(() => {
        this.getShiftAttachments();
        this.cdr.detectChanges();
      })
      .catch(() => {
        this.nzMessage.error(this.translate.instant('general.error'));
      });
  }
}

<div class="pt-2">
  <div class="row">
    <div class="col-9">
      {{ 'page.shifts.short_term_shift_takeover' | translate }}
    </div>
    <div class="col-3 toggle-container">
      <nz-switch
        [nzDisabled]="areInputsDisabled"
        [(ngModel)]="hasShiftBonus"
        [nzCheckedChildren]="checkedTemplate"
        (ngModelChange)="changeSpontaneousShiftBonus($event)"
        class="d-flex justify-content-end"
      ></nz-switch>
    </div>
  </div>

  <ng-container
    *ngIf="'mobile-shift-payroll-information' | featureFlagPure | async"
  >
    <br />
    <div class="row">
      <div class="col-9">
        {{ 'page.shifts.trainee_on_board' | translate }}
      </div>
      <div class="col-3 toggle-container">
        <nz-switch
          [nzDisabled]="areInputsDisabled"
          [(ngModel)]="traineeOnBoard"
          [nzCheckedChildren]="checkedTemplate"
          (ngModelChange)="changeTraineeOnBoard($event)"
          class="d-flex justify-content-end"
        ></nz-switch>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-12">
        <nz-select
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          [(ngModel)]="coveredFoodExpenses"
          (ngModelChange)="setCoveredFoodExpenses($event)"
          nzMode="multiple"
          [nzPlaceHolder]="'page.shifts.covered_food_expenses' | translate"
          [nzDisabled]="areInputsDisabled"
        >
          <nz-option
            [nzLabel]="
              'page.shifts.covered_food_expenses.' +
                CoveredFoodExpenseEnum.Breakfast | translate
            "
            [nzValue]="CoveredFoodExpenseEnum.Breakfast"
          ></nz-option>
          <nz-option
            [nzLabel]="
              'page.shifts.covered_food_expenses.' +
                CoveredFoodExpenseEnum.Lunch | translate
            "
            [nzValue]="CoveredFoodExpenseEnum.Lunch"
          ></nz-option>
          <nz-option
            [nzLabel]="
              'page.shifts.covered_food_expenses.' +
                CoveredFoodExpenseEnum.Dinner | translate
            "
            [nzValue]="CoveredFoodExpenseEnum.Dinner"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-duration-renderer',
  template: `
    <div>
      <span class="text-dark">
        {{ shiftDuration ? shiftDuration : '---' }}
      </span>
    </div>
  `,
  styles: [
    `
      span {
        line-height: 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationRendererComponent implements AgRendererComponent {
  public shiftDuration!: string | null;

  constructor(public readonly translate: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    this.shiftDuration = this.getDuration(params?.value);
  }

  refresh(params: ICellRendererParams) {
    this.shiftDuration = this.getDuration(params?.value);
    return true;
  }

  getDuration(hours: number) {
    if (!hours || typeof hours !== 'number') {
      return null;
    }
    const minusValue = hours < 0 ? '-' : '';
    const totalTimeInSeconds = Math.abs(hours);
    const hour = Number(String(totalTimeInSeconds).split('.')[0]);
    const minute = Math.round(
      (totalTimeInSeconds - Number(String(totalTimeInSeconds).split('.')[0])) *
        60,
    );
    let result = '';
    if (hour > 0) {
      result = `${hour} ${this.translate.instant('general.hour_short')}`;
      if (minute > 0) {
        result += ` ${minute} ${this.translate.instant(
          'general.minute_lowercase',
        )}`;
      }
    } else {
      result = `${minute} ${this.translate.instant(
        'general.minute_lowercase',
      )}`;
    }
    return minusValue + result;
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CertificateQualification,
  CountryQualification,
  DriverLicenseQualification,
  FurtherEducationQualification,
  LanguageQualification,
  LocationQualification,
  MedicalExaminationQualification,
  OtherQualification,
  ProcessedUserQualification,
  QualificationCategory,
  QualificationExportEntitiy,
  QualificationExportPayload,
  RouteQualification,
  SafetyCertificateQualification,
  TrainingQualification,
  UserQualification,
  VehicleQualification,
} from '@wilson/interfaces';
import { LocationsService, LocationsStoreService } from '@wilson/locations';
import { QualificationCategoriesService } from '@wilson/qualification-categories';
import { cloneDeep } from 'lodash';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { QualificationsUtilityService } from './qualifications-utility.service';

@Injectable()
export class QualificationsListService {
  constructor(
    private translate: TranslateService,
    private qualificationsUtilService: QualificationsUtilityService,
    private qualificationCategoriesService: QualificationCategoriesService,
    private readonly locationsService: LocationsService,
    private readonly locationStoreService: LocationsStoreService,
  ) {}

  prepareQualificationForRendering(
    userQualifications: UserQualification[],
  ): Observable<ProcessedUserQualification[]> {
    return this.qualificationCategoriesService
      .getQualificationCategories()
      .pipe(
        map((qualificationCategories: QualificationCategory[]) => {
          return userQualifications.map((qualification) => {
            const clonedQualification = cloneDeep(
              qualification,
            ) as ProcessedUserQualification;

            clonedQualification.name = this.getQualificationName(qualification);
            clonedQualification.status =
              this.qualificationsUtilService.getQualificationStatus(
                qualification,
              );

            const matchingQualificationCategory = this.findMatchingCategory(
              qualificationCategories,
              qualification,
            );
            if (matchingQualificationCategory) {
              clonedQualification.categoryRouteParams =
                matchingQualificationCategory.id;
              clonedQualification.qualificationCategoryName =
                matchingQualificationCategory.translatedName;
            }

            return clonedQualification;
          });
        }),
      );
  }

  prepareQualificationForExport(
    processedUserQualifications: ProcessedUserQualification[],
  ): QualificationExportPayload {
    const exportData: QualificationExportPayload = {
      qualifications: [],
      categories: [],
    };

    const categoryMap = new Map<string, QualificationExportEntitiy>();

    processedUserQualifications.forEach((puq) => {
      if (puq.name) {
        exportData.qualifications.push({
          id: puq.id,
          name: puq.name,
        });
      }

      if (!categoryMap.has(puq.qualificationCategoryId)) {
        categoryMap.set(puq.qualificationCategoryId, {
          id: puq.qualificationCategoryId,
          name: puq.qualificationCategoryName,
        });
      }
    });

    exportData.categories = Array.from(categoryMap.values());

    return exportData;
  }

  prepareRouteData(
    processedUsersQualifications: ProcessedUserQualification[],
  ): Observable<ProcessedUserQualification[]> {
    const qualificationsWithPreparedRouteData =
      processedUsersQualifications.map((qualification) => {
        if ((qualification as unknown as RouteQualification).routeId) {
          const routeQualification = qualification as RouteQualification;
          return combineLatest([
            this.locationsService.getGeoLocationsByIds(
              routeQualification.route.viaLocationIds,
            ),
            this.locationStoreService
              .getLocationFromCache(routeQualification.route.endLocationId)
              .pipe(take(1)),
            this.locationStoreService
              .getLocationFromCache(routeQualification.route.startLocationId)
              .pipe(take(1)),
          ]).pipe(
            map(([viaLocations, endLocation, startLocation]) => {
              routeQualification.route.viaLocations = viaLocations;
              routeQualification.route.endLocation = endLocation;
              routeQualification.route.startLocation = startLocation;
              return routeQualification as ProcessedUserQualification;
            }),
          );
        }
        return of(qualification);
      });

    return forkJoin(qualificationsWithPreparedRouteData);
  }

  private getQualificationName(qualification: UserQualification): string {
    if ((qualification as VehicleQualification).vehicleModelId) {
      return (qualification as VehicleQualification).vehicleModel.name;
    } else if ((qualification as CertificateQualification).certificateId) {
      return this.translate.instant(
        `certificate.${
          (qualification as CertificateQualification).certificate.name
        }`,
      );
    } else if ((qualification as CountryQualification).countryId) {
      return this.translate.instant(
        `country.${
          (qualification as CountryQualification).country.countryCode
        }`,
      );
    } else if ((qualification as LanguageQualification).languageId) {
      return this.translate.instant(
        `language.${
          (qualification as LanguageQualification).language.languageCode
        }`,
      );
    } else if ((qualification as LocationQualification).geoLocationId) {
      return (qualification as LocationQualification).geoLocation.name;
    } else if ((qualification as DriverLicenseQualification).driverLicenseId) {
      return this.translate.instant(
        `driverLicense.${
          (qualification as DriverLicenseQualification).driverLicense.name
        }`,
      );
    } else if (
      (qualification as FurtherEducationQualification).furtherEducationId
    ) {
      return this.translate.instant(
        `furtherEducation.${
          (qualification as FurtherEducationQualification).furtherEducation.name
        }`,
      );
    } else if ((qualification as TrainingQualification).trainingId) {
      return this.translate.instant(
        `training.${(qualification as TrainingQualification).training.name}`,
      );
    } else if (
      (qualification as MedicalExaminationQualification).medicalExaminationId
    ) {
      return this.translate.instant(
        `medicalExamination.${
          (qualification as MedicalExaminationQualification).medicalExamination
            .name
        }`,
      );
    } else if (
      (qualification as OtherQualification).otherQualificationSubCategoryId
    ) {
      return this.translate.instant(
        `otherQualification.${
          (qualification as OtherQualification).otherQualificationSubCategory
            .name
        }`,
      );
    } else if ((qualification as RouteQualification).route) {
      return (qualification as RouteQualification).route.name;
    } else if (
      (qualification as SafetyCertificateQualification).safetyCertificateId
    ) {
      return (qualification as SafetyCertificateQualification).safetyCertificate
        .name;
    }
  }

  private findMatchingCategory(
    qualificationCategories: QualificationCategory[],
    qualification: UserQualification,
  ): null | QualificationCategory {
    return qualificationCategories.find(
      (category: QualificationCategory) =>
        category.id === qualification.qualificationCategoryId,
    );
  }
}

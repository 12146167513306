import {
  Activity,
  ActivityRow,
  ActivityWithCategoryAndLocations,
  Shift,
  ShiftTemplate,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { TabEnum } from '@wilson/shift-timeline/services';

export class TimelineFetchUnassignedShiftsWithoutActivities {
  static readonly type =
    '[ShiftTimelineUnassignedRegion] Fetch Unassigned Shifts Without Activities';
}

export class TimelineUpdateUnassignedShiftsRegionWithoutActivities {
  static readonly type =
    '[ShiftTimelineUnassignedRegion] Update Unassigned Shifts Without Activities';
  constructor(public timeFrame: Interval) {}
}

export class BulkDeleteShiftActivitiesFromUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Delete Shift activities From Unassigned Region';
  constructor(public shiftId: string) {}
}

export class BulkAddShiftActivitiesToUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Add Shift activities To Unassigned Region';
  constructor(public shiftId: string, public activityRow: ActivityRow[]) {}
}

export class AddShiftWithoutActivitiesToUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Add Shift Without activities To Unassigned Region';
  constructor(
    public shift: Shift &
      WithPreparedAttributes & {
        id: string;
      },
  ) {}
}
export class AddShiftWithoutActivitiesToCellViewUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Add Shift Without activities To Unassigned Region';
  constructor(
    public shift: Shift &
      WithPreparedAttributes & {
        id: string;
      },
  ) {}
}

export class RemoveShiftWithoutActivitiesFromUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Remove Shift Without Activities From Unassigned Region';
  constructor(
    public payload: {
      shiftId: string;
    },
  ) {}
}

export class RemoveShiftWithoutActivitiesFromCellViewUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Remove Cell View Shift Without Activities From Unassigned Region';
  constructor(
    public payload: {
      shiftId: string;
    },
  ) {}
}

export class ToggleExpandUnassignedRegion {
  static readonly type = '[ShiftTimeline] Toggle Expand Unassigned Region';
  constructor(public payload: boolean) {}
}

export class UpdateShiftWithoutActivitiesFromUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Update Shift Without Activities From Unassigned Region';
  constructor(
    public shift: Shift &
      WithPreparedAttributes & {
        id: string;
      },
  ) {}
}

export class UpdateShiftWithoutActivitiesFromCellViewUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Update Cell View Shift Without Activities From Unassigned Region';
  constructor(
    public shift: Shift &
      WithPreparedAttributes & {
        id: string;
      },
  ) {}
}

export class AddShiftTemplateToSuggestionList {
  static readonly type =
    '[ShiftTimeline] Add Shift Template To Suggestion List';
  constructor(public shiftTemplate: ShiftTemplate | null) {}
}

export class TimelineUpdateUnassignedActivities {
  static readonly type =
    '[ShiftTimelineUnassignedRegion] Update Unassigned Activities';
  constructor(public timeFrame: Interval) {}
}

export class AddActivityToUnassignedRegion {
  static readonly type = '[ShiftTimeline] Add Activity To Unassigned Region';
  constructor(public activity: Activity) {}
}

export class RemoveActivityFromUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Remove Activity From Unassigned Region';
  constructor(public activity: Activity) {}
}

export class HideLoaderWhenLoadingUnassignedShiftsOrActivities {
  static readonly type =
    '[ShiftTimeline] Hide Loader When Loading Unassigned Shifts Or Activities';
  constructor(public payload: boolean) {}
}

export class TimelineUpsertActivityToUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Timeline Upsert Activity To Unassigned Region';
  constructor(
    public activity: ActivityWithCategoryAndLocations,
    public shiftId: string,
  ) {}
}

export class TimelineRemoveActivityFromUnassignedRegion {
  static readonly type =
    '[ShiftTimeline] Timeline Remove Activity From Unassigned Region';
  constructor(public activityId: string, public shiftId: string) {}
}

export class SetSelectedUnassignedRegion {
  static readonly type = '[ShiftTimeline] Set unassigned region';
  constructor(public payload: TabEnum) {}
}

export class FetchActivitiesForUnassignedShifts {
  static readonly type =
    '[ShiftTimeline] Fetch Activities For Unassigned Shifts';
  constructor(public shiftIds: string[]) {}
}
export class ClearShiftActivitiesRows {
  static readonly type =
    '[ShiftTimeline] Clear Unassigned Region Shift Activities';
}

export class ClearUnassignedShiftsWithoutActivities {
  static readonly type =
    '[ShiftTimeline] Clear Unassigned Shifts Without Activities';
}

export class ClearAllUnassignedActivitiesRelatedData {
  static readonly type =
    '[ShiftTimeline] Clear All Unassigned Activities Related Data';
}

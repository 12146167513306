import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faSearchMinus,
  faSearchPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { PDFDocumentProxy, PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'wilson-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, PdfViewerModule],
})
export class PdfPreviewComponent {
  @Input() pdfURL!: string;
  @Input() heightPx!: number;

  pdfCurrentPage = 1;
  pdfTotalPage = 1;
  pdfZoomLevel = 0.95;
  faSearchPlus = faSearchPlus;
  faSearchMinus = faSearchMinus;
  faAngleRight = faAngleRight;
  faAngleLeft = faAngleLeft;

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfTotalPage = pdf.numPages;
  }

  zoomIn() {
    this.pdfZoomLevel = this.pdfZoomLevel + 0.05;
  }

  zoomOut() {
    this.pdfZoomLevel = this.pdfZoomLevel - 0.05;
  }

  nextPage() {
    this.pdfCurrentPage = this.pdfCurrentPage + 1;
  }

  previousPage() {
    this.pdfCurrentPage = this.pdfCurrentPage - 1;
  }
}

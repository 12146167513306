<div>
  <p class="mb-1">
    {{ description }}
  </p>
  <nz-divider class="my-3"></nz-divider>

  <label class="form-text">
    {{ 'shift.page.move_shifts_forward' | translate }}/{{
      'shift.page.move_shifts_backward' | translate
    }}*
  </label>
  <nz-radio-group
    [(ngModel)]="moveDirection"
    nzButtonStyle="solid"
    class="d-block w-100 pe-3 text-center"
  >
    <label class="w-50" nz-radio-button [nzValue]="MoveDirectionType.Forward">
      <i class="fas fa-arrow-right me-1"></i>
      {{ 'shift.page.move_shifts_forward' | translate }}
    </label>
    <label class="w-50" nz-radio-button [nzValue]="MoveDirectionType.Backward">
      <i class="fas fa-arrow-left me-1"></i>
      {{ 'shift.page.move_shifts_backward' | translate }}
    </label>
  </nz-radio-group>

  <nz-divider class="my-3"></nz-divider>

  <div class="d-flex justify-content-between">
    <div class="w-100 me-3">
      <label class="form-text"> {{ 'unit.day.nn' | translate }}* </label>
      <nz-select
        [nzPlaceHolder]="'unit.day.nn' | translate"
        nzShowSearch
        nzAllowClear
        [(ngModel)]="days"
        (nzOnSearch)="search($event)"
      >
        <nz-option
          *ngFor="let value of dayOptions"
          [nzValue]="value"
          [nzLabel]="value"
        >
        </nz-option>
      </nz-select>
    </div>
    <div class="w-100 me-3">
      <label class="form-text"> {{ 'unit.hour.nn' | translate }}* </label>
      <nz-select
        [nzPlaceHolder]="'unit.hour.nn' | translate"
        nzShowSearch
        nzAllowClear
        [(ngModel)]="hours"
      >
        <nz-option
          *ngFor="let value of hourOptions"
          [nzValue]="value"
          [nzLabel]="value"
        >
        </nz-option>
      </nz-select>
    </div>
    <div class="w-100 me-3">
      <label class="form-text"> {{ 'unit.minute.nn' | translate }}* </label>
      <nz-select
        [nzPlaceHolder]="'unit.minute.nn' | translate"
        nzShowSearch
        nzAllowClear
        [(ngModel)]="minutes"
      >
        <nz-option
          *ngFor="let value of minuteOptions"
          [nzValue]="value"
          [nzLabel]="value"
        >
        </nz-option>
      </nz-select>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccountService } from '@wilson/account';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { OrganizationalUnit, Shift } from '@wilson/interfaces';
import { presetColors } from '@wilson/shared-components';
import { ShiftTemplateService } from '@wilson/shift-templates';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ShiftTemplateNameValidator } from './shift-template-name.validator';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { PipesModule } from '@wilson/pipes';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ColorPickerModule } from 'ngx-color-picker';
@Component({
  selector: 'wilson-save-as-template-dialog',
  templateUrl: './save-as-template-dialog.component.html',
  styleUrls: ['./save-as-template-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FeatureFlagsModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    PipesModule,
    NzSelectModule,
    ColorPickerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveAsTemplateDialogComponent implements OnInit {
  @Input() shift!: Shift;
  @Input() userId!: string;
  public organizationalUnits$: Observable<OrganizationalUnit[]>;
  public presetColors = presetColors;
  public form: FormGroup;
  public isNameAlreadyExists: boolean;

  constructor(
    private readonly accountService: AccountService,
    private readonly fb: FormBuilder,
    private readonly shiftTemplateService: ShiftTemplateService,
  ) {}

  public ngOnInit() {
    const templateName = `${this.shift?.name} Template`;
    this.form = this.fb.group({
      name: [
        templateName,
        Validators.required,
        ShiftTemplateNameValidator.createValidator(this.shiftTemplateService),
      ],
      colorCode: ['#60cc9f', Validators.required],
      description: [''],
      organizationalUnit: [this.shift?.organizationalUnit, Validators.required],
      organizationalUnitId: [
        this.shift?.organizationalUnitId,
        Validators.required,
      ],
    });
    this.organizationalUnits$ = this.accountService
      .getResolvedUser(this.userId)
      .pipe(
        switchMap((users) => {
          return of(
            users[0].accessibleOrganizationalUnits.sort((a, b) =>
              a.name.localeCompare(b.name),
            ),
          );
        }),
      );
  }

  public setOrganizationalUnit(orgUnit: OrganizationalUnit) {
    this.form.get('organizationalUnitId').patchValue(orgUnit?.id ?? null);
  }

  public compareOrgUnitFn = (
    selectedOrgUnit: OrganizationalUnit,
    orgUnit: OrganizationalUnit,
  ) =>
    selectedOrgUnit && orgUnit
      ? selectedOrgUnit.id === orgUnit.id
      : selectedOrgUnit === orgUnit;
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Activity, OperationStatus } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivityEndRendererService } from './activity-end-renderer.service';
import {
  DeviationTime,
  DeviationTimePipe,
} from '@wilson/service-deviation/pipes';
import { AffectedStop } from '@wilson/service-deviation/interfaces';

export interface ActivityEndWithDeviationRendererValue {
  hideCancelledActivities?: boolean;
}
@Component({
  selector: 'wilson-activity-end-with-deviation-renderer',
  template: `
    <wilson-activity-time-and-location-with-deviation
      [date]="endDate"
      [hour]="endHour"
      [location]="location"
      [deviation]="deviation"
    ></wilson-activity-time-and-location-with-deviation>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEndWithDeviationRendererComponent
  implements ICellRendererAngularComp
{
  public endDate: string;
  public endHour: string;
  public location!: string;
  public deviation!: DeviationTime;

  constructor(
    private readonly activityEndRendererService: ActivityEndRendererService,
    private readonly deviationTimePipe: DeviationTimePipe,
  ) {}

  async agInit(
    params: ICellRendererParams<
      Activity,
      ActivityEndWithDeviationRendererValue
    >,
  ) {
    this.setData(params);
  }

  refresh(
    params: ICellRendererParams<
      Activity,
      ActivityEndWithDeviationRendererValue
    >,
  ) {
    this.setData(params);
    return true;
  }

  private setData(
    params: ICellRendererParams<
      Activity,
      ActivityEndWithDeviationRendererValue
    >,
  ) {
    const { data, value } = params;

    if (
      value.hideCancelledActivities &&
      data.operationalStatus === OperationStatus.Cancelled
    ) {
      return;
    }

    this.endDate = this.activityEndRendererService.getFormattedDate(data);
    this.endHour = this.activityEndRendererService.getFormattedHour(data);
    this.location = this.activityEndRendererService.getEndLocation(data);
    this.deviation = this.deviationTimePipe.transform(
      data.serviceDeviations,
      AffectedStop.Arrival,
      data.endDatetime,
    );
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '@wilson/interfaces';
import { FindStaffButtonComponent } from '@wilson/non-domain-specific/shift-helpers/assignments';
import { ActivitiesPipesModule } from '@wilson/activities/pipes';
import { SharedComponentsModule } from '@wilson/shared-components';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'wilson-shift-assigned-user-identity',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SharedComponentsModule,
    ActivitiesPipesModule,
    FindStaffButtonComponent,
  ],
  templateUrl: './shift-assigned-user-identity.component.html',
  styleUrl: './shift-assigned-user-identity.component.scss',
})
export class ShiftAssignedUserIdentityComponent {
  @Input() user: User | null | undefined;
  @Input() canOpenModal = true;
  @Input() renderReassignButton = false;
  @Output()
  clicked = new EventEmitter();

  openDriverDetail() {
    if (!this.user?.id) {
      return;
    }
    window.open('/employees/' + this.user.id, '_blank');
  }
}

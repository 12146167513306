import { Pipe, PipeTransform } from '@angular/core';
import { QualificationCategory } from '@wilson/interfaces';
import { map, Observable } from 'rxjs';
import { QualificationCategoriesService } from './qualification-categories.service';

@Pipe({
  name: 'toQualificationCategory',
})
export class ToQualificationCategoryPipe implements PipeTransform {
  constructor(
    private qualificationCategoriesService: QualificationCategoriesService,
  ) {}

  transform(id: string): Observable<QualificationCategory | undefined> {
    return this.qualificationCategoriesService
      .getQualificationCategories()
      .pipe(
        map((qualificationCategories: QualificationCategory[]) => {
          return qualificationCategories.find(
            (qualificationCategory) => qualificationCategory.id === id,
          );
        }),
      );
  }
}

import { Injectable } from '@angular/core';
import { isOneActivityStarted } from '@wilson/activities/pipes';
import { areAllActivitiesFinished } from '@wilson/activities/pipes';
import { Shift } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class OpenFindStaffButtonHelperService {
  getCanAssignShift(shift: Shift): boolean {
    return (
      !isOneActivityStarted(shift?.activities) &&
      !areAllActivitiesFinished(shift?.activities)
    );
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EventType, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  InitializeAzureSSOFlow,
  SetCurrentlyReplacingAzureTokens,
} from '@wilson/auth/core';
import { FeatureFlagsService } from '@wilson/feature-flags';
import { LaunchDarklyUserKeys } from '@wilson/interfaces';
import { TitleManagerService } from '@wilson/title-manager';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'wilson-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly titleManager: TitleManagerService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly notification: NzNotificationService,
  ) {}

  ngOnInit() {
    this.translateService.addLangs(['de-DE', 'en-US', 'fr-FR', 'pl-PL']);

    this.featureFlags.initialize(LaunchDarklyUserKeys.WilsonPortal);
    this.titleManager.init();

    this.addAzureAuthListener();
    console.info(`Version: `, environment.releaseVersion);
  }

  /**
   * Handles Azure SSO for web portal.
   * - Listens for specific NavigationStart events with 'accessToken' and 'refreshToken'.
   * - Dispatches action and validates the extracted tokens.
   * - Invokes SSO flow and navigates to home, or shows a notification on failure.
   * Note: Uses URLSearchParams due to router not giving us the full URL
   */

  private addAzureAuthListener() {
    this.router.events
      .pipe(
        filter(
          (event: NavigationStart) =>
            event.type === EventType.NavigationStart &&
            event.url &&
            ((event.url.includes('accessToken') &&
              event.url.includes('refreshToken')) ||
              (event.url.includes('azureErrorInternal') &&
                event.url.includes('azureErrorExternal'))),
        ),
        take(1),
      )
      .subscribe(async (event) => {
        const urlSearchParams = new URLSearchParams(event.url.split('?')[1]);
        try {
          this.store.dispatch(new SetCurrentlyReplacingAzureTokens(true));
          const accessToken = urlSearchParams.get('accessToken');
          const refreshToken = urlSearchParams.get('refreshToken');
          if (!accessToken || !refreshToken) {
            throw new Error('Invalid access or refreshtoken!');
          }
          await firstValueFrom(
            this.store.dispatch(
              new InitializeAzureSSOFlow(accessToken, refreshToken),
            ),
          );
          this.router.navigate(['/']);
        } catch (e) {
          this.store.dispatch(new SetCurrentlyReplacingAzureTokens(false));
          const azureErrorInternal = urlSearchParams.get('azureErrorInternal');
          const azureErrorExternal = urlSearchParams.get('azureErrorExternal');
          console.error('azureError:', azureErrorInternal, azureErrorExternal);
          this.notification.error(
            this.translateService.instant('auth.azure_sso_failed.title'),
            `${this.translateService.instant(
              'auth.azure_sso_failed.content',
            )} ${azureErrorExternal}`,
          );
        }
      });
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ManyEntity } from '@wilson/base';
import { Client } from '@wilson/clients/interfaces';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { BatchPayload, ClientWhereInput } from '@wilson/interfaces';
import { stringify } from 'qs';
import { firstValueFrom, Observable } from 'rxjs';

export interface ClientFindAllOptions {
  where?: ClientWhereInput;
  relations?: ClientInclude[];
  limit?: number;
  offest?: number;
  order?: number;
}
export type ClientInclude =
  | 'agreements'
  | 'invoices'
  | 'organizationalUnit'
  | 'clientPartnership'
  | 'address';
export interface ClientFindOneOptions {
  relations?: ClientInclude[];
}

export interface BulkCreateClientDto {
  items: CreateClientDto[];
}
export interface CreateClientDto {
  name: string;
  email: string;
  description?: string;
  invoiceAddress?: string;
  taxId?: string;
  clientNumber?: string;
  contactPersonProvider?: string;
  contactPersonClient?: string;
  clientPartnership?: ClientPartnershipDto;
  invoiceDefaultText?: string;
  bankAccountId?: string;
  invoiceText?: string;
  invoiceLogo?: string | null;
  debtorNumber?: string;
  address: CreateAddressDto;
  discountDays?: number;
  discountPercentage?: number;
  daysToPayInvoice?: number;
  overallDiscountPercentage?: number;
  vendorNumberAtCustomer?: string;
  eInvoiceBuyerReference?: string;
  paymentMethod?: PaymentMethod;
}
export interface ClientPartnershipDto {
  partnerShipId: string;
  canEditActivity?: boolean;
  canSplitActivity?: boolean;
}
export interface CreateAddressDto {
  street: string;
  postCode: string;
  city: string;
  state: string;
  country: string;
}
export type PaymentMethod =
  | 'sepaCreditTransfer'
  | 'sepaDirectDebitTransfer'
  | 'cash';

export interface UpdateClientDto extends Partial<CreateClientDto> {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientsV2Service {
  /**
   * @deprecated Necessary in order to work in legacy usecases (e.g. clients-form.service)
   */
  public readonly http!: HttpClient;

  private readonly path = 'v2/clients';

  constructor(
    public readonly httpService: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  /**
   * @deprecated Use `findAll` instead.
   */
  public getMany: (
    options?: ClientFindAllOptions,
  ) => Observable<ManyEntity<Client>> = this.findAll.bind(this);
  /**
   * @deprecated Use `findAll` instead.
   */
  public getAll: (
    options?: ClientFindAllOptions,
  ) => Observable<ManyEntity<Client>> = this.findAll.bind(this);

  public findAll(
    options?: ClientFindAllOptions,
  ): Observable<ManyEntity<Client>> {
    const params = stringify(options);
    return this.httpService.get<ManyEntity<Client>>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  /**
   * @deprecated Use `findOne` instead.
   */
  public get = this.findOne.bind(this);

  public findOne(
    entityId: NonNullable<Client['id']>,
    options?: ClientFindOneOptions,
  ) {
    const params = stringify(options);
    return this.httpService.get<Client>(
      `${this.config.host}/${this.path}/${entityId}?${params}`,
    );
  }

  /**
   * @deprecated Use `create` instead.
   */
  public add = this.create.bind(this);

  /**
   * @deprecated Use `create` instead.
   */
  public addMany = this.create.bind(this);

  public create(createClient: CreateClientDto) {
    return this.httpService.post<Client[]>(
      `${this.config.host}/${this.path}`,
      createClient,
    );
  }

  public update(updateClient: UpdateClientDto) {
    const { id, ...payload } = updateClient;
    return this.httpService.patch<Client>(
      `${this.config.host}/${this.path}/${id}`,
      payload,
    );
  }

  /**
   * @deprecated Use `delete` instead.
   */
  public remove = this.delete.bind(this);

  public delete(entityId: NonNullable<Client['id']>) {
    return this.httpService.delete<BatchPayload[]>(
      `${this.config.host}/${this.path}/${entityId}`,
    );
  }

  addInvoiceLogo(file: File, clientId: string) {
    const formData = new FormData();
    formData.append('file', file);
    return firstValueFrom(
      this.httpService.patch(
        `${this.config.host}/${this.path}/${clientId}`,
        formData,
      ),
    );
  }
}

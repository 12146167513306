import { Activity } from '@wilson/interfaces';
import {
  AffectedStop,
  ServiceDeviation,
} from '@wilson/service-deviation/interfaces';
import { addMinutes } from 'date-fns';

interface DepartureDeviation {
  startDatetime: string;
  departureDeviation: ServiceDeviation | undefined;
}

export function findEarliestDepartureBasedOnDeviationTime(
  activities: Activity[] | null,
): DepartureDeviation | undefined | null {
  if (activities) {
    const clonedActivities: {
      startDatetime: string;
      departureDeviation: ServiceDeviation | undefined;
    }[] = activities.map((activity) => ({
      startDatetime: activity.startDatetime,
      departureDeviation: activity.serviceDeviations?.find(
        (deviation: ServiceDeviation) =>
          deviation.affectedStop === AffectedStop.Departure,
      ),
    }));

    clonedActivities.sort((a, b) => {
      const startDateOfA = a.departureDeviation
        ? addMinutes(
            new Date(a.startDatetime),
            a.departureDeviation.deviationInMinutes,
          ).toISOString()
        : a.startDatetime;

      const startDateOfB = b.departureDeviation
        ? addMinutes(
            new Date(b.startDatetime),
            b.departureDeviation.deviationInMinutes,
          ).toISOString()
        : b.startDatetime;

      return startDateOfA.localeCompare(startDateOfB);
    });

    return clonedActivities[0];
  } else {
    return undefined;
  }
}

import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { ColumnState } from 'ag-grid-community';
import { SetColumnSetting } from './columns.action';

interface AgGridColumnSettingsModel {
  settings: {
    [userId in string]: {
      [gridId in string]: ColumnState[];
    };
  };
}

const defaults: AgGridColumnSettingsModel = {
  settings: {},
};

@State<AgGridColumnSettingsModel>({
  name: 'AgGridColumnSettings',
  defaults,
})
@Injectable()
export class AgGridColumnSettingsState {
  static columnStates({ userId, gridId }: { userId: string; gridId: string }) {
    return createSelector(
      [AgGridColumnSettingsState],
      (state: AgGridColumnSettingsModel) => {
        return state.settings[userId]?.[gridId];
      },
    );
  }

  @Action(SetColumnSetting)
  setColumnSetting(
    ctx: StateContext<AgGridColumnSettingsModel>,
    { payload }: SetColumnSetting,
  ) {
    ctx.patchState({
      settings: {
        [payload.userId]: {
          [payload.gridId]: payload.columnStates,
        },
      },
    });
  }
}

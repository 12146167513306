import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProviderProposalResponse } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-provider-answer-status-renderer',
  template: `
    <ng-container [ngSwitch]="status">
      <div class="text-dark" *ngSwitchCase="ProviderProposalResponse.Open">
        <span class="px-3 badge rounded-pill badge-secondary-dark">
          {{ 'wilson.share.status.open' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ProviderProposalResponse.Accepted">
        <span class="px-3 badge rounded-pill badge-primary-dark">
          {{ 'wilson.share.status.accepted' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ProviderProposalResponse.Declined">
        <span class="px-3 badge rounded-pill badge-danger">
          {{ 'wilson.share.status.declined' | translate }}
        </span>
      </div>
      <div
        class="text-dark"
        *ngSwitchCase="ProviderProposalResponse.ExpiredDeclined"
      >
        <span class="px-3 badge rounded-pill badge-danger">
          {{ 'wilson.share.expired' | translate }}
        </span>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderAnswerStatusRendererComponent
  implements ICellRendererAngularComp
{
  ProviderProposalResponse = ProviderProposalResponse;
  status!: ProviderProposalResponse;

  agInit(params: ICellRendererParams): void {
    this.status = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.status = params.value;
    return true;
  }
}

import { Injectable } from '@angular/core';
import { StateContext } from '@ngxs/store';
import {
  AbsenceWithCategory,
  ResolvedShiftValidation,
  ShiftDispositionFilters,
  ShiftInDateRangeDto,
  ShiftWithActivitiesWithLocations,
  User,
  FindConditions,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { endOfDay, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';
import {
  FetchAllDataInView,
  UpdateViewableRowIndex,
} from './shift-disposition.actions';
import { ShiftDispositionStateModel } from './shift-disposition.state';
import { ShiftDispositionsDataService } from './shift-dispositions-data.service';

type Filters = Pick<
  ShiftDispositionStateModel,
  | 'orgUnitsFilter'
  | 'shiftCategoryIdsFilter'
  | 'publicationStatusFilter'
  | 'absenceCategoriesFilter'
  | 'groupsFilter'
  | 'shiftPlansFilter'
  | 'userSearchTerm'
  | 'userRoleIdsFilter'
  | 'userIdsFilter'
>;

@Injectable({
  providedIn: 'root',
})
export class ShiftDispositionStateHandlerService {
  static calculateViewableUsers(
    sortedUsers: User[],
    largestViewedRowIndex: number,
    viewableRowCount: number,
  ) {
    const nextViewableEndIndex =
      largestViewedRowIndex === 0
        ? viewableRowCount
        : largestViewedRowIndex + viewableRowCount + 10; // Adding 10 gives a better user experience when the user scrolls

    return sortedUsers.slice(0, nextViewableEndIndex);
  }

  constructor(
    private shiftDispositionsDataService: ShiftDispositionsDataService,
    private shiftsService: ShiftsService,
  ) {}

  handleAssignedShiftsAndAbsencesResponse(
    assignedShifts:
      | {
          shift: ShiftWithActivitiesWithLocations;
        }[]
      | ResolvedShiftValidation[],
    assignedAbsences: AbsenceWithCategory[],
    patchState: StateContext<ShiftDispositionStateModel>['patchState'],
  ) {
    const processedData = this.shiftDispositionsDataService.processAssignables(
      assignedShifts,
      assignedAbsences,
    );
    patchState({
      viewableAssignedTasks: processedData.assignedTasks,
      viewableAssignedShifts: processedData.assignedShifts,
      viewableAssignedAbsences: processedData.assignedAbsences,
    });
  }

  createFetchAssignedShiftsRequest(
    state: ShiftDispositionStateModel,
    fetchForAllUsers: boolean,
  ) {
    const startDateStartOfDay = startOfDay(state.dateRange[0]);
    const endDateEndOfDay = endOfDay(state.dateRange[1]);
    const userIdLookUp: FindConditions<Pick<ShiftInDateRangeDto, 'userId'>> =
      fetchForAllUsers
        ? this.createUserIdLookupForAllUsers(state.sortedUsers)
        : this.createUserIdLookUp(
            state.userIdsFilter,
            state.sortedUsers,
            state.largestViewedRowIndex,
            state.viewableRowCount,
          );

    const lookup: FindConditions<ShiftInDateRangeDto> = {
      startDate: startDateStartOfDay.toISOString(),
      endDate: endDateEndOfDay.toISOString(),
      ...userIdLookUp,
    };
    const relations = [
      `activities.endLocation`,
      `activities.startLocation`,
      `activities`,
    ];

    return this.shiftsService.getInDateRange(lookup, relations);
  }

  createUserIdLookUp(
    userIdsFilter: string[],
    users: User[],
    largestViewedRowIndex: number,
    viewableRowCount: number,
  ) {
    const lookup: FindConditions<Pick<ShiftInDateRangeDto, 'userId'>> = {
      userId: userIdsFilter.length
        ? userIdsFilter
        : ShiftDispositionStateHandlerService.calculateViewableUsers(
            users,
            largestViewedRowIndex,
            viewableRowCount,
          ).map((user) => user.id),
    };

    return lookup;
  }

  createUserIdLookupForAllUsers(users: User[]) {
    const lookup: FindConditions<Pick<ShiftInDateRangeDto, 'userId'>> = {
      userId: users.map((user) => user.id),
    };

    return lookup;
  }

  handleFiltersChangeEvent(
    payload: Partial<ShiftDispositionFilters>,
    currentFilters: Filters,
    largestViewedRowIndex: number,
    userListLength: number,
    patchState: (val: Partial<ShiftDispositionStateModel>) => void,
  ): UpdateViewableRowIndex | FetchAllDataInView | null {
    const newFilters: Filters = {
      orgUnitsFilter: payload.orgUnits || [],
      shiftCategoryIdsFilter: payload.shiftCategoryIds || [],
      publicationStatusFilter: payload.publicationStatus || [],
      absenceCategoriesFilter: payload.absenceCategories || [],
      groupsFilter: payload.groups || [],
      shiftPlansFilter: payload.shiftPlans || [],
      userSearchTerm: payload.searchTerm || '',
      userRoleIdsFilter: payload.userRoleIds || [],
      userIdsFilter: payload.userIds || [],
    };

    patchState(newFilters);

    if (!isEqual(newFilters, currentFilters)) {
      const maximumScrollableIndex = userListLength - 1;

      if (largestViewedRowIndex < maximumScrollableIndex) {
        // We need to filter through data for all users not just the viewable ones
        return new UpdateViewableRowIndex({ index: maximumScrollableIndex });
      } else {
        return new FetchAllDataInView();
      }
    } else {
      return null;
    }
  }
}

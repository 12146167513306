<nz-drawer
  [nzVisible]="true"
  [nzMask]="showMask"
  nzPlacement="right"
  [nzTitle]="'page.shift_timeline.hotel_stay.drawer.title' | translate"
  [nzFooter]="drawerFooter"
  [nzClosable]="false"
  (nzOnClose)="closeDrawer()"
>
  <ng-container *nzDrawerContent>
    <wilson-timeline-stays-drawer-form
      [isUserFieldReadOnly]="isUserFieldReadOnly"
      [prefillValues]="
        staysDrawerHelperService.preparePrefillEditStayObject(stayToEdit)
      "
      [sector]="sector"
      [showCancelledStatus]="true"
      (failedToFillForm)="failedToFillForm.emit()"
      (isInvalid)="isFormInvalidSignal.set($event)"
      (value)="formValueSignal.set($event)"
      (handleInvoice)="handleInvoice.emit($event)"
      (handleAttachments)="handleAttachments.emit($event)"
    ></wilson-timeline-stays-drawer-form>
  </ng-container>

  <ng-template #drawerFooter>
    <div class="drawer__footer">
      <button
        (click)="closeDrawer()"
        nz-button
        nzType="default"
        class="w-100"
        [disabled]="disableButtons"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <nz-button-group class="w-100">
        <button
          [nzLoading]="isSaving"
          [disabled]="isFormInvalidSignal() || disableButtons"
          (click)="emitConfirmEvent()"
          nz-button
          nzType="primary"
          class="w-100"
        >
          {{ 'general.accept' | translate }}
        </button>
        <button
          nz-button
          nz-dropdown
          [disabled]="isFormInvalidSignal() || disableButtons"
          nzType="primary"
          nzTrigger="click"
          [nzDropdownMenu]="action"
        >
          <i class="fas fa-angle-down"></i>
        </button>
        <nz-dropdown-menu #action="nzDropdownMenu">
          <ul nz-menu>
            <li
              class="fw-bold"
              nz-menu-item
              [nzDisabled]="disableButtons"
              (click)="emitConfirmAndCreateReimbursement()"
            >
              {{
                'page.shift_timeline.stay.save_and_create_reimbursement'
                  | translate
              }}
            </li>
            <li
              class="fw-bold"
              nz-menu-item
              [nzDisabled]="disableButtons"
              (click)="cancelStay()"
            >
              {{ 'page.stays.cancel_stay' | translate }}
            </li>
            <li
              class="fw-bold"
              nz-menu-item
              [nzDisabled]="disableButtons"
              (click)="cancelStayAndGeneratePdf()"
            >
              {{ 'page.stays.cancel_stay_and_notify_hotel' | translate }}
            </li>
            <li
              class="fw-bold"
              nz-menu-item
              [nzDisabled]="disableButtons"
              (click)="performDelete()"
            >
              {{ 'page.stays.delete_stay' | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-button-group>
    </div>
  </ng-template>
</nz-drawer>

import { Injectable } from '@angular/core';
import { ShiftTemplate } from '@wilson/interfaces';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ShiftTemplateService } from './shift-template.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftTemplateStoreService {
  private shiftTemplatesSubject = new BehaviorSubject<ShiftTemplate[]>([]);
  private isLoadingSubject = new BehaviorSubject(true);

  get shiftTemplates$() {
    return this.shiftTemplatesSubject.asObservable();
  }

  get isLoading$() {
    return this.isLoadingSubject.asObservable();
  }

  constructor(private readonly shiftTemplateService: ShiftTemplateService) {}

  findAllShiftTemplates() {
    this.isLoadingSubject.next(true);
    this.shiftTemplateService
      .getAll({
        where: {
          organizationalUnitIds: [],
        },
        relations: [
          'organizationalUnit',
          'shiftCategory',
          'createdBy',
          'shiftTemplateActivityTemplates',
          'shiftTemplateActivityTemplates.activityTemplate',
          'shiftTemplateActivityTemplates.activityTemplate.startLocation',
          'shiftTemplateActivityTemplates.activityTemplate.endLocation',
        ],
        limit: 0,
      })
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
      .subscribe((shiftTemplates: ShiftTemplate[]) => {
        shiftTemplates.sort((a, b) => a.name.localeCompare(b.name));
        this.shiftTemplatesSubject.next(shiftTemplates);
      });
  }
}

<div class="segmented-progress-bar">
  <div class="segmented-progress-bar-inner">
    <div
      *ngFor="let segment of segments"
      [style]="{
        'background-color': segment.color || 'blue' | antColor,
        width: segment.value + '%'
      }"
      [nzTooltipTitle]="
        segment.absoluteValue +
        ' ' +
        segment.label +
        ' (' +
        segment.value.toFixed() +
        '%)'
      "
      nz-tooltip
      class="segmented-progress-bar-value"
    ></div>
  </div>
  <div class="segmented-progress-bar-sum">{{ segmentSum.toFixed() }}%</div>
</div>

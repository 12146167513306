<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    #wageDataPanel
    [nzHeader]="shiftAttachmentsSectionHeader"
    [nzActive]="isExpandedOnOpen"
    [nzShowArrow]="false"
    class="collapse-panel"
    [nzExtra]="shiftAttachmentsCollapseIcon"
  >
    <wilson-shift-attachments
      (updatedAttachments)="updatedAttachments($event)"
      (deleteAttachments)="deleteAttachments($event)"
      [shift]="selectedShift"
      [showTitle]="false"
      [attachments]="shiftAttachments$ | async"
      [showAllAttachments]="true"
      [showAllButtonAndDrawer]="false"
    ></wilson-shift-attachments>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #shiftAttachmentsSectionHeader>
  <span class="header-text">
    {{ 'shift_drawer.shift_attachments' | translate }}
  </span>
  <ng-container *ngIf="shiftAttachments$ | async as shiftAttachments">
    <span class="header-text"> ({{ shiftAttachments?.length }}) </span>
  </ng-container>
</ng-template>

<ng-template #shiftAttachmentsCollapseIcon>
  <i
    class="fas fa-chevron-up"
    [ngClass]="wageDataPanel.nzActive ? 'rotate-180' : 'rotate-0'"
  ></i>
</ng-template>

<nz-divider class="my-3"></nz-divider>

import { Injectable } from '@angular/core';
import { Absence } from '@wilson/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AbsenceDrawerHelperService {
  private actualAbsenceSubject = new BehaviorSubject<Absence | null>(null);
  private isAbsenceDrawerVisibleSubject = new BehaviorSubject(false);
  private selectedAbsenceIdSubject = new BehaviorSubject('');

  public actualAbsence$ = this.actualAbsenceSubject.asObservable();
  public isAbsenceDrawerVisible$ =
    this.isAbsenceDrawerVisibleSubject.asObservable();
  public selectedAbsenceId$ = this.selectedAbsenceIdSubject.asObservable();

  setActualAbsence(absence: Absence | null) {
    this.actualAbsenceSubject.next(absence);
  }

  toggleAbsenceDrawer(isVisible: boolean) {
    this.isAbsenceDrawerVisibleSubject.next(isVisible);
  }

  setSelectedAbsenceId(id: string) {
    this.selectedAbsenceIdSubject.next(id);
  }
}

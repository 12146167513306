import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { MatomoTracker } from 'ngx-matomo-client';
import { DefaultActivityCategory } from '@wilson/interfaces';
import { EventCategory } from '@wilson/matomo';
import { ActivityInsertionService } from '../../services/activity-insertion/activity-insertion.service';
import { TotalPushableActivitiesPipe } from '../../pipes/total-pushable-activities/total-pushable-activities.pipe';
import { TotalPullableActivitiesPipe } from '../../pipes/total-pullable-activities/total-pullable-activities.pipe';
import { ActivityLocationFormCorrectionsService } from '../../services/activity-location-form-corrections/activity-location-form-corrections.service';
import { ActivityPushPullService } from '../../services/activity-push-pull/activity-push-pull.service';
import { ActivityExtensionService } from '../../services/activity-extension/activity-extension.service';

@Component({
  selector: 'wilson-corrective-action-bar',
  templateUrl: './corrective-action-bar.component.html',
  styleUrls: ['./corrective-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrectiveActionBarComponent {
  @Input() newOrSeries!: boolean;
  @Input() shouldUpdateLocation!: boolean;
  @Input() shouldUpdateTime!: boolean;
  @Input() timeGapDifference!: number;
  @Input() hasTimeOverlap!: boolean;
  @Input() activityFormArray!: FormArray;
  @Input() currentIndex!: number;
  @Input() isShiftSeries!: boolean;
  @Input() displayLocations!: boolean;
  @Input() areActivitiesEditable!: boolean;
  @Input() canEditServiceActivity!: boolean;

  public faChevronDown = faChevronDown;
  public isTimePopOverVisible = false;
  public isLocationPopOverVisible = false;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private activityLocationFormCorrectionsService: ActivityLocationFormCorrectionsService,
    private readonly activityPushPullService: ActivityPushPullService,
    private readonly activityInsertionService: ActivityInsertionService,
    private readonly activityExtensionService: ActivityExtensionService,
    private readonly tracker: MatomoTracker,
    private readonly totalPushableActivitiesPipe: TotalPushableActivitiesPipe,
    private readonly totalPullableActivitiesPipe: TotalPullableActivitiesPipe,
  ) {}

  changeEndLocationOfStartActivity() {
    this.resetVisibility();
    this.activityLocationFormCorrectionsService.changeEndLocationOfFirstActivity(
      this.activityFormArray,
      this.currentIndex,
    );
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-location-fix',
    );

    this.cdRef.detectChanges();
  }

  changeStartLocationOfEndActivity() {
    this.resetVisibility();
    this.activityLocationFormCorrectionsService.changeStartLocationOfNextActivity(
      this.activityFormArray,
      this.currentIndex,
    );
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-location-fix',
    );

    this.cdRef.detectChanges();
  }

  handlePushActivity() {
    this.resetVisibility();
    this.activityPushPullService.pushActivity(
      this.activityFormArray,
      this.currentIndex,
      this.timeGapDifference,
    );
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-move-previous-activity',
    );
    this.cdRef.detectChanges();
  }

  handlePullActivity() {
    this.resetVisibility();
    this.activityPushPullService.pullActivity(
      this.activityFormArray,
      this.currentIndex + 1,
      this.timeGapDifference,
    );
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-move-following-activity',
    );
    this.cdRef.detectChanges();
  }

  async handleInsertActivity() {
    this.resetVisibility();
    await this.activityInsertionService.addActivity({
      insertIndex: ++this.currentIndex,
      activityFormArray: this.activityFormArray,
      isShiftSeries: this.isShiftSeries,
      setEndLocationAndTimeToNextActivity: true,
      prefilledActivityCategoryName:
        DefaultActivityCategory.ActivityInterruption,
    });
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-insert-activity',
    );
  }

  handleProlongActivityEndTime() {
    this.resetVisibility();
    this.activityExtensionService.prolongActivityEndTime(
      this.activityFormArray,
      this.currentIndex,
      this.timeGapDifference,
    );
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-extend-previous-activity',
    );
    this.cdRef.detectChanges();
  }

  handleProlongActivityStartTime() {
    this.resetVisibility();
    this.activityExtensionService.prolongActivityStartTime(
      this.activityFormArray,
      this.currentIndex,
      this.timeGapDifference,
    );
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-extend-previous-activity',
    );
    this.cdRef.detectChanges();
  }

  handlePushActivities() {
    this.resetVisibility();
    const totalPushableActivities = this.totalPushableActivitiesPipe.transform(
      this.activityFormArray,
      this.currentIndex,
    );
    for (let index = 0; index < totalPushableActivities; index++) {
      this.activityPushPullService.pushActivity(
        this.activityFormArray,
        this.currentIndex - index,
        this.timeGapDifference,
      );
    }
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-move-all-previous-activities',
    );
    this.cdRef.detectChanges();
  }

  handlePullActivities() {
    this.resetVisibility();
    const totalPullableActivities = this.totalPullableActivitiesPipe.transform(
      this.activityFormArray,
      this.currentIndex,
    );
    for (let index = 1; index <= totalPullableActivities; index++) {
      this.activityPushPullService.pullActivity(
        this.activityFormArray,
        this.currentIndex + index,
        this.timeGapDifference,
      );
    }
    this.tracker.trackEvent(
      EventCategory.PortalShift,
      'shift-edit-gap-move-all-following-activities',
    );
    this.cdRef.detectChanges();
  }

  private resetVisibility() {
    this.isTimePopOverVisible = false;
  }
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { StayAttachment } from '@wilson/interfaces';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'wilson-stay-attachments',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzUploadModule,
    FontAwesomeModule,
    NzModalModule,
  ],
  templateUrl: './stay-attachments.component.html',
  styleUrl: './stay-attachments.component.scss',
})
export class StayAttachmentsComponent {
  @Input() set stayAttachments(attachments: StayAttachment[]) {
    this.fileList = [];
    attachments.forEach((attachment) => {
      this.fileList.push({
        name: attachment.filename,
        uid: attachment.id,
        status: 'done',
        url: attachment.presignedUrl,
      });
    });
  }
  @Input() isSingleSelection = false;

  @Output() updatedAttachments = new EventEmitter<NzUploadFile[]>();

  faFileUpload = faFileUpload;
  protected fileList: NzUploadFile[] = [];

  constructor(
    private readonly nzMessageService: NzMessageService,
    private readonly translate: TranslateService,
    private readonly modal: NzModalService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  protected beforeUpload = (file: NzUploadFile): Observable<boolean> => {
    return of(file).pipe(
      switchMap((file) => {
        const allowedMaxFileSize = 50;
        const isValidFileSize =
          file?.size && file.size / 1024 / 1024 < allowedMaxFileSize;
        if (!isValidFileSize) {
          this.nzMessageService.error(
            this.translate.instant('page.stay.upload.max_size'),
          );
          return of(false);
        } else {
          return this.handleFileUpload(file);
        }
      }),
    );
  };

  protected handleFileUpload = (file: NzUploadFile): Observable<boolean> => {
    return new Observable((observer) => {
      if (this.isSingleSelection) {
        if (this.fileList.length === 1) {
          this.modal.confirm({
            nzTitle: this.translate.instant('general.warning'),
            nzContent: this.translate.instant(
              'page.stay.upload.warning_message',
            ),
            nzOnOk: () => {
              this.fileList = [file];
              this.cdRef.detectChanges();
              this.updatedAttachments.emit(this.fileList);
              observer.next(false);
              observer.complete();
            },
            nzOnCancel: () => {
              observer.next(false);
              observer.complete();
            },
          });
        } else {
          this.fileList = [file];
          this.updatedAttachments.emit(this.fileList);
          observer.next(false);
          observer.complete();
        }
      } else {
        this.fileList = [...this.fileList, file];
        this.updatedAttachments.emit(this.fileList);
        observer.next(false);
        observer.complete();
      }
    });
  };

  protected handlePreview = (file: NzUploadFile): void => {
    const url = file.url || file.thumbUrl;
    if (url) {
      const modal = this.modal.create<FilePreviewComponent>({
        nzContent: FilePreviewComponent,
        nzFooter: null,
        nzWidth: 650,
      });
      if (modal.componentInstance) {
        modal.componentInstance.url = url;
        modal.componentInstance.fileName = file.name;
      }
    } else {
      console.error('File URL is missing');
    }
  };

  protected handleDelete = (file: NzUploadFile): boolean => {
    const indexToRemove = this.fileList.findIndex(
      (data) => data.uid === file.uid,
    );
    this.fileList.splice(indexToRemove, 1);
    this.updatedAttachments.emit(this.fileList);
    return false;
  };
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'decimalToTime',
})
export class DecimalToTimePipe implements PipeTransform {
  constructor(public readonly translate: TranslateService) {}

  transform(timeInHours: number): string {
    if (!timeInHours || typeof timeInHours !== 'number') {
      timeInHours = 0;
    }

    const minusValue = timeInHours < 0 ? '-' : '';
    const positiveTimeInHours = Math.abs(timeInHours);
    const hours = Math.trunc(positiveTimeInHours);
    const minutes = Math.round((positiveTimeInHours - hours) * 60);

    let result = '';

    if (hours !== 0) {
      result =
        result + `${hours}${this.translate.instant('general.time.hours')} `;
    }
    if (minutes !== 0) {
      result =
        result + `${minutes}${this.translate.instant('general.time.minutes')} `;
    }
    if (minutes === 0 && hours === 0) {
      result = `0${this.translate.instant('general.time.minutes')} `;
    }

    return (`${minusValue} ` + result).trim();
  }
}

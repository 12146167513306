import { FormControl } from '@angular/forms';
import { isAfter } from 'date-fns';

export function DateRangeValidator(control: FormControl) {
  const [fromDate, toDate] = control.value;
  if (fromDate && toDate && isAfter(fromDate, toDate)) {
    return {
      dateRange: [fromDate, toDate],
    };
  }
  return null;
}

import { Component } from '@angular/core';
import {
  convertWeekDayStringsInWeekdayObject,
  WeekDays,
} from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-weekdays-renderer',
  template: `
    <wilson-weekday-selector
      [weekDays]="cellValue"
      [disabled]="true"
    ></wilson-weekday-selector>
  `,
})
export class WeekDaysRendererComponent implements AgRendererComponent {
  public cellValue: WeekDays;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return convertWeekDayStringsInWeekdayObject(params.value);
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wilson-confirm-with-highlight-dialogue',
  templateUrl: './confirm-with-highlight-dialogue.component.html',
  styleUrls: ['./confirm-with-highlight-dialogue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmWithHighlightDialogueComponent {
  @Input() title!: string;
  @Input() primaryMessage!: string;
  @Input() secondaryMessage!: string;
  @Input() cancelButtonText!: string;
  @Input() confirmButtonText!: string;
  @Input() highlightTextColor: 'text-white' | 'text-dark' = 'text-dark';
  @Input() highlightClass: 'bg-danger' | 'bg-blue' = 'bg-blue';
  @Input() confirmButtonClass: 'btn-danger' | 'btn-primary' = 'btn-primary';

  constructor(private activeModal: NgbActiveModal) {}

  public decline(): void {
    this.activeModal.close(false);
  }

  public accept(): void {
    this.activeModal.close(true);
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LinkRendererService } from '@wilson/ag-grid/service';
import {
  ClientProposalResponse,
  JobLinkParams,
  JobLinkType,
  ClientJobListItem,
  ProviderProposalResponse,
} from '@wilson/interfaces';
import { ExpiryDateHelperService } from '@wilson/share/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-job-name-link',
  template: `
    <a
      [routerLink]="linkData?.link"
      class="title link-dark"
      *ngIf="!linkData?.isProvider; else provider"
    >
      <p class="m-0">{{ linkData?.title }}</p>
    </a>
    <ng-template #provider>
      <ng-container
        *ngIf="
          !isExpired &&
            (providerProposalResponse === ProviderProposalResponse.Open ||
              providerProposalResponse === ProviderProposalResponse.Accepted) &&
            (clientProposalResponse === ClientProposalResponse.Open ||
              clientProposalResponse === ClientProposalResponse.Accepted);
          else simpleText
        "
      >
        <a [routerLink]="linkData?.link" class="title link-dark">
          <p class="m-0">{{ linkData?.title }}</p>
        </a>
      </ng-container>
      <ng-template #simpleText>
        <p class="text-dark m-0">{{ linkData?.title }}</p>
      </ng-template>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobNameLinkComponent implements ICellRendererAngularComp {
  linkData!: JobLinkType;
  clientProposalResponse: ClientProposalResponse;
  ClientProposalResponse = ClientProposalResponse;
  providerProposalResponse: ProviderProposalResponse;
  ProviderProposalResponse = ProviderProposalResponse;
  isExpired: boolean;

  constructor(
    private linkRenderer: LinkRendererService,
    private readonly expiryDateHelperService: ExpiryDateHelperService,
  ) {}

  agInit(params: ICellRendererParams & JobLinkParams<ClientJobListItem>): void {
    this.linkData = this.parseLinkData(params);
    this.setProposalResponse(params);
  }

  refresh(params: ICellRendererParams & JobLinkParams<ClientJobListItem>) {
    this.linkData = this.parseLinkData(params);
    this.setProposalResponse(params);
    return true;
  }

  private setProposalResponse(
    params: ICellRendererParams & JobLinkParams<ClientJobListItem>,
  ) {
    if (params?.data) {
      this.clientProposalResponse = params.data.clientProposalResponse;
      this.providerProposalResponse = params.data.providerProposalResponse;
      this.isExpired = this.expiryDateHelperService.isJobExpired(params.data);
    }
  }

  private parseLinkData(
    params: ICellRendererParams & JobLinkParams<ClientJobListItem>,
  ) {
    return {
      ...this.linkRenderer.populateStringAndLink(params),
      isProvider: params?.isProvider() ?? false,
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { PublicationStatus, Shift } from '@wilson/interfaces';
import { isValid } from 'date-fns';
import { Observable, combineLatest, map } from 'rxjs';

@Pipe({
  name: 'isShiftLastSeenAtStatus',
  standalone: true,
})
export class IsShiftLastSeenAtStatusPipe implements PipeTransform {
  constructor(
    private readonly store: Store,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  transform(
    payload: Pick<Shift, 'userId' | 'lastSeenAt' | 'publicationStatus'>,
  ): Observable<boolean> {
    return combineLatest([
      this.store
        .select(AuthState.mobileSettings)
        .pipe(map((res) => res.trackShiftLastSeen)),
      this.featureFlagPurePipe.transform('portal-mobile-settings-last-seen'),
    ]).pipe(
      map(([trackShiftLastSeenSetting, trackShiftLastSeenFeatureFlag]) => {
        return (
          trackShiftLastSeenSetting &&
          trackShiftLastSeenFeatureFlag &&
          !!payload?.userId &&
          payload?.publicationStatus !== PublicationStatus.NotPublished &&
          payload?.publicationStatus !== PublicationStatus.NotPublishedAgain &&
          payload?.lastSeenAt &&
          isValid(new Date(payload?.lastSeenAt))
        );
      }),
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  CreateInvoiceDTO,
  CreateInvoicePdfPayload,
  InvoiceDTO,
  UpdateInvoiceDTO,
} from '@wilson/invoicing/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

export interface InvoicesOptions {
  where?: WhereOption;
  relations?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: {
    startDatetime: 'asc' | 'desc';
  };
}
interface WhereOption {
  createdAt?: {
    gte?: string;
    lte?: string;
  };
}

export interface PdfGeneratedResponse {
  response: string;
  meta: PdfMetaInformation;
}

interface PdfMetaInformation {
  name: string;
  display_name: string;
  encoding: string;
  'content-type': string;
}

@Injectable({
  providedIn: 'root',
})
export class InvoicesGateway {
  protected readonly path = 'invoices';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  generatePDF(pdfPayload: CreateInvoicePdfPayload) {
    return this.http.post<PdfGeneratedResponse>(
      `${this.config.host}/${this.path}/pdf`,
      pdfPayload,
    );
  }

  getInvoices(options?: InvoicesOptions): Observable<InvoiceDTO[]> {
    const params = stringify(options);
    return this.http.get<InvoiceDTO[]>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  createInvoice(invoicePayload: CreateInvoiceDTO) {
    return this.http.put<InvoiceDTO>(
      `${this.config.host}/${this.path}`,
      invoicePayload,
    );
  }

  updateInvoice(invoicePayload: UpdateInvoiceDTO) {
    return this.http.put<InvoiceDTO>(
      `${this.config.host}/${this.path}`,
      invoicePayload,
    );
  }

  cancelInvoice(invoiceId: string) {
    return this.http.post<InvoiceDTO>(
      `${this.config.host}/${this.path}/${invoiceId}/cancel`,
      {},
    );
  }

  getInvoice(id: string, options?: TypeOrmFindManyOptions) {
    const params = stringify(options);
    return this.http.get<InvoiceDTO>(
      `${this.config.host}/${this.path}/${id}?${params}`,
    );
  }

  deleteInvoices(ids: string[]) {
    const params = stringify({
      ids: ids,
    });
    return this.http.delete(`${this.config.host}/${this.path}?${params}`);
  }
}

import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteDialogueComponent } from '@wilson/shared-components';
import { ViewFilePopupComponent } from '../../../qualifications/view-file-popup/view-file-popup.component';
import { ConfirmationDialogueComponent } from '@wilson/shared-components';

/**
 * @deprecated This service should not be used instead use NzModalService
 */
@Injectable()
export class ModalService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message: string | TemplateRef<any>,
    btnOkText = 'general.save',
    btnCancelText = 'general.cancel',
    dialogSize: 'sm' | 'lg' | 'md' = 'md',
    icon?: string,
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogueComponent, {
      size: dialogSize,
      centered: true,
      backdrop: 'static',
    });
    const componentInstance: ConfirmationDialogueComponent =
      modalRef.componentInstance;
    componentInstance.title = title;
    if (typeof message === 'string') {
      componentInstance.message = message;
    } else {
      componentInstance.content = message;
    }
    componentInstance.btnOkText = btnOkText;
    componentInstance.btnCancelText = btnCancelText;
    if (icon) {
      componentInstance.icon = icon;
    }
    return modalRef.result;
  }

  public alert(
    title: string,
    message: string,
    btnOkText = 'general.ok',
    dialogSize: 'sm' | 'lg' | 'md' = 'md',
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogueComponent, {
      size: dialogSize,
      centered: true,
      backdrop: 'static',
    });
    const componentInstance: ConfirmationDialogueComponent =
      modalRef.componentInstance;
    componentInstance.title = title;
    componentInstance.message = message;
    componentInstance.btnOkText = btnOkText;
    return modalRef.result;
  }

  public delete(
    title: string,
    message: string,
    objectToDelete: string,
    twoStep = false,
    options?: {
      multiDelete?: boolean;
      customTexts?: boolean;
      alertMessage?: string;
    },
  ): Promise<boolean> {
    const modalRef = this.modalService.open(DeleteDialogueComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });
    const componentInstance: DeleteDialogueComponent =
      modalRef.componentInstance;
    componentInstance.title = title;
    componentInstance.message = message;
    componentInstance.alertMessage = options?.alertMessage
      ? options.alertMessage
      : null;
    componentInstance.customTexts = options?.customTexts
      ? options.customTexts
      : false;
    componentInstance.objectToDelete = objectToDelete;
    componentInstance.twoStep = twoStep;
    componentInstance.multiDelete = options?.multiDelete
      ? options.multiDelete
      : false;
    return modalRef.result;
  }

  public viewFile(file: string, name: string): Promise<boolean> {
    const modalRef = this.modalService.open(ViewFilePopupComponent, {
      size: name.search('.jpg') > 0 ? 'md' : 'lg',
      centered: true,
      backdrop: 'static',
    });
    const componentInstance: ViewFilePopupComponent =
      modalRef.componentInstance;
    componentInstance.file = file;
    componentInstance.name = name;
    return modalRef.result;
  }
}

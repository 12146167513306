<nz-drawer
  [nzVisible]="true"
  nzPlacement="right"
  [nzTitle]="'shift_drawer.shift_attachments' | translate"
  [nzFooter]="drawerFooter"
  [nzClosable]="false"
  (nzOnClose)="closeDrawer()"
>
  <ng-container *nzDrawerContent>
    <ul class="file-list">
      <li class="hover" *ngFor="let file of fileList">
        <div class="attachment-wrapper">
          <span
            class="paper-clip-icon"
            nz-icon
            nzType="paper-clip"
            nzTheme="outline"
          ></span>
          <a
            class="attachment-name ant-upload-list-item-name"
            (click)="handlePreview(file)"
          >
            {{ file.name }}
          </a>
        </div>
        <div>
          <span
            class="action-icon"
            nz-icon
            nzType="download"
            (click)="downloadFile(file)"
            nzTheme="outline"
          ></span>
          <span
            class="action-icon"
            nz-icon
            nzType="delete"
            (click)="handleDelete(file)"
            nzTheme="outline"
          ></span>
        </div>
      </li>
    </ul>
  </ng-container>

  <ng-template #drawerFooter>
    <button
      (click)="closeDrawer()"
      nz-button
      nzType="default"
      class="w-100"
      [disabled]="disableButtons"
    >
      {{ 'general.cancel' | translate }}
    </button>
  </ng-template>
</nz-drawer>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationContentParamsPipe } from '@wilson/notifications';
import { CountWeeklyHoursPipe } from './count-weekly-hours/count-weekly-hours.pipe';
import { DateIsHoveredPipe } from './date-is-hovered/date-is-hovered.pipe';
import { DateIsInsideRangePipe } from './date-is-inside-range/date-is-inside-range.pipe';
import { DateIsRangePipe } from './date-is-range/date-is-range.pipe';
import { IsShiftAbsencePipe } from './is-shift-absence/is-shift-absence.pipe';
import { NgbDateToDatePipe } from './ngb-date-to-date/ngb-date-to-date.pipe';
import { NotPublishedShiftsPipe } from './not-publish-shifts/not-publish-shifts.pipe';
import { SecretTextPipe } from './secret-text/secret-text.pipe';
import { ShiftValidationTranslationPipe } from './shift-validation-translation.pipe';

@NgModule({
  declarations: [
    NgbDateToDatePipe,
    DateIsHoveredPipe,
    DateIsRangePipe,
    DateIsInsideRangePipe,
    NotificationContentParamsPipe,
    ShiftValidationTranslationPipe,
    SecretTextPipe,
    NotPublishedShiftsPipe,
    IsShiftAbsencePipe,
    CountWeeklyHoursPipe,
  ],
  imports: [CommonModule],
  providers: [
    DateIsHoveredPipe,
    DateIsInsideRangePipe,
    NgbDateToDatePipe,
    NotPublishedShiftsPipe,
  ],
  exports: [
    NgbDateToDatePipe,
    DateIsHoveredPipe,
    DateIsRangePipe,
    DateIsInsideRangePipe,
    NotificationContentParamsPipe,
    ShiftValidationTranslationPipe,
    SecretTextPipe,
    NotPublishedShiftsPipe,
    IsShiftAbsencePipe,
    CountWeeklyHoursPipe,
  ],
})
export class PipesModule {}

import { Injectable } from '@angular/core';
import {
  ClientJobListItem,
  ClientProposalResponse,
  Job,
  JobOrigin,
} from '@wilson/interfaces';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable()
export class MyJobsService {
  private requestStatusesSubject = new BehaviorSubject<
    ClientProposalResponse[]
  >([]);
  private partnersSubject = new BehaviorSubject<string[]>([]);

  public setRequestStatusFilter(status: string[]) {
    this.requestStatusesSubject.next(status as ClientProposalResponse[]);
  }

  public setPartnerFilter(partnerIds: string[]) {
    this.partnersSubject.next(partnerIds);
  }

  public createFilteredJobsStream(
    jobsStream: Observable<Job[]>,
  ): Observable<ClientJobListItem[]> {
    return combineLatest([
      jobsStream.pipe(map(this.transformToViewData())),
      this.requestStatusesSubject,
      this.partnersSubject,
    ]).pipe(
      map(([data, statusFilters, partnerFilter]) => {
        if (statusFilters.length) {
          data = this.filterByRequestStatuses(data, statusFilters);
        }
        if (partnerFilter.length) {
          data = this.filterByPartners(data, partnerFilter);
        }
        return data;
      }),
    );
  }

  private filterByRequestStatuses(
    jobs: ClientJobListItem[],
    statuses: ClientProposalResponse[],
  ) {
    return jobs.filter((job: ClientJobListItem) =>
      statuses.includes(job.clientProposalResponse),
    );
  }

  private filterByPartners(jobs: ClientJobListItem[], partnerIds: string[]) {
    return jobs.filter((job: ClientJobListItem) =>
      partnerIds.includes(job.partnerId),
    );
  }

  private transformToViewData(): (jobs: Job[]) => ClientJobListItem[] {
    return (jobs: Job[]) =>
      jobs.map((job) => {
        const clientJobListItem: ClientJobListItem = {
          id: job.id as string,
          name: job.name,
          clientProposalResponse: job.status,
          createdAtISO: job.createdAt as string,
          createdFrom: job.createdFrom as JobOrigin,
          expiresInISO: job.expiresAt || null,
          partnerName: job.acceptedProviderOrganizationalUnit?.name ?? '',
          partnerId: job.acceptedProviderOrganizationalUnit?.id ?? '',
        };
        return clientJobListItem;
      });
  }
}

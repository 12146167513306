import { inject } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ConfirmationDialogueComponent } from '../confirmation-dialogue/confirmation-dialogue.component';
import { CanDeactivateFn } from '@angular/router';

export interface ComponentCanDeactivate {
  canDeactivate(): Observable<boolean>;
}

function confirm(
  modalService: NgbModal,
  translate: TranslateService,
): Promise<boolean> {
  const modalRef = modalService.open(ConfirmationDialogueComponent, {
    size: 'md',
    centered: true,
    backdrop: 'static',
  });
  modalRef.componentInstance.title = translate.instant(
    'can_deactive.modalConfirm.header',
  );
  modalRef.componentInstance.message = translate.instant(
    'can_deactive.modalConfirm.text',
  );
  modalRef.componentInstance.btnOkText = translate.instant(
    'can_deactive.modalConfirm.discard',
  );
  modalRef.componentInstance.btnCancelText = 'general.cancel';
  return modalRef.result;
}

export const canDeactivateGuardFn: CanDeactivateFn<
  ComponentCanDeactivate
> = async (component: ComponentCanDeactivate) => {
  const modalService = inject(NgbModal);
  const translate = inject(TranslateService);

  if (await firstValueFrom(component.canDeactivate())) {
    return await confirm(modalService, translate);
  } else {
    return true;
  }
};

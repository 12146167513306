import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Activity,
  ActivityCertificateQualification,
  ActivityCountryQualification,
  ActivityDriverLicenseQualification,
  ActivityFurtherEducationQualification,
  ActivityLanguageQualification,
  ActivityLocationQualification,
  ActivityMedicalExaminationQualification,
  ActivityOtherSubQualificationQualification,
  ActivityQualifications,
  ActivityRouteQualification,
  ActivitySafetyCertificateQualification,
  ActivityTemplate,
  ActivityTemplateQualifications,
  ActivityTrainingQualification,
  ActivityVehicleQualification,
  QualificationCategoryName,
} from '@wilson/interfaces';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityQualificationsService } from '../../lib/activity-qualifications.service';
import { ActivityTemplateQualificationsService } from '../../lib/activity-template-qualifications.service';
import { ActivityQualificationsModalService } from './activity-qualifications-modal/services/activity-qualifications-modal.service';

@Component({
  selector: 'wilson-activity-qualifications',
  templateUrl: './activity-qualifications.component.html',
  styleUrls: ['./activity-qualifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityQualificationsComponent implements OnInit, OnDestroy {
  @Input() activity: Activity;
  @Input() activityTemplate: ActivityTemplate;
  @Input() disabled = false;
  @Input() size: 'sm' | 'md' = 'md';
  @Output() qualificationChange = new EventEmitter();

  activityQualifications: ActivityQualifications[] = [];
  activityQualifications$: Observable<ActivityQualifications[]>;

  activityTemplateQualifications: ActivityTemplateQualifications[] = [];
  activityTemplateQualifications$: Observable<ActivityTemplateQualifications[]>;

  tooltipList: string[] = [];

  counter = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly activityQualificationsModalService: ActivityQualificationsModalService,
    private readonly activityQualificationsService: ActivityQualificationsService,
    private readonly activityTemplateQualificationsService: ActivityTemplateQualificationsService,
    private readonly translate: TranslateService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.activity) {
      this.getActivityQualifications();
    } else if (this.activityTemplate) {
      this.getActivityTemplateQualifications();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public getActivityQualifications() {
    this.activityQualificationsService
      .getActivityQualifications(this.activity.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((activityQualifications) => {
        this.counter = activityQualifications?.length
          ? activityQualifications.length
          : 0;
        this.activityQualifications = activityQualifications;
        this.tooltipList = this.activityQualifications
          .map((activityQualification) =>
            this.getActivityQualificationName(activityQualification),
          )
          .sort((a, b) => a.localeCompare(b));

        this.cd.detectChanges();
      });
  }

  public getActivityTemplateQualifications() {
    this.activityTemplateQualificationsService
      .getActivityTemplateQualifications(this.activityTemplate.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((activityTemplateQualifications) => {
        this.counter = activityTemplateQualifications?.length
          ? activityTemplateQualifications.length
          : 0;
        this.activityTemplateQualifications = activityTemplateQualifications;

        this.tooltipList = this.activityTemplateQualifications
          .map((activityQualification) =>
            this.getActivityQualificationName(activityQualification),
          )
          .sort((a, b) => a.localeCompare(b));

        this.cd.detectChanges();
      });
  }

  async openQualificationSelection() {
    const response =
      await this.activityQualificationsModalService.openActivityQualificationsModal(
        this.activity,
        this.activityTemplate,
        this.activityQualifications.length
          ? this.activityQualifications
          : this.activityTemplateQualifications.length
          ? this.activityTemplateQualifications
          : [],
      );
    if (response === 'saved') {
      this.destroy$.next();
      if (this.activity) {
        this.getActivityQualifications();
      } else if (this.activityTemplate) {
        this.getActivityTemplateQualifications();
      }
      this.qualificationChange.emit(true);
    }
  }

  getActivityQualificationName(
    activityQualification:
      | ActivityQualifications
      | ActivityTemplateQualifications,
  ) {
    switch (activityQualification.qualificationCategory.name) {
      case QualificationCategoryName.Certificate:
        return this.translate.instant(
          `certificate.${
            (activityQualification as ActivityCertificateQualification)
              .certificate.name
          }`,
        );
      case QualificationCategoryName.Country:
        return this.translate.instant(
          `country.${
            (activityQualification as ActivityCountryQualification).country
              .countryCode
          }`,
        );
      case QualificationCategoryName.DriverLicense:
        return this.translate.instant(
          `driverLicense.${
            (activityQualification as ActivityDriverLicenseQualification)
              .driverLicense.name
          }`,
        );
      case QualificationCategoryName.FurtherEducation:
        return this.translate.instant(
          `furtherEducation.${
            (activityQualification as ActivityFurtherEducationQualification)
              .furtherEducation.name
          }`,
        );
      case QualificationCategoryName.Language:
        return this.translate.instant(
          `language.${
            (activityQualification as ActivityLanguageQualification).language
              .languageCode
          }`,
        );
      case QualificationCategoryName.Location:
        return (activityQualification as ActivityLocationQualification)
          .geoLocation.name;
      case QualificationCategoryName.MedicalExamination:
        return this.translate.instant(
          `medicalExamination.${
            (activityQualification as ActivityMedicalExaminationQualification)
              .medicalExamination.name
          }`,
        );
      case QualificationCategoryName.More:
        return this.translate.instant(
          `otherQualification.${
            (
              activityQualification as ActivityOtherSubQualificationQualification
            ).otherQualificationSubCategory.name
          }`,
        );
      case QualificationCategoryName.Route:
        return (activityQualification as ActivityRouteQualification).route.name;
      case QualificationCategoryName.Training:
        return this.translate.instant(
          `training.${
            (activityQualification as ActivityTrainingQualification).training
              .name
          }`,
        );
      case QualificationCategoryName.Vehicle:
        return (activityQualification as ActivityVehicleQualification)
          .vehicleModel.name;
      case QualificationCategoryName.SafetyCertificate:
        return (activityQualification as ActivitySafetyCertificateQualification)
          .safetyCertificate.name;
      default:
        return '';
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-header-renderer',
  template: `
    <div class="text-dark text-truncate">
      {{ cellValue }}
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderRendererComponent implements AgRendererComponent {
  public cellValue!: string;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    const result = params.valueFormatted ? params.valueFormatted : params.value;
    return result || '---';
  }
}

import {
  BackendService,
  Base,
  TypeOrmFindManyOptions,
  UpdateResult,
} from '@wilson/base';
import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, tap } from 'rxjs';
import { take } from 'rxjs/operators';

export abstract class FormService<T extends Base> {
  protected abstract readonly datasource: BackendService<T>;
  protected id: string;
  public abstract readonly form: FormGroup<ControlsOf<T>>;
  public isSaving$ = new BehaviorSubject(false);
  public isLoading$ = new BehaviorSubject(false);

  initialize(id: string, options?: TypeOrmFindManyOptions): void {
    this.id = id;
    this.isLoading$.next(true);
    this.form.patchValue(
      this.datasource.get(id, options).pipe(
        take(1),
        tap(() => {
          this.isLoading$.next(false);
        }),
      ),
    );
  }

  initializeWithData(entity: T): void {
    this.id = entity.id;
    this.form.patchValue(entity);
  }

  submit(): Promise<UpdateResult> | Promise<T[]> {
    this.isSaving$.next(true);
    this.form.disable();
    let promise: Promise<UpdateResult> | Promise<T[]>;
    if (this.id) {
      promise = this.datasource.update({
        id: this.id,
        ...(this.form.getRawValue() as T),
      });
    } else {
      promise = this.datasource.addMany({ ...this.form.getRawValue() } as T);
    }

    return promise.then((res) => {
      this.form.enable();
      this.isSaving$.next(false);
      return res;
    });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityViewModelServiceDetails } from '@wilson/invoicing/state';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-redirecting-service-link-renderer',
  template: `
    <a
      *ngIf="title; else noValue"
      (click)="openInNewTabAndStopBubbling($event)"
      class="link-dark"
      >{{ title }}</a
    >

    <ng-template #noValue>{{ '---' }} </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectingServiceLinkRendererComponent
  implements ICellRendererAngularComp
{
  public title!: string;
  public propertyId: string;

  async agInit(params: ICellRendererParams) {
    this.initValues(params);
  }

  refresh(params: ICellRendererParams) {
    this.initValues(params);
    return true;
  }

  private initValues(params: ICellRendererParams) {
    const data: ActivityViewModelServiceDetails = params.data?.serviceDetails;

    this.propertyId = data?.serviceId;
    this.title = data?.serviceName;
  }

  openInNewTabAndStopBubbling(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const baseUrl = window.location.origin;
    const path = `/services/${this.propertyId}`;
    const url = baseUrl + path;

    window.open(url, '_blank');
  }
}

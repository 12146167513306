<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    #furtherInfoPanel
    class="collapse-panel"
    [nzHeader]="furtherInfoInfoSectionHeader"
    [nzActive]="isExpandedOnOpen"
    [nzShowArrow]="false"
    class="collapse-panel"
    [nzExtra]="furtherInfoCollapseIcon"
  >
    <div class="mb-2">
      <wilson-shift-further-information
        [shift]="shift"
        (reloadValidations)="reloadValidations.emit()"
      ></wilson-shift-further-information>
    </div>
    <wilson-necessary-stay-toggle
      [currentShiftNecessaryStay]="currentShiftNecessaryStay"
      (updatedNecessaryStay)="updatedNecessaryStay.emit($event)"
    ></wilson-necessary-stay-toggle>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #furtherInfoInfoSectionHeader>
  <span class="header-text">
    {{ 'general.further-information' | translate }}
  </span>
</ng-template>
<ng-template #furtherInfoCollapseIcon>
  <i
    class="fas fa-chevron-up"
    [ngClass]="furtherInfoPanel.nzActive ? 'rotate-180' : 'rotate-0'"
  ></i>
</ng-template>

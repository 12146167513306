import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  ActivityTemplate,
  ActivityTemplateQualifications,
} from '@wilson/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityTemplateQualificationsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getActivityTemplateQualifications(
    activityTemplateId: string,
  ): Observable<ActivityTemplateQualifications[]> {
    return this.httpClient.get<ActivityTemplateQualifications[]>(
      `${this.config.host}/activity-templates/${activityTemplateId}/qualifications`,
    );
  }

  setActivityTemplateQualifications(
    activityTemplate: Partial<ActivityTemplate>,
  ): Promise<ActivityTemplate[]> {
    return this.httpClient
      .post<ActivityTemplate[]>(
        `${this.config.host}/activity-templates/qualifications`,
        { items: [activityTemplate] },
      )
      .toPromise();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShiftViolation } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

type AzmDeviationColor = 'success-dark' | 'bs-danger' | 'orange' | 'gray';
interface AzmDeviationRendererValue {
  deviation: number | null | undefined;
  shiftReportComment: string | undefined;
  shiftReportViolationIds: ShiftViolation[] | undefined;
}

@Component({
  selector: 'wilson-activities-deviation-renderer',
  template: `
    <span
      [style.color]="'var(--' + color + ')'"
      nz-popover
      [nzPopoverTrigger]="
        shiftReportComment || hasViolatedWorktime ? 'hover' : null
      "
      [nzPopoverTitle]="
        'work_time_management.table.deviation_popover.title' +
          (hasViolatedWorktime ? '.rules_violated' : '.rules_respected')
          | translate
      "
      [nzPopoverContent]="
        shiftReportComment ||
        ('work_time_management.table.deviation_popover.no_comment' | translate)
      "
    >
      <ng-container
        *ngIf="deviation !== undefined && deviation !== null; else empty"
      >
        {{ deviation | decimalToTime }}
      </ng-container>
      <ng-template #empty> {{ 'general.n/a' | translate }} </ng-template>
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzmDeviationRendererComponent implements AgRendererComponent {
  public deviation: number | undefined | null;
  public shiftReportComment: string;
  public hasViolatedWorktime: boolean;
  public color: AzmDeviationColor;

  agInit(
    params: ICellRendererParams<unknown, AzmDeviationRendererValue>,
  ): void {
    this.deviation = params.value.deviation && params.value.deviation / 3600;
    this.shiftReportComment = params.value.shiftReportComment;
    this.hasViolatedWorktime = params.value.shiftReportViolationIds.some(
      (violation) => violation !== null && violation !== undefined,
    );

    this.color = this.getDeviationColor();
  }

  refresh(params: ICellRendererParams<unknown, AzmDeviationRendererValue>) {
    this.agInit(params);
    return true;
  }

  getDeviationColor(): AzmDeviationColor {
    if (this.hasViolatedWorktime) {
      return 'bs-danger';
    }

    if (this.deviation === null || this.deviation === undefined) {
      return 'gray';
    }

    const absDeviation = Math.abs(this.deviation);

    if (absDeviation < 1) {
      return 'success-dark';
    }

    if (absDeviation >= 1) {
      return 'orange';
    }

    return 'gray';
  }
}

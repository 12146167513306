import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-shift-template-name-renderer',
  template: `
    <div>
      <div
        class="text-dark text-truncate"
        nz-tooltip
        [nzTooltipTitle]="tooltipContent"
        [nzTooltipColor]="'#fff'"
      >
        {{ cellValue ? cellValue : '---' }}
      </div>
      <ng-template #tooltipContent>
        <div class="px-2 mb-1">
          <span>
            <b>{{ 'account.name' | translate }}:</b>
            <br />
            {{ cellValue }}
          </span>
          <br />
          <span>
            <b>{{ 'general.description' | translate }}:</b>
            <br />
            {{ description ? description : '---' }}
          </span>
        </div>
      </ng-template>
      <div class="list-header lh-m">
        {{
          shiftCategory ? ('shift_category.' + shiftCategory | translate) : ''
        }}
      </div>
    </div>
  `,
})
export class ShiftTemplateNameRendererComponent implements AgRendererComponent {
  public cellValue!: string;
  public shiftCategory!: string;
  public description!: string;

  async agInit(params: ICellRendererParams) {
    this.cellValue = params.value;
    this.shiftCategory = params?.data?.shiftCategory
      ? params.data.shiftCategory.name
      : null;
    this.description = params?.data ? params.data.description : null;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    this.shiftCategory = params?.data?.description
      ? params.data.shiftCategory.name
      : null;
    this.description = params?.data ? params.data.description : null;
    return true;
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UpdateResult } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  Absence,
  AbsenceCategory,
  AbsenceFields,
  AbsenceType,
  AbsenceTypeTranslationKey,
  AbsenceWithCategory,
  FindConditions,
  IntervalWithConsumedVacationDays,
  StringIntervalWithConsumedVacationDays,
  UserVacation,
  UsersIntervalWithConsumedVacationDays,
  VacationStatus,
} from '@wilson/interfaces';
import { Interval, parseISO } from 'date-fns';
import { Observable, combineLatest, firstValueFrom, map, reduce } from 'rxjs';
import { AbsenceCategoryEnum } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class AbsencesService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getByUserInDateRange(userId: string, startDate: Date, endDate: Date) {
    return this.getInDateRange(startDate, endDate, { userId });
  }

  public getById(absenceId: string) {
    return this.httpClient.get<Absence>(
      `${this.config.host}/absences/${absenceId}`,
    );
  }

  public getInDateRange(
    startDate: Date,
    endDate: Date,
    lookup: FindConditions<Absence> = {},
  ) {
    return this.httpClient.post<AbsenceWithCategory[]>(
      `${this.config.host}/absences/date-range`,
      { absentFrom: startDate, absentTo: endDate, ...lookup },
    );
  }

  public createAbsence(absence: Absence) {
    return firstValueFrom(
      this.httpClient.post<(AbsenceFields & { id: string })[]>(
        `${this.config.host}/absences`,
        {
          items: [absence],
        },
      ),
    );
  }

  public createAbsences(absences: Absence[]) {
    return firstValueFrom(
      this.httpClient.post<(AbsenceFields & { id: string })[]>(
        `${this.config.host}/absences`,
        {
          items: absences,
        },
      ),
    );
  }

  public updateAbsence(absence: Partial<Absence>, absenceId: string) {
    return this.httpClient.patch<UpdateResult>(
      `${this.config.host}/absences/${absenceId}`,
      absence,
    );
  }

  public deleteAbsence(absenceId: string) {
    return this.httpClient.delete<Absence>(
      `${this.config.host}/absences/${absenceId}`,
    );
  }

  public getTranslationKey(
    absenceCategoryName: AbsenceType,
  ): AbsenceTypeTranslationKey {
    return `enum.absence_category.${absenceCategoryName}`;
  }

  public getAbsenceCategories(): Observable<AbsenceCategory[]> {
    return this.httpClient.get<AbsenceCategory[]>(
      `${this.config.host}/absence-categories`,
    );
  }

  public getAbsenceCategory(categoryId: string): Observable<AbsenceCategory> {
    return this.httpClient.get<AbsenceCategory>(
      `${this.config.host}/absence-categories/${categoryId}`,
    );
  }

  public getVacations(userId: string) {
    return this.httpClient.post<UserVacation[]>(
      `${this.config.host}/user-vacations/search`,
      {
        userId,
      },
    );
  }

  public getVacationStatus(userId: string, year: number) {
    return this.httpClient.get<VacationStatus>(
      `${this.config.host}/users/${userId}/vacation-status?where[year]=${year}`,
    );
  }

  getConsumedVacationDaysInIntervals(
    userId: string,
    intervals: Interval[],
  ): Observable<IntervalWithConsumedVacationDays[]> {
    return this.httpClient.post<IntervalWithConsumedVacationDays[]>(
      `${this.config.host}/user-vacations/consumed-vacations-in-intervals`,
      {
        userId,
        intervals,
      },
    );
  }

  getConsumedVacationDaysForUsersInIntervals(
    absences: Absence[],
  ): Observable<UsersIntervalWithConsumedVacationDays> {
    const vacationsByUser: Record<string, Absence[]> = absences.reduce(
      (accumulatedVacationsByUser, absence) => {
        if (absence.absenceCategoryId === AbsenceCategoryEnum.Vacation) {
          if (!accumulatedVacationsByUser[absence.userId]) {
            accumulatedVacationsByUser[absence.userId] = [];
          }
          accumulatedVacationsByUser[absence.userId].push(absence);
        }
        return accumulatedVacationsByUser;
      },
      {} as Record<string, Absence[]>,
    );

    const observablesForUsers$ = Object.entries(vacationsByUser).map(
      ([userId, vacationsByUser]) => {
        const intervals: Interval[] = vacationsByUser.map((vacation) => ({
          start: parseISO(vacation.absentFrom),
          end: parseISO(vacation.absentTo),
        }));

        return this.getConsumedVacationDaysInIntervals(userId, intervals).pipe(
          map((consumedVacationDaysInInterval) => {
            const correctlyTypedData =
              consumedVacationDaysInInterval as unknown as StringIntervalWithConsumedVacationDays[];
            return {
              [userId]: correctlyTypedData,
            } as UsersIntervalWithConsumedVacationDays;
          }),
        );
      },
    );

    return combineLatest(observablesForUsers$).pipe(
      reduce((combinedResults, userResultsArray) => {
        return userResultsArray.reduce(
          (mergedResults, userResults) => ({
            ...mergedResults,
            ...userResults,
          }),
          combinedResults,
        );
      }, {} as UsersIntervalWithConsumedVacationDays),
    );
  }

  getAbsenceCategoryTranslationKeyById(categoryId: string | undefined): string {
    const translationKeys: Record<string, string> = {};

    Object.keys(AbsenceCategoryEnum).forEach((key) => {
      const absenceCategoryEnumValue =
        AbsenceCategoryEnum[key as keyof typeof AbsenceCategoryEnum];
      const translationKey = `enum.absence_category.${
        AbsenceType[key as keyof typeof AbsenceType]
      }`;
      translationKeys[absenceCategoryEnumValue] = translationKey;
    });

    return translationKeys[categoryId || ''] ?? 'enum.absence_category.more';
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AbsenceStates,
  AbsenceWithCategory,
  AffectedShifts,
  AssignableShiftsWithDate,
  PublicationStatus,
  ResolvedShiftValidation,
  ShiftDispositionAssignable,
  ShiftDispositionAssignableShift,
  ShiftDispositionAssignableShifts,
  ShiftDispositionAssignableTypes,
  ShiftDispositionTask,
  ShiftValidationDetails,
  ShiftWithActivitiesWithLocations,
} from '@wilson/interfaces';
import {
  eachDayOfInterval,
  lightFormat,
  parseISO,
  startOfToday,
} from 'date-fns';
import { cloneDeep } from 'lodash';
import { isSameDay } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ShiftDispositionsDataService {
  constructor(private translate: TranslateService) {}

  processAssignables(
    shiftsWithValidations:
      | {
          shift: ShiftWithActivitiesWithLocations;
        }[]
      | ResolvedShiftValidation[],
    absences: AbsenceWithCategory[],
  ): {
    assignableShifts: AssignableShiftsWithDate[];
    assignedTasks: ShiftDispositionTask;
    assignedShifts: ShiftWithActivitiesWithLocations[];
    assignedAbsences: AbsenceWithCategory[];
  } {
    // we only care about active or open absences in the calendar
    absences = absences.filter(
      (absence) =>
        absence.status !== AbsenceStates.declined &&
        absence.status !== AbsenceStates.withdrawn,
    );

    const shifts: ShiftWithActivitiesWithLocations[] = [];
    const validations: Record<string, ShiftValidationDetails[]> = {};

    for (const shiftWithValidations of shiftsWithValidations) {
      shifts.push(shiftWithValidations.shift);
      if ((shiftWithValidations as ResolvedShiftValidation)['validations']) {
        const invalid: ShiftValidationDetails[] = (
          shiftWithValidations as ResolvedShiftValidation
        )['validations']?.filter((v) => !v.valid);
        if (invalid?.length) {
          validations[shiftWithValidations.shift.id as string] = invalid;
        }
      }
    }

    // combining the different things into a list by date
    const assigned: ShiftDispositionTask = {};
    const assignablesIndexed: Record<string, AssignableShiftsWithDate> = {};

    const resultGroups: {
      type: string;
      items: ShiftWithActivitiesWithLocations[] | AbsenceWithCategory[];
    }[] = [
      { type: 'shift', items: shifts },
      {
        type: 'absence',
        items: absences,
      },
    ];

    for (const group of resultGroups) {
      for (const record of group.items) {
        // absences often span multiple days, so we simply add those for multiple days
        const recordDates =
          group.type === ShiftDispositionAssignableTypes.Absence
            ? eachDayOfInterval({
                start: parseISO((record as AbsenceWithCategory).absentFrom),
                end: parseISO((record as AbsenceWithCategory).absentTo),
              })
            : [
                parseISO(
                  (record as ShiftWithActivitiesWithLocations).startDate,
                ),
              ];
        for (const dateFrom of recordDates) {
          const formattedDate = this.generateFormattedDate(dateFrom);

          const assignable: ShiftDispositionAssignable = {
            type: group.type as ShiftDispositionAssignable['type'], // we need this for handling data in the different components
            date: dateFrom,
            formattedDate: formattedDate,
            record,
          } as ShiftDispositionAssignable;

          if (group.type === 'shift') {
            if (validations[record.id as string]) {
              (assignable as ShiftDispositionAssignableShift).issues =
                validations[record.id as string];
            }

            if (
              (record as ShiftWithActivitiesWithLocations).activities?.length
            ) {
              (record as ShiftWithActivitiesWithLocations).activities = (
                record as ShiftWithActivitiesWithLocations
              ).activities.sort(
                ({ startDatetime: dateA }, { startDatetime: dateB }) =>
                  dateA < dateB ? -1 : dateA > dateB ? 1 : 0,
              );
            }
          }

          // assigned
          if (
            (group.type === 'shift' || group.type === 'absence') &&
            record['userId']
          ) {
            if (!assigned[formattedDate]) {
              assigned[formattedDate] = {};
            }
            if (!assigned[formattedDate][record['userId']]) {
              assigned[formattedDate][record['userId']] = [];
            }

            assigned[formattedDate][record['userId']].push(assignable);
          } else {
            // assignable
            if (!assignablesIndexed[formattedDate]) {
              assignablesIndexed[formattedDate] = { date: dateFrom, items: [] };
            }
            assignablesIndexed[formattedDate].items.push(
              assignable as ShiftDispositionAssignableShifts,
            );
          }
        }
      }
    }

    // sort things by dates and publish data
    const today = startOfToday();
    const assignables = this.sortAssignableShiftList(
      Object.values(assignablesIndexed).filter(
        (indexed) => indexed.date >= today,
      ),
    );

    return {
      assignableShifts: assignables,
      assignedTasks: assigned,
      assignedShifts: shifts,
      assignedAbsences: absences,
    };
  }

  addAssignableShiftToAssignedList(
    assignedShiftsList: ShiftWithActivitiesWithLocations[],
    shiftToAssign: ShiftWithActivitiesWithLocations,
    userId: string,
  ): {
    shift: ShiftWithActivitiesWithLocations;
  }[] {
    const updatedShiftRecord = cloneDeep({
      ...shiftToAssign,
      userId,
    }) as ShiftWithActivitiesWithLocations;

    const currentAssignedShifts: ShiftWithActivitiesWithLocations[] =
      cloneDeep(assignedShiftsList);

    return this.transformToShiftObjectList([
      ...currentAssignedShifts.concat(updatedShiftRecord),
    ]);
  }

  removeAssignedShiftFromAssignedList(
    assignedShiftsList: ShiftWithActivitiesWithLocations[],
    shiftsToUnassign: ShiftDispositionAssignable[],
  ): {
    shift: ShiftWithActivitiesWithLocations;
  }[] {
    const recordIdsToRemove: string[] = shiftsToUnassign.map(
      (assignedShift) => assignedShift.record.id as string,
    );
    const currentAssignedShifts: ShiftWithActivitiesWithLocations[] =
      cloneDeep(assignedShiftsList);

    return this.transformToShiftObjectList([
      ...currentAssignedShifts.filter((currentAssignedShift) => {
        const indexOfRecordToRemove = recordIdsToRemove.indexOf(
          currentAssignedShift.id as string,
        );
        return indexOfRecordToRemove === -1;
      }),
    ]);
  }

  removeAssignableShiftFromAssignableList(
    list: AssignableShiftsWithDate[],
    shiftToRemove: ShiftDispositionAssignable,
  ): AssignableShiftsWithDate[] {
    const clonedList = cloneDeep(list);

    const indexOfAssignedDate = clonedList.findIndex(
      (item: AssignableShiftsWithDate) => {
        return isSameDay(item.date, shiftToRemove.date);
      },
    );

    const dataForAssignedDate = clonedList[indexOfAssignedDate];

    if (dataForAssignedDate) {
      // Reassign only objects that do not have the same id as the assigned item
      dataForAssignedDate.items = dataForAssignedDate.items.filter(
        (assignableItem: ShiftDispositionAssignable) =>
          assignableItem.record.id !== shiftToRemove.record.id,
      );

      if (dataForAssignedDate.items.length === 0) {
        clonedList.splice(indexOfAssignedDate, 1);
      }
    }

    return clonedList;
  }

  addAssignedShiftToAssignableList(
    list: AssignableShiftsWithDate[],
    shiftsToAddInTheSameDay: ShiftDispositionAssignableShifts[], // We assume that all shifts to add are from the same day
  ): AssignableShiftsWithDate[] {
    const clonedList: AssignableShiftsWithDate[] = cloneDeep(list);

    const dateOfInterest = new Date(shiftsToAddInTheSameDay[0]?.date);

    const dataForInterestedDate = clonedList.find(
      (item: AssignableShiftsWithDate) => {
        return isSameDay(item.date, dateOfInterest);
      },
    );

    if (dataForInterestedDate) {
      dataForInterestedDate.items = dataForInterestedDate.items.concat(
        shiftsToAddInTheSameDay,
      );

      return clonedList;
    } else {
      clonedList.push({
        date: dateOfInterest,
        items: shiftsToAddInTheSameDay,
      });

      return this.sortAssignableShiftList(clonedList);
    }
  }

  sortAssignableShiftList(
    list: AssignableShiftsWithDate[],
  ): AssignableShiftsWithDate[] {
    const clonedList = cloneDeep(list);

    const absenceFallbackName = this.translate.instant(
      'page.shift_disposition.gen_template_absence',
    );

    return clonedList
      .map((indexed) => {
        indexed.items = indexed.items.sort((a, b) => {
          const aName: string =
            a.type === 'shift' ? a.record.name : absenceFallbackName;
          const bName: string =
            b.type === 'shift' ? b.record.name : absenceFallbackName;
          return aName.localeCompare(bName);
        });
        return indexed;
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  publishAssignedShifts(
    shiftIds: string[],
    assignedShifts: ShiftWithActivitiesWithLocations[],
  ): {
    newRecords: {
      shift: ShiftWithActivitiesWithLocations;
    }[];
    affectedShifts: AffectedShifts;
  } {
    const affectedShifts: AffectedShifts = {};

    const clonedShifts = cloneDeep(assignedShifts);
    clonedShifts.forEach((shift) => {
      const isInterestedShift = shiftIds.includes(shift.id as string);
      if (isInterestedShift) {
        affectedShifts[shift.id as string] = {
          previousStatus: shift.publicationStatus,
        };
        shift.publicationStatus = PublicationStatus.Published;
      }
    });

    return {
      newRecords: this.transformToShiftObjectList(clonedShifts),
      affectedShifts,
    };
  }

  unpublishAssignedShifts(
    shiftIds: string[],
    assignedShifts: ShiftWithActivitiesWithLocations[],
    affectedShifts: AffectedShifts,
  ) {
    const clonedShifts = cloneDeep(assignedShifts);

    clonedShifts.forEach((shift) => {
      const isInterestedShift = shiftIds.includes(shift.id as string);
      const affectedShift = affectedShifts[shift.id as string];

      if (isInterestedShift && affectedShift) {
        shift.publicationStatus = affectedShift.previousStatus;
      }
    });

    return this.transformToShiftObjectList(clonedShifts);
  }

  transformToShiftObjectList(shifts: ShiftWithActivitiesWithLocations[]): {
    shift: ShiftWithActivitiesWithLocations;
  }[] {
    return shifts.map((shift) => ({ shift }));
  }

  private generateFormattedDate(date: Date) {
    return lightFormat(date, 'yyyy-MM-dd');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmWithHighlightDialogueComponent } from './confirm-with-highlight-dialogue.component';
import { ConfirmWithHighlightDialogueService } from './confirm-with-highlight-dialogue.service';

@NgModule({
  declarations: [ConfirmWithHighlightDialogueComponent],
  providers: [ConfirmWithHighlightDialogueService],
  imports: [CommonModule, TranslateModule],
  exports: [ConfirmWithHighlightDialogueComponent],
})
export class ConfirmWithHighlightDialogueModule {}

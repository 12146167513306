<form [formGroup]="form">
  <nz-textarea-count [nzMaxCharacterCount]="420">
    <textarea
      [class.invalid]="form.errors?.['maxNotes']"
      formControlName="note"
      nz-input
      [placeholder]="'general.add_comment' | translate"
      type="text"
      id="note"
      rows="4"
    ></textarea>
  </nz-textarea-count>
</form>

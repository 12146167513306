import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Shift,
  ShiftDispositionAssignable,
  ShiftDispositionAssignableTypes,
  ShiftTemplate,
} from '@wilson/interfaces';
import { ShiftDispositionState } from './state/shift-disposition.state';
import {
  ShiftTemplateService,
  ShiftTemplateStoreService,
} from '@wilson/shift-templates';
import { DateTimeFormat } from '@wilson/utils';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map, first, withLatestFrom } from 'rxjs/operators';
import {
  UpdateViewableRowCount,
  UpdateViewableRowIndex,
} from './state/shift-disposition.actions';

@Injectable()
export class ShiftDispositionsViewHelperService {
  constructor(
    private shiftTemplateService: ShiftTemplateService,
    private readonly shiftTemplateStoreService: ShiftTemplateStoreService,
    private store: Store,
  ) {
    this.shiftTemplateStoreService.findAllShiftTemplates();
  }

  getAssignableTemplates(): Observable<
    Omit<ShiftDispositionAssignable, 'date' | 'formattedDate'>[]
  > {
    return this.shiftTemplateStoreService.shiftTemplates$.pipe(
      first((m) => !!m.length),
      map((templates: ShiftTemplate[]) =>
        templates.map((template) => ({
          template: true,
          record: template,
          type: ShiftDispositionAssignableTypes.ShiftTemplate,
        })),
      ),
    );
  }

  public createAndAssignShift(
    userId: string,
    date: Date,
    shiftTemplate: ShiftTemplate,
  ): Promise<Shift> {
    return this.shiftTemplateService.createShiftFromTemplate(
      shiftTemplate.id as string,
      userId,
      format(date, DateTimeFormat.DatabaseDateFormat),
    );
  }

  handleAdditionalCalendarRendering(hasCalendarMoreSpaceToRender: boolean) {
    if (hasCalendarMoreSpaceToRender) {
      this.store
        .select(ShiftDispositionState.areAllFiltersEmpty)
        .pipe(
          withLatestFrom(
            this.store.select(ShiftDispositionState.largestViewedRowIndex),
            this.store.select(ShiftDispositionState.viewableRowCount),
          ),
          first(),
        )
        .subscribe(
          ([areAllFiltersEmpty, largestViewedRowIndex, viewableRowCount]: [
            boolean,
            number,
            number,
          ]) => {
            if (areAllFiltersEmpty) {
              this.store.dispatch(
                new UpdateViewableRowCount({
                  count: viewableRowCount + 22, // We bump by 22 since it is a good known amount
                }),
              );

              this.store.dispatch(
                new UpdateViewableRowIndex({
                  index: largestViewedRowIndex + 1,
                }),
              );
            }
          },
        );
    }
  }
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Shift } from '@wilson/interfaces';
import { AssignStaffPopupComponent } from '@wilson/shared-components';

@Injectable()
export class AssignStaffPopupService {
  constructor(private modalService: NgbModal) {}

  public openAssignStaffModal(shift: Pick<Shift, 'id' | 'name' | 'startDate'>) {
    const modalRef = this.modalService.open(AssignStaffPopupComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
    });
    (modalRef.componentInstance as AssignStaffPopupComponent).shift = shift;
    return modalRef.result;
  }
}

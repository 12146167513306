import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { EmptyComponent } from '../empty/empty.component';

@Component({
  selector: 'wilson-ag-grid-empty',
  standalone: true,
  imports: [CommonModule, EmptyComponent],
  templateUrl: './ag-grid-empty.component.html',
  styleUrl: './ag-grid-empty.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridEmptyComponent implements INoRowsOverlayAngularComp {
  message: string | null | undefined;

  public params!: INoRowsOverlayParams & { message: string };

  agInit(params: INoRowsOverlayParams & { message: string }): void {
    this.message = params.message;
  }
}

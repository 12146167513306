import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { CustomLocationsModule } from '@wilson/locations-v2/custom-locations';
import { FormsModule } from '@wilson/forms';
import { GeoLocation } from '@wilson/interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface LocationChangedEvent {
  location: GeoLocation | null;
  locationId: string;
}

@Component({
  selector: 'wilson-location-selects',
  standalone: true,
  imports: [
    CommonModule,
    FeatureFlagsModule,
    CustomLocationsModule,
    FormsModule,
  ],
  templateUrl: './location-selects.component.html',
  styleUrls: ['./location-selects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectsComponent),
      multi: true,
    },
  ],
})
export class LocationSelectsComponent implements ControlValueAccessor {
  protected location: GeoLocation | null | undefined;
  @Input({ required: true })
  sector!: string | undefined;

  @Input()
  set selectedLocation(value: GeoLocation | null | undefined) {
    this.location = value;
  }

  @Input() invalid = false;

  @Input() warning = false;

  @Input() disabled = false;

  @Input() readonly = false;
  @Input() tabindexValue!: number;
  @Input() placeholder!: string;

  @Output() locationChange = new EventEmitter<LocationChangedEvent>();
  @Output() touched = new EventEmitter<boolean>();

  constructor(private cdRef: ChangeDetectorRef) {}

  handleLocationSelect(location: GeoLocation | null) {
    const output: {
      location: GeoLocation | null;
      locationId: string;
    } = {
      location: null,
      locationId: '',
    };
    if (this.sector === 'rail') {
      output.locationId = location ? (location.id as string) : '';
    }

    output.location = location || null;

    this.locationChange.next(output);
    this.propagateChange(output.location);
  }

  writeValue(value: GeoLocation) {
    this.location = value;
    this.cdRef.detectChanges();
  }

  // eslint-disable-next-line
  propagateChange = (_: any) => {};

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched() {}
}

import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthState } from '@wilson/auth/core';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { OrganizationalUnitService } from '@wilson/organizational-unit';

@NgModule({
  imports: [CommonModule],
})
@Injectable()
export class NonDomainSpecificActivitiesHelpersServicesModule {
  constructor(
    private store: Store,
    private organizationalUnitService: OrganizationalUnitService,
  ) {}

  async findOrgUnitWithTransportGoods(
    accessibleOrgUnitsIdAndRootOrgUnit: string[],
  ): Promise<string | null> {
    for (const orgUnitId of accessibleOrgUnitsIdAndRootOrgUnit) {
      const organizationalUnit = await firstValueFrom(
        this.organizationalUnitService.getOrganizationalUnit(orgUnitId, [
          'organizationalUnitTransportGoods',
        ]),
      );

      if (
        organizationalUnit.organizationalUnitTransportGoods &&
        organizationalUnit.organizationalUnitTransportGoods.length > 0
      ) {
        return organizationalUnit.id as string;
      }
    }
    return null;
  }

  getAccessibleAndRootOrgUnitIds(): string[] {
    const rootOrgUnitId =
      this.store.selectSnapshot<string | undefined>(
        AuthState.organizationalUnitId,
      ) || '';

    const accessibleOrgUnits = this.store.selectSnapshot(
      AuthState.accessibleOrgUnits,
    );

    const accessibleOrgUnitsIds: string[] =
      (accessibleOrgUnits?.map((unit) => unit.id) as string[]) || [];

    const accessibleOrgUnitsIdAndRootOrgUnit: string[] = Array.from(
      new Set([rootOrgUnitId, ...accessibleOrgUnitsIds]),
    );

    return accessibleOrgUnitsIdAndRootOrgUnit;
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';
import { Ability } from '@casl/ability';
import { Store } from '@ngxs/store';
import { AtLeastOneRolePermissionGuard } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

export const atLeastOneRolePermissionGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> | UrlTree => {
  const ability = inject(Ability);
  const store = inject(Store);
  const router = inject(Router);

  const routePermission: AtLeastOneRolePermissionGuard =
    route.data.rolePermission;
  if (
    !routePermission ||
    !routePermission.action ||
    !routePermission.subjects ||
    !Array.isArray(routePermission.subjects) ||
    !routePermission.subjects.length
  ) {
    return router.createUrlTree(['/']);
  }

  return store
    .select((state) => state.permissions.rolePermissions)
    .pipe(
      skipWhile((permissions) => !permissions.length),
      map(() => {
        const hasAtLeastOnePermission = routePermission.subjects.some(
          (subject) => ability.can(routePermission.action, subject),
        );
        if (hasAtLeastOnePermission) {
          return true;
        }

        return router.createUrlTree(['/']);
      }),
    );
};

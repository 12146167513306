<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    #userPanel
    class="collapse-panel"
    [nzHeader]="userInfoSectionHeader"
    [nzActive]="isExpandedOnOpen"
    [nzShowArrow]="false"
    [nzExtra]="userInfoCollapseIcon"
  >
    <wilson-shift-assigned-user-identity
      [user]="user"
      [renderReassignButton]="renderReassignButton"
      (clicked)="clicked.emit()"
    >
      <ng-content></ng-content>
    </wilson-shift-assigned-user-identity>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #userInfoSectionHeader>
  <span class="header-text">
    {{ 'page.shifts.staff' | translate }}
  </span>
</ng-template>

<ng-template #userInfoCollapseIcon>
  <i
    class="fas fa-chevron-up"
    [ngClass]="userPanel.nzActive ? 'rotate-180' : 'rotate-0'"
  ></i>
</ng-template>

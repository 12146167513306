import { Injectable } from '@angular/core';
import { Base, ManyEntity } from '@wilson/base';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import {
  BehaviorSubject,
  catchError,
  defer,
  finalize,
  Observable,
  of,
  ReplaySubject,
  take,
  tap,
} from 'rxjs';

export function startWithTap<T>(callback: () => void) {
  return (source: Observable<T>) =>
    defer(() => {
      callback();
      return source;
    });
}

/**
 * @deprecated() - use the native grid api instead.
 */
@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line
export class GridService<T extends Base = any> {
  public gridApi: GridApi | undefined = undefined;
  public loading$ = new BehaviorSubject<boolean>(false);
  public quickFilterText = new ReplaySubject<string>(1);

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.quickFilterText.pipe(take(1)).subscribe((val) => {
      params.api.setGridOption('quickFilterText', val);
    });
    if (this.loading$.value) {
      this.gridApi.setGridOption('loading', true);
    }
  }

  public setQuickFilter(quickFilterString: string) {
    if (this.gridApi) {
      this.gridApi.setGridOption('quickFilterText', quickFilterString);
    } else {
      this.quickFilterText.next(quickFilterString);
    }
  }

  startWithLoad<K>() {
    return startWithTap<K>(() => {
      this.startLoad();
    });
  }

  tapLoad<K>() {
    return tap<K>(() => {
      this.startLoad();
    });
  }

  startLoad() {
    this.loading$.next(true);
    this.gridApi?.setGridOption('loading', true);
  }

  endLoad() {
    this.loading$.next(false);
    this.gridApi?.setGridOption('loading', false);
  }

  catchErrorContinueWithManyEntity<K>() {
    return catchError<K, Observable<ManyEntity<T>>>((err) => {
      console.warn(err);
      return of({ data: [], count: 0 });
    });
  }

  catchErrorContinue<K>() {
    return catchError<K, Observable<T[]>>((err) => {
      console.warn(err);
      return of([]);
    });
  }

  finalizeLoad<K>() {
    this.loading$.next(false);
    return finalize<K>(() => {
      this.gridApi?.setGridOption('loading', false);
    });
  }
}

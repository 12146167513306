import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { BillingSetting, BillingSettingPatchPayload } from '@wilson/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingSettingsService {
  private path = 'billing-settings';

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  get(): Observable<BillingSetting> {
    return this.httpClient.get<BillingSetting>(
      `${this.config.host}/${this.path}`,
    );
  }

  update(payload: BillingSettingPatchPayload): Observable<BillingSetting> {
    return this.httpClient.patch<BillingSetting>(
      `${this.config.host}/${this.path}`,
      payload,
    );
  }
}

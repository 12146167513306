import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Agreement } from '@wilson/clients/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgreementsService extends BackendService<Agreement> {
  protected readonly path = 'agreements';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }
  public addAgreementsToMultipleClients(
    agreements: Agreement[],
  ): Promise<Agreement[]> {
    return firstValueFrom(
      this.http.post<Agreement[]>(`${this.config.host}/${this.path}`, {
        items: agreements,
      }),
    );
  }

  bulkUpdateAgreements(agreements: Partial<Agreement[]>) {
    return firstValueFrom(
      this.http.patch(`${this.config.host}/${this.path}/bulk`, {
        items: agreements,
      }),
    );
  }
}

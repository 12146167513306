import { Injectable } from '@angular/core';
import { PlannedAndReportedTimeService } from '@wilson/activities/reports';
import { Activity } from '@wilson/interfaces';
import { compareAsc } from 'date-fns';

@Injectable()
export class ActivitySortService {
  constructor(
    private plannedAndReportedTimeService: PlannedAndReportedTimeService,
  ) {}

  sortActivitiesByReportedTime(activities: Activity[]): Activity[] {
    return [...activities].sort((a, b) => {
      const reportStartTime1 =
        this.plannedAndReportedTimeService.reportedStartTime(
          a.activityReports || [],
        );
      const reportStartTime2 =
        this.plannedAndReportedTimeService.reportedStartTime(
          b.activityReports || [],
        );

      return compareAsc(
        Date.parse(reportStartTime1 || ''),
        Date.parse(reportStartTime2 || ''),
      );
    });
  }
}

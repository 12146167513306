<div class="title-text align-items-center">
  {{ 'page.shift_timeline.stays.find_hotel' | translate }}
</div>
<button
  type="button"
  nz-button
  nzType="primary"
  class="hotel-button booking-button"
  (click)="openBooking()"
>
  <img class="w-100 h-100" src="assets/img/shift-timeline/hotel/booking.svg" />
</button>
<button
  type="button"
  nz-button
  nzType="default"
  class="hotel-button ms-2"
  (click)="openGoogle()"
>
  <img class="w-100 h-100" src="assets/img/shift-timeline/hotel/google.svg" />
</button>

import { Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PublicationStatus, Shift } from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { AssignShiftsModalComponent } from '../../components/assign-shifts-modal/assign-shifts-modal.component';
import { CanAllShiftBeModifiedPipe } from '../../pipes/can-all-shift-be-modified/can-all-shift-be-modified.pipe';

@Injectable()
export class AssignShiftHelperService {
  private _isAssignAllowedSignal = signal(false);
  public isAssignAllowedSignal = this._isAssignAllowedSignal.asReadonly();

  constructor(
    private readonly translate: TranslateService,
    private readonly nzModalService: NzModalService,
    private readonly shiftsService: ShiftsService,
    private readonly nzMessageService: NzMessageService,
    private readonly canAllShiftBeModified: CanAllShiftBeModifiedPipe,
  ) {}

  public updateShiftAssignmentPermission(selectedShifts: Shift[]) {
    const doShiftsMatchAssignConditions =
      this.canAllShiftBeModified.transform(selectedShifts);

    if (doShiftsMatchAssignConditions) {
      this._isAssignAllowedSignal.set(true);
    } else {
      this._isAssignAllowedSignal.set(false);
    }
  }

  public async assignShifts(
    selectedShifts: Shift[],
  ): Promise<string | undefined> {
    const isSingleShift = selectedShifts.length === 1;

    const modalRef: NzModalRef<AssignShiftsModalComponent> =
      this.nzModalService.create<AssignShiftsModalComponent>({
        nzTitle: this.translate.instant(
          isSingleShift
            ? 'shift.page.assign_shift_header'
            : 'shift.page.assign_shifts_header',
        ),
        nzContent: AssignShiftsModalComponent,
        nzClosable: false,
        nzCentered: true,
        nzFooter: [
          {
            label: this.translate.instant('general.cancel'),
            type: 'default',
            onClick: () => {
              modalRef.destroy();
            },
          },
          {
            label: this.translate.instant('general.apply'),
            type: 'primary',
            disabled: (modalInstance) => !modalInstance?.isInputValid,
            onClick: async (modalInstance) => {
              if (modalInstance) {
                const selectedUserId = modalInstance.selectedUserId;
                const hasPublishedShift = selectedShifts.some(
                  (shift) =>
                    shift.publicationStatus === PublicationStatus.Published,
                );
                if (hasPublishedShift) {
                  if (
                    await this.showShiftIsPublishedConfirmModal(isSingleShift)
                  ) {
                    modalRef.destroy(selectedUserId);
                  } else {
                    modalRef.destroy();
                  }
                } else {
                  modalRef.destroy(selectedUserId);
                }
              }
            },
          },
        ],
      });
    if (modalRef.componentInstance) {
      modalRef.componentInstance.isSingleShift = isSingleShift;
    }
    return await firstValueFrom(modalRef.afterClose);
  }

  private showShiftIsPublishedConfirmModal(isSingleShift: boolean) {
    const confirmModalRef = this.nzModalService.warning({
      nzTitle: this.translate.instant(
        isSingleShift ? 'shift.page.edit_shift' : 'shift.page.edit_shifts',
      ),
      nzContent: this.translate.instant(
        isSingleShift
          ? 'shift.page.move_shift_confirm'
          : 'shift.page.move_shifts_confirm',
      ),
      nzCancelText: this.translate.instant('general.cancel'),
      nzOkText: this.translate.instant('general.ok'),
      nzOkType: 'primary',
      nzOkDanger: false,
      nzCentered: true,
      nzOnOk: () => {
        confirmModalRef.close(true);
      },
    });
    return firstValueFrom(confirmModalRef.afterClose);
  }

  public async updateShifts(selectedShifts: Shift[], userId: string) {
    try {
      await firstValueFrom(
        this.shiftsService.updateShiftsBulk(
          selectedShifts.map((shift) => {
            return {
              id: shift.id,
              publicationStatus:
                shift.publicationStatus === PublicationStatus.Published
                  ? PublicationStatus.NotPublished
                  : shift.publicationStatus,
              userId: userId,
            };
          }),
        ),
      );
      this.nzMessageService.success(
        this.translate.instant(
          selectedShifts.length > 1
            ? 'shift.page.move_shifts_success'
            : 'shift.page.move_shift_success',
        ),
      );
    } catch {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
  }
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Activity, ActivityTemplate } from '@wilson/interfaces';

@Injectable()
export class ActivityEndRendererService {
  constructor(private datePipe: DatePipe) {}

  public getFormattedDate(data: Activity | ActivityTemplate) {
    return this.datePipe.transform(data.endDatetime, 'dd.MM.yy') + ',';
  }

  public getFormattedHour(data: Activity | ActivityTemplate) {
    return this.datePipe.transform(data.endDatetime, 'HH:mm');
  }

  public getEndLocation(
    data: Pick<Activity | ActivityTemplate, 'endLocation'>,
  ) {
    if (!data?.endLocation) return '---';
    return data?.endLocation?.locationCode + ' - ' + data?.endLocation?.name;
  }

  public toString(data: Activity) {
    return [
      this.getFormattedDate(data),
      this.getFormattedHour(data),
      this.getEndLocation(data),
    ].join(' ');
  }
}

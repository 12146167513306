import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimelineStayGateway } from '@wilson/api/gateway';
import { StayAttachment } from '@wilson/interfaces';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { LoadingStateComponent } from '@wilson/shared-components';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class StaysAttachmentsHelperService {
  @WilsonState<StayAttachment[]>([])
  stayAttachments!: StayAttachment[];

  @WilsonState<NzUploadFile[]>([])
  private invoiceToCreate!: NzUploadFile[];

  @WilsonState<StayAttachment[]>([])
  private invoiceToDelete!: StayAttachment[];

  @WilsonState<NzUploadFile[]>([])
  private attachmentsToCreate!: NzUploadFile[];

  @WilsonState<StayAttachment[]>([])
  private attachmentsToDelete!: StayAttachment[];

  constructor(
    private readonly timelineStayGateway: TimelineStayGateway,
    private translate: TranslateService,
    private nzMessageService: NzMessageService,
    private modal: NzModalService,
  ) {}

  public handleInvoice(files: NzUploadFile[]) {
    const existingAttachments = this.stayAttachments.filter(
      (attachment) => attachment.isInvoice,
    );

    this.invoiceToCreate = [];
    this.invoiceToDelete = [];

    const isFileCreated = files.length && !existingAttachments.length;
    const isFileOverwritten =
      files.length &&
      existingAttachments.length &&
      files[0].uid !== existingAttachments[0].id;
    const isFileDeleted = !files.length && existingAttachments.length;

    if (isFileCreated) {
      this.invoiceToCreate = files;
    } else if (isFileOverwritten) {
      this.invoiceToCreate = files;
      this.invoiceToDelete = existingAttachments;
    } else if (isFileDeleted) {
      this.invoiceToDelete = existingAttachments;
    }
  }

  public handleAttachments(files: NzUploadFile[]) {
    const existingAttachments = this.stayAttachments.filter(
      (attachment) => !attachment.isInvoice,
    );

    this.attachmentsToCreate = files.filter(
      (file) =>
        !existingAttachments.some((attachment) => attachment.id === file.uid),
    );
    this.attachmentsToDelete =
      existingAttachments.filter(
        (attachment) => !files.some((file) => file.uid === attachment.id),
      ) || [];
  }

  public async handleFileUpload(stayId: string): Promise<void> {
    try {
      if (this.invoiceToDelete.length) {
        await this.deleteAttachment(stayId, this.invoiceToDelete[0].id);
        this.invoiceToDelete = [];
      }
      if (this.invoiceToCreate.length) {
        await this.uploadAttachment(stayId, this.invoiceToCreate[0], true);
        this.invoiceToCreate = [];
      }

      if (this.attachmentsToDelete.length) {
        for (const attachment of this.attachmentsToDelete) {
          await this.deleteAttachment(stayId, attachment.id);
        }
        this.attachmentsToDelete = [];
      }
      if (this.attachmentsToCreate.length) {
        for (const attachment of this.attachmentsToCreate) {
          await this.uploadAttachment(stayId, attachment);
        }
        this.attachmentsToCreate = [];
      }
    } catch (error) {
      console.error('An error occurred while uploading a attachments:', error);
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
  }

  private uploadAttachment(
    stayId: string,
    attachment: NzUploadFile,
    isInvoice = false,
  ): Promise<StayAttachment> {
    return firstValueFrom(
      this.timelineStayGateway.uploadStayAttachment(
        stayId,
        attachment,
        isInvoice,
      ),
    );
  }

  private deleteAttachment(
    stayId: string,
    attachmentId: string,
  ): Promise<StayAttachment> {
    return this.timelineStayGateway.deleteStayAttachment(stayId, attachmentId);
  }

  public openLoaderDialog() {
    const modalRef = this.modal.create({
      nzContent: LoadingStateComponent,
      nzCentered: true,
      nzKeyboard: false,
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: null,
    });
    if (modalRef.componentInstance) {
      modalRef.componentInstance.loadingMessageTranslationKey =
        'shift.page.accept.loading';
    }
    return modalRef;
  }
}

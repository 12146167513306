import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { InvoiceItem } from '@wilson/invoicing/interfaces';

@Injectable({
  providedIn: 'root',
})
export class InvoicingItemsGateway {
  protected readonly path = 'invoice-items';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getInvoiceItems(activityIds: string[]) {
    return this.http.post<InvoiceItem[]>(`${this.config.host}/${this.path}`, {
      activityIds,
    });
  }
}

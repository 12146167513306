import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateActivityCategoryPipeModule } from '@wilson/activities/pipes';
import { Activity } from '@wilson/interfaces';

@Component({
  selector: 'wilson-activity-conflict-popup',
  templateUrl: './activity-conflict-popup.component.html',
  styleUrls: ['./activity-conflict-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateActivityCategoryPipeModule],
})
export class ActivityConflictPopupComponent {
  @Input() serviceActivities: Activity[];
  @Input() canEditServiceActivities = false;

  constructor(private activeModal: NgbActiveModal) {}

  public decline(): void {
    this.activeModal.close(false);
  }

  public accept(): void {
    this.activeModal.close(true);
  }
}

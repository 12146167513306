import { Component } from '@angular/core';
import { DateTimeFormat } from '@wilson/utils';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';

@Component({
  selector: 'wilson-template-activity-start-time-renderer',
  template: `
    <div>
      <span class="text-dark">
        {{ cellValue }}
      </span>
      <br />
      <span class="text-dark text-truncate">
        {{ location }}
      </span>
    </div>
  `,
  styles: [
    `
      span {
        line-height: 5px;
      }
    `,
  ],
})
export class TemplateActivityStartTimeRendererComponent
  implements AgRendererComponent
{
  public cellValue!: string;
  public location!: string;

  async agInit(params: ICellRendererParams) {
    this.cellValue = format(
      new Date(params.value.startDatetime),
      DateTimeFormat.TimeFormat,
    );
    this.location = `${params.value.startLocation.locationCode} - ${params.value.startLocation.name}`;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    return true;
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ShiftNotesComponent } from '../../shift-notes/shift-notes.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'wilson-collapsible-shift-note',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    AuthorizationModule,
    FeatureFlagsModule,
    TranslateModule,
    ShiftNotesComponent,
    NzDividerModule,
  ],
  templateUrl: './collapsible-shift-note.component.html',
  styleUrl: './collapsible-shift-note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftNoteComponent {
  @Input() shiftId!: string | undefined;
  @Input() isExpandedOnOpen = false;
}

export enum ProviderProposalResponse {
  Open = 'open',
  Accepted = 'accepted',
  Declined = 'declined',
  ExpiredDeclined = 'expired-declined',
}

export enum ClientProposalResponse {
  Open = 'open',
  Accepted = 'accepted',
  Declined = 'declined',
  AutoDeclined = 'auto-declined',
  Withdrawn = 'withdrawn',
}

<input
  nz-input
  [placeholder]="
    'page.shift_timeline.hotel_stay.drawer.form.accomodation_name.placeholder'
      | translate
  "
  (input)="onSearch($event.target?.value)"
  [disabled]="disabled"
  [ngClass]="disabled ? 'form-control-disabled' : 'form-control'"
  class="text-dark"
  [ngModel]="accomodationName"
  (ngModelChange)="prefillSelectedStay($event)"
  [nzAutocomplete]="customAutoComplete"
/>
<nz-autocomplete #customAutoComplete>
  <nz-auto-option
    *ngFor="let stay of filteredStays$ | async"
    [nzValue]="stay"
    [nzLabel]="stay.accommodationName"
  >
    {{ stay.accommodationName }}
  </nz-auto-option>
</nz-autocomplete>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ActivityNameParams,
  ResolvedService,
  ServiceSeries,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-name-renderer',
  template: `
    <div>
      <div class="text-dark text-truncate">
        {{ cellValue ? ('activity_category.' + cellValue | translate) : '---' }}
        <span *ngIf="activityName">- {{ activityName }}</span>
      </div>
      <div class="lh-m">
        <div *ngIf="service || serviceSeries; else notLink">
          <div *ngIf="!isProvider; else provider">
            <a
              *ngIf="service"
              class="link-dark"
              [routerLink]="'/services/' + service.id"
            >
              {{ service.name }}
            </a>
            <a
              *ngIf="serviceSeries"
              class="link-dark"
              [routerLink]="'/service-series/' + serviceSeries.id"
            >
              {{ serviceSeries.name }}
            </a>
          </div>
          <ng-template #provider>
            <div *ngIf="service">
              {{ service.name }}
            </div>
            <div *ngIf="serviceSeries">
              {{ serviceSeries.name }}
            </div>
          </ng-template>
        </div>

        <ng-template #notLink>---</ng-template>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityNameRendererComponent implements ICellRendererAngularComp {
  public cellValue!: string;
  public activityName!: string;
  public service!: ResolvedService;
  public isProvider: boolean;
  public serviceSeries: ServiceSeries;

  async agInit(params: ICellRendererParams & ActivityNameParams) {
    this.cellValue = params.value;
    this.activityName = params.data.name;
    this.service = params.data.service;
    this.serviceSeries = params.data.serviceSeries;
    this.isProvider = params.isProvider && params.isProvider();
  }

  refresh(params: ICellRendererParams & ActivityNameParams) {
    this.cellValue = params.value;
    this.activityName = params.data.name;
    this.service = params.data.service;
    this.serviceSeries = params.data.serviceSeries;
    this.isProvider = params.isProvider && params.isProvider();
    return true;
  }
}

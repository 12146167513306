import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { ActivityTemplate } from '@wilson/interfaces';

@Pipe({
  name: 'activityTemplateIsSharedByClient',
  standalone: true,
})
export class ActivityTemplateIsSharedByClientPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(activityTemplate: ActivityTemplate) {
    const accessibleOrgUnits = this.store.selectSnapshot(
      AuthState.accessibleOrgUnits,
    );
    const jobActivityTemplate =
      activityTemplate.jobActivityTemplates &&
      activityTemplate.jobActivityTemplates[0];
    const isShared = !!jobActivityTemplate?.jobId;
    return (
      isShared &&
      !accessibleOrgUnits?.find(
        (orgUnit) => orgUnit.id === jobActivityTemplate.jobId,
      )
    );
  }
}

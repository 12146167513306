import { Base } from '@wilson/base';
import { Activity } from '../activities/activity';
import { Certificate } from '../certificates/certificate';
import { Country } from '../countries/country';
import { Language } from '../languages/language';
import { GeoLocation } from '../locations/geoLocation';
import { QualificationCategory } from '../qualification-categories/qualificationCategory';
import { DriverLicense } from '../qualifications/DriverLicense';
import { FurtherEducation } from '../qualifications/FurtherEducation';
import { MedicalExamination } from '../qualifications/MedicalExamination';
import { OtherSubQualification } from '../qualifications/OtherSubQualifications';
import { Route } from '../routes/routes';
import { SafetyCertificate } from '../safety-certificate/SafetyCertificate';
import { Training } from '../trainings/training';
import { VehicleModel } from '../vehicles/vehicle';

export type ActivityQualifications =
  | ActivityCertificateQualification
  | ActivityCountryQualification
  | ActivityDriverLicenseQualification
  | ActivityFurtherEducationQualification
  | ActivityLanguageQualification
  | ActivityLocationQualification
  | ActivityMedicalExaminationQualification
  | ActivityOtherSubQualificationQualification
  | ActivityRouteQualification
  | ActivitySafetyCertificateQualification
  | ActivityTrainingQualification
  | ActivityVehicleQualification;

export enum QualificationIdKey {
  Certificate = 'certificateId',
  Country = 'countryId',
  DriverLicense = 'driverLicenseId',
  FurtherEducation = 'furtherEducationId',
  GeoLocation = 'geoLocationId',
  Language = 'languageId',
  MedicalExamination = 'medicalExaminationId',
  OtherQualificationSubCategory = 'otherQualificationSubCategoryId',
  Route = 'routeId',
  SafetyCertificate = 'safetyCertificateId',
  Training = 'trainingId',
  VehicleModel = 'vehicleModelId',
}

export interface ActivityQualification extends Base {
  activity: Activity;
  activityId: string;
  qualificationCategory: QualificationCategory;
  qualificationCategoryId: string;
}

export interface ActivityCertificateQualification
  extends ActivityQualification {
  certificate: Certificate;
  [QualificationIdKey.Certificate]: string;
}

export interface ActivityCountryQualification extends ActivityQualification {
  country: Country;
  [QualificationIdKey.Country]: string;
}

export interface ActivityDriverLicenseQualification
  extends ActivityQualification {
  driverLicense: DriverLicense;
  [QualificationIdKey.DriverLicense]: string;
}

export interface ActivityFurtherEducationQualification
  extends ActivityQualification {
  furtherEducation: FurtherEducation;
  [QualificationIdKey.FurtherEducation]: string;
}

export interface ActivityLanguageQualification extends ActivityQualification {
  language: Language;
  [QualificationIdKey.Language]: string;
}

export interface ActivityLocationQualification extends ActivityQualification {
  geoLocation: GeoLocation;
  [QualificationIdKey.GeoLocation]: string;
}

export interface ActivityMedicalExaminationQualification
  extends ActivityQualification {
  medicalExamination: MedicalExamination;
  [QualificationIdKey.MedicalExamination]: string;
}

export interface ActivityOtherSubQualificationQualification
  extends ActivityQualification {
  otherQualificationSubCategory: OtherSubQualification;
  [QualificationIdKey.OtherQualificationSubCategory]: string;
}

export interface ActivityRouteQualification extends ActivityQualification {
  route: Route;
  [QualificationIdKey.Route]: string;
}

export interface ActivityTrainingQualification extends ActivityQualification {
  training: Training;
  [QualificationIdKey.Training]: Training['id'];
}

export interface ActivityVehicleQualification extends ActivityQualification {
  vehicleModel: VehicleModel;
  [QualificationIdKey.VehicleModel]: string;
}

export interface ActivitySafetyCertificateQualification
  extends ActivityQualification {
  safetyCertificate: SafetyCertificate;
  [QualificationIdKey.SafetyCertificate]: string;
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wilson-share-job-created-at',
  template: `
    <div class="text-dark">
      {{ dateValue ? (dateValue | date : 'dd.MM.yy, HH:mm') : '---' }}
    </div>
    <span class="list-header text-dark">
      {{ dateValue | formatDistanceFromToday }}
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareJobCreatedAtComponent {
  @Input()
  dateValue: Date;
}

import { LinkRendererService } from '@wilson/ag-grid/service';
import { LinkRendererParams, LinkType } from '@wilson/interfaces';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-link-renderer-with-icon',
  template: `
    <div class="link-renderer-container">
      <wilson-link-renderer-content
        [linkData]="linkData"
      ></wilson-link-renderer-content>
      <div>
        <img
          nz-tooltip
          [nzTooltipTitle]="''"
          nzTooltipOverlayClassName="dark"
          nzTooltipPlacement="top"
          *ngIf="isEdited"
          class="w-100 form-icon"
          src="/assets/img/service/step-over-service.svg"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .link-renderer-container {
        display: flex;
        justify-content: space-between;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesLinkRendererWithIconComponent
  implements ICellRendererAngularComp
{
  linkData: LinkType | undefined;
  isEdited: boolean;

  constructor(private linkRenderer: LinkRendererService) {}

  agInit(params: ICellRendererParams & LinkRendererParams): void {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
    this.isEdited = !!params.data.serviceSeriesEditLogId;
  }

  refresh(params: ICellRendererParams & LinkRendererParams) {
    this.linkData = this.linkRenderer.populateStringAndLink(params);
    this.isEdited = !!params.data.serviceSeriesEditLogId;
    return true;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PipesModule } from '@wilson/pipes';
import { GeoLocation, User } from '@wilson/interfaces';

@Component({
  selector: 'wilson-location-renderer',
  standalone: true,
  imports: [CommonModule, PipesModule],
  templateUrl: './location-renderer.component.html',
  styleUrls: ['./location-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRendererComponent implements AgRendererComponent {
  cellValue: GeoLocation | null | undefined;

  agInit(params: ICellRendererParams<User>): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams<User>) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams<User>) {
    return params?.data?.homeLocation;
  }
}

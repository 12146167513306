import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsShiftPublishedPipe } from './is-shift-published/is-shift-published.pipe';
import { IsShiftSelfDispatchedPipe } from './is-shift-self-dispatched/is-shift-selfdispatched.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsShiftPublishedPipe, IsShiftSelfDispatchedPipe],
  exports: [IsShiftPublishedPipe, IsShiftSelfDispatchedPipe],
  providers: [IsShiftPublishedPipe],
})
export class ShiftHelpersPipesModule {}

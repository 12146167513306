import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ValueGetterService } from '@wilson/ag-grid/value-getter';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-railml-validity-renderer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div>{{ cellValue }}</div> `,
})
export class RailMLValidityRendererComponent implements AgRendererComponent {
  public cellValue: string;

  constructor(public readonly valueGetterService: ValueGetterService) {}

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return this.valueGetterService.serviceSeriesValidityValueGetter(
      params as ValueGetterParams,
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import {
  ActivityCategory,
  ActivityCategoryContract,
  ActivityCategoryContractMap,
  ActivityCategoryDefaultSettingMap,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { addDays, format } from 'date-fns';
import {
  ActivityTimeOverday,
  ReportedTimeService,
  ShiftDurationService,
} from '../../..';
import { PipesModule } from '@wilson/pipes';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'wilson-calculate-working-hour',
  templateUrl: './calculate-working-hour.component.html',
  styleUrls: ['./calculate-working-hour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PipesModule, ActivityTimeOverday],
})
export class CalculateWorkingHourComponent implements OnChanges {
  @Input() isUserAssigned = false;
  @Input() assignedUserActivityCategoryContracts: ActivityCategoryContract[] =
    [];
  @Input() startDate: string = null;
  @Input() activities: ResolvedActivityWithReports[] = [];
  @Input() activityCategories: ActivityCategory[] = [];
  @Input() reportedTime = false;
  @Input() disabled = false;
  @Input() shiftSeries = false;
  public workingHours = 0.0;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly reportedTimeService: ReportedTimeService,
    private readonly activityTimeOverday: ActivityTimeOverday,
    private readonly shiftDurationService: ShiftDurationService,
  ) {}

  ngOnChanges() {
    this.calculateWorkingHour();
  }

  async calculateWorkingHour() {
    let shiftDuration: number;
    if (this.reportedTime) {
      shiftDuration =
        this.shiftDurationService.calculateDurationForReportedTimes(
          this.activities,
          this.startDate,
          this.disabled,
          this.shiftSeries,
        );
    } else {
      shiftDuration =
        this.shiftDurationService.calculateDurationForPlannedTimes(
          this.activities,
          this.startDate,
          this.disabled,
          this.shiftSeries,
        );
    }
    this.activities = this.reportedTime
      ? this.activities
      : this.activities.filter(
          (activity) => activity.createdFrom !== WilsonApp.Mobile,
        );

    const nonProtectiveWorkingTimeActivities =
      this.filterNonProtectiveWorkingTimeActivities(this.activities);

    let countHour = 0.0;
    nonProtectiveWorkingTimeActivities.forEach((activity) => {
      const activityIndexInAllActivities = this.activities.findIndex(
        (a) => a.id === activity.id,
      );
      const prevStartDate = this.startDate
        ? format(
            addDays(
              new Date(this.startDate),
              this.activityTimeOverday.transform(
                this.activities,
                this.startDate,
                activityIndexInAllActivities,
                true,
                this.disabled,
                this.shiftSeries,
              ),
            ),
            DateTimeFormat.DatabaseDateFormat,
          )
        : null;
      const newStartDate = this.startDate
        ? format(
            addDays(
              new Date(this.startDate),
              this.activityTimeOverday.transform(
                this.activities,
                this.startDate,
                activityIndexInAllActivities,
                false,
                this.disabled,
                this.shiftSeries,
              ),
            ),
            DateTimeFormat.DatabaseDateFormat,
          )
        : null;
      if (this.reportedTime) {
        const reportedStart = this.reportedTimeService.reportedStartTime(
          activity.activityReports,
        );
        const reportedEnd = this.reportedTimeService.reportedEndTime(
          activity.activityReports,
        );
        countHour =
          countHour +
          (!reportedEnd
            ? 0
            : Number(
                this.calculateDate(
                  prevStartDate,
                  newStartDate,
                  reportedStart,
                  reportedEnd,
                ),
              ));
      } else {
        countHour =
          countHour +
          Number(
            this.calculateDate(
              prevStartDate,
              newStartDate,
              activity.startDatetime,
              activity.endDatetime,
            ),
          );
      }
    });

    this.workingHours = shiftDuration - countHour;
    this.cd.detectChanges();
  }

  calculateDate(
    prevStartDate: string,
    startDate: string,
    startTime?: string,
    endTime?: string,
  ): number {
    if (startDate && startTime && endTime) {
      const newStartTime = startTime.includes('Z')
        ? startTime
        : `${prevStartDate} ${startTime}`;
      const newEndTime = endTime.includes('Z')
        ? endTime
        : `${startDate} ${endTime}`;

      const startDateTime = new Date(newStartTime).setSeconds(0);
      const endDateTime = new Date(newEndTime).setSeconds(0);
      return Math.round((endDateTime - startDateTime) / 60000) / 60;
    } else {
      return 0.0;
    }
  }

  private filterNonProtectiveWorkingTimeActivities(
    activities: ResolvedActivityWithReports[],
  ) {
    const activityCategoryContracts = this.isUserAssigned
      ? this.assignedUserActivityCategoryContracts
      : [];
    const activityCategoryContractMap = Object.fromEntries(
      activityCategoryContracts?.map((a) => [a.activityCategoryId, a]) || [],
    );

    const activityCategoryDefaultSettingMap = Object.fromEntries(
      this.activityCategories?.map((a) => [
        a.id,
        a.activityCategoryDefaultSetting,
      ]) || [],
    );

    return activities.filter(
      (activity) =>
        activity.activityCategoryId &&
        !this.isActivityCategoryProtectiveWorkingTime(
          activity.activityCategoryId,
          activityCategoryContractMap,
          activityCategoryDefaultSettingMap,
        ),
    );
  }

  private isActivityCategoryProtectiveWorkingTime(
    activityCategoryId: string,
    activityCategoryContractMap: ActivityCategoryContractMap,
    activityCategoryDefaultSettingMap: ActivityCategoryDefaultSettingMap,
  ): boolean {
    const activityCategoryContract =
      activityCategoryContractMap[activityCategoryId];
    if (activityCategoryContract) {
      return activityCategoryContract?.isProtectiveWorkingTime;
    }

    return activityCategoryDefaultSettingMap[activityCategoryId]
      ?.isProtectiveWorkingTime;
  }
}

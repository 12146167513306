import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ActivityCategory,
  ActivityQualifications,
  ActivityTemplate,
  ActivityTemplateQualifications,
  Certificate,
  Country,
  DriverLicense,
  FurtherEducation,
  GeoLocation,
  Language,
  MedicalExamination,
  OtherSubQualification,
  Qualifiable,
  Qualifiables,
  QualificationCategory,
  QualificationCategoryName,
  QualificationIdKey,
  Route,
  SafetyCertificate,
  VehicleModel,
} from '@wilson/interfaces';
import { RoutesService } from '@wilson/route';
import { ActivityTemplateQualificationsService } from '../../../../lib/activity-template-qualifications.service';
import { ActivityQualificationsService } from '../../../../lib/activity-qualifications.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityQualificationsModalDataService {
  constructor(
    private readonly translate: TranslateService,
    private readonly activityQualificationsService: ActivityQualificationsService,
    private readonly routesService: RoutesService,
    private readonly activityTemplateQualificationsService: ActivityTemplateQualificationsService,
  ) {}

  getQualificationIds(qualifications: ActivityQualifications[]): string[] {
    return qualifications.map((qualification) => {
      const qualificationIdKey = Object.values(QualificationIdKey).find(
        (keyName: QualificationIdKey) => !!qualification[keyName],
      );

      return qualification[qualificationIdKey];
    });
  }

  getQualificationName(
    qualificationCategory: QualificationCategory,
    qualification: Qualifiables,
  ) {
    switch (qualificationCategory.name) {
      case QualificationCategoryName.Certificate:
        return this.translate.instant(
          `certificate.${(qualification as Certificate).name}`,
        );
      case QualificationCategoryName.Country:
        return this.translate.instant(
          `country.${(qualification as Country).countryCode}`,
        );
      case QualificationCategoryName.DriverLicense:
        return this.translate.instant(
          `driverLicense.${(qualification as DriverLicense).name}`,
        );
      case QualificationCategoryName.FurtherEducation:
        return this.translate.instant(
          `furtherEducation.${(qualification as FurtherEducation).name}`,
        );
      case QualificationCategoryName.Language:
        return this.translate.instant(
          `language.${(qualification as Language).languageCode}`,
        );
      case QualificationCategoryName.Location:
        return (qualification as GeoLocation).name;
      case QualificationCategoryName.MedicalExamination:
        return this.translate.instant(
          `medicalExamination.${(qualification as MedicalExamination).name}`,
        );
      case QualificationCategoryName.More:
        return this.translate.instant(
          `otherQualification.${(qualification as OtherSubQualification).name}`,
        );
      case QualificationCategoryName.Route:
        return (qualification as Route).name;
      case QualificationCategoryName.Training:
        return this.translate.instant(
          `training.${(qualification as ActivityCategory).name}`,
        );
      case QualificationCategoryName.Vehicle:
        return (qualification as VehicleModel).name;
      case QualificationCategoryName.SafetyCertificate:
        return (qualification as SafetyCertificate).name;
      default:
        return '';
    }
  }

  compareActivityQualifications(
    activityQualification:
      | ActivityQualifications
      | ActivityTemplateQualifications,
    qualifiable: Qualifiable,
    key: QualificationIdKey,
  ): boolean {
    return (
      activityQualification[key] === qualifiable.qualification.id &&
      activityQualification.qualificationCategoryId ===
        qualifiable.qualificationCategory.id
    );
  }

  async setActivityQualifications(
    selectedQualifiables: Qualifiable[],
    activityId: string,
    oldActivityQualifications: ActivityQualifications[],
  ): Promise<void> {
    if (activityId) {
      for (const category in QualificationCategoryName) {
        const filteredQualifiablesByCategory = selectedQualifiables.filter(
          (selectedQualifiable) =>
            selectedQualifiable.qualificationCategory.name ===
            QualificationCategoryName[category],
        );
        const filteredOldActivityQualifications =
          oldActivityQualifications.filter(
            (oldActivityQualification) =>
              oldActivityQualification.qualificationCategory.name ===
              QualificationCategoryName[category],
          );
        await this.setActivityQualificationsByCategory(
          filteredQualifiablesByCategory,
          activityId,
          filteredOldActivityQualifications,
          QualificationCategoryName[category],
        );
      }
    }
  }

  async setActivityQualificationsByCategory(
    filteredQualifiablesByCategory: Qualifiable[],
    activityId: string,
    oldActivityQualifications: ActivityQualifications[],
    qualificationCategory: QualificationCategoryName,
  ): Promise<void> {
    switch (qualificationCategory) {
      case QualificationCategoryName.Certificate: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.Certificate,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.Country: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.Country,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.DriverLicense: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.DriverLicense,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.FurtherEducation: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.FurtherEducation,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.Language: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.Language,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.Location: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.GeoLocation,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.MedicalExamination: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.MedicalExamination,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.More: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.OtherQualificationSubCategory,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.Route: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.Route,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.Training: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.Training,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.Vehicle: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.VehicleModel,
          qualificationCategory,
        );
      }
      case QualificationCategoryName.SafetyCertificate: {
        return await this.setActivityQualificationsByKeyAndCategory(
          filteredQualifiablesByCategory,
          activityId,
          oldActivityQualifications,
          QualificationIdKey.SafetyCertificate,
          qualificationCategory,
        );
      }
      default:
        return;
    }
  }

  async setActivityQualificationsByKeyAndCategory(
    qualifiables: Qualifiable[],
    activityId: string,
    oldActivityQualifications: ActivityQualifications[],
    key: QualificationIdKey,
    qualificationCategory: QualificationCategoryName,
  ): Promise<void> {
    // eslint-disable-next-line
    const selectedActivityQualifications: any[] =
      await this.prepareActivityQualificationObjects(
        qualifiables,
        activityId,
        key,
      );
    await this.deleteActivityQualificationsByKeyAndCategory(
      selectedActivityQualifications,
      oldActivityQualifications,
      key,
      qualificationCategory,
    );
    await this.createActivityQualificationsByKeyAndCategory(
      selectedActivityQualifications,
      oldActivityQualifications,
      key,
      qualificationCategory,
    );
  }

  async prepareActivityQualificationObjects(
    qualifiables: Qualifiable[],
    activityId: string,
    key: QualificationIdKey,
  ) {
    const activityQualifications = [];
    for (const qualifiable of qualifiables) {
      // id is set to null for temporary routes
      if (key === QualificationIdKey.Route && !qualifiable.qualification.id) {
        try {
          delete qualifiable.qualification.id;
          const newRoute = await this.routesService.createRoutes(
            qualifiable.qualification as Route,
          );
          qualifiable.qualification.id = newRoute[0].id;
        } catch (err) {
          console.log(err); // sentry logging could be used here
          continue;
        }
      }

      activityQualifications.push({
        activityId: activityId,
        [key]: qualifiable.qualification.id,
        qualificationCategoryId: qualifiable.qualificationCategory.id,
      });
    }

    return activityQualifications;
  }

  async createActivityQualificationsByKeyAndCategory(
    activityQualifications: ActivityQualifications[],
    oldActivityQualifications: ActivityQualifications[],
    key: QualificationIdKey,
    qualificationCategory: QualificationCategoryName,
  ): Promise<ActivityQualifications[]> {
    const activityQualificationsToCreate = activityQualifications.filter(
      (activityQualification) =>
        !oldActivityQualifications.find(
          (oldActivityQualification) =>
            activityQualification[key] === oldActivityQualification[key] &&
            activityQualification.qualificationCategoryId ===
              oldActivityQualification.qualificationCategoryId,
        ),
    );

    if (activityQualificationsToCreate?.length) {
      return await this.activityQualificationsService.createActivityQualificationByCategory(
        activityQualificationsToCreate,
        qualificationCategory,
      );
    }

    return null;
  }

  async deleteActivityQualificationsByKeyAndCategory(
    activityQualifications: ActivityQualifications[],
    oldActivityQualifications: ActivityQualifications[],
    key: QualificationIdKey,
    qualificationCategory: QualificationCategoryName,
  ) {
    return await Promise.all([
      oldActivityQualifications.map(
        // eslint-disable-next-line
        async (oldActivityQualification: any) => {
          if (
            !activityQualifications.find(
              (activityQualification) =>
                activityQualification[key] === oldActivityQualification[key] &&
                activityQualification.qualificationCategoryId ===
                  oldActivityQualification.qualificationCategoryId,
            )
          ) {
            return await this.activityQualificationsService.deleteActivityQualificationByCategory(
              oldActivityQualification.id,
              qualificationCategory,
            );
          }
        },
      ),
    ]);
  }

  async setActivityTemplateQualifications(
    selectedQualifiables: Qualifiable[],
    activityTemplate: ActivityTemplate,
    oldActivityTemplateQualifications: ActivityTemplateQualifications[],
  ) {
    const activityTemplateQualifications: Partial<ActivityTemplateQualifications>[] =
      [];

    for (const selectedQualifiable of selectedQualifiables) {
      if (
        selectedQualifiable.qualificationCategory.name === 'route' &&
        !selectedQualifiable.qualification.id
      ) {
        try {
          delete selectedQualifiable.qualification.id;
          const newRoute = await this.routesService.createRoutes(
            selectedQualifiable.qualification as Route,
          );
          selectedQualifiable.qualification.id = newRoute[0].id;
        } catch (err) {
          console.log(err); // sentry logging could be used here
          continue;
        }
      }

      const activityTemplateQualification: Partial<ActivityTemplateQualifications> =
        {
          qualificationRefId: selectedQualifiable.qualification.id,
          appliesToServiceSeries: null,
          qualificationCategoryId: selectedQualifiable.qualificationCategory.id,
        };

      let existingActivityTemplateQualification;

      switch (selectedQualifiable.qualificationCategory.name) {
        case QualificationCategoryName.Certificate: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.Certificate,
                ),
            );
          break;
        }
        case QualificationCategoryName.Country: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.Country,
                ),
            );
          break;
        }
        case QualificationCategoryName.DriverLicense: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.DriverLicense,
                ),
            );
          break;
        }
        case QualificationCategoryName.FurtherEducation: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.FurtherEducation,
                ),
            );
          break;
        }
        case QualificationCategoryName.Language: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.Language,
                ),
            );
          break;
        }
        case QualificationCategoryName.Location: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.GeoLocation,
                ),
            );
          break;
        }
        case QualificationCategoryName.MedicalExamination: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.MedicalExamination,
                ),
            );
          break;
        }
        case QualificationCategoryName.More: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.OtherQualificationSubCategory,
                ),
            );
          break;
        }
        case QualificationCategoryName.Route: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.Route,
                ),
            );
          break;
        }
        case QualificationCategoryName.Training: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.Training,
                ),
            );
          break;
        }
        case QualificationCategoryName.Vehicle: {
          existingActivityTemplateQualification =
            oldActivityTemplateQualifications.find(
              (oldActivityTemplateQualification) =>
                this.compareActivityQualifications(
                  oldActivityTemplateQualification,
                  selectedQualifiable,
                  QualificationIdKey.VehicleModel,
                ),
            );
          break;
        }
      }

      if (existingActivityTemplateQualification)
        activityTemplateQualification.id =
          existingActivityTemplateQualification.id;
      activityTemplateQualifications.push(activityTemplateQualification);
    }

    await this.activityTemplateQualificationsService.setActivityTemplateQualifications(
      {
        ...activityTemplate,
        activityTemplateQualifications: activityTemplateQualifications,
      },
    );
  }
}

<ng-container *ngIf="'portal-delays-on-services' | featureFlagPure | async">
  <ng-container
    *ngIf="
      activity?.serviceDeviations
        | deviationTime : affectedStop : plannedDate as deviation
    "
  >
    <wilson-activity-time-tag
      [activityTimeDifference]="deviation.deviationInMinutes"
      [activityTimeStatus]="ActivityDeviationStatus.Deviated"
      [accurateTime]="deviation.timeWithDeviation"
    ></wilson-activity-time-tag>
  </ng-container>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Shift, ShiftTemplate } from '@wilson/interfaces';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShiftTemplateService extends BackendService<ShiftTemplate> {
  protected readonly path = 'shift-templates';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public checkNameAlreadyExists(shiftTemplateName: string) {
    return this.getAll({
      where: {
        name: shiftTemplateName,
      },
    }).pipe(
      map((shiftTemplates: ShiftTemplate[]) => {
        return !!shiftTemplates.length;
      }),
    );
  }

  public createShiftFromTemplate(
    templateId: string,
    userId: string,
    dateString: string,
  ) {
    return firstValueFrom(
      this.http.post<Shift>(
        `${this.config.host}/${this.path}/${templateId}/shift`,
        {
          userId,
          date: dateString, // e.g. 2022-07-20
        },
      ),
    );
  }
}

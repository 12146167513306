import { Base } from '@wilson/base';
import { OperativeReportStatus, Shift, User } from '..';
import { Activity } from '../activities/activity';

export interface OperativeReportsCategory {
  id: string;
  name: string;
  priorityDefault: string;
  canEdit?: boolean;
}

export interface OperativeReport extends Base {
  activityId?: string | null;
  shiftId: string;
  affectsTo?: AffectsTo | AffectsTo[];
  categoryId: string;
  comment?: string | null;
  delayInMinutes?: number | null;
  occurredAt: Date;
  priority?: string;
  receivedAt?: string;
  senderUserId?: string;
  status?: OperativeReportStatus;
  activity?: Activity;
  updatedBy?: User;
  operativeReportCategory?: OperativeReportsCategory;
  sender?: OpUser | string;
  senderUser?: User;
  serviceName?: string;
  shift?: Shift;
}

export enum OpUser {
  User = 'user',
  System = 'system',
}

export interface AffectsTo {
  id: string;
  entityName: EntityName;
}

export enum EntityName {
  Shift = 'Shift',
  Service = 'Service',
  Activity = 'Activity',
}

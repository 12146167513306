<div class="modal-header">
  <div class="d-flex align-items-center flex-fill m-0">
    <span class="fa-stack fa-1x text-secondary">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-clock fa-stack-1x fa-inverse"></i>
    </span>
    <div class="d-flex flex-column flex-fill ps-1">
      <div class="modal-title">
        {{ 'shift.activity.comment_on_activity' | translate }}
      </div>
      <div class="shift-name">{{ shift?.name }}</div>
    </div>
  </div>
  <div>
    <button
      (click)="refreshComments()"
      [disabled]="loading"
      nz-button
      nzShape="circle"
    >
      <i [ngClass]="{ 'loading-comments': loading }" class="fas fa-sync"></i>
    </button>
    <button
      ngbAutofocus
      type="button"
      class="btn m-0"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>
<div class="modal-body d-flex flex-column" #comments>
  <ng-container *ngIf="comments$ | async as comments">
    <ng-container *ngIf="comments.length > 0; else emptyMessages">
      <wilson-comment
        *ngFor="let comment of comments; trackBy: trackById"
        [comment]="comment"
        [userId]="userId$ | async"
      >
      </wilson-comment>
    </ng-container>
  </ng-container>
  <ng-template #emptyMessages>
    <div
      class="no-messages flex-fill d-flex flex-column align-items-center justify-content-center"
    >
      <i class="far fa-comment-dots mb-3"></i>
      <div class="no-messages-title">
        {{ 'comments.no-message.title' | translate }}
      </div>
      <div class="no-messages-info">
        {{ 'comments.no-message.info' | translate }}
      </div>
    </div>
  </ng-template>
</div>
<div class="modal-footer d-flex">
  <wilson-activity-comments-prompt
    [activityId]="activity.id"
    class="flex-fill"
    (messageSent)="refreshComments()"
  >
  </wilson-activity-comments-prompt>
</div>

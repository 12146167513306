import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-present-shifts-renderer',
  template: `
    <div class="text-dark text-truncate">
      <span *ngIf="cellValue">
        {{ cellValue }}
        {{ 'tooltip.shifts' | translate }}</span
      >
      <span *ngIf="!cellValue">---</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresentShiftsRendererComponent implements AgRendererComponent {
  public cellValue: string | null;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params?.data && !(!params?.value && params?.value !== 0)
      ? params.value + '/' + params.data.numPublishedShifts
      : null;
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  MonthlyClosureStatusEnum,
  UserPayrollMonthlyClosure,
} from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-month-closure-status-renderer',
  template: `<div class="d-flex align-items-center">
    <span
      class="fas fa-check-circle text-success"
      *ngIf="closureStatus === MonthlyClosureStatusEnum.SuccessfullyClosed"
    ></span>
    <span
      class="fas fa-times-circle text-danger"
      *ngIf="closureStatus === MonthlyClosureStatusEnum.Failed"
    ></span>
    <div class="text-dark ms-1">{{ cellValue | translate }}</div>
  </div>`,
  styles: [
    `
      span {
        font-size: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class MonthClosureStatusRendererComponent
  implements AgRendererComponent
{
  public cellValue!: string;
  public closureStatus!: MonthlyClosureStatusEnum;
  public MonthlyClosureStatusEnum = MonthlyClosureStatusEnum;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    const userPayrollMonthlyClosures: UserPayrollMonthlyClosure[] =
      params?.data?.userPayrollMonthlyClosures;
    const currentMonthClosure = userPayrollMonthlyClosures.find(
      (closure) => closure.yearAndMonth === params.value,
    );
    if (currentMonthClosure) {
      this.closureStatus = currentMonthClosure.status;
      return (
        'page.payroll.monthly_closure.status.' + currentMonthClosure.status
      );
    } else {
      return 'page.payroll.monthly_closure.status.open';
    }
  }
}

export enum ShiftStatusTranslationKey {
  Occupied = 'page.shifts.occupied',
  NotOccupied = 'page.shifts.not_occupied',
  NotReleased = 'page.shifts.not_released',
  Released = 'page.shifts.released',
}

export enum ShiftPlanStatusTranslationKey {
  Draft = 'page.shift_plan.status.draft',
  RolledOut = 'page.shift_plan.status.rolled-out',
  Reverted = 'page.shift_plan.status.reverted',
}

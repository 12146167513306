import { BillingSetting } from '@wilson/interfaces';

export class BillingSettingsInit {
  static readonly type = '[BillingSettings] - Init';
}

export class BillingSettingsUpdate {
  static readonly type = '[BillingSettings] - Update';
  constructor(public newBillingSettings: BillingSetting) {}
}

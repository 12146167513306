import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { ResolvedActivityWithReports } from '@wilson/interfaces';
import { ShiftDurationService } from '../../..';
import { PipesModule } from '@wilson/pipes';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'wilson-calculate-duration',
  templateUrl: './calculate-duration.component.html',
  styleUrls: ['./calculate-duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PipesModule],
})
export class CalculateDurationComponent implements OnChanges {
  startTime: string = null;
  endTime: string = null;
  @Input() startDate: string = null;
  @Input() activities: ResolvedActivityWithReports[] = [];
  @Input() reportedTime = false;
  @Input() disabled = false;
  @Input() shiftSeries = false;

  public duration = 0.0;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly shiftDurationService: ShiftDurationService,
  ) {}

  ngOnChanges() {
    if (this.reportedTime) {
      this.duration =
        this.shiftDurationService.calculateDurationForReportedTimes(
          this.activities,
          this.startDate,
          this.disabled,
          this.shiftSeries,
        );
    } else {
      this.duration =
        this.shiftDurationService.calculateDurationForPlannedTimes(
          this.activities,
          this.startDate,
          this.disabled,
          this.shiftSeries,
        );
    }
    this.cd.detectChanges();
  }
}

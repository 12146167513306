import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { ServiceDeviationPipesModule } from '@wilson/service-deviation/pipes';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ActivityTimeAndLocationWithDeviationComponent } from './activity-time-and-location-with-deviation.component';
import { ActivityTimeTagComponent } from '@wilson/non-domain-specific/activities-helpers/components';

@NgModule({
  declarations: [ActivityTimeAndLocationWithDeviationComponent],
  imports: [
    ActivityTimeTagComponent,
    CommonModule,
    TranslateModule,
    NzTagModule,
    FeatureFlagsModule,
    ServiceDeviationPipesModule,
  ],
  exports: [ActivityTimeAndLocationWithDeviationComponent],
})
export class ActivityTimeAndLocationWithDeviation {}

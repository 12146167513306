<div
  class="row"
  *ngIf="
    ('portal-exclude-shift-from-validation-calculation'
      | featureFlagPure
      | async) &&
    (RoleAction.Update | ablePure : RolePermissionSubject.Shift | async)
  "
>
  <div class="col-9">
    {{ 'page.shifts.exclude_from_validation_calculation' | translate }}
  </div>
  <div class="col-3 toggle-container">
    <nz-switch
      [(ngModel)]="excludeFromValidationCalculation"
      [nzCheckedChildren]="checkedTemplate"
      (ngModelChange)="updateCalculationExclusion($event)"
      class="d-flex justify-content-end"
    ></nz-switch>
  </div>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>

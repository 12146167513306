import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityViewModelShiftDetails } from '@wilson/invoicing/state';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-redirecting-shift-link-renderer',
  template: `
    <a
      *ngIf="title; else noValue"
      (click)="openInNewTabAndStopBubbling($event)"
      class="link-dark"
      >{{ title }}</a
    >

    <ng-template #noValue>{{ '---' }} </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectingShiftLinkRendererComponent
  implements ICellRendererAngularComp
{
  public title!: string;
  public propertyId: string;

  async agInit(params: ICellRendererParams) {
    this.initValues(params);
  }

  refresh(params: ICellRendererParams) {
    this.initValues(params);
    return true;
  }

  private initValues(params: ICellRendererParams) {
    const data: ActivityViewModelShiftDetails = params.data?.shiftDetails;

    this.propertyId = data?.shiftId;
    this.title = data?.shiftName;
  }

  openInNewTabAndStopBubbling(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const baseUrl = window.location.origin;
    const path = `/shifts/${this.propertyId}`;
    const url = baseUrl + path;

    window.open(url, '_blank');
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  UserTimelinesWithoutActivitiesPayload,
  UserTimelinesWithActivitiesPayload,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTimelinesGateway {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public getUserTimelinesWithActivities({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds?: string[];
  }) {
    return this.getUserTimelines<UserTimelinesWithActivitiesPayload>({
      dateRange,
      userIds,
      withActivities: true,
    });
  }

  public getUserTimelinesWithoutActivities({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds?: string[];
  }) {
    return this.getUserTimelines<UserTimelinesWithoutActivitiesPayload>({
      dateRange,
      userIds,
      withActivities: false,
    });
  }

  private getUserTimelines<T>({
    dateRange,
    userIds,
    withActivities,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds?: string[];
    withActivities: boolean;
  }): Observable<T[]> {
    const whereClause: {
      from: string;
      to: string;
      userIds?: string[];
    } = {
      from: dateRange.start.toISOString(),
      to: dateRange.end.toISOString(),
    };

    if (userIds) {
      whereClause.userIds = userIds;
    }

    const queryParams = withActivities
      ? { where: whereClause, withActivities: true }
      : {
          where: whereClause,
        };

    const queryString = stringify(queryParams, {
      arrayFormat: 'comma',
    });
    return this.http.get<T[]>(
      `${this.config.host}/v2/user-timelines?${queryString}`,
    );
  }
}

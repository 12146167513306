import {
  EnvironmentInjector,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivityValidationService } from '@wilson/activities';
import { canSharedActivityBeEdited } from '@wilson/activities/pipes';
import {
  Activity,
  ActivityCategory,
  ActivitySelectionListItem,
  ActivityTemplate,
  JobActivityTemplate,
  ResolvedActivity,
} from '@wilson/interfaces';
import { SplitActivityPopupComponent } from '@wilson/shared-components';
import { DateTimeFormat } from '@wilson/utils';
import { format } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiftFormActivitiesService {
  constructor(
    private readonly activityValidationService: ActivityValidationService,
    private formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly nzModalService: NzModalService,
    private readonly injector: EnvironmentInjector,
  ) {}

  tranformActivityToFormData(
    activities: (ResolvedActivity | Activity)[],
    canEditServiceActivities?: boolean,
  ) {
    const result: Record<string, string | object>[] = [];

    for (const activity of activities) {
      result.push({
        name: activity.name?.trim() || '',
        id: activity.id,
        job: {
          organizationalUnitId: (activity as ActivitySelectionListItem)
            ?.jobOrganizationalUnitId,
        },
        jobId: activity.jobId,
        startDatetime: this.getStartDateTime(
          activity,
          canEditServiceActivities,
        ),
        endDatetime: this.getEndDateTime(activity, canEditServiceActivities),
        activityCategoryId: activity.activityCategoryId,
        activityCategory: activity.activityCategory,
        startLocationId: activity.startLocationId,
        startLocation: activity.startLocation,
        endLocationId: activity.endLocationId,
        endLocation: activity.endLocation,
        shiftId: null,
        serviceId: activity.serviceId,
        operationalStatus: activity.operationalStatus,
        activityReports: activity.activityReports
          ? activity.activityReports
          : [],
        agreementId: activity.agreementId,
        agreement: (activity as ResolvedActivity).agreement,
        professionId: activity.professionId,
        profession: activity.profession,
      });
    }

    return result;
  }

  getStartDateTime(
    activity: Activity | ActivitySelectionListItem | ActivityTemplate,
    canEditServiceActivities: boolean,
  ) {
    const canEdit = runInInjectionContext(this.injector, () =>
      canSharedActivityBeEdited(activity),
    );
    if (canEdit) {
      return canEditServiceActivities && canEdit
        ? format(new Date(activity.startDatetime), DateTimeFormat.TimeFormat)
        : activity.startDatetime;
    }
    return activity.startDatetime;
  }

  getEndDateTime(
    activity: Activity | ActivitySelectionListItem | ActivityTemplate,
    canEditServiceActivities: boolean,
  ) {
    const canEdit = runInInjectionContext(this.injector, () =>
      canSharedActivityBeEdited(activity),
    );
    if (canEdit) {
      return canEditServiceActivities && canEdit
        ? format(new Date(activity.endDatetime), DateTimeFormat.TimeFormat)
        : activity.endDatetime;
    }
    return activity.endDatetime;
  }

  transformActivityTemplateToFormData(
    activityTemplates: ActivityTemplate[],
    canEditServiceActivities?: boolean,
  ) {
    const result: Record<string, string | object>[] = [];

    for (const activity of activityTemplates) {
      result.push({
        name: activity.name?.trim() || '',
        id: activity.id,
        startDatetime: this.getStartDateTime(
          activity,
          !!canEditServiceActivities,
        ),
        endDatetime: this.getEndDateTime(activity, !!canEditServiceActivities),
        activityCategoryId: activity.activityCategoryId,
        activityCategory: activity.activityCategory,
        startLocationId: activity.startLocationId,
        startLocation: activity.startLocation,
        endLocationId: activity.endLocationId,
        endLocation: activity.endLocation,
        shiftId: null,
        serviceId: activity.serviceSeriesId,
        agreementId: activity.agreementId,
        agreement: activity.agreement,
        professionId: activity.professionId,
        profession: activity.profession,
        jobActivityTemplates: activity.jobActivityTemplates ?? [],
      });
    }
    return result;
  }

  addActivityDataToForm(
    activityFormArray: FormArray,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activityData: any[],
    startingIndex: number,
  ) {
    for (const activity of activityData) {
      const formGroup = this.formBuilder.group(activity, {
        validators: this.activityValidationService.plannedTimesValidatorFn(),
      });
      if (formGroup.contains('jobActivityTemplates')) {
        if (activity?.jobActivityTemplates?.length) {
          formGroup.controls['jobActivityTemplates'].setValue(
            activity.jobActivityTemplates.map(
              (jobActivityTemplate: JobActivityTemplate) => ({
                jobId: jobActivityTemplate?.jobId,
                job: {
                  organizationalUnitId:
                    jobActivityTemplate?.job?.organizationalUnitId,
                },
              }),
            ),
          );
        } else {
          formGroup.controls['jobActivityTemplates'].setValue([]);
        }
      }
      activityFormArray.insert(startingIndex, formGroup);
      startingIndex++;
    }
  }

  getSelectedActivities(
    activities: (Activity | ActivityTemplate)[] | undefined,
  ): (Activity | ActivityTemplate)[] | undefined {
    return activities?.filter((activity) => !!activity.id);
  }

  public async openSplitActivityPopup(
    activity: ResolvedActivity,
    activityCategories: ActivityCategory[],
    activitiesFormArray: FormArray,
    index: number,
    canEditServiceActivities: boolean,
  ) {
    const modal = this.nzModalService.create<SplitActivityPopupComponent>({
      nzTitle: this.translate.instant('activity.split_activity'),
      nzContent: SplitActivityPopupComponent,
      nzCentered: true,
      nzWidth: '600px',
      nzFooter: null,
      nzData: {
        activity,
        activityCategories,
      },
    });
    const result = await firstValueFrom(modal.afterClose);
    if (result) {
      const existingActivity =
        activity.serviceId && !canEditServiceActivities
          ? result.existingActivity
          : {
              ...result.existingActivity,
              startDatetime: format(
                new Date(result.existingActivity.startDatetime),
                DateTimeFormat.TimeFormat,
              ),
              endDatetime: format(
                new Date(result.existingActivity.endDatetime),
                DateTimeFormat.TimeFormat,
              ),
            };
      const newActivities = result.newActivities.map((activity: Activity) => {
        if (activity.serviceId && !canEditServiceActivities) {
          return activity;
        }
        return {
          ...activity,
          startDatetime: format(
            new Date(activity.startDatetime),
            DateTimeFormat.TimeFormat,
          ),
          endDatetime: format(
            new Date(activity.endDatetime),
            DateTimeFormat.TimeFormat,
          ),
        };
      });

      activitiesFormArray.at(index).patchValue(existingActivity);
      this.addActivityDataToForm(activitiesFormArray, newActivities, index + 1);
    }
  }
}

<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="activities && activities.length; else emptyState">
    <ag-grid-angular
      class="ag-theme-wilson"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [components]="components"
      [rowSelection]="'multiple'"
      [rowMultiSelectWithClick]="true"
      [isRowSelectable]="isRowSelectable"
      [rowData]="activities"
      [columnDefs]="columnDefs"
      (gridReady)="gridService.onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)"
    >
    </ag-grid-angular>
  </ng-container>
</ng-container>
<ng-template #loading>
  <wilson-loading-spinner></wilson-loading-spinner>
</ng-template>
<ng-template #emptyState>
  <wilson-empty-state></wilson-empty-state>
</ng-template>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShiftPayrollAcceptanceStatus } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-acceptance-status-renderer',
  template: `
    <div class="text-dark">
      <div
        class="p-0 m-0 mt-1"
        *ngIf="cellValue === ShiftPayrollAcceptanceStatus.SubmittedToPayroll"
      >
        <span class="fas fa-check-circle text-primary"></span>
        {{ 'page.payroll.status.' + cellValue | translate }}
      </div>
      <div
        class="p-0 m-0 mt-1"
        *ngIf="
          cellValue === ShiftPayrollAcceptanceStatus.PartlySubmittedToPayroll
        "
      >
        <span class="fas fa-check-circle text-warning"></span>
        {{ 'page.payroll.status.' + cellValue | translate }}
      </div>
      <div
        class="p-0 m-0 mt-1"
        *ngIf="cellValue === ShiftPayrollAcceptanceStatus.WorkTimeCheckComplete"
      >
        <span class="fas fa-check-circle text-secondary"></span>
        {{ 'page.payroll.status.' + cellValue | translate }}
      </div>
      <div
        class="p-0 m-0 mt-1"
        *ngIf="cellValue === ShiftPayrollAcceptanceStatus.WorkTimeCheckOpen"
      >
        <span class="far fa-check-circle text-secondary"></span>
        {{ 'page.payroll.status.' + cellValue | translate }}
      </div>
      <div
        class="p-0 m-0 mt-1"
        *ngIf="cellValue === ShiftPayrollAcceptanceStatus.ReportingMissing"
      >
        <span class="fas fa-info-circle text-secondary"></span>
        {{ 'page.payroll.status.' + cellValue | translate }}
      </div>
    </div>
  `,
  styles: [
    `
      span {
        font-size: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptanceStatusRendererComponent implements AgRendererComponent {
  public cellValue: ShiftPayrollAcceptanceStatus;
  public ShiftPayrollAcceptanceStatus = ShiftPayrollAcceptanceStatus;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params?.data?.acceptanceStatus;
  }
}

<div class="w-100 pdf-section" [style.height.px]="heightPx">
  <pdf-viewer
    class="invoice-pdf-preview"
    *ngIf="pdfURL"
    [src]="pdfURL"
    [original-size]="false"
    [fit-to-page]="true"
    [autoresize]="true"
    [show-all]="false"
    [page]="pdfCurrentPage"
    [zoom]="pdfZoomLevel"
    (after-load-complete)="afterLoadComplete($event)"
  >
  </pdf-viewer>

  <div *ngIf="pdfURL">
    <div class="d-flex justify-content-between align-items-center px-2">
      <div class="pdf-actions d-flex justify-content-center align-items-center">
        <div class="zoom">
          <fa-icon
            class="px-2"
            [icon]="faSearchPlus"
            (click)="zoomIn()"
          ></fa-icon>
          <fa-icon
            class="px-2"
            [icon]="faSearchMinus"
            (click)="zoomOut()"
          ></fa-icon>
        </div>
        <div class="page">
          <fa-icon
            class="px-2"
            [icon]="faAngleLeft"
            [class.disable-icon]="pdfCurrentPage === 1"
            (click)="pdfCurrentPage !== 1 && previousPage()"
          ></fa-icon>
          <span class="px-2"> {{ pdfCurrentPage }} / {{ pdfTotalPage }}</span>
          <fa-icon
            class="px-2"
            [icon]="faAngleRight"
            [class.disable-icon]="pdfCurrentPage === pdfTotalPage"
            (click)="pdfCurrentPage !== pdfTotalPage && nextPage()"
          ></fa-icon>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>

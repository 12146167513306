import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ResolvedActivity } from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { format } from 'date-fns';

@Pipe({
  name: 'activityTimeCheck',
})
export class ActivityTimePipe implements PipeTransform {
  transform(
    activities: Activity[] | ResolvedActivity[],
    index: number,
    isStartLocation = true,
  ): boolean {
    if (activities[index]?.createdFrom !== WilsonApp.Mobile) {
      const currentStart =
        activities[index]?.startDatetime?.length === 5
          ? activities[index].startDatetime
          : format(new Date(activities[index].startDatetime), 'HH:mm');
      const currentEnd =
        activities[index]?.endDatetime?.length === 5
          ? activities[index].endDatetime
          : format(new Date(activities[index].endDatetime), 'HH:mm');

      if (isStartLocation) {
        if (index === 0) return false;
        let activity = null;
        for (let i = index - 1; i >= 0; i--) {
          if (activities[i].createdFrom !== WilsonApp.Mobile) {
            activity = activities[i];
            break;
          }
        }
        const previousEnd = activity
          ? activity?.endDatetime?.length === 5
            ? activity.endDatetime
            : format(new Date(activity.endDatetime), 'HH:mm')
          : null;
        return currentStart < previousEnd;
      } else {
        if (index === activities.length - 1) return false;
        let activity = null;
        for (let i = index + 1; i <= activities.length - 1; i++) {
          if (activities[i].createdFrom !== WilsonApp.Mobile) {
            activity = activities[i];
            break;
          }
        }
        const nextStart = activity
          ? activity?.startDatetime?.length === 5
            ? activity.startDatetime
            : format(new Date(activity.startDatetime), 'HH:mm')
          : null;

        return currentEnd > nextStart;
      }
    } else {
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Activity } from '@wilson/interfaces';
import { NewWilsonJobBucketState } from './new-wilson-job-bucket.state';

@Injectable({
  providedIn: 'root',
})
export class ActivityEvaluatorService {
  constructor(private readonly store: Store) {}

  public isNotAbleToShare(activity: Activity): boolean {
    return !!(
      activity?.jobId ||
      activity?.shiftId ||
      this.isItemInBucket(activity) ||
      !activity?.serviceId
    );
  }

  private isItemInBucket(activity: Activity) {
    return this.store
      .selectSnapshot(NewWilsonJobBucketState.itemIds)
      .includes(activity.id as string);
  }
}

import { OperationStatus } from '@wilson/interfaces';
import { NzPresetColor } from 'ng-zorro-antd/core/color';

export function getOperationalStatusColor(
  operationalStatus: OperationStatus,
): NzPresetColor | undefined {
  switch (operationalStatus) {
    case OperationStatus.Completed:
      return 'green';
    case OperationStatus.Ongoing:
      return 'blue';
    case OperationStatus.Paused:
      return 'geekblue';
    case OperationStatus.Parked:
      return 'geekblue';
    case OperationStatus.SkippedByUser:
      return 'volcano';
    case OperationStatus.Cancelled:
      return 'red';
    default:
      return undefined;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateJobService } from './create-job/create-job.service';
import { ExpiryDateHelperService } from './expiry-date-helper/expiry-date-helper.service';
import { DateTimeRangeHelperService } from './date-time-range-helper/date-time-range-helper.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    CreateJobService,
    ExpiryDateHelperService,
    DateTimeRangeHelperService,
  ],
})
export class ShareServicesModule {}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  signal,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserNamePipeModule } from '@wilson/account';
import { Note } from '@wilson/shift/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { DateTimeFormat } from '@wilson/utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { ShiftNotesPopupComponent } from '../../..';

@Component({
  selector: 'wilson-shift-notes',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    NzButtonModule,
    UserNamePipeModule,
  ],
  templateUrl: './shift-notes.component.html',
  styleUrl: './shift-notes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftNotesComponent implements OnChanges {
  @Input() shiftId: string | undefined;
  public faTrash = faTrash;
  public DateTimeFormat = DateTimeFormat;
  shiftNotes = signal<Note[]>([]);

  constructor(
    private readonly translate: TranslateService,
    private nzModalService: NzModalService,
    private readonly nzMessageService: NzMessageService,
    private shiftGateway: ShiftsService,
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['shiftId']) {
      await this.fetchShiftNotes();
    }
  }

  protected openNotesPopup() {
    if (this.shiftId) {
      const modalRef: NzModalRef =
        this.nzModalService.create<ShiftNotesPopupComponent>({
          nzContent: ShiftNotesPopupComponent,
          nzTitle: this.translate.instant(
            'page.shifts.add_billing_note.popup.title',
          ),
          nzClosable: true,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzCentered: true,
          nzFooter: [
            {
              label: this.translate.instant('general.cancel'),
              type: 'default',
              onClick: () => {
                modalRef.destroy();
              },
            },
            {
              label: this.translate.instant('general.save'),
              type: 'primary',
              disabled: () => {
                return !modalRef.componentInstance.form.valid;
              },
              onClick: async () => {
                try {
                  const note =
                    modalRef.componentInstance.form.value.note.trim();

                  await firstValueFrom(
                    this.shiftGateway.createShiftNote(this.shiftId, note),
                  );

                  await this.fetchShiftNotes();
                  this.nzMessageService.success(
                    this.translate.instant('general.saved_successfully'),
                  );
                  modalRef.destroy();
                } catch (error) {
                  this.nzMessageService.error(
                    this.translate.instant('general.something_went_wrong'),
                  );
                }
              },
            },
          ],
        });
    } else {
      console.error('No ShiftId found!');
    }
  }
  async fetchShiftNotes() {
    if (this.shiftId) {
      this.shiftNotes.set(
        await firstValueFrom(this.shiftGateway.fetchShiftNotes(this.shiftId)),
      );
    }
  }

  protected deleteNote(noteId: string) {
    return this.nzModalService.confirm({
      nzTitle: this.translate.instant(
        'page.shift_detail.notes.delete_note_header',
      ),
      nzContent: this.translate.instant('page.shift_detail.notes.delete_note'),
      nzOkText: this.translate.instant('general.yes'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: this.translate.instant('general.no'),
      nzOnOk: async () => {
        try {
          await this.shiftGateway.deleteShiftNotes(this.shiftId, noteId);
          await this.fetchShiftNotes();
          this.nzMessageService.success(
            this.translate.instant('general.deleted_successfully'),
          );
        } catch (error) {
          this.nzMessageService.error(
            this.translate.instant('general.something_went_wrong'),
          );
        }
      },
    });
  }
}

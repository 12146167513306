import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wilson-confirmation-dialogue',
  templateUrl: './confirmation-dialogue.component.html',
  styleUrls: ['./confirmation-dialogue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogueComponent {
  public loading = false;
  @Input() title!: string;
  @Input() message!: string;
  // eslint-disable-next-line
  @Input() content!: TemplateRef<any>;
  @Input() btnOkText!: string;
  @Input() btnCancelText!: string;
  @Input() icon = 'fas fa-retweet';
  @Input() showDangerConfirmation = false;

  constructor(private activeModal: NgbActiveModal) {}

  public decline(): void {
    this.activeModal.close(false);
  }

  public accept(): void {
    this.activeModal.close(true);
  }
}

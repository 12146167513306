import { Pipe, PipeTransform } from '@angular/core';
import { NzPresetColor } from 'ng-zorro-antd/core/color';
import * as colors from '@ant-design/colors';

@Pipe({
  name: 'antColor',
  standalone: true,
})
export class AntColorPipe implements PipeTransform {
  transform(color: Exclude<NzPresetColor, 'pink'>, derivative = 5): string {
    return colors[color][derivative];
  }
}

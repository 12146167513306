import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';

@Component({
  selector: 'wilson-via-points-tool-tip',
  templateUrl: './via-points-tool-tip.component.html',
  styleUrls: ['./via-points-tool-tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaPointsToolTipComponent {
  @Input()
  startLocation!: GeoLocation;

  @Input()
  endLocation!: GeoLocation;

  @Input()
  viaLocations!: GeoLocation[];

  @Input()
  disableTooltip!: boolean;
}

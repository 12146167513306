import { DateInterval, DateStringInterval } from '@wilson/interfaces';
import { createDateTimeWithoutSeconds } from '@wilson/non-domain-specific/overlap-helpers';

export function determineInterval(items: DateStringInterval[]): DateInterval {
  let start = items[0].startDatetime;
  let end = items[0].endDatetime;

  items.forEach((item) => {
    if (item.startDatetime < start) start = item.startDatetime;
    if (item.endDatetime > end) end = item.endDatetime;
  });

  return {
    startDatetime: createDateTimeWithoutSeconds(start),
    endDatetime: createDateTimeWithoutSeconds(end),
  };
}

import { Injectable } from '@angular/core';
import { LinkRendererParams, LinkType } from '@wilson/interfaces';
import { ICellRendererParams } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class LinkRendererService {
  populateStringAndLink(
    params: ICellRendererParams & LinkRendererParams,
  ): LinkType {
    return {
      title: params.link.title
        ? params.link.title(params.data)
        : params.valueFormatted ?? params.value,
      link: params.link.link(params.data),
      params: params.link.params ? params.link.params(params.data) : null,
      linkClass: params.link.linkClass
        ? params.link.linkClass(params.data)
        : 'link-dark',
      emptyClass: params.link.emptyClass
        ? params.link.emptyClass(params.data)
        : '',
      openInNewTab:
        params.link.openInNewTab && params.link.openInNewTab(params.data),
    };
  }
}

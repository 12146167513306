import { Injectable } from '@angular/core';
import {
  ClientProposalResponse,
  Job,
  JobOrigin,
  ProviderJobListItem,
  ProviderProposalResponse,
  RequestForProposal,
} from '@wilson/interfaces';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable()
export class ExternalJobsService {
  private clientStatusesSubject = new BehaviorSubject<ClientProposalResponse[]>(
    [],
  );
  private proiderStatusesSubject = new BehaviorSubject<
    ProviderProposalResponse[]
  >([]);
  private partnersSubject = new BehaviorSubject<string[]>([]);

  public setClientStatusFilter(status: string[]) {
    this.clientStatusesSubject.next(status as ClientProposalResponse[]);
  }

  public setProviderStatusFilter(status: string[]) {
    this.proiderStatusesSubject.next(status as ProviderProposalResponse[]);
  }

  public setPartnerFilter(partnerIds: string[]) {
    this.partnersSubject.next(partnerIds);
  }

  public createFilteredJobsStream(
    jobsStream: Observable<
      (Job & { requestsForProposals: RequestForProposal[] })[]
    >,
  ): Observable<ProviderJobListItem[]> {
    return combineLatest([
      jobsStream.pipe(map(this.transformToViewData())),
      this.clientStatusesSubject,
      this.proiderStatusesSubject,
      this.partnersSubject,
    ]).pipe(
      map(
        ([
          data,
          clientStatusFilters,
          providerStatusFilters,
          partnerFilters,
        ]) => {
          if (clientStatusFilters.length) {
            data = this.filterByClientStatuses(data, clientStatusFilters);
          }
          if (providerStatusFilters.length) {
            data = this.filterByProviderStatuses(data, providerStatusFilters);
          }
          if (partnerFilters.length) {
            data = this.filterByPartners(data, partnerFilters);
          }
          return data;
        },
      ),
    );
  }

  private transformToViewData(): (
    jobs: (Job & { requestsForProposals: RequestForProposal[] })[],
  ) => ProviderJobListItem[] {
    return (jobs: (Job & { requestsForProposals: RequestForProposal[] })[]) =>
      jobs.map((job) => {
        const providerJobListItem: ProviderJobListItem = {
          id: job.requestsForProposals[0].id as string,
          name: job.name,
          clientProposalResponse:
            job.requestsForProposals[0].clientProposalResponse,
          providerProposalResponse:
            job.requestsForProposals[0].providerProposalResponse,
          createdAtISO: job.createdAt as string,
          createdFrom: job.createdFrom as JobOrigin,
          expiresInISO: job.expiresAt || null,
          partnerName: job.organizationalUnit?.name as string,
          partnerId: job.organizationalUnit?.id as string,
        };
        return providerJobListItem;
      });
  }

  filterByClientStatuses(
    jobs: ProviderJobListItem[],
    statuses: ClientProposalResponse[],
  ) {
    return jobs.filter((job: ProviderJobListItem) =>
      statuses.includes(job.clientProposalResponse),
    );
  }

  private filterByProviderStatuses(
    jobs: ProviderJobListItem[],
    statuses: ProviderProposalResponse[],
  ) {
    return jobs.filter((job: ProviderJobListItem) =>
      statuses.includes(job.providerProposalResponse),
    );
  }

  private filterByPartners(jobs: ProviderJobListItem[], partnerIds: string[]) {
    return jobs.filter((job: ProviderJobListItem) =>
      partnerIds.includes(job.partnerId),
    );
  }
}

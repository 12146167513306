import { Pipe, PipeTransform } from '@angular/core';
import { ShiftTemplate } from '@wilson/interfaces';

@Pipe({
  name: 'filterTemplateByName',
})
export class FilterTemplateByName implements PipeTransform {
  transform(templates: ShiftTemplate[], searchText: string): ShiftTemplate[] {
    if (!templates) {
      return [];
    }
    if (!searchText) {
      return templates;
    }

    searchText = searchText.toLocaleLowerCase();

    return templates.filter((it) => {
      return it.name.toLowerCase().includes(searchText);
    });
  }
}

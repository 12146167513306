import {
  AdditionalShiftActivityStatusFilter,
  ShiftActivityStatus,
} from './publicationStatus';
import { ShiftStatusTranslationKey } from './statusTranslationKey';

export const ShiftActivityStatusTranslationMap: {
  [key in
    | ShiftActivityStatus
    | AdditionalShiftActivityStatusFilter]: ShiftStatusTranslationKey;
} = {
  [ShiftActivityStatus.Occupied]: ShiftStatusTranslationKey.Occupied,
  [ShiftActivityStatus.NotOccupied]: ShiftStatusTranslationKey.NotOccupied,
  [ShiftActivityStatus.NotReleased]: ShiftStatusTranslationKey.NotReleased,
  [AdditionalShiftActivityStatusFilter.Released]:
    ShiftStatusTranslationKey.Released,
};

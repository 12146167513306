import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClientProposalResponse } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-provider-acceptance-status-renderer',
  template: `
    <ng-container [ngSwitch]="status">
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Open">
        <span class="px-3 badge rounded-pill badge-secondary-dark">
          {{ 'wilson.share.status.open' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Accepted">
        <span class="px-3 badge rounded-pill badge-primary-dark">
          {{ 'wilson.share.status.accepted' | translate }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="ClientProposalResponse.Withdrawn">
        <span class="px-3 badge rounded-pill badge-danger">
          {{ 'wilson.share.status.withdrawn' | translate }}
        </span>
      </div>
      <div
        class="text-dark"
        *ngSwitchCase="
          status === ClientProposalResponse.Declined ||
          status === ClientProposalResponse.AutoDeclined
            ? status
            : ''
        "
      >
        <span class="px-3 badge rounded-pill badge-danger">
          {{ 'wilson.share.status.declined' | translate }}
        </span>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderAcceptanceStatusRendererComponent
  implements ICellRendererAngularComp
{
  ClientProposalResponse = ClientProposalResponse;
  status!: ClientProposalResponse;

  agInit(params: ICellRendererParams): void {
    this.status = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.status = params.value;
    return true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { differenceInDays, format } from 'date-fns';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'activityTimeOverday',
  standalone: true,
})
export class ActivityTimeOverday implements PipeTransform {
  transform(
    activities: Activity[],
    startDate: string,
    index: number,
    isStartTime = true,
    disabled = true,
    shiftSeries = false,
    canEditServiceActivities = true,
  ): number {
    const allActivities = cloneDeep(activities);
    allActivities.map((activity, i) => {
      if (activity.createdFrom === WilsonApp.Mobile) {
        activity.startDatetime =
          i !== 0 ? allActivities[i - 1].endDatetime : '';
        activity.endDatetime = i !== 0 ? allActivities[i - 1].endDatetime : '';
      }
    });
    let dayCounter = 0;
    for (let i = 0; i <= allActivities.length - 1; i++) {
      const currentActivity = allActivities[i];
      const previousActivity = allActivities[i - 1];
      let isServiceId;
      if (canEditServiceActivities) {
        isServiceId = false;
      } else {
        isServiceId = currentActivity?.serviceId;
      }

      const currentStart = this.getTime(currentActivity?.startDatetime);
      const currentEnd = this.getTime(currentActivity?.endDatetime);
      const previousEnd = this.getTime(previousActivity?.endDatetime);

      const isStartTimeOfCurrentActivity = i === index && isStartTime;
      if (isStartTimeOfCurrentActivity) {
        const isFirstActivity = index === 0;
        if (isFirstActivity) {
          dayCounter = disabled
            ? this.calculateDifferenceInDays(
                allActivities[0].startDatetime,
                startDate,
              )
            : dayCounter;
        }
        if (disabled) {
          dayCounter = this.calculateDifferenceInDays(
            currentActivity.startDatetime,
            startDate,
          );
        } else {
          if (isServiceId && !shiftSeries) {
            dayCounter = this.calculateDifferenceInDays(
              currentActivity.startDatetime,
              startDate,
            );
          } else {
            const shouldIncrement =
              currentStart && previousEnd && currentStart < previousEnd;
            dayCounter = shouldIncrement ? dayCounter + 1 : dayCounter;
          }
        }
      } else {
        if (disabled) {
          dayCounter = this.calculateDifferenceInDays(
            currentActivity.endDatetime,
            startDate,
          );
        } else {
          if (isServiceId && !shiftSeries) {
            dayCounter = this.calculateDifferenceInDays(
              currentActivity.endDatetime,
              startDate,
            );
          } else {
            const shouldIncrement =
              (currentStart && previousEnd && currentStart < previousEnd) ||
              (currentEnd && currentStart && currentEnd < currentStart);
            dayCounter = shouldIncrement ? dayCounter + 1 : dayCounter;
          }
        }
      }
      if (i === index) break;
    }
    return dayCounter;
  }

  public calculateDifferenceInDays(
    targetDate: string | null,
    startDate: string,
  ): number {
    if (targetDate) {
      return differenceInDays(
        new Date(
          format(new Date(targetDate), DateTimeFormat.DatabaseDateFormat),
        ),
        new Date(startDate),
      );
    } else {
      return 0;
    }
  }

  public convertDateToTime(date: string): string {
    return format(new Date(date), DateTimeFormat.TimeFormat);
  }

  private getTime(date: string | undefined): string | null {
    if (!date) {
      return null;
    }
    return date.length === 5
      ? date
      : format(new Date(date), DateTimeFormat.TimeFormat);
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PdfPreviewComponent } from '@wilson/non-domain-specific/pdf-helpers';

@Component({
  selector: 'wilson-file-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule, PdfPreviewComponent],
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  @Input() url!: string;
  @Input() set fileName(name: string) {
    this.isImage =
      name.endsWith('.png') || name.endsWith('.jpg') || name.endsWith('.jpeg');
  }
  public isImage = true;
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Notification,
  NotificationCategory,
  PublicationStatus,
  Shift,
} from '@wilson/interfaces';
import { NotificationsService } from '@wilson/notifications';

import { ShiftsService } from '@wilson/shifts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiftOperationsService {
  constructor(
    private readonly translate: TranslateService,
    private readonly notificationsService: NotificationsService,
    private readonly shiftsService: ShiftsService,
    private readonly message: NzMessageService,
    private readonly modalService: NzModalService,
  ) {}

  cleanUpShiftBeforeUpdating(shift): void {
    delete shift.createdAt;
    delete shift.updatedAt;
    delete shift.version;
    delete shift.activities;
    delete shift.user;
    delete shift.shiftCategory;
    delete shift.organizationalUnit;
    delete shift.shiftSeries;
    delete shift.score;
  }

  public cleanUpShiftObject(shift) {
    delete shift.createdAt;
    delete shift.ngbStartDate;
    delete shift.organizationalUnit;
    delete shift.shiftCategory;
    delete shift.updatedAt;
    delete shift.user;
    delete shift.activities;
    delete shift.recurringOn;
    delete shift.shiftSeries;
    delete shift.score;
  }

  async sendUnassignedNotification(userId: string, shift: Shift) {
    const notification: Notification = {
      title: 'notifications.titles.TITLE_SHIFT_UNASSIGN',
      content: 'notifications.messages.MSG_SHIFT_UNASSIGN',
      portalUrl: `/shifts`,
      mobileUrl: `/tabs/calendar`,
      notificationCategory: NotificationCategory.Shift,
      contentParams: {
        name: shift.name,
        startDate: shift.startDate,
      },
    };

    await this.notificationsService.createNotificationWithUserNotificationAndNativePush(
      notification,
      [userId],
    );
  }

  public async publishShift(shift: Shift) {
    try {
      await this.cleanUpAndPublishShift(shift);
    } catch {
      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
    }
  }

  async cleanUpAndPublishShift(shift: Shift) {
    this.cleanUpShiftBeforeUpdating(shift);
    const notificationContent: string =
      shift.publicationStatus === PublicationStatus.NotPublished
        ? 'notifications.messages.MSG_SHIFT_ASSIGN_SINGLE'
        : 'notifications.messages.MSG_SHIFT_CHANGES';
    shift.publicationStatus = PublicationStatus.Published;
    await firstValueFrom(
      this.shiftsService.updateShift({
        ...shift,
        id: shift.id,
      }),
    );
    const notification: Notification = {
      title: 'notifications.titles.TITLE_SHIFT_ASSIGN',
      content: notificationContent,
      portalUrl: `/shifts/${shift.id}`,
      mobileUrl: `/shift/${shift.id}`,
      notificationCategory: NotificationCategory.Shift,
      contentParams: {
        name: shift.name,
        startDate: shift.startDate,
      },
    };
    await this.notificationsService.createNotificationWithUserNotificationAndNativePush(
      notification,
      [shift.userId],
    );
  }

  confirmNavigateAway(onConfirmCallback: () => void) {
    return this.modalService.confirm({
      nzTitle: this.translate.instant('general.cancel'),
      nzContent: this.translate.instant(
        'page.shift_timeline.drawer.confirm.popup',
      ),
      nzOkText: this.translate.instant('general.yes'),
      nzCancelText: this.translate.instant('general.no'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        onConfirmCallback();
      },
    });
  }
}

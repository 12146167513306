import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivityAdjustmentGuardService } from '../../services/activity-adjustment-guard/activity-adjustment-guard.service';

@Pipe({
  name: 'isActivityAdjustable',
})
export class IsActivityAdjustablePipe implements PipeTransform {
  constructor(
    private activityAdjustmentGuardService: ActivityAdjustmentGuardService,
  ) {}

  transform(activityFormArray: FormArray, index: number): boolean {
    return this.activityAdjustmentGuardService.canBeAdjusted(
      activityFormArray?.at(index).value,
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faFileContract } from '@fortawesome/pro-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTemplatesGateway } from '@wilson/api/gateway';
import { Agreement } from '@wilson/clients/interfaces';
import {
  ActivityTemplate,
  FeatureName,
  ResolvedActivity,
} from '@wilson/interfaces';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ActivitiesGateway } from '@wilson/api/gateway';
import { ActivityRefreshService } from '../../services/activity-refresh.service';
import {
  ChangeAgreementModalComponent,
  ChangeAgreementModalInputData,
} from '../change-agreement-modal/change-agreement-modal.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'wilson-agreement-icon',
  templateUrl: './agreement-icon.component.html',
  styleUrls: ['./agreement-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementIconComponent {
  @Input() isEditable = false;
  @Input() activity: ResolvedActivity | ActivityTemplate;
  @Input() isSeries = false;
  @Input() isDisabled = false;
  @Output() agreementChanged = new EventEmitter<
    ResolvedActivity | ActivityTemplate
  >();

  public faFileContract = faFileContract;
  FeatureName = FeatureName;

  constructor(
    private readonly activityRefreshService: ActivityRefreshService,
    private readonly nzModalService: NzModalService,
    private readonly activitiesService: ActivitiesGateway,
    private readonly activityTemplatesGateway: ActivityTemplatesGateway,
    private readonly translate: TranslateService,
    private readonly toastMessage: NzMessageService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  async openChangeAgreementModal() {
    const modalRef: NzModalRef = this.nzModalService.create<
      ChangeAgreementModalComponent,
      ChangeAgreementModalInputData
    >({
      nzTitle: this.translate.instant('agreement.change_modal.title'),
      nzContent: ChangeAgreementModalComponent,
      nzClosable: true,
      nzData: {
        agreement: this.activity.agreement,
        description: 'agreement.change_modal.description',
      },
      nzFooter: [
        {
          label: this.translate.instant('general.cancel'),
          type: 'default',
          onClick: () => {
            modalRef.destroy();
          },
        },
        {
          label: this.translate.instant('general.save'),
          type: 'primary',
          onClick: async (modalInstance: ChangeAgreementModalComponent) => {
            try {
              const result: Agreement | null = modalInstance.agreement;
              if (this.isSeries) {
                await this.saveAgreementToActivityTemplate(result);
              } else {
                await this.saveAgreementToActivity(result);
              }

              this.activity = cloneDeep({
                ...this.activity,
                agreement: result ?? undefined,
              });
              this.agreementChanged.emit(this.activity);

              this.toastMessage.success(
                this.translate.instant('popup.save.text'),
                {
                  nzDuration: 3000,
                },
              );
              modalRef.destroy();
              this.activityRefreshService.triggerRefresh();
            } catch (error) {
              if (error instanceof HttpErrorResponse) {
                this.toastMessage.error(
                  this.translate.instant('general.error'),
                  {
                    nzDuration: 3000,
                  },
                );
              }
            }
          },
        },
      ],
    });
  }

  async saveAgreementToActivity(agreement: Agreement | null) {
    await this.activitiesService.updateActivity({
      id: this.activity.id,
      agreementId: agreement ? agreement.id : null,
    });
  }

  async saveAgreementToActivityTemplate(agreement: Agreement | null) {
    await this.activityTemplatesGateway.updateActivityTemplate({
      id: this.activity.id,
      agreementId: agreement ? agreement.id : null,
    });
  }
}

import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { cloneDeep } from 'lodash';
import { SetSetting } from './operations-board-settings.action';

export interface OperationsBoardSetting {
  autoUpdate: boolean;
}
interface OperationsBoardSettingStateModel {
  settings: {
    [userId in string]: OperationsBoardSetting;
  };
}

const defaultOperationsState: OperationsBoardSettingStateModel = {
  settings: {},
};

@State<OperationsBoardSettingStateModel>({
  name: 'operationsBoardSetting',
  defaults: defaultOperationsState,
})
@Injectable()
export class OperationsBoardSettingState {
  static getSetting(userId: string) {
    return createSelector(
      [OperationsBoardSettingState],
      (state: OperationsBoardSettingStateModel) => {
        return state.settings[userId];
      },
    );
  }

  @Action(SetSetting)
  SetSetting(
    ctx: StateContext<OperationsBoardSettingStateModel>,
    { userId, setting }: SetSetting,
  ) {
    const state = ctx.getState();
    const settingsToUpdate = cloneDeep(state.settings);
    settingsToUpdate[userId] = setting;
    ctx.patchState({
      settings: settingsToUpdate,
    });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  GeoLocation,
  LinkRendererParams,
  LinkType,
  RouteQualification,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-qualification-link-renderer',
  styles: [
    `
      .display {
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        align-items: center;
        margin: 1rem 0;
        max-width: 190px;
      }
    `,
  ],
  template: `
    <div class="display">
      <wilson-link-renderer-content
        [linkData]="linkData"
        class="me-3"
      ></wilson-link-renderer-content>
      <ng-container
        *ngIf="routeViaLocations && routeEndLocation && routeStartLocation"
      >
        <wilson-via-points-tool-tip
          *ngIf="routeViaLocations?.length"
          [startLocation]="routeStartLocation"
          [endLocation]="routeEndLocation"
          [viaLocations]="routeViaLocations"
        ></wilson-via-points-tool-tip>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationLinkRendererComponent
  implements ICellRendererAngularComp
{
  public routeStartLocation: GeoLocation;
  public routeEndLocation: GeoLocation;
  public routeViaLocations: GeoLocation[];
  public linkData: LinkType;

  agInit(params: ICellRendererParams & LinkRendererParams): void {
    this.setData(params);
  }

  refresh(params: ICellRendererParams & LinkRendererParams) {
    this.setData(params);
    return true;
  }

  private setData(params: ICellRendererParams & LinkRendererParams): void {
    if (params.data.routeId) {
      const routeQualification = params.data as RouteQualification;

      this.routeViaLocations = routeQualification.route.viaLocations;
      this.routeEndLocation = routeQualification.route.endLocation;
      this.routeStartLocation = routeQualification.route.startLocation;
    }

    this.linkData = {
      link: `/employees/${params.data.userId}/qualifications/${params.data.id}`,
      params: {
        category: params.data.categoryRouteParams,
      },
      title:
        this.routeStartLocation && this.routeEndLocation
          ? `${this.routeStartLocation.name} (${this.routeStartLocation.locationCode}) - ${this.routeEndLocation.name} (${this.routeEndLocation.locationCode})`
          : `${params.value}`,
      linkClass: 'link-dark',
    };
  }
}

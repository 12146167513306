import { Activity, ResolvedActivity } from '../activities/activity';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import {
  ServiceActivityStatus,
  ServiceOperationalStatus,
} from './serviceActivityStatus';
import { Label } from '../labels/labels';
import { StrictBase } from '@wilson/base';

export interface Service extends StrictBase {
  name: string;
  startDate: string;
  organizationalUnitId: OrganizationalUnit['id'];
  comment?: string;
  trainNumber: string | null;
  activities?: Activity[];
  agreementId?: string;
  labels?: Label[];
}

export interface ResolvedService extends Service {
  organizationalUnit: OrganizationalUnit;
  activities: ResolvedActivity[];
  serviceSeries?: NewResolvedServiceSeries;
  activityStatus?: ServiceActivityStatus;
  operationalStatus?: ServiceOperationalStatus;
  labels?: Label[];
}
export interface NewResolvedServiceSeries {
  serviceSeriesId?: string;
}

export const PREPARED_SERVICE = 'preparedService';
export const SELECTED_ACTIVITIES = 'selectedActivities';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ServiceSeries } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class RailMLParserGateway {
  protected readonly path = 'railml-parser';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getServiceFromRailML(railML: string) {
    return this.http.post<ServiceSeries[]>(
      `${this.config.host}/${this.path}`,
      railML,
      {
        headers: {
          'Content-Type': 'application/xml',
        },
      },
    );
  }
}

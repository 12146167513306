import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OperationStatus, PublicationStatus, Shift } from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';

@Component({
  selector: 'wilson-shift-status-tags',
  templateUrl: './shift-status-tags.component.html',
  styleUrls: ['./shift-status-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftStatusTagsComponent {
  @Input() shift!: Shift | null;
  public WilsonApp = WilsonApp;
  public PublicationStatus = PublicationStatus;
  public OperationStatus = OperationStatus;
}

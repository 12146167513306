import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { DateTimeFormat } from '@wilson/utils';
import { differenceInMinutes, format } from 'date-fns';

export function SplitActivityTimeDurationValidator(
  sourceEndDate: string,
): ValidatorFn {
  return (form): ValidationErrors | null => {
    const breakTimeControl: AbstractControl | null = (form as FormGroup)
      .controls['breakTime'];

    if (breakTimeControl?.value) {
      const date: string = format(
        breakTimeControl.parent?.value.date,
        DateTimeFormat.DatabaseDateFormat,
      );
      const time: string = format(
        breakTimeControl.parent?.value.time,
        DateTimeFormat.TimeFormat,
      );
      const newActivityDateTime = new Date(`${date} ${time}`);
      const allowedDiffInMinutes = differenceInMinutes(
        new Date(sourceEndDate),
        newActivityDateTime,
      );
      const todayStartDateTime = new Date(
        `${format(
          breakTimeControl.value,
          DateTimeFormat.DatabaseDateFormat,
        )} 00:00`,
      );
      const selectedDiffInMinutes = differenceInMinutes(
        breakTimeControl.value,
        todayStartDateTime,
      );

      if (selectedDiffInMinutes <= allowedDiffInMinutes) {
        breakTimeControl.setErrors(null);
        return null;
      } else {
        breakTimeControl.setErrors({
          isInvalid: true,
        });
        return {
          isInvalid: true,
        };
      }
    } else if (!breakTimeControl) {
      return null;
    } else if (!breakTimeControl.value) {
      breakTimeControl.setErrors({
        isInvalid: true,
      });
      return {
        isInvalid: true,
      };
    } else {
      return null;
    }
  };
}

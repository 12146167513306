import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CountriesService } from '@wilson/countries';
import { PipesModule } from '@wilson/pipes';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BankDetailsForm } from '@wilson/interfaces';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'wilson-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    PipesModule,
    NzButtonModule,
    FontAwesomeModule,
  ],
  providers: [CountriesService],
  standalone: true,
})
export class BankDetailsComponent {
  @Input() bankFormArray!: FormArray<FormGroup<BankDetailsForm>>;
  @Output() addMoreBankDetail = new EventEmitter();
  @Output() addBankDetail = new EventEmitter();
  @Output() deleteDetails = new EventEmitter();

  faTrash = faTrash;
  faPlus = faPlus;

  isFieldInvalid(controlName: string, bankDetail: FormGroup): boolean {
    const control = bankDetail.controls[controlName];
    return (control?.dirty || control?.touched) && control?.invalid;
  }

  onBankDetailsChange(bankDetails: FormGroup<BankDetailsForm>) {
    this.addBankDetail.emit(bankDetails.value);
  }

  addMoreBankDetails() {
    this.addMoreBankDetail.emit();
  }

  deleteBankDetails(index: number, bankDetails: FormGroup<BankDetailsForm>) {
    if (bankDetails.value.id) {
      this.deleteDetails.emit(bankDetails.value.id);
    } else {
      this.bankFormArray.removeAt(index);
    }
  }

  setAsDefault(bankDetails: FormGroup<BankDetailsForm>) {
    this.bankFormArray.controls.forEach((control) => {
      if (control.value.id !== bankDetails.value.id) {
        control.patchValue({ isDefault: false });
        this.addBankDetail.emit(control.value);
      }
    });

    bankDetails.patchValue({ isDefault: true });
    this.addBankDetail.emit(bankDetails.value);
  }
}

import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  ShiftTimelineFilterUnassignedRegionStateModel,
  ShiftTimelineUnassignedRegionStateModel,
  UnassignedShiftStackByDay,
} from '../interfaces';
import { ShiftTimelineUnassignedRegionState } from '../shift-timeline-unassigned-region/shift-timeline-unassigned-region.state';
import {
  SetUnassignedServiceIdsFilter,
  SetUnassignedServiceSearchesFilter,
  SetUnassignedShiftIdsFilter,
  SetUnassignedShiftSearchesFilter,
} from './shift-timeline-filter-unassigned-region.actions';
import { Activity } from '@wilson/interfaces';
import {
  determineUnassignmentsOverlaps,
  groupActivitiesByServiceAndJob,
} from '@wilson/non-domain-specific/overlap-helpers';
import { isDebugEnabled } from '@wilson/non-domain-specific/logger/logger';

const defaults: ShiftTimelineFilterUnassignedRegionStateModel = {
  unassignedShiftSearches: [],
  unassignedShiftIds: [],
  unassignedServiceSearches: [],
  unassignedServiceIds: [],
};

@State<ShiftTimelineFilterUnassignedRegionStateModel>({
  name: 'shiftTimelineFilterUnassignedRegion',
  defaults,
})
@Injectable()
export class ShiftTimelineFilterUnassignedRegionState {
  static filteredUnassignedActivities() {
    return createSelector(
      [
        ShiftTimelineUnassignedRegionState.unassignedActivities,
        ShiftTimelineFilterUnassignedRegionState,
      ],
      (
        unassignedActivities: ShiftTimelineUnassignedRegionStateModel['unassignedActivities'],
        filterRegionState: ShiftTimelineFilterUnassignedRegionStateModel,
      ) => {
        const { unassignedServiceIds, unassignedServiceSearches } =
          filterRegionState;

        const filteredActivities = this.filterUnassignedActivities(
          unassignedActivities,
          unassignedServiceIds,
          unassignedServiceSearches,
        );

        //TODO: can be done on worker level
        return determineUnassignmentsOverlaps({
          ...groupActivitiesByServiceAndJob(filteredActivities),
          isDebugEnabled: isDebugEnabled(),
        });
      },
    );
  }

  static filteredUnassignedShiftsStacksWithoutActivities() {
    return createSelector(
      [
        ShiftTimelineUnassignedRegionState.unassignedShiftStacksInView,
        ShiftTimelineFilterUnassignedRegionState,
      ],
      (
        unassignedShiftsWithoutActivities: UnassignedShiftStackByDay[],
        filterRegionState: ShiftTimelineFilterUnassignedRegionStateModel,
      ) => {
        const { unassignedShiftIds, unassignedShiftSearches } =
          filterRegionState;

        return this.filterUnassignedShiftStacks(
          unassignedShiftsWithoutActivities,
          unassignedShiftIds,
          unassignedShiftSearches,
        );
      },
    );
  }

  static filteredUnassignedShiftsWithoutActivities() {
    return createSelector(
      [
        ShiftTimelineUnassignedRegionState.unassignedShiftsInView,
        ShiftTimelineFilterUnassignedRegionState,
      ],
      (
        unassignedShiftsWithoutActivities: ShiftTimelineUnassignedRegionStateModel['unassignedShiftsWithoutActivities'],
        filterRegionState: ShiftTimelineFilterUnassignedRegionStateModel,
      ) => {
        const { unassignedShiftIds, unassignedShiftSearches } =
          filterRegionState;

        return this.filterUnassignedShift(
          Object.values(unassignedShiftsWithoutActivities),
          unassignedShiftIds,
          unassignedShiftSearches,
        );
      },
    );
  }

  @Action(SetUnassignedShiftIdsFilter)
  setUnassignedShiftIdsFilter(
    ctx: StateContext<ShiftTimelineFilterUnassignedRegionStateModel>,
    action: SetUnassignedShiftIdsFilter,
  ) {
    ctx.setState(
      patch<ShiftTimelineFilterUnassignedRegionStateModel>({
        unassignedShiftIds: action.ids,
      }),
    );
  }

  @Action(SetUnassignedShiftSearchesFilter)
  setFilters(
    ctx: StateContext<ShiftTimelineFilterUnassignedRegionStateModel>,
    action: SetUnassignedShiftSearchesFilter,
  ) {
    ctx.setState(
      patch<ShiftTimelineFilterUnassignedRegionStateModel>({
        unassignedShiftSearches: action.searchStrings,
      }),
    );
  }

  @Action(SetUnassignedServiceIdsFilter)
  setUnassignedServiceIdsFilter(
    ctx: StateContext<ShiftTimelineFilterUnassignedRegionStateModel>,
    action: SetUnassignedServiceIdsFilter,
  ) {
    ctx.setState(
      patch<ShiftTimelineFilterUnassignedRegionStateModel>({
        unassignedServiceIds: action.ids,
      }),
    );
  }

  @Action(SetUnassignedServiceSearchesFilter)
  setActivityFilters(
    ctx: StateContext<ShiftTimelineFilterUnassignedRegionStateModel>,
    action: SetUnassignedServiceSearchesFilter,
  ) {
    ctx.setState(
      patch({
        unassignedServiceSearches: action.searchStrings,
      }),
    );
  }

  static filterUnassignedActivities(
    activities: Activity[],
    unassignedServiceIds: string[],
    unassignedServiceSearches: string[],
  ) {
    let filteredActivities: Activity[] =
      !unassignedServiceIds.length && !unassignedServiceSearches.length
        ? activities
        : [];

    if (unassignedServiceIds.length) {
      filteredActivities = activities.filter(
        (activity) =>
          activity.service &&
          unassignedServiceIds.includes(activity.service.id),
      );
    }

    if (unassignedServiceSearches.length) {
      const remainingActivities = activities.filter(
        (activity) =>
          activity.service &&
          !unassignedServiceIds.includes(activity.service.id),
      );
      const filteredBySearch = remainingActivities.filter((activity) => {
        return unassignedServiceSearches.some((searchString) =>
          activity.service?.name
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()),
        );
      });
      filteredActivities = [...filteredActivities, ...filteredBySearch];
    }

    return filteredActivities;
  }

  static filterUnassignedShiftStacks(
    unassignedShiftStacks: UnassignedShiftStackByDay[],
    unassignedShiftIds: string[],
    unassignedShiftSearches: string[],
  ) {
    const isNoFilterAtAll =
      !unassignedShiftIds.length && !unassignedShiftSearches.length;
    const isOnlyShiftIdsFilter =
      unassignedShiftIds.length && !unassignedShiftSearches.length;
    const isOnlyShiftSearchesFilter =
      !unassignedShiftIds.length && unassignedShiftSearches.length;
    const areBothFilters =
      unassignedShiftIds.length && unassignedShiftSearches.length;

    const clonedStack = () => {
      return unassignedShiftStacks.map((stack) => {
        return {
          day: stack.day,
          shifts: { ...stack.shifts },
        };
      });
    };

    const lowerCasedNamesFunc = () =>
      unassignedShiftSearches.map((name) => name.toLocaleLowerCase());

    if (isNoFilterAtAll) {
      return unassignedShiftStacks;
    } else if (isOnlyShiftIdsFilter) {
      return clonedStack().map((stack) => {
        Object.entries(stack.shifts).forEach(([shiftId]) => {
          if (
            unassignedShiftIds.length &&
            !unassignedShiftIds.includes(shiftId)
          ) {
            delete stack.shifts[shiftId];
          }
        });

        return stack;
      });
    } else if (isOnlyShiftSearchesFilter) {
      const clonsedUnassignedShiftStacks = clonedStack();
      const lowerCasedNames = lowerCasedNamesFunc();

      return clonsedUnassignedShiftStacks.map((stack) => {
        Object.entries(stack.shifts).forEach(([shiftId, shift]) => {
          const isMatchingName = lowerCasedNames.some(
            (lowerCasedSearchString) =>
              shift.name.toLocaleLowerCase().includes(lowerCasedSearchString),
          );

          if (!isMatchingName) {
            delete stack.shifts[shiftId];
          }
        });

        return stack;
      });
    } else if (areBothFilters) {
      const clonsedUnassignedShiftStacks = clonedStack();
      const lowerCasedNames = lowerCasedNamesFunc();

      return clonsedUnassignedShiftStacks.map((stack) => {
        Object.entries(stack.shifts).forEach(([shiftId, shift]) => {
          const isMatchingId = unassignedShiftIds.includes(shiftId);
          const isMatchingName = lowerCasedNames.some(
            (lowerCasedSearchString) =>
              shift.name.toLocaleLowerCase().includes(lowerCasedSearchString),
          );
          if (!isMatchingId && !isMatchingName) {
            delete stack.shifts[shiftId];
          }
        });

        return stack;
      });
    } else {
      return unassignedShiftStacks;
    }
  }

  static filterUnassignedShift<T>(
    shifts: (T & { id: string; name: string })[],
    unassignedShiftIds: string[],
    unassignedShiftSearches: string[],
  ) {
    let filteredShifts: (T & { id: string; name: string })[] =
      !unassignedShiftIds.length && !unassignedShiftSearches.length
        ? shifts
        : [];

    if (unassignedShiftIds.length) {
      filteredShifts = this.filterByIds(shifts, unassignedShiftIds);
    }

    if (unassignedShiftSearches.length) {
      const remainingShifts = shifts.filter(
        (shift) => !unassignedShiftIds.includes(shift.id),
      );

      filteredShifts = [
        ...filteredShifts,
        ...this.filterBySearches(remainingShifts, unassignedShiftSearches),
      ];
    }

    return filteredShifts;
  }

  static filterByIds<T>(shifts: (T & { id: string })[], ids: string[]) {
    return shifts.filter((shift) => ids.includes(shift.id));
  }

  static filterBySearches<T>(
    shifts: (T & { name: string })[],
    searches: string[],
  ) {
    return shifts.filter((shift) =>
      searches.some((searchString) =>
        shift.name
          .toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase()),
      ),
    );
  }
}

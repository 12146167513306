import { Injectable } from '@angular/core';
import {
  findEarliestDepartureBasedOnDeviationTime,
  findLatestArrivalBasedOnDeviationTime,
} from '@wilson/activities/util';
import {
  ActivityTemplate,
  GeoLocation,
  OrganizationalUnitWithSubUnit,
  Service,
} from '@wilson/interfaces';
import { ValueGetterParams } from 'ag-grid-community';
import { format } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class ValueGetterService {
  public startLocationValueGetter(
    params: ValueGetterParams,
  ): GeoLocation | undefined {
    return params.data.activityTemplates?.[0]?.startLocation;
  }

  public endLocationValueGetter(params: ValueGetterParams) {
    return params?.data?.activityTemplates?.[
      params?.data?.activityTemplates?.length - 1
    ]?.endLocation;
  }

  public serviceSeriesFromValueGetter(params: ValueGetterParams) {
    if (params?.data?.activityTemplates?.length) {
      return (
        params?.data?.activityTemplates?.[0]?.startLocation?.locationCode +
        ' - ' +
        params?.data?.activityTemplates?.[0]?.startLocation?.name
      );
    } else {
      return '---';
    }
  }

  public serviceSeriesToValueGetter(params: ValueGetterParams) {
    if (params?.data?.activityTemplates?.length) {
      const activityTemplates = params.data.activityTemplates;
      return (
        activityTemplates?.[activityTemplates.length - 1].endLocation
          .locationCode +
        ' - ' +
        activityTemplates?.[activityTemplates.length - 1].endLocation.name
      );
    } else {
      return '---';
    }
  }

  public serviceSeriesValidityValueGetter(params: ValueGetterParams) {
    return params.data
      ? format(new Date(params.data.validFrom), 'dd.MM.yy') +
          ' - ' +
          format(new Date(params.data.validTill), 'dd.MM.yy')
      : '---';
  }

  public nameLocationValueGetter(
    params: ValueGetterParams,
  ): string | undefined {
    return params?.data?.activityTemplates?.[0]?.name;
  }

  public validityLocationValueGetter(
    params: ValueGetterParams,
  ): string | undefined {
    return params?.data?.activityTemplates?.[0]?.createdAt;
  }

  public modifyLocationValueGetter(params: ValueGetterParams) {
    return params?.data?.activityTemplates?.[0]?.updatedAt;
  }
  public fromvalueGetter(params: ValueGetterParams) {
    return params?.data?.activityTemplates?.[0]?.startLocation?.locationCode;
  }
  //no Need
  public shiftNameLocationValueGetter(params: ValueGetterParams) {
    return params?.data?.name;
  }
  public startValueGetter(params: ValueGetterParams) {
    if (!params?.data?.activities) return '---';
    return (
      params?.data?.activities?.[0]?.startLocation?.locationCode +
      ' - ' +
      params?.data?.activities?.[0]?.startLocation?.name
    );
  }

  public serviceFromValueGetter(params: ValueGetterParams) {
    return params?.data?.activities?.[0]?.startLocation;
  }
  public serviceToValueGetter(params: ValueGetterParams) {
    return params?.data?.activities?.[params?.data?.activities?.length - 1]
      ?.endLocation;
  }
  public serviceStartValueGetter(params: ValueGetterParams) {
    return params?.data?.activities?.[0]?.startDatetime;
  }
  public serviceEndValueGetter(params: ValueGetterParams) {
    const activities = params?.data?.activities;
    return activities?.[activities.length - 1]?.endDatetime;
  }
  public shiftNameValueGetter(params: ValueGetterParams) {
    return params?.data?.name;
  }

  public shiftFromValueGetter(params: ValueGetterParams) {
    if (params?.data?.activityTemplates) {
      params.data.activityTemplates.sort(
        (a: ActivityTemplate, b: ActivityTemplate) =>
          a.startDatetime.localeCompare(b.startDatetime),
      );
      return params?.data?.activityTemplates?.[0]?.startLocation;
    }
    return params?.data?.activities?.[0]?.startLocation;
  }

  public shiftToValueGetter(params: ValueGetterParams) {
    if (params?.data?.activityTemplates) {
      params.data.activityTemplates.sort(
        (a: ActivityTemplate, b: ActivityTemplate) =>
          a.startDatetime.localeCompare(b.startDatetime),
      );
      return params?.data?.activityTemplates?.[
        params?.data?.activityTemplates?.length - 1
      ]?.endLocation;
    }
    return params?.data?.activities?.[params?.data?.activities?.length - 1]
      ?.endLocation;
  }

  public qulificationNameValueGetter(params: ValueGetterParams) {
    if (!params?.data?.user) return '---';
    return params?.data?.user?.firstName + ' ' + params?.data?.user?.lastName;
  }

  public orgunitNameValueGetter(params: ValueGetterParams) {
    return params?.data?.name;
  }

  public accountNameValueGetter(params: ValueGetterParams) {
    return params?.data?.name;
  }

  public shiftStartTimeGetter(params: ValueGetterParams) {
    return params?.data?.activities?.[0]?.startDatetime;
  }

  public shiftEndTimeGetter(params: ValueGetterParams) {
    const activities = params?.data?.activities;
    if (!activities) return '---';
    return activities?.[activities?.length - 1]?.endDatetime;
  }

  public orgSubUnitNameValueGetter(params: ValueGetterParams) {
    const orgUnit = params.data as OrganizationalUnitWithSubUnit;
    if (orgUnit.subUnits && orgUnit.subUnits.length) {
      let result = '';
      orgUnit.subUnits.sort((a, b) => a.name.localeCompare(b.name));
      if (orgUnit.subUnits.length == 1) {
        result = orgUnit.subUnits[0]?.name;
      } else if (orgUnit.subUnits.length > 5) {
        for (let i = 0; i < 5; i++) {
          result =
            i == 0
              ? result + orgUnit.subUnits[i].name
              : result + ', ' + orgUnit.subUnits[i].name;
        }
        result = result + `, ...(+${orgUnit.subUnits.length - 5})`;
      } else {
        for (let i = 0; i < orgUnit.subUnits.length; i++) {
          result =
            i == 0
              ? result + orgUnit.subUnits[i].name
              : result + ', ' + orgUnit.subUnits[i].name;
        }
      }
      return result;
    }
    return null;
  }

  public overtimeStatusValueGetter(params: ValueGetterParams) {
    return params.data.overtimes[0]?.overtimeCompensationInMinutes;
  }

  public isServiceDelayValueGetter(params: ValueGetterParams<Service>) {
    if (params.data?.activities) {
      const activities = params.data.activities;
      const earliesDeparturetDeviation =
        findEarliestDepartureBasedOnDeviationTime(
          activities,
        )?.departureDeviation;
      const latestArrivalDeviation =
        findLatestArrivalBasedOnDeviationTime(activities)?.arrivalDeviation;
      return (
        (earliesDeparturetDeviation &&
          earliesDeparturetDeviation.deviationInMinutes > 0) ||
        (latestArrivalDeviation &&
          latestArrivalDeviation.deviationInMinutes > 0)
      );
    } else {
      return false;
    }
  }
}

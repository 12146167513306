import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { GeoLocation } from '@wilson/interfaces';

@Component({
  selector: 'wilson-update-location-popover',
  templateUrl: './update-location-popover.component.html',
  styleUrls: ['./update-location-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateLocationPopoverComponent {
  @Input() activityFormArray!: FormArray;
  @Input() currentIndex!: number;
  @Input() canEditServiceActivity!: boolean;

  @Output()
  changeEndLocationOfFirstActivity = new EventEmitter<GeoLocation>();
  @Output()
  changeStartLocationOfSecondActivity = new EventEmitter<GeoLocation>();

  changeStartLocationOfNextActivity() {
    this.changeStartLocationOfSecondActivity.emit();
  }

  changeEndLocationOfNextActivity() {
    this.changeEndLocationOfFirstActivity.emit();
  }

  get firstActivityEndLocation() {
    return this.activityFormArray?.at(this.currentIndex)?.get('endLocation')
      ?.value.name;
  }

  get firstActivityLocationCode() {
    return this.activityFormArray?.at(this.currentIndex)?.get('endLocation')
      ?.value.locationCode;
  }

  get nextActivityStartLocation() {
    return this.activityFormArray
      ?.at(this.currentIndex + 1)
      ?.get('startLocation')?.value.name;
  }

  get nextActivityLocationCode() {
    return this.activityFormArray
      ?.at(this.currentIndex + 1)
      ?.get('startLocation')?.value.locationCode;
  }
}

import { Base } from '@wilson/base';
import { User } from '../accounts/User';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';

export enum WebhookAuthType {
  Token = 'token',
}

export enum WebhookHttpMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete',
}

export enum WebhookEvent {
  ShiftStarted = 'shift.started',
  ShiftSubmitted = 'shift.submitted',
  ShiftEnded = 'shift.ended',
  ShiftAccept = 'shift.accept',
  UserCreate = 'user.create',
  ShiftNotStarted = 'shift.not-started',
  ShiftNotFinished = 'shift.not-finished',
}

export interface Webhook extends Base {
  httpMethod: WebhookHttpMethod;
  url: string;
  name: string;
  authType: WebhookAuthType;
  auth: string;
  events: WebhookEvent[];
  createdBy?: User;
  createdById: User['id'];
  organizationalUnit?: OrganizationalUnit;
  organizationalUnitId: OrganizationalUnit['id'];
}

<div class="header py-3 d-flex align-items-center justify-content-center px-5">
  <img class="img-fluid" src="assets/img/nav/wilson-white-log.svg" />
</div>

<div class="row mt-4 justify-content-center">
  <div class="col-xl-6 col-md-9 col">
    <p class="text-center text-light font-weight-normal">Impressum</p>

    <div class="d-flex justify-content-center mt-5">
      <img src="assets/img/m79-logo.png" />
    </div>

    <div class="mt-2 text-center">
      <span class="d-block font-weight-bold">Menlo79 GmbH</span>
      <span class="d-block">Wilhelmine Gemberg Weg 6</span>
      <span class="d-block">10179 Berlin</span>
    </div>

    <div class="mt-4 text-center">
      <span class="d-block font-weight-bold">Kontakt</span>
      <span class="d-block text-primary">contact&#64;menlo79.com</span>

      <span class="d-block font-weight-bold mt-3"
        >Vertretungsberechtigte Geschäftsführer</span
      >
      <span class="d-block">Fabian Stöffler</span>

      <span class="d-block font-weight-bold mt-3">Registereintrag</span>
      <span class="d-block">Eingetragen im Handelsregister</span>
      <span class="d-block">Registergericht: Amtsgericht Charlottenburg</span>

      <span class="d-block mt-3">Registernummer: HRB 200053 B</span>
      <span class="d-block">Steuernummer: 37/440/50299</span>
      <span class="d-block">USt-IdNr.: DE320512420</span>

      <span class="d-block font-weight-bold text-primary mt-3"
        >www.menlo79.com</span
      >
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OperativeReportsGateway } from '@wilson/api/gateway';
import {
  OperativeReportsCategorySettings,
  OperativeReportsStateModel,
} from '@wilson/operative-reports/interfaces';
import { Observable, tap } from 'rxjs';
import {
  InitializeOperativeReportsState,
  OpenOperativeReportIssueDrawer,
  SelectedOperativeReportIssue,
  UpdateOperativeReportIssue,
} from './operative-reports.actions';

export const OPERATIVE_REPORTS_STATE_NAME = 'OperativeReports';
const defaultValue: OperativeReportsCategorySettings[] = [];

@State<OperativeReportsStateModel>({
  name: OPERATIVE_REPORTS_STATE_NAME,
  defaults: { issues: defaultValue, selectedIssue: null, open: false },
})
@Injectable()
export class OperativeReportsState {
  constructor(
    private readonly operativeReportsGateway: OperativeReportsGateway,
  ) {}

  @Selector()
  static issues(state: OperativeReportsStateModel) {
    return state.issues;
  }

  @Selector()
  static open(state: OperativeReportsStateModel) {
    return state.open;
  }

  @Selector()
  static selectedIssue(state: OperativeReportsStateModel) {
    return state.selectedIssue;
  }

  @Action(InitializeOperativeReportsState)
  InitializeOperativeReportsState(
    ctx: StateContext<OperativeReportsStateModel>,
  ): Observable<OperativeReportsCategorySettings[]> {
    return this.operativeReportsGateway
      .getOperativeReportsCategoriesSettings()
      .pipe(
        tap((operativeReportsStateModel) => {
          const operativeReports = this.attachStaticSettings(
            operativeReportsStateModel,
          );
          ctx.setState({
            issues: operativeReports,
            selectedIssue: ctx.getState().selectedIssue,
          });
        }),
      );
  }

  @Action(SelectedOperativeReportIssue)
  SelectedOperativeReportIssue(
    ctx: StateContext<OperativeReportsStateModel>,
    action: SelectedOperativeReportIssue,
  ) {
    ctx.patchState({
      selectedIssue: action.issues,
    });
  }

  @Action(UpdateOperativeReportIssue)
  UpdateOperativeReportIssue(
    ctx: StateContext<OperativeReportsStateModel>,
    action: UpdateOperativeReportIssue,
  ) {
    const state = ctx.getState();
    const issueIndex = state.issues.findIndex(
      (issue) =>
        issue.operativeReportCategoryId ===
        action.issue.operativeReportCategoryId,
    );

    if (issueIndex !== -1) {
      const updatedIssues = [...state.issues];
      updatedIssues[issueIndex] = action.issue;

      ctx.setState({
        ...state,
        issues: updatedIssues,
      });
    }
  }

  @Action(OpenOperativeReportIssueDrawer)
  OpenOperativeReportIssueDrawer(
    ctx: StateContext<OperativeReportsStateModel>,
    action: OpenOperativeReportIssueDrawer,
  ) {
    ctx.patchState({
      open: action.open,
    });
  }

  private attachStaticSettings(
    operativeReportsStateModel: OperativeReportsCategorySettings[],
  ) {
    const staticSettings = this.createStaticSettings([
      'qualifications',
      'not_released_shift',
      'work_time_rules',
      'maximum_shift_time_exceeded',
      'unassigned_shift',
      'unassigned_service_activity',
    ]);

    const mergedStaticSettings = [
      ...operativeReportsStateModel,
      ...staticSettings,
    ];

    let sortedSettings: OperativeReportsCategorySettings[] = [];
    for (const setting of mergedStaticSettings) {
      if (setting.operativeReportCategory.canEdit) {
        sortedSettings = [setting, ...sortedSettings];
      } else {
        sortedSettings = [...sortedSettings, setting];
      }
    }

    return sortedSettings;
  }

  createStaticSettings(
    staticSettings: string[],
  ): OperativeReportsCategorySettings[] {
    return staticSettings.map((setting) => {
      return {
        operativeReportCategoryId: setting,
        isEnabled: true,
        settings: {},
        organizationalUnitId: '',
        operativeReportCategory: {
          id: '',
          name: setting,
          priorityDefault: '',
          canEdit: false,
        },
      };
    });
  }
}

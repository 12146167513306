import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, startWith, mergeMap } from 'rxjs';

@Injectable()
export class TitleManagerService {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {}

  init() {
    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );

    // Change page title on navigation or language change, based on route data
    combineLatest([
      // inducing the first value since combineLatest does not start until all observables have data
      this.translateService.onLangChange.pipe(
        startWith({
          lang: this.translateService.currentLang,
          translations: null,
        }),
      ),
      onNavigationEnd,
    ])
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        const title = event['breadcrumb'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }
}

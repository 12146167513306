import { Activity } from '../activities/activity';

export enum JobActivityType {
  NotShared = 'not-shared',
  SharedToPartner = 'shared-to-partner',
  SharedWithUs = 'shared-with-us',
}

export interface ActivitySelectionListItem extends Activity {
  jobName: string;
  jobOrganizationalUnitId: string;
  jobOriginOrgUnit: string;
  requestForProposalId: string;
  jobActivityType?: JobActivityType;
}

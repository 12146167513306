import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { Activity } from '@wilson/interfaces';

@Pipe({
  name: 'activityIsSharedByClient',
  standalone: true,
})
export class ActivityIsSharedByClientPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(activity: Activity) {
    const accessibleOrgUnits = this.store.selectSnapshot(
      AuthState.accessibleOrgUnits,
    );
    const isShared = !!activity.jobId;
    return (
      isShared &&
      !accessibleOrgUnits?.find(
        (orgUnit) => orgUnit.id === activity.job?.organizationalUnitId,
      )
    );
  }
}

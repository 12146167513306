import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'navigateToService',
})
export class NavigateToServicePipe implements PipeTransform {
  transform(id: string, isShiftSeries = false): string {
    if (isShiftSeries) {
      return '/service-series/' + id;
    } else {
      return '/services/' + id;
    }
  }
}

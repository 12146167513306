import { Pipe, PipeTransform } from '@angular/core';
import {
  PublicationStatus,
  ShiftDispositionAssignable,
} from '@wilson/interfaces';

@Pipe({
  name: 'notPublishedShifts',
})
export class NotPublishedShiftsPipe implements PipeTransform {
  transform(
    oneOrMoreShiftAssignables:
      | ShiftDispositionAssignable[]
      | ShiftDispositionAssignable,
  ) {
    const shiftAssignables = Array.isArray(oneOrMoreShiftAssignables)
      ? oneOrMoreShiftAssignables
      : [oneOrMoreShiftAssignables];

    return shiftAssignables.filter(
      (shift) =>
        shift?.record['publicationStatus'] === PublicationStatus.NotPublished ||
        shift?.record['publicationStatus'] ===
          PublicationStatus.NotPublishedAgain,
    );
  }
}

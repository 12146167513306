import { Injectable } from '@angular/core';
import {
  AccurateDateIntervalWithAbsence,
  AccurateDateIntervalWithShift,
  AccurateDateIntervalWithStay,
  DetermineAssignmentsOverlapWorkerWrapper,
  UserTimelines,
} from '@wilson/interfaces';
import { isDebugEnabled } from '@wilson/non-domain-specific/logger/logger';
import { determineAssignmentsOverlaps } from '@wilson/non-domain-specific/overlap-helpers';
import { InputData } from './determine-assignments-overlap.worker';

@Injectable()
export class DetermineAssignmentsOverlapService
  implements DetermineAssignmentsOverlapWorkerWrapper
{
  execute(
    inputData: Pick<
      UserTimelines,
      | 'shiftsWithoutActivitiesDictionary'
      | 'absencesDictionary'
      | 'staysDictionary'
      | 'id'
    >[],
  ) {
    return new Promise<
      {
        userId: string;
        rows: {
          shifts: AccurateDateIntervalWithShift[];
          absences: AccurateDateIntervalWithAbsence[];
          stays: AccurateDateIntervalWithStay[];
        }[];
        resultTimestamp: number;
      }[]
    >((resolve) => {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(
          new URL('./determine-assignments-overlap.worker', import.meta.url),
        );
        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        const input: InputData = {
          userTimeline: inputData,
          isDebugEnabled: isDebugEnabled(),
        };

        worker.postMessage(input);
      } else {
        const response = inputData.map(
          ({
            id,
            shiftsWithoutActivitiesDictionary,
            absencesDictionary,
            staysDictionary,
          }) => {
            const absences = Object.values(absencesDictionary);
            const shifts = Object.values(shiftsWithoutActivitiesDictionary);
            const stays = Object.values(staysDictionary);
            return {
              userId: id as string,
              rows: determineAssignmentsOverlaps({
                shifts,
                absences,
                stays,
                isDebugEnabled: isDebugEnabled(),
              }),
              resultTimestamp: new Date().getTime(),
            };
          },
        );

        resolve(response);
      }
    });
  }
}

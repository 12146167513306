import { Injectable } from '@angular/core';
import { ServiceSeriesGateway, ServicesGateway } from '@wilson/api/gateway';
import { Activity, ActivityTemplate, ServiceSeries } from '@wilson/interfaces';
import { Bucket } from '@wilson/share/interfaces';
import { differenceInMilliseconds } from 'date-fns';
import { forkJoin, map, Observable } from 'rxjs';

interface NotFound {
  not_found: string;
}

@Injectable()
export class CreateJobService {
  constructor(
    private readonly servicesGateway: ServicesGateway,
    private readonly serviceSeriesGateway: ServiceSeriesGateway,
  ) {}

  public fetchActivitiesFromServices(activityBucket: Bucket[]): Observable<{
    activityItems: Activity[];
    notFoundParentIds: string[];
  }> {
    return forkJoin(
      activityBucket.map((item) =>
        this.servicesGateway
          .getServices({
            where: {
              id: item.id,
            },
            relations: [
              'activities',
              'activities.activityCategory',
              'activities.startLocation',
              'activities.endLocation',
              'activities.profession',
            ],
            order: {},
            limit: 0,
            offset: 0,
          })
          .pipe(
            map((service) => {
              if (service.length > 0) {
                let actualItems = service[0].activities;
                actualItems = actualItems.filter((activity) =>
                  item.itemIds.includes(activity.id as string),
                );

                actualItems.map((activity) => (activity.service = service[0]));
                return actualItems;
              } else {
                return { not_found: item.id };
              }
            }),
          ),
      ),
    ).pipe(map(this.sortItemsFromNotFound.bind(this)));
  }

  fetchActivityTemplatesFromServiceSeries(
    serviceSeriesIds: string[],
    activityTemplateIds: string[],
  ): Observable<{
    templatesSortedByStartDate: ActivityTemplate[];
    notFoundParentIds: string[];
  }> {
    return this.serviceSeriesGateway
      .getResolvedServiceSeriesByIds(serviceSeriesIds)
      .pipe(
        map((series: ServiceSeries[]) => {
          const notFoundServiceSeries = serviceSeriesIds.filter(
            (serviceSeriesId: string) =>
              series.findIndex((serie) => serie.id === serviceSeriesId) === -1,
          );

          const sortedSeries =
            this.sortServiceSeriesAndActivityTemplates(series);

          return {
            templatesSortedByStartDate: this.matchSelectedTemplates(
              sortedSeries,
              activityTemplateIds,
            ),
            notFoundParentIds: notFoundServiceSeries,
          };
        }),
      );
  }

  private matchSelectedTemplates(
    sortedSeries: ServiceSeries[],
    selectedIds: string[],
  ): ActivityTemplate[] {
    const sortedTemplates: ActivityTemplate[] = [];

    sortedSeries.forEach((serie) => {
      serie.activityTemplates?.forEach((template) => {
        if (selectedIds.includes(template.id as string)) {
          // Reverse map to the parent serie for later use
          template.serviceSeries = serie;

          sortedTemplates.push(template);
        }
      });
    });

    return sortedTemplates;
  }

  // sorting in place
  private sortServiceSeriesAndActivityTemplates(series: ServiceSeries[]) {
    const sortedSeriesByValidFromDate = series.sort((seriesA, seriesB) =>
      differenceInMilliseconds(
        new Date(seriesA.validFrom),
        new Date(seriesB.validFrom),
      ),
    );

    sortedSeriesByValidFromDate.forEach((serie: ServiceSeries) => {
      // Sorting templates within series by start date time
      serie.activityTemplates?.sort((templateA, templateB) =>
        differenceInMilliseconds(
          new Date(templateA.startDatetime),
          new Date(templateB.startDatetime),
        ),
      );
    });

    return series;
  }

  private sortItemsFromNotFound(
    items: (
      | Activity[]
      | ActivityTemplate[]
      | undefined
      | {
          not_found: string;
        }
    )[],
  ) {
    const result: {
      activityItems: Activity[];
      notFoundParentIds: string[];
    } = {
      activityItems: [],
      notFoundParentIds: [],
    };

    items.forEach(
      (item: NotFound | Activity[] | undefined | ActivityTemplate[]) => {
        if ((item as NotFound).not_found) {
          result.notFoundParentIds.push((item as NotFound).not_found);
        } else {
          result.activityItems = result.activityItems.concat(
            item as Activity[],
          );
        }
      },
    );

    return result;
  }
}

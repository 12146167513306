import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Observable } from 'rxjs';
import { ServiceDeviation } from '@wilson/service-deviation/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ServiceDeviationGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public createServiceDeviation(
    serviceDeviation: ServiceDeviation,
  ): Observable<ServiceDeviation> {
    return this.httpClient.post<ServiceDeviation>(
      `${this.config.host}/service-deviations`,
      serviceDeviation,
    );
  }
}

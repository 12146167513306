<div class="card">
  <div class="card-body">
    <h5 class="card-title text-dark">
      <i class="text-secondary me-2" [ngClass]="icon"></i> {{ title }}
    </h5>
    <hr />
    <div
      *ngIf="message"
      class="card-text text-dark"
      [innerHTML]="message"
    ></div>
    <ng-template [ngIf]="content" [ngTemplateOutlet]="content"></ng-template>
    <hr />
    <div class="d-flex justify-content-end mt-4">
      <button
        type="button"
        *ngIf="btnCancelText"
        class="btn btn-dark p-1 me-2 fw-bold"
        (click)="decline()"
      >
        {{ btnCancelText | translate }}
      </button>
      <button
        type="button"
        [ngClass]="
          showDangerConfirmation
            ? 'btn-danger text-white fw-bold'
            : 'btn-primary fw-bold'
        "
        class="btn p-1"
        (click)="accept()"
      >
        {{ btnOkText | translate }}
      </button>
    </div>
  </div>
</div>

import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { ShiftTemplateService } from '@wilson/shift-templates';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ShiftTemplateNameValidator {
  static createValidator(
    shiftTemplateService: ShiftTemplateService,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return shiftTemplateService
        .checkNameAlreadyExists(control.value)
        .pipe(
          map((result: boolean) =>
            result ? { shiftTemplateNameAlreadyExists: true } : null,
          ),
        );
    };
  }
}

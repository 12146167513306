import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ActivityTemplateTimePeriodRendererValue,
  convertWeekDayStringsInWeekdayObject,
  WeekDays,
} from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-template-time-period-renderer',
  template: `
    <div>
      <wilson-weekday-selector
        [weekDays]="cellValue"
        [disabled]="true"
      ></wilson-weekday-selector>
      <div>
        <span> {{ startDate }} - {{ endDate }}</span>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTemplateTimePeriodRendererComponent
  implements AgRendererComponent
{
  public cellValue: WeekDays;
  public startDate: string;
  public endDate: string;

  constructor(private datePipe: DatePipe) {}

  agInit(params: ICellRendererParams): void {
    this.setDisplayValues(params.value);
  }

  refresh(params: ICellRendererParams) {
    this.setDisplayValues(params.value);
    return true;
  }

  setDisplayValues(value: ActivityTemplateTimePeriodRendererValue) {
    this.startDate = this.datePipe.transform(value.startDate, 'dd.MM.yy');
    this.endDate = this.datePipe.transform(value.endDate, 'dd.MM.yy');
    this.cellValue = convertWeekDayStringsInWeekdayObject(value.recurringOn);
  }
}

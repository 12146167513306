import { Injectable } from '@angular/core';
import {
  ClientProposalResponse,
  ProviderJobListItem,
  ProviderProposalResponse,
} from '@wilson/interfaces';
import { isBefore } from 'date-fns';
import { DateTimeRangeHelperService } from '../date-time-range-helper/date-time-range-helper.service';

@Injectable()
export class ExpiryDateHelperService extends DateTimeRangeHelperService {
  set maxValidityDate(value: Date) {
    this.maxDate = value;
  }

  get maxValidityDate() {
    return this.maxDate;
  }

  set minValidityDate(value: Date) {
    this.minDate = value;
  }

  get minValidityDate() {
    return this.minDate;
  }

  public isJobExpired(job: ProviderJobListItem) {
    const clientProposalResponse = job.clientProposalResponse;
    const providerProposalResponse = job.providerProposalResponse;
    if (
      clientProposalResponse === ClientProposalResponse.Accepted &&
      providerProposalResponse === ProviderProposalResponse.Accepted
    ) {
      return false;
    } else {
      return job.expiresInISO
        ? isBefore(new Date(job.expiresInISO), new Date())
        : false;
    }
  }
}

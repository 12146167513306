<ng-template #currentProject>
  <div class="project-container">
    <div
      class="dot"
      [style.background-color]="selectedProject?.colorCode"
    ></div>
    <div class="project-name">
      {{ selectedProject | projectName }}
    </div>
  </div>
</ng-template>

<nz-select
  nzShowSearch
  [(ngModel)]="selectedProject"
  (ngModelChange)="setProject($event)"
  [nzCustomTemplate]="currentProject"
  [nzAllowClear]="true"
  [nzPlaceHolder]="'projects.select.placeholder' | translate"
>
  <nz-option
    *ngFor="let project of projects"
    [nzValue]="project"
    [nzLabel]="project.projectId"
    nzCustomContent
  >
    <div class="project-container">
      <div class="dot" [style.background-color]="project?.colorCode"></div>
      <div class="project-name">
        {{ project | projectName }}
      </div>
    </div>
  </nz-option>
</nz-select>

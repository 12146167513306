import { Shift, WithPreparedAttributes } from '@wilson/interfaces';
import { ShiftId } from '../interfaces';

export class UpdateAssignedShiftsKPIs {
  static readonly type = '[Timeline] fetch unreleased shifts';
}

export class RemoveUnreleasedShifts {
  static readonly type = '[Timeline] update unreleased shifts';
  constructor(public releasedShiftId: string) {}
}

export class FetchProductivityForAllFilteredUsers {
  static readonly type = '[Timeline] fetch users productivity';
  constructor(public userIds: string[], public showLoading: boolean) {}
}

export class UpdateProductivityOfUsers {
  static readonly type = '[Timeline] update users productivity';
  constructor(public userIds: string[]) {}
}

export class FetchProductivityOfUsersInDraftTimeline {
  static readonly type =
    '[Timeline] fetch users productivity in draft timeline';
  constructor(
    public usersAndShiftIds: {
      userId: string;
      shiftIds: ShiftId[];
    }[],
    public showLoading: boolean,
  ) {}
}

export class UpdateProductivityOfUsersInDraftTimeline {
  static readonly type =
    '[Timeline] update users productivity in draft timeline';
  constructor(
    public usersAndShiftIds: {
      userId: string;
      shiftIds: ShiftId[];
    }[],
  ) {}
}

<ng-container
  *ngIf="
    (('portal-mobile-setting-confirm-shift' | featureFlagPure | async) &&
      (canConfirmShiftSetting$ | async) &&
      payload?.confirmedAt) ||
    (('portal-mobile-settings-decline-shift' | featureFlagPure | async) &&
      (canDeclineShiftSetting$ | async) &&
      payload?.declinedAt)
  "
>
  <p class="confirmed_declined_at">{{ formattedShiftDecision }}</p>
</ng-container>

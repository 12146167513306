export class SetUnassignedShiftIdsFilter {
  static readonly type =
    '[ShiftTimelineFilterUnassignedRegion] Set Unassigned Shifts Ids Filter';
  constructor(public ids: string[]) {}
}

export class SetUnassignedShiftSearchesFilter {
  static readonly type =
    '[ShiftTimelineFilterUnassignedRegion] Set Unassigned Shifts Searches Filter';
  constructor(public searchStrings: string[]) {}
}

export class SetUnassignedServiceIdsFilter {
  static readonly type =
    '[ShiftTimelineFilterUnassignedRegion] Set Unassigned Shifts Ids Filter';
  constructor(public ids: string[]) {}
}

export class SetUnassignedServiceSearchesFilter {
  static readonly type =
    '[ShiftTimelineFilterUnassignedRegion] Set Unassigned Services Searches Filter';
  constructor(public searchStrings: string[]) {}
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ServiceShiftAssignment } from '@wilson/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AssignmentService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  async assignActivity(assignment: ServiceShiftAssignment) {
    return firstValueFrom(
      this.httpClient.post<ServiceShiftAssignment[]>(
        `${this.config.host}/shifts/activities/assign`,
        assignment,
      ),
    );
  }
}

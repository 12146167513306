import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  Activity,
  ActivityTemplate,
  ResolvedService,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivityEndRendererService } from './activity-end-renderer.service';

@Component({
  selector: 'wilson-activity-end-renderer',
  template: `
    <div>
      <div
        class="text-dark text-truncate"
        *ngIf="endDate && endHour; else noData"
      >
        <span *ngIf="service">{{ endDate }}</span>
        <span> {{ endHour }} {{ 'general.hour' | translate }} </span>
      </div>
      <ng-template #noData>---</ng-template>
      <div class="list-header lh-m">
        {{ location }}
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEndRendererComponent implements ICellRendererAngularComp {
  public endDate: string;
  public endHour: string;
  public location!: string;
  public service: ResolvedService;

  constructor(
    private readonly activityEndRendererService: ActivityEndRendererService,
  ) {}

  async agInit(params: ICellRendererParams) {
    this.setData(params.data);
    this.service = params.data.service;
  }

  refresh(params: ICellRendererParams) {
    this.setData(params.data);
    this.service = params.data.service;
    return true;
  }

  private setData(data: Activity | ActivityTemplate) {
    this.endDate = this.activityEndRendererService.getFormattedDate(data);
    this.endHour = this.activityEndRendererService.getFormattedHour(data);
    this.location = this.activityEndRendererService.getEndLocation(data);
  }
}

import { GridOptions } from 'ag-grid-community';

export const payrollGridOptionsFactory = (): GridOptions => ({
  pagination: false,
  accentedSort: true,
  animateRows: true,
  enableCellTextSelection: true,
  suppressPropertyNamesCheck: true,
  domLayout: 'normal',
});

import { Component } from '@angular/core';
import { OpUser } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-op-report-staff-renderer',
  template: ` <ng-container *ngIf="!isSystem; else systemTemplate">
      <div class="container">
        <wilson-user-avatar
          [s3Urn]="profileUrl"
          [fallbackImage]="fallbackImage"
          class="image"
        ></wilson-user-avatar>
        <span class="d-block text-dark text-info fw-normal name">
          {{ userName }}
        </span>
      </div>
    </ng-container>
    <ng-template #systemTemplate>
      <div class="container container--system">
        <span class="name_prefix--system">{{ 'general.by' | translate }}</span>
        <span class="name--system">{{
          'operativereports.sender.system.name' | translate
        }}</span>
      </div>
    </ng-template>`,
  styles: [
    `
      .container {
        display: flex;
        gap: 8px;
        padding: 0;
      }
      .container--system {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
        justify-content: center;
      }
      .image {
        height: 30px;
        width: 30px;
      }
      .name {
        line-height: 30px;
      }
      .name_prefix--system {
        font-size: 0.75rem;
        color: var(--grey-8);
      }
      .name--system {
        font-size: 0.875rem;
        color: var(--grey-8);
      }
    `,
  ],
})
export class OpReportStaffRendererComponent implements AgRendererComponent {
  userName!: string;
  profileUrl!: string;
  isSystem = false;
  public fallbackImage = 'assets/img/profile/profile-img-placeholder-dark.svg';

  agInit(params: ICellRendererParams): void {
    this.userName = this.getUserName(params.data);
  }

  private getUserName({ sender, senderUser }) {
    if (sender === OpUser.User) {
      this.profileUrl = senderUser.profileImageUrl;
      return `${senderUser.lastName} ${senderUser.firstName}`;
    } else {
      this.isSystem = true;
      return sender;
    }
  }

  refresh(params: ICellRendererParams) {
    this.userName = this.getUserName(params.data);
    return true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { IsOneActivityStartedPipe } from '@wilson/activities/pipes';
import { OperationStatus, Shift } from '@wilson/interfaces';
import { IsShiftSubmittedOrAcceptedPipe } from '../is-shift-submitted-or-accepted/is-shift-submitted-or-accepted.pipe';

@Pipe({
  name: 'canAllShiftBeModified',
  standalone: true,
})
export class CanAllShiftBeModifiedPipe implements PipeTransform {
  constructor(
    private readonly isShiftStartedPipe: IsOneActivityStartedPipe,
    private readonly isShiftSubmittedOrAcceptedPipe: IsShiftSubmittedOrAcceptedPipe,
  ) {}

  transform(shifts: Shift[]): boolean {
    return shifts.every((shift) => {
      const isShiftSubmittedOrAccepted =
        this.isShiftSubmittedOrAcceptedPipe.transform(shift.state);
      const isShiftStarted = this.isShiftStartedPipe.transform(
        shift.activities,
      );
      const isShiftCompleted =
        shift.operationalStatus === OperationStatus.Completed;

      return !(
        isShiftSubmittedOrAccepted ||
        isShiftStarted ||
        isShiftCompleted
      );
    });
  }
}

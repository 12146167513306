<div
  nz-tooltip
  [nzTooltipTitle]="tooltipContent"
  [nzTooltipColor]="'#fff'"
  nzTooltipPlacement="top"
  [nzTooltipOverlayStyle]="{ 'max-width': '25rem' }"
>
  <ng-container
    *ngIf="category === operativeReportCategory.Delay; else openReport"
  >
    <span class="d-block comment">
      {{ time }}

      {{ 'operativeReport.createNewOperativeReportModal.delay' | translate }}

      {{ comment }}
    </span>
  </ng-container>
  <ng-template #openReport>
    <span class="d-block" [ngClass]="comment ? 'comment' : 'no-comment'">
      {{ comment ? comment : ('general.no.description' | translate) }}
    </span>
  </ng-template>
</div>

<ng-template #tooltipContent>
  <div class="px-2 mb-1">
    <ng-container
      *ngIf="category === operativeReportCategory.Delay; else openReportTooltip"
    >
      <span>
        {{ time }}

        {{ 'operativeReport.createNewOperativeReportModal.delay' | translate }}

        {{ comment }}
      </span>
    </ng-container>

    <ng-template #openReportTooltip>
      <span class="d-block">
        {{ comment ? comment : ('general.no.description' | translate) }}
      </span>
    </ng-template>
  </div>
</ng-template>

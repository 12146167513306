import { Injectable } from '@angular/core';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { Observable } from 'rxjs';

@Injectable()
export class ShiftAttachmentsDrawerStateService {
  @WilsonState<boolean>(false)
  isShiftAttachmentDrawerOpened!: boolean;
  isShiftAttachmentDrawerOpened$!: Observable<boolean>;
}

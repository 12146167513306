import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { DateTimeFormat } from '@wilson/utils';
import { addMinutes, format, subMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ActivityPushPullService {
  pushActivity(
    activityFormArray: FormArray,
    index: number,
    differenceInMinute: number,
  ) {
    const startTimeControl = activityFormArray.at(index)?.get('startDatetime');
    const endTimeControl = activityFormArray.at(index)?.get('endDatetime');
    if (startTimeControl && endTimeControl) {
      const newStartDatetime = addMinutes(
        new Date(
          `${format(new Date(), DateTimeFormat.DatabaseDateFormat)} ${
            startTimeControl.value
          }`,
        ),
        differenceInMinute,
      );
      const newEndDatetime = addMinutes(
        new Date(
          `${format(new Date(), DateTimeFormat.DatabaseDateFormat)} ${
            endTimeControl.value
          }`,
        ),
        differenceInMinute,
      );
      startTimeControl.setValue(
        format(newStartDatetime, DateTimeFormat.TimeFormat),
      );
      endTimeControl.setValue(
        format(newEndDatetime, DateTimeFormat.TimeFormat),
      );
    }
  }

  pullActivity(
    activityFormArray: FormArray,
    index: number,
    differenceInMinute: number,
  ) {
    const startTimeControl = activityFormArray.at(index)?.get('startDatetime');
    const endTimeControl = activityFormArray.at(index)?.get('endDatetime');
    if (startTimeControl && endTimeControl) {
      const newStartDatetime = subMinutes(
        new Date(
          `${format(new Date(), DateTimeFormat.DatabaseDateFormat)} ${
            startTimeControl.value
          }`,
        ),
        differenceInMinute,
      );
      const newEndDatetime = subMinutes(
        new Date(
          `${format(new Date(), DateTimeFormat.DatabaseDateFormat)} ${
            endTimeControl.value
          }`,
        ),
        differenceInMinute,
      );
      startTimeControl.setValue(
        format(newStartDatetime, DateTimeFormat.TimeFormat),
      );
      endTimeControl.setValue(
        format(newEndDatetime, DateTimeFormat.TimeFormat),
      );
    }
  }
}

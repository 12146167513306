/* eslint-disable @nx/enforce-module-boundaries */
import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Activity, ActivityTemplate } from '@wilson/interfaces';
import { NewWilsonJobBucketState } from '@wilson/share/state';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'isItemInBucket',
})
export class IsItemInBucketPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(item: Activity | ActivityTemplate): Observable<boolean> {
    return this.store
      .select(NewWilsonJobBucketState.itemIds)
      .pipe(map((itemIds) => itemIds.includes(item.id as string)));
  }
}

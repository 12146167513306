import { Injectable } from '@angular/core';
import {
  AssignableTasksWithDate,
  PublicationStatus,
  ShiftDispositionAssignable,
  ShiftDispositionTask,
  ShiftsFilters,
  ShiftTemplate,
  ShiftWithActivitiesWithLocations,
  User,
  UserFilters,
} from '@wilson/interfaces';
import { format, parseISO } from 'date-fns';
import { cloneDeep } from 'lodash';
import { DateFnsConfigurationService } from 'ngx-date-fns';

@Injectable({
  providedIn: 'root',
})
export class ShiftDispositionFilterService {
  constructor(private readonly dateConfig: DateFnsConfigurationService) {}

  filterAssignableShifts(
    shiftsWithDates: AssignableTasksWithDate[],
    searchTerm: string,
    orgUnitIds: string[],
    shiftCategoryIds: string[],
  ) {
    let result = shiftsWithDates;

    if (searchTerm) {
      result = this.getItemsMatchingSearchTerm(shiftsWithDates, searchTerm);
    }

    if (orgUnitIds.length > 0) {
      result = this.getItemsMatchingOrgUnitIds(result, orgUnitIds);
    }

    if (shiftCategoryIds.length > 0) {
      result = this.getItemsMatchingShiftCategoryIds(result, shiftCategoryIds);
    }
    return result;
  }

  public filterShiftTemplatesByOrgUnitIds(
    shiftDispositionAssignable: Omit<
      ShiftDispositionAssignable,
      'date' | 'formattedDate'
    >[],
    orgUnitIds: string[],
    shiftCategoryIds: string[],
  ): Omit<ShiftDispositionAssignable, 'date' | 'formattedDate'>[] {
    let result = shiftDispositionAssignable;
    if (orgUnitIds.length > 0) {
      result = result.filter((item) =>
        orgUnitIds.includes(
          (item.record as ShiftTemplate).organizationalUnitId,
        ),
      );
    }
    if (shiftCategoryIds.length > 0) {
      result = result.filter((item) =>
        shiftCategoryIds.includes(
          (item.record as ShiftTemplate).shiftCategoryId,
        ),
      );
    }
    return result;
  }

  private getItemsMatchingSearchTerm(
    shiftsWithDates: AssignableTasksWithDate[],
    searchTerm: string,
  ) {
    const term = searchTerm.toLowerCase();

    const dayFilter =
      term.indexOf('.') !== -1 || term.indexOf('-') !== -1 ? term : undefined;
    const nameFilter = dayFilter ? undefined : term;

    const filteredShifts: AssignableTasksWithDate[] = [];
    for (const shiftsWithDate of shiftsWithDates) {
      if (
        dayFilter &&
        format(shiftsWithDate.date, 'P PPPP yyyy-MM-dd', {
          locale: this.dateConfig.locale(),
        })
          .toLowerCase()
          .indexOf(term) === -1
      ) {
        continue;
      }

      const items = nameFilter
        ? shiftsWithDate.items.filter((item) =>
            (item.record as ShiftWithActivitiesWithLocations).name
              .toLowerCase()
              ?.includes(term),
          )
        : shiftsWithDate.items;
      if (items.length > 0) {
        filteredShifts.push({ date: shiftsWithDate.date, items });
      }
    }

    return filteredShifts;
  }

  private getItemsMatchingOrgUnitIds(
    shiftsWithDates: AssignableTasksWithDate[],
    orgUnitIds: string[],
  ) {
    const filteredShifts: AssignableTasksWithDate[] = [];
    for (const shiftsWithDate of shiftsWithDates) {
      const items = shiftsWithDate.items.filter((item) =>
        orgUnitIds.includes(
          (item.record as ShiftWithActivitiesWithLocations)
            .organizationalUnitId,
        ),
      );
      if (items.length > 0) {
        filteredShifts.push({ date: shiftsWithDate.date, items });
      }
    }

    return filteredShifts;
  }

  private getItemsMatchingShiftCategoryIds(
    shiftsWithDates: AssignableTasksWithDate[],
    shiftCategoryIds: string[],
  ) {
    const filteredShifts: AssignableTasksWithDate[] = [];
    for (const shiftsWithDate of shiftsWithDates) {
      const items = shiftsWithDate.items.filter((item) =>
        shiftCategoryIds.includes(
          (item.record as ShiftWithActivitiesWithLocations).shiftCategoryId,
        ),
      );
      if (items.length > 0) {
        filteredShifts.push({ date: shiftsWithDate.date, items });
      }
    }

    return filteredShifts;
  }

  filterAssignedTasks(
    assignedTasks: ShiftDispositionTask,
    filters: ShiftsFilters,
    areAllShiftFiltersEmpty: boolean,
  ) {
    const clonedAssignedTasks = cloneDeep(assignedTasks);

    if (areAllShiftFiltersEmpty) {
      return clonedAssignedTasks;
    }

    for (const [day, daysUserAssignemts] of Object.entries(
      clonedAssignedTasks,
    )) {
      for (const [userId, itemsValue] of Object.entries(daysUserAssignemts)) {
        let items = itemsValue;
        if (filters.publicationStatus?.length) {
          items = items.filter((assignable) => {
            if (assignable.type === 'shift') {
              if (Array.isArray(filters.publicationStatus)) {
                return (
                  filters.publicationStatus.indexOf(
                    assignable.record.publicationStatus,
                  ) !== -1
                );
              }

              return (
                assignable.record.publicationStatus ===
                filters.publicationStatus
              );
            } else {
              return false;
            }
          });
        }

        if (filters.absenceCategories?.length) {
          items = items.filter((assignable) => {
            if (assignable.type === 'absence') {
              return (
                (filters.absenceCategories as string[]).indexOf(
                  assignable.record.absenceCategoryId as string,
                ) !== -1
              );
            }
            return false;
          });
        }

        if (filters.shiftCategoryIds?.length) {
          items = items.filter((category) => {
            if (category.type === 'shift') {
              return (
                (filters.shiftCategoryIds as string[]).indexOf(
                  category.record.shiftCategoryId,
                ) !== -1
              );
            }
            return false;
          });
        }

        if (filters.orgUnits?.length) {
          items = items.filter(
            (item) =>
              item.type === 'shift' &&
              (filters.orgUnits as string[]).indexOf(
                item.record.organizationalUnitId,
              ) !== -1,
          );
        }

        if (!items?.length) {
          delete daysUserAssignemts[userId];
        } else {
          daysUserAssignemts[userId] = items;
        }
      }

      if (!Object.keys(daysUserAssignemts).length) {
        delete clonedAssignedTasks[day];
      }
    }
    return clonedAssignedTasks;
  }

  filterUsers(users: User[], filters: UserFilters): User[] {
    let clonedUsers = [...users];

    const filteredUser = clonedUsers.filter((user) => {
      return user.userRoles?.some((role) => {
        return filters.userRoleIds?.includes(role.roleId as string);
      });
    });
    clonedUsers = filteredUser.length ? filteredUser : clonedUsers;

    const userIds = filters.userIds;
    if (userIds?.length) {
      clonedUsers = clonedUsers.filter((user) =>
        userIds.includes(user.id as string),
      );
    }

    if (filters.orgUnits?.length) {
      clonedUsers = clonedUsers.filter((user) =>
        filters.orgUnits?.includes(user.organizationalUnitId),
      );
    }

    const searchTerm = filters.searchTerm?.toLowerCase().replace(/\s/g, '');
    if (searchTerm?.length) {
      clonedUsers = clonedUsers.filter((user) => {
        return (
          `${user.firstName}${user.lastName}`
            .toLowerCase()
            .replace(/\s/g, '')
            .indexOf(searchTerm) !== -1
        );
      });
    }

    return clonedUsers;
  }

  sortUserByName(users: User[]): User[] {
    return users.sort((a, b) =>
      `${a.lastName} ${a.firstName}`.localeCompare(
        `${b.lastName} ${b.firstName}`,
      ),
    );
  }

  findUnreleasedShifts(
    shifts: ShiftWithActivitiesWithLocations[],
    startDate: Date,
  ): ShiftWithActivitiesWithLocations[] {
    return shifts.filter((shift) => {
      if (
        shift.publicationStatus !== PublicationStatus.NotPublished &&
        shift.publicationStatus !== PublicationStatus.NotPublishedAgain
      ) {
        return false;
      }
      // due to the difference in activities start and startDate we can get shifts that are before our first shown date, and must therefore be excluded (also timezone)
      return parseISO(shift.activities[0].startDatetime) >= startDate;
    });
  }

  findDeclinedShifts(
    shifts: ShiftWithActivitiesWithLocations[],
    startDate: Date,
  ): ShiftWithActivitiesWithLocations[] {
    return shifts.filter((shift) => {
      if (shift.declinedAt === null) {
        return false;
      }
      // due to the difference in activities start and startDate we can get shifts that are before our first shown date, and must therefore be excluded (also timezone)
      return parseISO(shift.activities[0].startDatetime) >= startDate;
    });
  }
}

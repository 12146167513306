<div>
  <div
    [ngClass]="
      (activityFormArray | isActivityAdjustable : currentIndex + 1) ||
      canEditServiceActivity
        ? 'cursor-pointer'
        : 'disable-action'
    "
    (click)="changeStartLocationOfNextActivity()"
  >
    <div class="px-3 py-1 action-list">
      <img src="assets/img/shift/copy-location-down.svg" class="me-2" />
      <span class="text-dark">
        {{ firstActivityLocationCode }}
        <span *ngIf="firstActivityLocationCode"> - </span>
        {{ firstActivityEndLocation }}
      </span>
    </div>
  </div>

  <nz-divider class="my-0"></nz-divider>

  <div
    [ngClass]="
      (activityFormArray | isActivityAdjustable : currentIndex) ||
      canEditServiceActivity
        ? 'cursor-pointer'
        : 'disable-action'
    "
    (click)="changeEndLocationOfNextActivity()"
  >
    <div class="px-3 py-1 action-list">
      <img src="assets/img/shift/copy-location-up.svg" class="me-2" />
      <span class="text-dark">
        {{ nextActivityLocationCode }}
        <span *ngIf="nextActivityLocationCode"> - </span>
        {{ nextActivityStartLocation }}
      </span>
    </div>
  </div>
</div>

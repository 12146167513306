import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  Activity,
  ActivityTemplate,
  ResolvedService,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivityStartRendererService } from './activity-start-renderer.service';

@Component({
  selector: 'wilson-activity-start-renderer',
  template: `
    <div>
      <div
        class="text-dark text-truncate"
        *ngIf="startDate && startHour; else noData"
      >
        <span *ngIf="service">{{ startDate }} </span>
        <span> {{ startHour }} {{ 'general.hour' | translate }} </span>
      </div>
      <ng-template #noData>---</ng-template>
      <div class="list-header lh-m">
        {{ location }}
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStartRendererComponent
  implements ICellRendererAngularComp
{
  public startDate: string;
  public startHour: string;
  public location!: string;
  public service: ResolvedService;

  constructor(
    private readonly activityStartRendererService: ActivityStartRendererService,
  ) {}

  async agInit(params: ICellRendererParams) {
    this.setData(params.data);
    this.service = params.data.service;
  }

  refresh(params: ICellRendererParams) {
    this.service = params.data.service;
    this.setData(params.data);
    return true;
  }

  private setData(data: Activity | ActivityTemplate) {
    this.startDate = this.activityStartRendererService.getFormattedDate(data);
    this.startHour = this.activityStartRendererService.getFormattedHour(data);
    this.location = this.activityStartRendererService.getStartLocation(data);
  }
}

<div class="row mb-2">
  <div [ngClass]="{ 'fw-bold': boldText }" class="col-9 form-title text-dark">
    {{
      'page.shift_timeline.hotel_stay.drawer.form.isOverNightStayRequired.toggle'
        | translate
    }}
  </div>
  <div class="col-3 toggle-container">
    <nz-switch
      class="d-flex justify-content-end"
      [ngModel]="currentShiftNecessaryStay"
      (ngModelChange)="updatedNecessaryStay.emit($event)"
      [nzCheckedChildren]="checkedTemplate"
    ></nz-switch>
  </div>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Address } from '@wilson/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public getAddressBySearch(address: Address): Promise<Address[]> {
    return firstValueFrom(
      this.httpClient.post<Address[]>(
        `${this.config.host}/addresses/search`,
        address,
      ),
    );
  }

  public createNewAddress(address: Address): Promise<Address[]> {
    return firstValueFrom(
      this.httpClient.post<Address[]>(`${this.config.host}/addresses`, {
        items: [address],
      }),
    );
  }
}

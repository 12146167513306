import { Base } from '@wilson/base';
import { User } from '../accounts/User';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { ShiftPlanTemplate } from './shift-plan-template';

export const NO_SHIFT_PLAN = 'none';

export enum ShiftPlanRecurringPattern {
  Static = 'static',
  Rolling = 'rolling',
}

export enum ShiftPlanStatus {
  Draft = 'draft',
  RolledOut = 'rolled-out',
  Reverted = 'reverted',
}

export interface ShiftPlanUser extends Base {
  position: number;
  shiftPlan?: ShiftPlan;
  shiftPlanId: ShiftPlan['id'];
  user?: User;
  userId: User['id'];
}

export interface ShiftPlan extends Base {
  name: string;
  validFrom: string;
  validTill: string;
  totalDays: number;
  recurringPattern: ShiftPlanRecurringPattern;
  status: ShiftPlanStatus;
  organizationalUnitId?: string;
  organizationalUnit?: OrganizationalUnit;
  shiftPlanUsers?: ShiftPlanUser[];
  shiftPlanTemplates?: ShiftPlanTemplate[];
  conflictSetting: ConflictSetting;
}

export enum ConflictSetting {
  Assign = 'assign',
  Create = 'create',
  NoCreate = 'no-create',
}

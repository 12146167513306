import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { formatDistance, isDate } from 'date-fns';
import { DateFnsConfigurationService } from 'ngx-date-fns';

@Pipe({
  name: 'formatDistanceFromToday',
})
export class FormatDistanceFromTodayPipe implements PipeTransform {
  constructor(private readonly dateConfig: DateFnsConfigurationService) {}

  transform(dateValue: Date | string): string {
    dateValue = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
    return isDate(dateValue)
      ? formatDistance(dateValue, new Date(), {
          addSuffix: true,
          locale: this.dateConfig.locale(),
        })
      : '';
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [FormatDistanceFromTodayPipe],
  exports: [FormatDistanceFromTodayPipe],
})
export class FormatDistanceFromTodayPipeModule {}

export enum QuickFilterType {
  ALL = 'ALL',
  UNASSIGNED = 'UNASSIGNED',
  SHIFT_CONTENTS = 'SHIFT_CONTENTS',
  QUALIFICATION_MISMATCH = 'QUALIFICATIONS_MISMATCH',
  INVALID_WORKING_TIME = 'INVALID_WORKING_TIME',
}

export interface OperationsTimeAdjustment {
  hoursToSubtract: number;
  hoursToAdd: number;
}

export const durationAdjustmentRecord: Record<
  number,
  OperationsTimeAdjustment
> = {
  0: { hoursToSubtract: 4, hoursToAdd: 16 },
  1: { hoursToSubtract: 8, hoursToAdd: 32 },
  2: { hoursToSubtract: 24, hoursToAdd: 72 },
};

export enum OperationsBoardShiftStatus {
  START_PENDING = 'START_PENDING',
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  NOT_FINISHED = 'NOT_FINISHED',
  FINISHED = 'FINISHED',
}

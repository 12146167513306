import { Injectable } from '@angular/core';
import { activitiesCompareFn } from '@wilson/activities/util';
import { Activity, Shift, WithPreparedAttributes } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShiftTimelineDataTransformerService {
  convertShiftToShiftWithoutActivities(
    shift: Shift &
      WithPreparedAttributes & {
        id: string;
        activities: Activity[];
      },
  ): Shift &
    WithPreparedAttributes & {
      id: string;
    } {
    const { activities, ...shiftWithoutActivities } = shift;
    // We do not want to modify the original data.
    const sortedActivities = [...activities].sort(activitiesCompareFn);
    const firstActivity = sortedActivities[0];
    const lastActivity = sortedActivities[sortedActivities.length - 1];
    return {
      ...shiftWithoutActivities,
      startDatetime: firstActivity.startDatetime,
      endDatetime: lastActivity.endDatetime,
      startLocationId: firstActivity.startLocationId as string,
      endLocationId: lastActivity.endLocationId as string,
      startLocation: {
        name: firstActivity.startLocation?.name || '',
        locationCode: firstActivity.startLocation?.locationCode || '',
      },
      endLocation: {
        name: lastActivity.endLocation?.name || '',
        locationCode: lastActivity.endLocation?.locationCode || '',
      },
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ManyEntity, StrictBase, TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  OrganizationalUnit,
  Shift,
  ShiftViolation,
  User,
} from '@wilson/interfaces';
import { Note } from '@wilson/shift/interfaces';
import { stringify } from 'qs';

export type ShiftForWorkTimes = StrictBase &
  Pick<
    Shift,
    'id' | 'name' | 'state' | 'startDate' | 'publicationStatus' | 'createdFrom'
  > & {
    deviation: number | null;
    user: Pick<User, 'firstName' | 'lastName' | 'organizationalUnitId'> & {
      id: string;
    };
    organizationalUnit: Pick<OrganizationalUnit, 'name' | 'id'>;
    dates: {
      isEnd: string;
      isStart: string;
      plannedEnd: string;
      plannedStart: string;
    };
    agreementIds: string[];
    shiftNotes: Note[];
    shiftReportComment: string;
    shiftReportViolationIds: ShiftViolation[];
    reportedStartLocationId: string | null;
    reportedEndLocationId: string | null;
  };

@Injectable({
  providedIn: 'root',
})
export class ShiftWorkTimesGateway {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getShifts(options: TypeOrmFindManyOptions) {
    const params = stringify({
      ...options,
    });

    return this.http.get<ManyEntity<ShiftForWorkTimes>>(
      `${this.config.host}/shift-work-times?${params}`,
    );
  }
}

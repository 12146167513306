import { ColumnState } from 'ag-grid-community';

export class SetColumnSetting {
  static readonly type = '[AgGridColumnSettings] Set column settings';
  constructor(
    public payload: {
      userId: string;
      gridId: string;
      columnStates: ColumnState[];
    },
  ) {}
}

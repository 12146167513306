import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ShiftTemplate } from '@wilson/interfaces';

@Component({
  selector: 'wilson-shift-template-list-item',
  templateUrl: './shift-template-list-item.component.html',
  styleUrls: ['./shift-template-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftTemplateListItemComponent {
  @Input() shiftTemplate!: ShiftTemplate;
  @Input() isSelected!: boolean;

  @Output() shiftTemplateSelected = new EventEmitter();
}

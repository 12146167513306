import { Injectable } from '@angular/core';
import {
  AbsenceCategoriesIdsMap,
  AssignmentTimeRange,
  ShiftCategoriesIdsMap,
  UserTimelinesModal,
  UserTimelinesWithActivitiesPayload,
} from '@wilson/interfaces';
import { areIntervalsOverlapping } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ShiftOverlappingService {
  static isShiftToAssignOverlappingWithNonDispoOrNonDepartureShift(
    userTimeline: UserTimelinesModal,
    assignmentTimeRange: AssignmentTimeRange | null,
  ): boolean {
    if (!assignmentTimeRange) return false;

    const shiftToAssignInterval = {
      start: new Date(assignmentTimeRange.start),
      end: new Date(assignmentTimeRange.end),
    };

    const hasOverlappingShiftOtherThanDispoShift = userTimeline.shifts.some(
      (shift) => {
        if (
          shift.shiftCategoryId === ShiftCategoriesIdsMap.Disposition ||
          shift.shiftCategoryId === ShiftCategoriesIdsMap.NonDepatureShift
        ) {
          return false;
        } else {
          return areIntervalsOverlapping(
            {
              start: new Date(shift.activities[0].startDatetime),
              end: new Date(
                shift.activities[shift.activities.length - 1].endDatetime,
              ),
            },
            shiftToAssignInterval,
          );
        }
      },
    );

    return hasOverlappingShiftOtherThanDispoShift;
  }

  static isShiftToAssignOverlappingWithNonRelevantAbsences(
    userTimeline: UserTimelinesWithActivitiesPayload,
    assignmentTimeRange: AssignmentTimeRange | null,
  ): boolean {
    if (!assignmentTimeRange) return false;

    const shiftToAssignInterval = {
      start: new Date(assignmentTimeRange.start),
      end: new Date(assignmentTimeRange.end),
    };

    const ignoredAbsencesPattern: string[] = Object.values(
      AbsenceCategoriesIdsMap,
    );
    const absencesToCheck = userTimeline.absences.filter((absence) =>
      ignoredAbsencesPattern.includes(absence.absenceCategoryId as string),
    );

    if (!absencesToCheck.length) {
      return false;
    } else {
      const hasOverlappingAbsences = absencesToCheck.some((absence) =>
        areIntervalsOverlapping(
          {
            start: new Date(absence.absentFrom),
            end: new Date(absence.absentTo),
          },
          shiftToAssignInterval,
        ),
      );

      return hasOverlappingAbsences;
    }
  }
}

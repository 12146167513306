import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-time-renderer',
  template: `
    <div>
      <span
        class="text-dark"
        nz-tooltip
        nzTooltipTitle="{{ cellValue }} {{ 'general.hour' | translate }}"
        [nzTooltipColor]="'#fff'"
      >
        {{ cellValue ? cellValue : '---' }}
        {{ cellValue ? ('general.hour' | translate) : '' }}
      </span>
    </div>
  `,
  styles: [
    `
      span {
        line-height: 5px;
      }
    `,
  ],
})
export class TimeRendererComponent implements AgRendererComponent {
  public cellValue!: string;

  async agInit(params: ICellRendererParams) {
    this.cellValue = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    return true;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ActivityNameParams,
  OperationStatus,
  ResolvedService,
  ServiceSeries,
} from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-cancelled-name-renderer',
  template: `
    <div>
      <div class="text-dark text-truncate">
        {{
          isActivityCancelled
            ? ('activity_category.' + cancelledActivityCategoryName | translate)
            : cellValue
            ? ('activity_category.' + cellValue | translate)
            : '---'
        }}
        <span *ngIf="activityName">
          {{ isActivityCancelled ? activityName : '-' + ' ' + activityName }}
        </span>
      </div>
      <div class="lh-m">
        <div
          *ngIf="service || serviceSeries || isActivityCancelled; else notLink"
        >
          <div *ngIf="!isProvider; else provider">
            <a
              *ngIf="service || isActivityCancelled"
              class="link-dark"
              [routerLink]="
                isActivityCancelled
                  ? '/services/' + serviceId
                  : '/services/' + service.id
              "
            >
              {{ isActivityCancelled ? serviceName : service.name }}
            </a>
            <a
              *ngIf="serviceSeries"
              class="link-dark"
              [routerLink]="'/service-series/' + serviceSeries.id"
            >
              {{ isActivityCancelled ? serviceName : serviceSeries.name }}
            </a>
          </div>
          <ng-template #provider>
            <div *ngIf="service">
              {{ service.name }}
            </div>
            <div *ngIf="isActivityCancelled">
              {{ serviceName }}
            </div>
            <div *ngIf="serviceSeries">
              {{ serviceSeries.name }}
            </div>
          </ng-template>
        </div>

        <ng-template #notLink>---</ng-template>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCancelledNameRendererComponent
  implements ICellRendererAngularComp
{
  public cellValue!: string;
  public activityName!: string;
  public service!: ResolvedService;
  public isProvider: boolean;
  public serviceSeries: ServiceSeries;
  public isActivityCancelled: boolean;
  public serviceName: string;
  public serviceId: string;
  public cancelledActivityCategoryName: string;

  async agInit(params: ICellRendererParams & ActivityNameParams) {
    this.cellValue = params.value;
    this.isActivityCancelled = params.data.status === OperationStatus.Cancelled;
    this.activityName = params.data.name;
    this.service = params.data.service;
    this.serviceSeries = params.data.serviceSeries;
    this.isProvider = params.isProvider && params.isProvider();
    this.cancelledActivityCategoryName = params.data.activityCategoryName;
    this.serviceId = params.data.serviceId;
    this.serviceName = params.data.serviceName;
  }

  refresh(params: ICellRendererParams & ActivityNameParams) {
    this.cellValue = params.value;
    this.activityName = params.data.name;
    this.isActivityCancelled = params.data.status === OperationStatus.Cancelled;
    this.service = params.data.service;
    this.serviceSeries = params.data.serviceSeries;
    this.isProvider = params.isProvider && params.isProvider();
    this.cancelledActivityCategoryName = params.data.activityCategoryName;
    this.serviceId = params.data.serviceId;
    this.serviceName = params.data.serviceName;
    return true;
  }
}

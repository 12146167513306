import { Injectable } from '@angular/core';
import {
  ShiftActivityStatus,
  ShiftWithActivityStatus,
} from '@wilson/interfaces';
import { RowNode } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class ShiftAssignmentStatusHelperService {
  compareUserNames(
    nodeA: RowNode<ShiftWithActivityStatus>,
    nodeB: RowNode<ShiftWithActivityStatus>,
  ): number {
    const fullNameA =
      `${nodeA.data?.user?.lastName} ${nodeA.data?.user?.firstName}`
        .trim()
        .toLowerCase();
    const fullNameB =
      `${nodeB.data?.user?.lastName} ${nodeB.data?.user?.firstName}`
        .trim()
        .toLowerCase();
    return fullNameA.localeCompare(fullNameB);
  }

  customActivityStatusComparator(
    valueA: ShiftActivityStatus,
    valueB: ShiftActivityStatus,
    nodeA: RowNode<ShiftWithActivityStatus>,
    nodeB: RowNode<ShiftWithActivityStatus>,
    isDescending: boolean,
  ): number {
    if (!isDescending) {
      this.customAscActivityStatusComparator(valueA, valueB, nodeA, nodeB);
      this.compareUserNames(nodeA, nodeB);
    } else if (isDescending) {
      return this.customAscActivityStatusComparator(
        valueA,
        valueB,
        nodeA,
        nodeB,
      );
    }
    return this.compareUserNames(nodeA, nodeB);
  }

  customAscActivityStatusComparator(
    valueA: ShiftActivityStatus,
    valueB: ShiftActivityStatus,
    nodeA: RowNode<ShiftWithActivityStatus>,
    nodeB: RowNode<ShiftWithActivityStatus>,
  ) {
    if (valueA === valueB) {
      return this.compareUserNames(nodeA, nodeB);
    }
    if (
      valueA === ShiftActivityStatus.NotReleased ||
      valueA === ShiftActivityStatus.Occupied
    ) {
      return -1;
    } else if (
      valueB === ShiftActivityStatus.NotReleased ||
      valueB === ShiftActivityStatus.Occupied
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  customDescActivityStatusComparator(
    valueA: ShiftActivityStatus,
    valueB: ShiftActivityStatus,
    nodeA: RowNode<ShiftWithActivityStatus>,
    nodeB: RowNode<ShiftWithActivityStatus>,
  ) {
    if (valueA === valueB) {
      return this.compareUserNames(nodeA, nodeB);
    }
    if (
      valueA === ShiftActivityStatus.NotReleased ||
      valueA === ShiftActivityStatus.Occupied
    ) {
      return 1;
    } else if (
      valueB === ShiftActivityStatus.NotReleased ||
      valueB === ShiftActivityStatus.Occupied
    ) {
      return -1;
    } else {
      return 0;
    }
  }
}

import {
  ControlsOf,
  FormArray,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { CalendarForm } from './calendar-form';
import { HeaderForm } from './header-form';
import { ShiftPlanTemplate } from './shift-plan-template';

export interface ShiftPlanForm<T extends FormArray<ShiftPlanTemplate>> {
  [ShiftPlanFormKey.Header]: FormGroup<ControlsOf<HeaderForm>>;
  [ShiftPlanFormKey.Calendar]: FormGroup<CalendarForm<T>>;
  [ShiftPlanFormKey.TotalDays]: FormControl<number>;
}

export enum ShiftPlanFormKey {
  Header = 'header',
  Calendar = 'calendar',
  TotalDays = 'totalDays',
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeleteResult } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Service } from '@wilson/interfaces';
import { ServiceTemplate } from '@wilson/services/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceTemplateGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  createServiceTemplate(service: ServiceTemplate) {
    return firstValueFrom(
      this.httpClient.post<
        Pick<
          ServiceTemplate,
          | 'id'
          | 'description'
          | 'colorCode'
          | 'createdAt'
          | 'createdById'
          | 'name'
          | 'organizationalUnitId'
          | 'updatedAt'
        >
      >(`${this.config.host}/service-templates`, {
        ...service,
      }),
    );
  }

  createServiceFromServiceTemplate(id: string, date: string) {
    return firstValueFrom(
      this.httpClient.post<Service>(
        `${this.config.host}/service-templates/${id}/service`,
        {
          date,
        },
      ),
    );
  }

  deleteServiceTemplate(entityId: string): Promise<DeleteResult> {
    return firstValueFrom(
      this.httpClient.delete<DeleteResult>(
        `${this.config.host}/service-templates/${entityId}`,
      ),
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { PublicationStatus, Shift } from '@wilson/interfaces';
import { isValid } from 'date-fns';
import { Observable, combineLatest, map } from 'rxjs';

@Pipe({
  name: 'isShiftConfirmedAtStatus',
  standalone: true,
})
export class IsShiftConfirmedAtStatusPipe implements PipeTransform {
  constructor(
    private readonly store: Store,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  transform(
    payload: Pick<Shift, 'userId' | 'confirmedAt' | 'publicationStatus'>,
  ): Observable<boolean> {
    return combineLatest([
      this.store
        .select(AuthState.mobileSettings)
        .pipe(map((res) => res.canConfirmShift)),
      this.featureFlagPurePipe.transform('portal-mobile-setting-confirm-shift'),
    ]).pipe(
      map(([canConfirmShiftSetting, canConfirmFeatureFlag]) => {
        return (
          canConfirmShiftSetting &&
          canConfirmFeatureFlag &&
          !!payload?.userId &&
          payload?.publicationStatus !== PublicationStatus.NotPublished &&
          payload?.publicationStatus !== PublicationStatus.NotPublishedAgain &&
          payload?.confirmedAt &&
          isValid(new Date(payload?.confirmedAt))
        );
      }),
    );
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivitySelectionListItem } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-service-link-renderer',
  template: `
    <a
      *ngIf="isLink; else noLink"
      (click)="openInNewTabAndStopBubbling($event)"
      class="link-dark"
      >{{ title }}</a
    >
    <ng-template #noLink>
      <span>{{ title }}</span>
    </ng-template>
  `,
  styleUrls: ['./activity-service-link-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityServiceLinkRendererComponent
  implements ICellRendererAngularComp
{
  public isLink = false;
  public title: string;
  public serviceId: string;

  async agInit(params: ICellRendererParams) {
    this.initValues(params);
  }

  refresh(params: ICellRendererParams) {
    this.initValues(params);
    return true;
  }

  openInNewTabAndStopBubbling(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    window.open(
      `${window.location.origin}/services/${this.serviceId}`,
      '_blank',
    );
  }

  private initValues(params: ICellRendererParams) {
    const selectionListItem = params.data as ActivitySelectionListItem;

    this.title = selectionListItem.service?.name;
    this.serviceId = selectionListItem.serviceId;
    this.isLink = !!this.serviceId && !selectionListItem.jobId;
  }
}

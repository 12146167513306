import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { Shift } from '@wilson/interfaces';
import { formatDistanceToNow } from 'date-fns';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { Subject, map, takeUntil } from 'rxjs';

type Payload = Pick<
  Shift,
  'userId' | 'confirmedAt' | 'declinedAt' | 'publicationStatus'
>;

@Component({
  selector: 'wilson-shift-confirmed-declined',
  standalone: true,
  imports: [CommonModule, FeatureFlagsModule],
  templateUrl: './shift-confirmed-declined.component.html',
  styleUrl: './shift-confirmed-declined.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftConfirmedDeclinedComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private _payload: Payload;
  public canConfirmShiftSetting$ = this.store
    .select(AuthState.mobileSettings)
    .pipe(
      takeUntil(this.destroyed$),
      map((res) => res.canConfirmShift),
    );
  public canDeclineShiftSetting$ = this.store
    .select(AuthState.mobileSettings)
    .pipe(
      takeUntil(this.destroyed$),
      map((res) => res.canDeclineShift),
    );
  public formattedShiftDecision!: string;
  public hideShiftDecision = false;
  @Input() set payload(newValue: Payload) {
    this._payload = newValue;
    this.setFormattedShiftDecision(newValue);
    this.cdr.detectChanges();
  }
  get payload() {
    return this._payload;
  }

  constructor(
    private readonly translateService: TranslateService,
    private readonly dateConfig: DateFnsConfigurationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.lang.lang)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setFormattedShiftDecision(this.payload);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setFormattedShiftDecision(newValue: Payload) {
    let partialTranslationKey!: string;
    let timestampToFormat!: string;
    if (newValue?.confirmedAt) {
      partialTranslationKey = 'confirmed_at';
      timestampToFormat = newValue.confirmedAt;
    } else if (newValue?.declinedAt) {
      partialTranslationKey = 'declined_at';
      timestampToFormat = newValue.declinedAt;
    }

    if (partialTranslationKey) {
      this.formattedShiftDecision = this.translateService.instant(
        `shift.${partialTranslationKey}.formatted_timestamp`,
        {
          formattedDistance: formatDistanceToNow(new Date(timestampToFormat), {
            includeSeconds: false,
            addSuffix: true,
            locale: this.dateConfig.locale(),
          }),
        },
      );
    } else {
      this.formattedShiftDecision = undefined;
      return;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Shift } from '@wilson/interfaces';
import { isShiftPublished } from './is-shift-published.fn';

@Pipe({
  name: 'isShiftPublished',
})
export class IsShiftPublishedPipe implements PipeTransform {
  transform(shift: Shift): boolean {
    return isShiftPublished(shift);
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GridApi } from 'ag-grid-community';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  NzFormatEmitEvent,
  NzTreeComponent,
  NzTreeModule,
  NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { SetColumnSetting } from '../state/columns.action';

@Component({
  selector: 'wilson-ag-grid-column-tree',
  standalone: true,
  imports: [CommonModule, NzTreeModule],
  templateUrl: './ag-grid-column-tree.component.html',
  styleUrl: './ag-grid-column-tree.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridColumnTreeComponent {
  protected gridApiSubject = new ReplaySubject<GridApi>(1);
  private CHECK_ALL_KEY = 'check-all';

  @Input({
    required: true,
  })
  userId!: string;

  @Input({ required: true })
  gridUniqueId!: string;

  @Input({
    required: true,
  })
  set gridApi(value: GridApi | null | undefined) {
    if (value) {
      this.gridApiSubject.next(value);
    }
  }

  constructor(
    private translateService: TranslateService,
    private store: Store,
    private nzMessage: NzMessageService,
  ) {}

  protected nodes$: Observable<NzTreeNodeOptions[]> = combineLatest([
    this.gridApiSubject,
  ]).pipe(
    map(([gridApi]) => {
      const baseNode = this.getBaseNode();
      let isAllNodeChecked = true;

      gridApi.getColumnState().forEach((colState) => {
        const column = gridApi.getColumn(colState.colId);
        const headerName = column
          ? gridApi.getDisplayNameForColumn(column, 'header')
          : false;
        if (headerName) {
          const newNode = {
            title: headerName,
            key: colState.colId,
            isLeaf: true,
            checked: !colState.hide,
          };
          isAllNodeChecked = isAllNodeChecked && newNode.checked;
          baseNode.children.push(newNode);
        }
      });

      baseNode.checked = isAllNodeChecked;

      return [baseNode];
    }),
  );

  private getBaseNode(): NzTreeNodeOptions & {
    children: NzTreeNodeOptions[];
  } {
    return {
      title: this.translateService.instant('general.select_all'),
      key: this.CHECK_ALL_KEY,
      expanded: true,
      isLeaf: false,
      checked: false,
      children: [],
    };
  }

  nzCheck(
    event: NzFormatEmitEvent,
    gridApi: GridApi | null,
    nzTreeComponent: NzTreeComponent,
  ) {
    if (event.node && gridApi) {
      if (event.node.key === this.CHECK_ALL_KEY) {
        const node = event.node;
        const columnKeys = node.origin.children?.map(({ key }) => key) || [];

        gridApi.setColumnsVisible(columnKeys, !!node.origin.checked);
      } else {
        gridApi.setColumnsVisible(
          [event.node.key],
          !!event.node.origin.checked,
        );
      }

      this.ensureMinimumSelectedOption(gridApi, nzTreeComponent);

      this.store.dispatch(
        new SetColumnSetting({
          userId: this.userId,
          gridId: this.gridUniqueId,
          columnStates: gridApi.getColumnState(),
        }),
      );
    }
  }

  private ensureMinimumSelectedOption(
    gridApi: GridApi,
    nzTreeComponent: NzTreeComponent,
  ) {
    const parentNode = nzTreeComponent.getTreeNodes();
    const isNoneChecked = parentNode[0].children.every(
      (node) => !node.isChecked,
    );

    if (isNoneChecked) {
      this.nzMessage.warning(
        this.translateService.instant('minimum.column.selected.required'),
      );

      const firstNode = parentNode[0].children[0];
      firstNode.setChecked(true);
      gridApi.setColumnsVisible([firstNode.key], true);
    }
  }
}

<div class="file-modal" *ngIf="name">
  <div class="d-flex align-items-center justify-content-between p-3">
    <h6 class="card-title text-dark bg-white">{{ name }}</h6>
    <i class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </i>
  </div>
  <div
    class="card-text text-dark text-center"
    [ngClass]="{ 'image-height': name.search('.jpg') > 0 }"
  >
    <ng-container *ngIf="name.search('.jpg') > 0; else renderPdf">
      <img
        [src]="file"
        [ngClass]="{ 'zoom-in': zoomIn, 'zoom-out': !zoomIn }"
        (click)="zoom()"
      />
    </ng-container>
    <ng-template #renderPdf>
      <pdf-viewer
        class="h-100"
        [src]="file"
        *ngIf="name.search('.pdf') > 0"
        [render-text]="true"
      >
      </pdf-viewer>
    </ng-template>
  </div>
</div>

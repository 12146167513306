import { Injectable } from '@angular/core';
import {
  Activity,
  DetermineUnassignedActivitiesOverlapWorkerWrapper,
  GroupedUnassignedUserDataV2,
} from '@wilson/interfaces';
import { isDebugEnabled } from '@wilson/non-domain-specific/logger/logger';
import {
  determineUnassignmentsOverlaps,
  groupActivitiesByServiceAndJob,
} from '@wilson/non-domain-specific/overlap-helpers';
import { DetermineUnassignmentsOverlapsWorkerInput } from './determine-unassigned-activities-overlap.worker';

@Injectable()
export class DetermineUnassignedActivitiesOverlapWebWorkerService
  implements DetermineUnassignedActivitiesOverlapWorkerWrapper
{
  execute(inputData: Activity[]) {
    return new Promise<GroupedUnassignedUserDataV2[]>((resolve) => {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(
          new URL(
            './determine-unassigned-activities-overlap.worker',
            import.meta.url,
          ),
        );
        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };
        const message: DetermineUnassignmentsOverlapsWorkerInput = {
          activities: inputData,
          isDebugEnabled: isDebugEnabled(),
        };
        worker.postMessage(message);
      } else {
        const response = determineUnassignmentsOverlaps({
          ...groupActivitiesByServiceAndJob(inputData),
          isDebugEnabled: isDebugEnabled(),
        });

        resolve(response);
      }
    });
  }
}

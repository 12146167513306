<div class="d-flex flex-column justify-content-between assign-activity-header">
  <div class="mt-0 mb-3">
    {{ description | translate }}
  </div>
  <div class="mt-0 mb-3">
    <nz-cascader
      *ngIf="agreements$ | async as agreements"
      class="w-100"
      [nzOptions]="agreements"
      [nzShowSearch]="nzSearch"
      [ngModel]="agreement?.id | selectedAgreement : agreements"
      [ngModelOptions]="{ standalone: true }"
      (nzSelectionChange)="setAgreement($event)"
    ></nz-cascader>
  </div>
</div>

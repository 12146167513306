import {
  Absence,
  AbsenceEntity,
  ActivityWithCategoryAndLocations,
  Shift,
  Stay,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { Interval } from 'date-fns';
import { ShiftId } from '../interfaces';

export class SetVisibleDateRange {
  static readonly type = '[ShiftTimelineFilter] Set Visible DateRange';
  constructor(
    public range: {
      start: Date | null;
      end: Date | null;
    },
  ) {}
}

export class TimelineSetCurrentDate {
  static readonly type = '[ShiftTimeline] Set Current Date';
  constructor(public date: string) {}
}

export class TimelineSetDate {
  static readonly type = '[ShiftTimelineFilter] Timeline Set Date';
  constructor(public date: Interval) {}
}

export class ChangeNumberOfDaysInDatepicker {
  static readonly type =
    '[ShiftTimelineFilter] Change Number Of Days In Datepicker';
  constructor(public days: number) {}
}

export class TimelineFetchUsers {
  static readonly type = '[ShiftTimeline] Timeline Fetch Users';
}

export class TimelineFetchUsersOvertimes {
  static readonly type = '[ShiftTimeline] Timeline Fetch Users Overtimes';
}

export class TimelineFetchUserValidations {
  static readonly type = '[ShiftTimeline] Timeline Set User Shift Validations';
}

export class TimelineUpdateAssignmentsV2 {
  static readonly type = '[ShiftTimeline] TimelineUpdateAssignments V2';
  constructor(
    public payload: {
      userIds: string[];
      dates: { start: Date | null; end: Date | null };
    },
  ) {}
}

export class TimelineUpdateShiftValidations {
  static readonly type =
    '[ShiftTimeline] Timeline Update Single Shift Validations';
  constructor(
    public payload: {
      userIdsOfUsersWithShifts: string[];
      dates?: { start: Date | null; end: Date | null };
      useCache?: boolean;
    },
  ) {}
}

export class ResetTimelineData {
  static readonly type = '[ShiftAssignment] Reset';
}

export class TimelineUpsertNewShiftV2 {
  static readonly type = '[ShiftAssignment] Timeline Upsert New Shift V2';
  constructor(
    public payload: {
      shift: Shift &
        WithPreparedAttributes & {
          id: string;
        };
    },
  ) {}
}

export class TimelineCreateNewShiftV2 {
  static readonly type = '[ActivityAssignment] Timeline Create New Shift V2';
  constructor(
    public shiftToCreate: Shift & WithPreparedAttributes & { id: string },
    public userId: string,
  ) {}
}

export class TimelineUpsertNewAbsence {
  static readonly type = '[ActivityAssignment] Timeline Upsert New Absence';
  constructor(public payload: { absence: AbsenceEntity & { id: string } }) {}
}

export class TimelineRemoveAbsence {
  static readonly type = '[ActivityAssignment] Timeline Remove Absence';
  constructor(public payload: { absenceId: string; userId: string }) {}
}

export class TimelineUpdateShiftV2 {
  static readonly type = '[ActivityAssignment] Timeline Update Shift V2';
  constructor(
    public shiftToUpdate: Shift &
      WithPreparedAttributes & {
        id: string;
      },
  ) {}
}

export class TimelineUpdateAbsence {
  static readonly type = '[ShiftTimeline] Timeline Update Absence';
  constructor(
    public absenceToUpdate: Absence & {
      id: string;
    },
  ) {}
}

export class TimelinePublishShifts {
  static readonly type =
    '[ShiftTimeline] Timeline Update Shift Publication Status To Published';
  constructor(public shiftIdsToUpdate: string[]) {}
}

export class TimelineRemoveShiftFromUserV2 {
  static readonly type =
    '[ActivityAssignment] Timeline Remove Shift From User V2';
  constructor(
    public shiftToRemove: {
      shiftId: string;
      userId: string;
    },
  ) {}
}

export class SetMainTimelineHeightPx {
  static readonly type = '[ShiftTimeline] Set Current Timeline Height Px';
  constructor(public payload: { heightPx: number }) {}
}

export class HighlightSelectedShifts {
  static readonly type = '[ShiftTimeline] Highlight Selected Shifts';
  constructor(public selectedShiftIds: string[]) {}
}

export class HighlightSelectedStays {
  static readonly type = '[ShiftTimeline] Highlight Selected Stays';
  constructor(public selectedStayIds: string[]) {}
}

export class HighlightSelectedServices {
  static readonly type = '[ShiftTimeline] Highlight Selected Services';
  constructor(public selectedServiceIds: string[]) {}
}

export class PinUsersOnTimeline {
  static readonly type = '[ShiftTimeline] Pin Users On Timeline';
  constructor(public userIds: string[]) {}
}

export class TimelineUpsertActivityV2 {
  static readonly type = '[ShiftAssignment] Timeline Upsert Activity V2';
  constructor(
    public activities: ActivityWithCategoryAndLocations[],
    public shiftId: string,
  ) {}
}

export class TimelineRemoveActivityFromShiftV2 {
  static readonly type =
    '[ShiftAssignment] Timeline Remove Activity From Shift V2';
  constructor(public activityId: string, public shiftId: string) {}
}

export class DeleteShiftDeterminedActivities {
  static readonly type = '[ShiftTimeline] Add Shift activities To User';
  constructor(public shiftIds: string[]) {}
}

export class TimelineLoadActivitiesForShifts {
  static readonly type =
    '[ShiftAssignment] Timeline Load Activities For Shifts';
  constructor(public payload: Set<ShiftId>) {}
}

export class ClearDeterminedShiftActivities {
  static readonly type = '[ShiftTimeline] Clear Determined Shift Activities';
}

export class ClearAssignmentsFromAllUsers {
  static readonly type = '[ShiftAssignment] Clear Assignments from all users';
}

export class TimelineUpdateUserStays {
  static readonly type = '[ShiftTimeline] TimelineUpdate User Stays';
  constructor(
    public payload: {
      stays: Stay[];
      userIds: string[];
      dates: { start: Date; end: Date };
    },
  ) {}
}

export class ClearTimelineStays {
  static readonly type = '[ShiftTimeline] Clear Determined Stays';
}

export class TimelineUpsertNewStay {
  static readonly type = '[ActivityAssignment] Timeline Upsert New Stay';
  constructor(public payload: Stay) {}
}

export class TimelineRemoveStay {
  static readonly type = '[ActivityAssignment] Timeline Remove Stay';
  constructor(public payload: { stayId: string; userId: string }) {}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@Component({
  selector: 'wilson-assign-all-alert',
  standalone: true,
  imports: [CommonModule, TranslateModule, NzAlertModule],
  templateUrl: './assign-all-alert.component.html',
  styleUrls: ['./assign-all-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignAllAlertComponent {
  @Input() isHeader = false;
}

import { Injectable } from '@angular/core';
import {
  Activity,
  PublicationStatus,
  ResolvedShiftWithActivity,
  ShiftActivityStatus,
  ShiftWithActivityStatus,
} from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShiftsDataService {
  generateShiftsWithActivityStatus(
    shifts: ResolvedShiftWithActivity[],
  ): ShiftWithActivityStatus[] {
    return shifts.map((shift) => ({
      ...shift,
      activityStatus: this.computeActivityStatus(shift),
      priority: this.getHighestActivityPriority(shift.activities),
    }));
  }

  computeActivityStatus(shift: ResolvedShiftWithActivity): ShiftActivityStatus {
    const occupied = !!shift?.userId;
    const published = shift?.publicationStatus === PublicationStatus.Published;
    return occupied
      ? published
        ? ShiftActivityStatus.Occupied
        : ShiftActivityStatus.NotReleased
      : ShiftActivityStatus.NotOccupied;
  }

  getHighestActivityPriority(activities?: Activity[]): number | null {
    if (activities && activities.length) {
      let highestPriority: number | null = null;
      for (const activity of activities) {
        if (
          activity.priority !== null &&
          (highestPriority === null || activity.priority! < highestPriority)
        ) {
          highestPriority = activity.priority ?? null;
        }
      }
      return highestPriority;
    }
    return null;
  }
}

<div>
  <p class="mb-1">
    {{
      (isSingleShift
        ? 'shift.page.assign_shift_description'
        : 'shift.page.assign_shifts_description'
      ) | translate
    }}
  </p>

  <nz-divider class="my-3"></nz-divider>
  <wilson-users-select
    [label]="('general.users' | translate) + '*'"
    [formControl]="userIdFormControl"
  ></wilson-users-select>
</div>

import { Component } from '@angular/core';
import { OperativeReportPriority } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-op-report-priority-renderer',
  template: `
    <ng-container [ngSwitch]="priority">
      <div class="text-dark" *ngSwitchCase="OperativeReportPriority.Low">
        <span class="px-3 badge rounded-pill badge-info">
          {{
            'operativeReport.operativeReportCategory.priority.low' | translate
          }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="OperativeReportPriority.Medium">
        <span class="px-3 badge rounded-pill badge-warning">
          {{
            'operativeReport.operativeReportCategory.priority.medium'
              | translate
          }}
        </span>
      </div>
      <div class="text-dark" *ngSwitchCase="OperativeReportPriority.High">
        <span class="px-3 badge rounded-pill badge-danger">
          {{
            'operativeReport.operativeReportCategory.priority.high' | translate
          }}
        </span>
      </div>
    </ng-container>
  `,
  styles: [],
})
export class OpReportPriorityRendererComponent implements AgRendererComponent {
  priority!: string;
  OperativeReportPriority = OperativeReportPriority;

  agInit(params: ICellRendererParams): void {
    this.priority = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.priority = params.value;
    return true;
  }
}
